import * as rx from 'rxjs'
import * as rxops from 'rxjs/operators'
import { v4 as uuidv4 } from 'uuid';
import { IcePolicy, RTCIceServerWithHostname } from './Domain';
import { withoutTransportWideCongestionControl } from './SdpUtils'
import KaldunTicketSubject, { CanRecvVideo, CanSendVideo } from './TicketSubject';
import { filterServersByPolicy } from '../Utils';

interface RoomSessionConfiguration {
  roomId: string,
  iceServers: Array<RTCIceServerWithHostname>,
  ticketSubject: KaldunTicketSubject,
  icePolicy: IcePolicy,
  enableScreenShare: boolean,
}


class RoomSession {
  private readonly roomId: string;
  private readonly peerConnectionId: string = uuidv4();
  private readonly pc: RTCPeerConnection
  private readonly outgoingAudioTransceiver: RTCRtpTransceiver
  private readonly outgoingCameraTransceiver: RTCRtpTransceiver | null = null
  private readonly outgoingScreenAudioTransceiver: RTCRtpTransceiver | null = null
  private readonly outgoingScreenVideoTransceiver: RTCRtpTransceiver | null = null;

  private readonly signalingStateSub: rx.Subscription;
  private readonly iceCandidateSub: rx.Subscription;
  private readonly connectionStateSub: rx.Subscription;
  private readonly iceErrorSub: rx.Subscription;
  private readonly iceGatheringSub: rx.Subscription;
  private readonly negotiationNeededSub: rx.Subscription;
  private readonly trackAddedSub: rx.Subscription;
  private readonly trackAddedObservable: rx.Observable<{mid: string, track: MediaStreamTrack, stream: MediaStream}>
  private readonly iceRestartRequiredSub: rx.Subscription
  private readonly iceRestartRequiredObservable: rx.Observable<void>

  constructor(configuration: RoomSessionConfiguration) {

    const iceServers =  filterServersByPolicy({
      servers: configuration.iceServers,
      icePolicy: configuration.icePolicy
    });

    this.roomId = configuration.roomId;
    this.pc = new RTCPeerConnection({
      bundlePolicy: "max-bundle",
      rtcpMuxPolicy: "require",
      iceServers: iceServers,
      iceTransportPolicy: configuration.icePolicy === "all" ? "all" : "relay",
    })

    this.signalingStateSub = rx.fromEvent(this.pc, 'signalingstatechange').subscribe(_ => {
      console.log(`[room = ${this.roomId}]: onSignalingStateChange: signalingState=`, this.pc.signalingState)
    })

    this.connectionStateSub = rx.fromEvent(this.pc, 'connectionstatechange').subscribe(_ => {
      console.log(`[room = ${this.roomId}]: onConnectionStateChange: connectionState=`, this.pc.connectionState)
    })

    this.iceCandidateSub = rx.fromEvent<RTCPeerConnectionIceEvent>(this.pc, 'icecandidate').subscribe(ev => {
      if (ev.candidate) {
        console.log(`[room = ${this.roomId}]: onIceCandidate: candidate=`, ev.candidate)
      }
    })

    const iceRestartRequiredObservable = rx.connectable(rx.fromEvent(this.pc, 'iceconnectionstatechange').pipe(
      rxops.tap(ev => {
        let pc = ev.target as RTCPeerConnection;
        console.log(`[room = ${this.roomId}]: onIceConnectionStateChange: iceConnectionState=`, pc.iceConnectionState)
      }),
      rxops.switchMap(ev => {
        let pc = ev.target as RTCPeerConnection;
        switch (pc.iceConnectionState) {
          case 'new':
          case 'checking':
          case 'connected':
          case 'completed':
          case 'closed':
            return rx.EMPTY
          case 'disconnected':
            return rx.of(void 0).pipe(rx.delay(5000))
          case 'failed':
            return rx.of(void 0)
        }
      })
    ))
    this.iceRestartRequiredSub = iceRestartRequiredObservable.connect();
    this.iceRestartRequiredObservable = iceRestartRequiredObservable;

    this.iceErrorSub = rx.fromEvent<RTCPeerConnectionIceErrorEvent>(this.pc, 'icecandidateerror').subscribe(ev => {
      console.log(`[room = ${this.roomId}]: onIceCandidateError: code=`, ev.errorCode, `, text=`, ev.errorText)
    })

    this.iceGatheringSub = rx.fromEvent(this.pc, 'icegatheringstatechange').subscribe(ev => {
      console.log(`[room = ${this.roomId}]: onIceGatheringStateChange: iceGatheringState=`, this.pc.iceGatheringState)
    })

    this.negotiationNeededSub = rx.fromEvent(this.pc, 'negotiationneeded').subscribe(ev => {
      console.log(`[room = ${this.roomId}]: onNegotiationNeeded: `)
    })

    const trackAddedObservable = rx.connectable(rx.fromEvent<RTCTrackEvent>(this.pc, 'track').pipe(
      rxops.filter(x => x.track.id !== "probator"),
      rxops.map(ev => {
        return ({ 
          mid: ev.transceiver.mid!,
          track: ev.track,
          stream: ev.streams[0],
        })
      })
    ))
    this.trackAddedSub = trackAddedObservable.connect();
    this.trackAddedObservable = trackAddedObservable;

    const micAndCamStream = new MediaStream()
    this.outgoingAudioTransceiver = this.pc.addTransceiver("audio", {
      direction: "sendrecv", 
      streams: [micAndCamStream],
      sendEncodings: [{
        adaptivePtime: true,
      } as RTCRtpEncodingParameters]
    })
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if (CanSendVideo(configuration.ticketSubject) || CanRecvVideo(configuration.ticketSubject)) {
      let outgoingCameraTransceiver = this.pc.addTransceiver("video", {
        direction: "sendrecv", 
        streams: [micAndCamStream],
      })
      if (CanSendVideo(configuration.ticketSubject) && isFirefox) {
        const cameraSendEncodings = [
          { rid: "l", scaleResolutionDownBy: 4, maxBitrate: 250000, },
          { rid: "m", scaleResolutionDownBy: 2, maxBitrate: 1000000, },
          { rid: "h", scaleResolutionDownBy: 1, maxBitrate: 4000000, },
        ]
  
        let cameraSendParameters = outgoingCameraTransceiver.sender.getParameters()
        outgoingCameraTransceiver.sender.setParameters({...cameraSendParameters, ...{ encodings: cameraSendEncodings }}).then(() => {
          console.log(outgoingCameraTransceiver.sender.getParameters())
        })
      }
      this.outgoingCameraTransceiver = outgoingCameraTransceiver;
    }

    if (CanSendVideo(configuration.ticketSubject) && configuration.enableScreenShare) {
      const screenStream = new MediaStream()

      this.outgoingScreenAudioTransceiver = this.pc.addTransceiver("audio", {
        direction: "sendrecv", 
        streams: [screenStream],
        sendEncodings: [{
          adaptivePtime: true,
        } as RTCRtpEncodingParameters]
      })

      this.outgoingScreenVideoTransceiver = this.pc.addTransceiver("video", {
        direction: "sendrecv", 
        streams: [screenStream],
      })

      let screenSendParameters = this.outgoingScreenVideoTransceiver.sender.getParameters();
      this.outgoingScreenVideoTransceiver.sender.setParameters({...screenSendParameters, degradationPreference: "maintain-resolution"}).then(() => {
        console.log(this.outgoingScreenVideoTransceiver!.sender.getParameters());

        if (isFirefox && this.outgoingScreenVideoTransceiver) {
          const screenSendEncodings = [
            { rid: "l", scaleResolutionDownBy: 4, maxBitrate: 250000, },
            { rid: "m", scaleResolutionDownBy: 2, maxBitrate: 1000000, },
            { rid: "h", scaleResolutionDownBy: 1, maxBitrate: 4000000, },
          ];

          let screenSendParameters = this.outgoingScreenVideoTransceiver?.sender.getParameters()
          this.outgoingScreenVideoTransceiver.sender.setParameters({...screenSendParameters, ...{ encodings: screenSendEncodings }}).then(() => {
            console.log(this.outgoingScreenVideoTransceiver!.sender.getParameters());
          });
        }
      });
    }
  }

  public get id(): string {
    return this.peerConnectionId;
  }

  public get microphoneSendTransceiver(): RTCRtpTransceiver {
    return this.outgoingAudioTransceiver;
  }

  public get cameraSendTransceiver(): RTCRtpTransceiver | null {
    return this.outgoingCameraTransceiver
  }

  public get screenSendTransceiver(): RTCRtpTransceiver | null {
    return this.outgoingScreenVideoTransceiver
  }

  public get screenAudioSendTransceiver(): RTCRtpTransceiver | null {
    return this.outgoingScreenAudioTransceiver
  }

  public get trackAdded(): rx.Observable<{mid: string, track: MediaStreamTrack, stream: MediaStream}> {
    return this.trackAddedObservable;
  }

  public get iceRestartRequired(): rx.Observable<void> {
    return this.iceRestartRequiredObservable
  }

  public getStats(): Promise<RTCStatsReport> {
    return this.pc.getStats()
  }

  public async createInitialOffer(): Promise<string> {
    try {
      let sessionDescription = await this.pc.createOffer()

      if (sessionDescription.sdp!.includes("o=mozilla...THIS_IS_SDPARTA")) {
        console.log("Will disable transport-cc for Firefox");
        // https://bugzilla.mozilla.org/show_bug.cgi?id=1722217
        sessionDescription = withoutTransportWideCongestionControl(sessionDescription);
      }
      console.log(`Created initial offer: ${sessionDescription.sdp}`)
      
      return sessionDescription.sdp!
    } catch (error) {
      console.log(`Failed to create initial offer, error is ${error}`)

      return ""
    }
  }

  async applyProducedSdpOffer(offer: string): Promise<boolean> {
    try {
      await this.pc.setLocalDescription({sdp: offer, type: "offer"}) 
      console.log("Effective local description (after applying offer modified by server)", this.pc.localDescription?.sdp);   
      return true;
    } catch(error) {
      console.log(`applyProducedSdpOffer failed: ${error}`)
      return false;
    }
  }

  async applyProducedSdpAnswer(answer: string): Promise<boolean> {
    try {
      await this.pc.setRemoteDescription({sdp: answer, type: "answer"})
      console.log("Effective local description (after applying answer from server)", this.pc.localDescription?.sdp);   
      return true;
    } catch(error) {
      console.log(`applyProducedSdpAnswer failed: ${error}`)
      return false;
    }
  }

  async applyConsumedSdpOffer(offer: string): Promise<boolean> {
    try {
      await this.pc.setRemoteDescription({sdp: offer, type: "offer"})
      await this.pc.setLocalDescription(undefined as any)
      return true;
    } catch(error) {
      console.log(`applyConsumedSdpOffer failed: ${error}`)
      return false;
    }
  }

  public async terminate() {
    this.connectionStateSub.unsubscribe();
    this.signalingStateSub.unsubscribe();
    this.iceCandidateSub.unsubscribe();
    this.iceErrorSub.unsubscribe();
    this.iceGatheringSub.unsubscribe();
    this.negotiationNeededSub.unsubscribe();
    this.trackAddedSub.unsubscribe();
    this.iceRestartRequiredSub.unsubscribe();
    this.pc.close();
  }
}

export default RoomSession