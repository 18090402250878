import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { SciezkaTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { checkConnectionToSignallingServer, checkGarconUrl, checkTicket, tryFetchSciezkaTicket } from "./Utils";
import { SciezkaTicketSubject } from "../client/TicketSubject";


export default class WebSocketToSignallingServerInCall implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.Calls,
        Services.Garcon,
        Services.Kvashanina,
        Services.Sciezka,
    ]);
    readonly name: string = "Connect to rtc-sciezka with call tickets";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new SciezkaTicketsClient(garconUrl);

        const subjects = [
            SciezkaTicketSubject.Caller,
            SciezkaTicketSubject.Callee,
        ];

        for (let subject of subjects) {
            const accountId = randomString();
            const clientId = randomString();
            const roomId = randomString();

            const ticket = await tryFetchSciezkaTicket(client, {
                accountId,
                clientId,
                roomId,
                subject,
            });

            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                accountId,
                clientId,
                roomId,
                subject,
            });

            if (!ticket) {
                return;
            }
            
            await checkConnectionToSignallingServer(assert, ticket, subject);
        }
    }
}