import { rtc_types } from '../generated/submodules/sciezka-messages/rtc_types'
import Delay from './Delay';
import * as stat from './WebRTCStatistics'
const ZstdCodec = require('zstd-codec').ZstdCodec;

export async function getDictionary(url: string): Promise<null | { buffer: ArrayBuffer, etag: string }> {
    const getDictionaryResponse = await fetch(url, {
        method: "GET",
    });
    if (!getDictionaryResponse.ok) {
        console.error("Failed to obtain dictionary: ", getDictionaryResponse.status, getDictionaryResponse.statusText);
        return null;
    }

    return {
        buffer: await getDictionaryResponse.arrayBuffer(),
        etag: getDictionaryResponse.headers.get("etag")!,
    };
}

const taskQueue : Array<{
    data: Uint8Array,
    dict: ArrayBuffer,
    resolver: (buffer: Uint8Array) => void,
}> = [];

ZstdCodec.run(async (zstd: any) => {
    const simple = new zstd.Simple();
    let cdict : any = null;

    while (true) {
        while (taskQueue.length > 0) {
            const item = taskQueue.pop();
            if (!item) {
                break;
            }

            if (cdict === null) {
                cdict = new zstd.Dict.Compression(new Uint8Array(item.dict), 19);
            }
            
            const compressed = simple.compressUsingDict(item.data, cdict);
            item.resolver(compressed);
        }

        await Delay(1000);
    }
});

export function compressData(data: Uint8Array, dict: ArrayBuffer): Promise<Uint8Array> {
    return new Promise(resolver => {
        taskQueue.push({
            data: data,
            dict: dict,
            resolver: (buffer: Uint8Array) => resolver(buffer),
        })
    });
}

function processReport(report: stat.RTCStatsReport): rtc_types.RTCStats | null {
    const result = new rtc_types.RTCStats({
        id: report.id,
        timestamp: report.timestamp
    });

    switch (report.type) {
        case 'candidate-pair':
            return processCandidatePair(report, result);
        case 'certificate':
            return null;
        case 'codec':
            if (report.mimeType === "audio/opus" || report.mimeType.startsWith("video")) {
                return processCodec(report, result);
            }
            return null;
            
        case 'csrc':
            return processCSrc(report, result);
        case 'data-channel':
            return processDataChannel(report, result);
        case 'ice-server':
            return processIceServer(report, result);
        case 'inbound-rtp':
            return processInboundRtp(report, result);
        case 'local-candidate':
            return processIceCandidate(report, result);
        case 'media-source':
            return processMediaSource(report, result);
        case 'outbound-rtp':
            return processOutboundRtp(report, result);
        case 'peer-connection':
            return processPeerConnection(report, result);
        case 'receiver':
            return processReceiver(report, result);
        case 'remote-candidate':
            return processIceCandidate(report, result);
        case 'remote-inbound-rtp':
            return processRemoteInboundRtp(report, result);
        case 'remote-outbound-rtp':
            return processRemoteOutboundRtp(report, result);
        case 'sctp-transport':
            return processSctpTransport(report, result);
        case 'sender':
            return processSender(report, result);
        case 'transceiver':
            return processTransceiver(report, result);
        case 'transport':
            return processTransport(report, result);
        default:
            return null;
    }
}

export function createProtoRTCStatsReport(report: RTCStatsReport): rtc_types.RTCStatsReport {
    const result = new rtc_types.RTCStatsReport();

    for (const rep of report.values()) {
        const stats = processReport(rep as stat.RTCStatsReport);
        if (stats)
            result.stats.push(stats);
    }

    return result;
}

function check<T>(value: T | undefined, f: (value: T) => void) {
    if (typeof value !== 'undefined') {
        f(value)
    }
}

function processCandidatePair(report: stat.RTCIceCandidatePairStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCIceCandidatePairStats({
        transport_id: report.transportId,
        local_candidate_id: report.localCandidateId,
        remote_candidate_id: report.remoteCandidateId,
    });

    switch (report.state) {
        case 'failed':
            protoReport.state = rtc_types.RTCStatsIceCandidatePairState.Failed;
            break;
        case 'frozen':
            protoReport.state = rtc_types.RTCStatsIceCandidatePairState.Frozen;
            break;
        case 'in-progress':
            protoReport.state = rtc_types.RTCStatsIceCandidatePairState.InProgress;
            break;
        case 'succeeded':
            protoReport.state = rtc_types.RTCStatsIceCandidatePairState.Succeeded;
            break;
        case 'waiting':
            protoReport.state = rtc_types.RTCStatsIceCandidatePairState.Waiting;
            break;
    }

    check(report.nominated, x => protoReport.nominated = x);
    check(report.packetsSent, x => protoReport.packets_sent = x);
    check(report.packetsReceived, x => protoReport.packets_received = x);
    check(report.bytesSent, x => protoReport.bytes_sent = x);
    check(report.bytesReceived, x => protoReport.bytes_received = x);
    check(report.lastPacketReceivedTimestamp, x => protoReport.last_packet_received_timestamp = x);
    check(report.lastPacketSentTimestamp, x => protoReport.last_packet_sent_timestamp = x);
    check(report.firstRequestTimestamp, x => protoReport.first_request_timestamp = x);
    check(report.lastRequestTimestamp, x => protoReport.last_request_timestamp = x);
    check(report.lastResponseTimestamp, x => protoReport.last_response_timestamp = x);
    check(report.totalRoundTripTime, x => protoReport.total_round_trip_time = x);
    check(report.currentRoundTripTime, x => protoReport.current_round_trip_time = x);
    check(report.availableOutgoingBitrate, x => protoReport.available_outgoing_bitrate = x);
    check(report.availableIncomingBitrate, x => protoReport.available_incoming_bitrate = x);
    check(report.circuitBreakerTriggerCount, x => protoReport.circuit_breaker_trigger_count = x);
    check(report.requestsReceived, x => protoReport.requests_received = x);
    check(report.requestsSent, x => protoReport.requests_sent = x);
    check(report.responsesReceived, x => protoReport.responses_received = x);
    check(report.responsesSent, x => protoReport.responses_sent = x);
    check(report.retransmissionsReceived, x => protoReport.retransmissions_received = x);
    check(report.retransmissionsSent, x => protoReport.retransmissions_sent = x);
    check(report.consentRequestsSent, x => protoReport.consent_requests_sent = x);
    check(report.consentExpiredTimestamp, x => protoReport.consent_expired_timestamp = x);
    check(report.packetsDiscardedOnSend, x => protoReport.packets_discarded_on_send = x);
    check(report.bytesDiscardedOnSend, x => protoReport.bytes_discarded_on_send = x);
    check(report.requestBytesSent, x => protoReport.request_bytes_sent = x);
    check(report.consentRequestBytesSent, x => protoReport.consent_request_bytes_sent = x);
    check(report.responseBytesSent, x => protoReport.response_bytes_sent = x);

    result.candidate_pair = protoReport;
    return result;
}

// eslint-disable-next-line
function processCertificate(report: stat.RTCCertificateStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCCertificateStats({
        fingerprint: report.fingerprint,
        fingerprint_algorithm: report.fingerprintAlgorithm,
        base64_certificate: report.base64Certificate,
    });

    check(report.issuerCertificateId, x => protoReport.issuer_certificate_id = x);

    result.certificate = protoReport;
    return result;
}

function processCodec(report: stat.RTCCodecStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCCodecStats({
        payload_type: report.payloadType,
        transport_id: report.transportId,
        mime_type: report.mimeType,
    });

    check(report.codecType, x => {
        switch (x) {
            case 'decode':
                protoReport.codec_type = rtc_types.RTCCodecType.Decode;
                break;
            case 'encode':
                protoReport.codec_type = rtc_types.RTCCodecType.Encode;
                break;
        }
    });
    check(report.clockRate, x => protoReport.clock_rate = x);
    check(report.channels, x => protoReport.channels = x);
    check(report.sdpFmtpLine, x => protoReport.sdp_fmtp_line = x);

    result.codec = protoReport;
    return result;
}

function processCSrc(report: stat.RTCRtpContributingSourceStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCRtpContributingSourceStats({
        contributor_ssrc: report.contributorSsrc,
        inbound_rtp_stream_id: report.inboundRtpStreamId,
    });

    check(report.packetsContributedTo, x => protoReport.packets_contributed_to = x);
    check(report.audioLevel, x => protoReport.audio_level = x);

    result.csrc = protoReport;
    return result;
}

function processDataChannel(report: stat.RTCDataChannelStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCDataChannelStats();

    check(report.label, x => protoReport.label = x);
    check(report.protocol, x => protoReport.protocol = x);
    check(report.dataChannelIdentifier, x => protoReport.data_channel_identifier = x);

    switch (report.state) {
        case 'closed':
            protoReport.state = rtc_types.RTCDataChannelState.Closed;
            break;
        case 'closing':
            protoReport.state = rtc_types.RTCDataChannelState.Closing;
            break;
        case 'connecting':
            protoReport.state = rtc_types.RTCDataChannelState.Connecting;
            break;
        case 'open':
            protoReport.state = rtc_types.RTCDataChannelState.Open;
            break;
    }

    check(report.messagesReceived, x => protoReport.messages_received = x);
    check(report.bytesSent, x => protoReport.bytes_sent = x);
    check(report.messagesReceived, x => protoReport.messages_received = x);
    check(report.bytesReceived, x => protoReport.bytes_received = x);

    result.data_channel = protoReport;
    return result;
}

function processIceServer(report: stat.RTCIceServerStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCIceServerStats({
        url: report.url,
    });

    check(report.port, x => protoReport.port = x);
    check(report.relayProtocol, x => protoReport.relay_protocol = x);
    check(report.totalRequestsSent, x => protoReport.total_requests_sent = x);
    check(report.totalResponsesReceived, x => protoReport.total_responses_received = x);
    check(report.totalRoundTripTime, x => protoReport.total_round_trip_time = x);

    result.ice_server = protoReport;
    return result;
}

function processInboundRtp(report: stat.RTCInboundRtpStreamStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCInboundRtpStreamStats({
        ssrc: report.ssrc,
        receiver_id: report.receiverId,
    });

    switch (report.kind) {
        case 'audio':
            protoReport.kind = rtc_types.RTCMediaKind.Audio;

            check(report.voiceActivityFlag, x => protoReport.voice_activity_flag = x);
            check(report.samplesDecodedWithSilk, x => protoReport.samples_decoded_with_silk = x);
            check(report.samplesDecodedWithCelt, x => protoReport.samples_decoded_with_celt = x);
            check(report.concealedSamples, x => protoReport.concealed_samples = x);
            check(report.silentConcealedSamples, x => protoReport.silent_concealed_samples = x);
            check(report.concealmentEvents, x => protoReport.concealment_events = x);
            check(report.insertedSamplesForDeceleration, x => protoReport.inserted_samples_for_deceleration = x);
            check(report.removedSamplesForAcceleration, x => protoReport.removed_samples_for_acceleration = x);
            check(report.audioLevel, x => protoReport.audio_level = x);
            check(report.totalAudioEnergy, x => protoReport.total_audio_energy = x);
            check(report.totalSamplesDuration, x => protoReport.total_samples_duration = x);
            break;
        case 'video':
            protoReport.kind = rtc_types.RTCMediaKind.Video;

            check(report.framesDropped, x => protoReport.frames_dropped = x);
            check(report.partialFramesLost, x => protoReport.partial_frames_lost = x);
            check(report.fullFramesLost, x => protoReport.full_frames_lost = x);
            check(report.framesDecoded, x => protoReport.frames_decoded = x);
            check(report.keyFramesDecoded, x => protoReport.key_frames_decoded = x);
            check(report.frameWidth, x => protoReport.frame_width = x);
            check(report.frameHeight, x => protoReport.frame_height = x);
            check(report.frameBitDepth, x => protoReport.frame_bit_depth = x);
            check(report.framesPerSecond, x => protoReport.frames_per_second = x);
            check(report.qpSum, x => protoReport.qp_sum = x);
            check(report.totalDecodeTime, x => protoReport.total_decode_time = x);
            check(report.totalInterFrameDelay, x => protoReport.total_inter_frame_delay = x);
            check(report.totalSquaredInterFrameDelay, x => protoReport.total_squared_inter_frame_delay = x);
            check(report.firCount, x => protoReport.fir_count = x);
            check(report.pliCount, x => protoReport.pli_count = x);
            check(report.sliCount, x => protoReport.sli_count = x);
            check(report.framesReceived, x => protoReport.frames_received = x);
            break;
    }

    check(report.mid, x => protoReport.mid = x);
    check(report.transportId, x => protoReport.transport_id = x);
    check(report.codecId, x => protoReport.codec_id = x);
    check(report.packetsReceived, x => protoReport.packets_received = x);
    check(report.packetsLost, x => protoReport.packets_lost = x);
    check(report.jitter, x => protoReport.jitter = x);
    check(report.packetsDiscarded, x => protoReport.packets_discarded = x);
    check(report.packetsRepaired, x => protoReport.packets_repaired = x);
    check(report.burstPacketsLost, x => protoReport.burst_packets_lost = x);
    check(report.burstPacketsDiscarded, x => protoReport.burst_packets_discarded = x);
    check(report.burstLossCount, x => protoReport.burst_loss_count = x);
    check(report.burstDiscardCount, x => protoReport.burst_discard_count = x);
    check(report.burstLossRate, x => protoReport.burst_loss_rate = x);
    check(report.burstDiscardRate, x => protoReport.burst_discard_rate = x);
    check(report.gapLossRate, x => protoReport.gap_loss_rate = x);
    check(report.gapDiscardRate, x => protoReport.gap_discard_rate = x);
    check(report.remoteId, x => protoReport.remote_id = x);
    check(report.lastPacketReceivedTimestamp, x => protoReport.last_packet_received_timestamp = x);
    check(report.averageRtcpInterval, x => protoReport.average_rtcp_interval = x);
    check(report.headerBytesReceived, x => protoReport.header_bytes_received = x);
    check(report.fecPacketsReceived, x => protoReport.fec_packets_received = x);
    check(report.fecPacketsDiscarded, x => protoReport.fec_packets_discarded = x);
    check(report.bytesReceived, x => protoReport.bytes_received = x);
    check(report.packetsFailedDecryption, x => protoReport.packets_failed_decryption = x);
    check(report.packetsDuplicated, x => protoReport.packets_duplicated = x);
    check(report.perDscpPacketsReceived, x => {
        for(let key in x) {
            protoReport.per_dscp_packets_received.set(key, x[key]);
        }
    });
    check(report.nackCount, x => protoReport.nack_count = x);
    check(report.totalProcessingDelay, x => protoReport.total_processing_delay = x);
    check(report.estimatedPlayoutTimestamp, x => protoReport.estimated_playout_timestamp = x);
    check(report.jitterBufferDelay, x => protoReport.jitter_buffer_delay = x);
    check(report.jitterBufferEmittedCount, x => protoReport.jitter_buffer_emitted_count = x);
    check(report.decoderImplementation, x => protoReport.decoder_implementation = x);

    result.inbound_rtp = protoReport;
    return result;
}

function processIceCandidate(report: stat.RTCIceCandidateStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCIceCandidateStats({
        transport_id: report.transportId,
    });

    check(report.address, x => protoReport.address = x);
    check(report.port, x => protoReport.port = x);
    check(report.protocol, x => protoReport.protocol = x);

    switch (report.candidateType) {
        case 'host':
            protoReport.candidate_type = rtc_types.RTCIceCandidateType.Host;
            break;
        case 'prflx':
            protoReport.candidate_type = rtc_types.RTCIceCandidateType.Prflx;
            break;
        case 'relay':
            protoReport.candidate_type = rtc_types.RTCIceCandidateType.Relay;
            break;
        case 'srflx':
            protoReport.candidate_type = rtc_types.RTCIceCandidateType.Srflx;
            break;
    }

    check(report.priority, x => protoReport.priority = x);

    switch (report.type) {
        case 'local-candidate':
            check(report.url, x => protoReport.url = x);
            check(report.relayProtocol, x => protoReport.relay_protocol = x);
            result.local_candidate = protoReport;
            break;
        case 'remote-candidate':
            result.remote_candidate = protoReport;
            break;
    }

    return result;
}
function processMediaSource(report: stat.RTCAudioSourceStats | stat.RTCVideoSourceStats, result: rtc_types.RTCStats): rtc_types.RTCStats {

    switch (report.kind) {
        case 'audio': {
            const protoReport = new rtc_types.RTCAudioSourceStats({
                track_identifier: report.trackIdentifier,
            });
            check(report.relayedSource, x => protoReport.relayed_source = x);
            check(report.audioLevel, x => protoReport.audio_level = x);
            check(report.totalAudioEnergy, x => protoReport.total_audio_energy = x);
            check(report.totalSamplesDuration, x => protoReport.total_samples_duration = x);
            check(report.echoReturnLoss, x => protoReport.echo_return_loss = x);
            check(report.echoReturnLossEnhancement, x => protoReport.echo_return_loss_enhancement = x);

            result.media_source_audio = protoReport;
            break;
        }
        case 'video': {
            const protoReport = new rtc_types.RTCVideoSourceStats({
                track_identifier: report.trackIdentifier,
            });
            check(report.relayedSource, x => protoReport.relayed_source = x);
            check(report.width, x => protoReport.width = x);
            check(report.height, x => protoReport.height = x);
            check(report.bitDepth, x => protoReport.bit_depth = x);
            check(report.frames, x => protoReport.frames = x);
            check(report.framesPerSecond, x => protoReport.frames_per_second = x);

            result.media_source_video = protoReport;
            break;
        }
    }

    return result;
}

function processOutboundRtp(report: stat.RTCOutboundRtpStreamStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCOutboundRtpStreamStats({
        ssrc: report.ssrc,
    });

    function qualityLimitationReasonToEnum(str: string): rtc_types.RTCQualityLimitationReason {
        switch (str) {
            case 'bandwidth':
                return rtc_types.RTCQualityLimitationReason.Bandwidth;
            case 'cpu':
                return rtc_types.RTCQualityLimitationReason.Cpu;
            case 'none':
                return rtc_types.RTCQualityLimitationReason.None;
            case 'other':
                return rtc_types.RTCQualityLimitationReason.Other;
            default:
                return rtc_types.RTCQualityLimitationReason.Other;
        }
    }

    switch (report.kind) {
        case 'audio':
            protoReport.kind = rtc_types.RTCMediaKind.Audio;

            check(report.totalSamplesSent, x => protoReport.total_samples_sent = x);
            check(report.samplesEncodedWithSilk, x => protoReport.samples_encoded_with_silk = x);
            check(report.samplesEncodedWithCelt, x => protoReport.samples_encoded_with_celt = x);
            check(report.voiceActivityFlag, x => protoReport.voice_activity_flag = x);
            break;
        case 'video':
            protoReport.kind = rtc_types.RTCMediaKind.Video;

            check(report.frameWidth, x => protoReport.frame_width = x);
            check(report.frameHeight, x => protoReport.frame_height = x);
            check(report.frameBitDepth, x => protoReport.frame_bit_depth = x);
            check(report.framesPerSecond, x => protoReport.frames_per_second = x);
            check(report.framesSent, x => protoReport.frames_sent = x);
            check(report.hugeFramesSent, x => protoReport.huge_frames_sent = x);
            check(report.framesEncoded, x => protoReport.frames_encoded = x);
            check(report.keyFramesEncoded, x => protoReport.key_frames_encoded = x);
            check(report.framesDiscardedOnSend, x => protoReport.frames_discarded_on_send = x);
            check(report.qpSum, x => protoReport.qp_sum = x);
            check(report.qualityLimitationReason, x => protoReport.quality_limitation_reason = qualityLimitationReasonToEnum(x));
            check(report.qualityLimitationDurations, x => {
                for (let key in x) {
                    protoReport.quality_limitation_durations.set(qualityLimitationReasonToEnum(key), x[key as stat.RTCQualityLimitationReason]);
                }
            });
            check(report.qualityLimitationResolutionChanges, x => protoReport.quality_limitation_resolution_changes = x);
            check(report.firCount, x => protoReport.fir_count = x);
            check(report.pliCount, x => protoReport.pli_count = x);
            check(report.sliCount, x => protoReport.sli_count = x);
            break;
    }

    check(report.transportId, x => protoReport.transport_id = x);
    check(report.codecId, x => protoReport.codec_id = x);
    check(report.packetsSent, x => protoReport.packets_sent = x);
    check(report.bytesSent, x => protoReport.bytes_sent = x);
    check(report.rtxSsrc, x => protoReport.rtx_ssrc = x);
    check(report.mediaSourceId, x => protoReport.media_source_id = x);
    check(report.senderId, x => protoReport.sender_id = x);
    check(report.remoteId, x => protoReport.remote_id = x);
    check(report.rid, x => protoReport.rid = x);
    check(report.lastPacketSentTimestamp, x => protoReport.last_packet_sent_timestamp = x);
    check(report.headerBytesSent, x => protoReport.header_bytes_sent = x);
    check(report.packetsDiscardedOnSend, x => protoReport.packets_discarded_on_send = x);
    check(report.bytesDiscardedOnSend, x => protoReport.bytes_discarded_on_send = x);
    check(report.fecPacketsSent, x => protoReport.fec_packets_sent = x);
    check(report.retransmittedPacketsSent, x => protoReport.retransmitted_packets_sent = x);
    check(report.retransmittedBytesSent, x => protoReport.retransmitted_bytes_sent = x);
    check(report.targetBitrate, x => protoReport.target_bitrate = x);
    check(report.totalEncodedBytesTarget, x => protoReport.total_encoded_bytes_target = x);
    check(report.totalEncodeTime, x => protoReport.total_encode_time = x);
    check(report.totalPacketSendDelay, x => protoReport.total_packet_send_delay = x);
    check(report.averageRtcpInterval, x => protoReport.average_rtcp_interval = x);
    check(report.perDscpPacketsSent, x => {
        for (let key in report.perDscpPacketsSent) {
            protoReport.per_dscp_packets_sent.set(key, x[key]);
        }
    });
    check(report.nackCount, x => protoReport.nack_count = x);
    check(report.encoderImplementation, x => protoReport.encoder_implementation = x);

    result.outbound_rtp = protoReport;
    return result;
}

function processPeerConnection(report: stat.RTCPeerConnectionStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCPeerConnectionStats();

    check(report.dataChannelsOpened, x => protoReport.data_channels_opened = x);
    check(report.dataChannelsClosed, x => protoReport.data_channels_closed = x);
    check(report.dataChannelsRequested, x => protoReport.data_channels_requested = x);
    check(report.dataChannelsAccepted, x => protoReport.data_channels_accepted = x);

    result.peer_connection = protoReport;
    return result;
}

function processReceiver(report: stat.RTCAudioReceiverStats | stat.RTCVideoReceiverStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    
    switch (report.kind) {
        case 'audio': {
            const protoReport = new rtc_types.RTCAudioReceiverStats();
            check(report.trackIdentifier, x => protoReport.track_identifier = x);
            check(report.ended, x => protoReport.ended = x);
            result.receiver_audio = protoReport;
            break;
        }
        case 'video': {
            const protoReport = new rtc_types.RTCVideoReceiverStats();
            check(report.trackIdentifier, x => protoReport.track_identifier = x);
            check(report.ended, x => protoReport.ended = x);
            result.receiver_video = protoReport;
            break;
        }
    }
    
    return result;
}

function processSctpTransport(report: stat.RTCSctpTransportStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCSctpTransportStats();

    check(report.transportId, x => protoReport.transport_id = x);
    check(report.smoothedRoundTripTime, x => protoReport.smoothed_round_trip_time = x);
    check(report.congestionWindow, x => protoReport.congestion_window = x);
    check(report.receiverWindow, x => protoReport.receiver_window = x);
    check(report.mtu, x => protoReport.mtu = x);
    check(report.unackData, x => protoReport.unack_data = x);
    result.sctp_transport = protoReport;
    return result;
}

function processSender(report: stat.RTCAudioSenderStats | stat.RTCVideoSenderStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    switch (report.kind) {
        case 'audio': {
            const protoReport = new rtc_types.RTCAudioSenderStats();
            check(report.trackIdentifier, x => protoReport.track_identifier = x);
            check(report.ended, x => protoReport.ended = x);
            check(report.mediaSourceId, x => protoReport.media_source_id = x);
            result.sender_audio = protoReport;
            break;
        }
        case 'video': {
            const protoReport = new rtc_types.RTCVideoSenderStats();
            check(report.trackIdentifier, x => protoReport.track_identifier = x);
            check(report.ended, x => protoReport.ended = x);
            check(report.mediaSourceId, x => protoReport.media_source_id = x);
            result.sender_video = protoReport;
            break;
        }
    }
    
    return result;
}

function processTransceiver(report: stat.RTCRtpTransceiverStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCRtpTransceiverStats({
        sender_id: report.senderId,
        receiver_id: report.receiverId,
    });

    check(report.mid, x => protoReport.mid = x);

    result.transceiver = protoReport;
    return result;
}

function processTransport(report: stat.RTCTransportStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCTransportStats();

    check(report.packetsSent, x => protoReport.packets_sent = x);
    check(report.packetsReceived, x => protoReport.packets_received = x);
    check(report.bytesSent, x => protoReport.bytes_sent = x);
    check(report.bytesReceived, x => protoReport.bytes_received = x);
    check(report.rtcpTransportStatsId, x => protoReport.rtcp_transport_stats_id = x);
    check(report.iceRole, x => {
        switch (x) {
            case 'controlled':
                protoReport.ice_role = rtc_types.RTCIceRole.Controlled;
                break;
            case 'controlling':
                protoReport.ice_role = rtc_types.RTCIceRole.Controlling;
                break;
            default:
                protoReport.ice_role = rtc_types.RTCIceRole.Unknown;
                break;
        }
    });
    check(report.iceLocalUsernameFragment, x => protoReport.ice_local_username_fragment = x);

    switch (report.dtlsState) {
        case 'closed':
            protoReport.dtls_state = rtc_types.RTCDtlsTransportState.DtlsClosed;
            break;
        case 'connected':
            protoReport.dtls_state = rtc_types.RTCDtlsTransportState.DtlsConnected;
            break;
        case 'connecting':
            protoReport.dtls_state = rtc_types.RTCDtlsTransportState.DtlsConnecting;
            break;
        case 'failed':
            protoReport.dtls_state = rtc_types.RTCDtlsTransportState.DtlsFailed;
            break;
        case 'new':
            protoReport.dtls_state = rtc_types.RTCDtlsTransportState.DtlsNew;
            break;
    }

    check(report.iceState, x => {
        switch (report.iceState) {
            case 'checking':
                protoReport.ice_state = rtc_types.RTCIceTransportState.IceChecking;
                break;
            case 'closed':
                protoReport.ice_state = rtc_types.RTCIceTransportState.IceClosed;
                break;
            case 'completed':
                protoReport.ice_state = rtc_types.RTCIceTransportState.IceCompleted;
                break;
            case 'connected':
                protoReport.ice_state = rtc_types.RTCIceTransportState.IceConnected;
                break;
            case 'disconnected':
                protoReport.ice_state = rtc_types.RTCIceTransportState.IceDisconnected;
                break;
            case 'failed':
                protoReport.ice_state = rtc_types.RTCIceTransportState.IceFailed;
                break;
            case 'new':
                protoReport.ice_state = rtc_types.RTCIceTransportState.IceNew;
                break;
        }
    });

    check(report.selectedCandidatePairId, x => protoReport.selected_candidate_pair_id = x);
    check(report.localCertificateId, x => protoReport.local_certificate_id = x);
    check(report.remoteCertificateId, x => protoReport.remote_certificate_id = x);
    check(report.tlsVersion, x => protoReport.tls_version = x);
    check(report.dtlsCipher, x => protoReport.dtls_cipher = x);
    check(report.srtpCipher, x => protoReport.srtp_cipher = x);
    check(report.tlsGroup, x => protoReport.tls_group = x);
    check(report.selectedCandidatePairChanges, x => protoReport.selected_candidate_pair_changes = x);

    result.transport = protoReport;
    return result;
}

function processRemoteInboundRtp(report: stat.RTCRemoteInboundRtpStreamStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCRemoteInboundRtpStreamStats({
        ssrc: report.ssrc,
    });

    switch (report.kind) {
        case 'audio':
            protoReport.kind = rtc_types.RTCMediaKind.Audio;
            break;
        case 'video':
            protoReport.kind = rtc_types.RTCMediaKind.Video;
            check(report.framesDropped, x => protoReport.frames_dropped = x);
            check(report.partialFramesLost, x => protoReport.partial_frames_lost = x);
            check(report.fullFramesLost, x => protoReport.full_frames_lost = x);
            break;
    }

    check(report.transportId, x => protoReport.transport_id = x);
    check(report.codecId, x => protoReport.codec_id = x);
    check(report.packetsReceived, x => protoReport.packets_received = x);
    check(report.packetsLost, x => protoReport.packets_lost = x);
    check(report.jitter, x => protoReport.jitter = x);
    check(report.packetsDiscarded, x => protoReport.packets_discarded = x);
    check(report.packetsRepaired, x => protoReport.packets_repaired = x);
    check(report.burstPacketsLost, x => protoReport.burst_packets_lost = x);
    check(report.burstPacketsDiscarded, x => protoReport.burst_packets_discarded = x);
    check(report.burstLossCount, x => protoReport.burst_loss_count = x);
    check(report.burstDiscardCount, x => protoReport.burst_discard_count = x);
    check(report.gapLossRate, x => protoReport.gap_loss_rate = x);
    check(report.gapDiscardRate, x => protoReport.gap_discard_rate = x);
    check(report.fractionLost, x => protoReport.fraction_lost = x);
    check(report.reportsReceived, x => protoReport.reports_received = x);
    check(report.roundTripTimeMeasurements, x => protoReport.round_trip_time_measurements = x);

    result.remote_inbound_rtp = protoReport;
    return result;
}

function processRemoteOutboundRtp(report: stat.RTCRemoteOutboundRtpStreamStats, result: rtc_types.RTCStats): rtc_types.RTCStats {
    const protoReport = new rtc_types.RTCRemoteOutboundRtpStreamStats({
        ssrc: report.ssrc,
    });

    switch (report.kind) {
        case 'audio':
            protoReport.kind = rtc_types.RTCMediaKind.Audio;
            break;
        case 'video':
            protoReport.kind = rtc_types.RTCMediaKind.Video;
            break;
    }

    check(report.transportId, x => protoReport.transport_id = x);
    check(report.codecId, x => protoReport.codec_id = x);
    check(report.packetsSent, x => protoReport.packets_sent = x);
    check(report.bytesSent, x => protoReport.bytes_sent = x);
    check(report.localId, x => protoReport.local_id = x);
    check(report.remoteTimestamp, x => protoReport.remote_timestamp = x);
    check(report.reportsSent, x => protoReport.reports_sent = x);
    check(report.roundTripTime, x => protoReport.round_trip_time = x);
    check(report.totalRoundTripTime, x => protoReport.total_round_trip_time = x);
    check(report.roundTripTimeMeasurements, x => protoReport.round_trip_time_measurements = x);
    result.remote_outbound_rtp = protoReport;
    return result;
}

