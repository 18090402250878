import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { SciezkaTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { Ticket } from "../generated/submodules/garcon-api/tickets_pb";
import { User, checkGarconUrl, checkSignallingServer, checkTicket, tryFetchSciezkaTicket } from "./Utils";
import { SciezkaTicketSubject } from "../client/TicketSubject";


export default class GetTicketsForCall implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.Calls,
        Services.Garcon,
    ]);
    readonly name: string = "Get caller and callee ticktes for call";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new SciezkaTicketsClient(garconUrl);

        const roomId = randomString();

        const users = [
            {
                accountId: randomString(),
                clientId: randomString(),
                roomId,
                subject: SciezkaTicketSubject.Caller,
            },
            {
                accountId: randomString(),
                clientId: randomString(),
                roomId,
                subject: SciezkaTicketSubject.Callee,
            },
        ];
        
        const tickets = await Promise.all(users.map(async user => {
            const ticket = await tryFetchSciezkaTicket(client, user);
            return { ticket, user };
        }));

        tickets.forEach(({ user, ticket }) => {
            checkTicket(
                assert,
                {
                    ticket: ticket?.getTicket(),
                    ...user,
                }
            );
        });

        const verifiedTickets = tickets as Array<{ ticket: Ticket, user: User }>;

        checkSignallingServer(assert, verifiedTickets.map(x => x.ticket.getTicket()));
    }
}