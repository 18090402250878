import React from 'react'
import * as messages from '../client/Messages'

type Props = {
    src?: messages.MediaSourceA
    status?: messages.TrackStatus
    paused?: boolean
    score?: messages.ConsumedTrackScore
}

class ReceivedAudioTrackInfoView extends React.PureComponent<Props, {}> {
    render() {
        let sourceText = null;
        switch (this.props.src?.aud) {
          case messages.AudioSource.Microphone: {
            sourceText = "microphone";
            break;
          }
          case messages.AudioSource.Screen: {
            sourceText = "screen";
            break;
          }
          default: {
            sourceText = "unknown";
            break;
          } 
        }
        let scoreText = null;
        let statusText = null;
        if (this.props.paused) {
          statusText = "you've chosen not to receive it"
        } else {
          switch (this.props.status) {
            case messages.TrackStatus.On: {
              statusText = "live";
              let score = this.props.score
              if (score) {
                scoreText = `score: ${score.score}, producer_score: ${score.producer_score}, producer_scores: ${score.producer_scores}`
              }
              break;
            }
            case messages.TrackStatus.Muted: {
              statusText = "muted by sender";
              break;
            }
            case messages.TrackStatus.Off: {
              statusText = "turned off by sender";
              break;
            }
          }
        }
    
        return <span className="track-info">audio from {sourceText} source, {statusText}, {scoreText}</span>
    }
};

export default ReceivedAudioTrackInfoView;