import * as rx from 'rxjs'
import * as rxops from 'rxjs/operators'
import { v4 as uuidv4 } from 'uuid';
import { sciezka_messages } from '../generated/submodules/sciezka-messages/client_messages';
import { IcePolicy } from './Domain';

interface LeaderSessionConfiguration {
  callId: string,
  iceServers: Array<RTCIceServer>,
  icePolicy: IcePolicy,
  followerHello: sciezka_messages.FollowerHello,
}


class LeaderSession {
  private readonly callId: string;
  private readonly peerConnectionId: string = uuidv4();
  private readonly pc: RTCPeerConnection
  private audioTransceiver: RTCRtpTransceiver | null = null;
  private cameraTransceiver: RTCRtpTransceiver | null = null;
  private screenAudioTransceiver: RTCRtpTransceiver | null = null;
  private screenVideoTransceiver: RTCRtpTransceiver | null = null;
  private screenStream: MediaStream | null = null;
  private cameraStream: MediaStream | null = null;
  private remoteIceCandidates: Array<RTCIceCandidateInit> = [];

  private localScreenStream = new MediaStream();
  private localCameraStream = new MediaStream();

  private dataChannel: RTCDataChannel | null = null;

  private readonly signalingStateSub: rx.Subscription;
  private readonly connectionStateSub: rx.Subscription;
  private readonly iceErrorSub: rx.Subscription;
  private readonly iceGatheringSub: rx.Subscription;
  private readonly negotiationNeededSub: rx.Subscription;
  private readonly iceRestartRequiredSub: rx.Subscription
  private readonly iceRestartRequiredObservable: rx.Observable<void>
  private readonly iceCandidateObservable: rx.Observable<RTCIceCandidateInit>
  private readonly iceCandidateSub: rx.Subscription
  private readonly dataChannelMessageSubject = new rx.Subject<sciezka_messages.PeerMessage>();

  constructor(configuration: LeaderSessionConfiguration) {

    const iceServers = configuration.iceServers.map(x => {
      let urls : string[] = Array.isArray(x.urls) ? x.urls : [ x.urls ];

      const iceServer : RTCIceServer = {
        ...x,
        urls: urls.filter(url => {
          switch (configuration.icePolicy) {
            case "all": return true;
            case "relay": return true;
            case "tcp-relay": return url.includes("transport=tcp");
            case "udp-relay": return url.includes("transport=udp");
            case "tcp-tls-relay": return url.includes("transport=tcp") && url.startsWith("turns");
            case "udp-tls-relay": return url.includes("transport=udp") && url.startsWith("turns");
            default: return false;
          }
        }),
      }
      return iceServer;
    })

    this.callId = configuration.callId;
    this.pc = new RTCPeerConnection({
      bundlePolicy: "max-bundle",
      rtcpMuxPolicy: "require",
      iceServers: iceServers,
      iceTransportPolicy: configuration.icePolicy === "all" ? "all" : "relay",
    })

    this.signalingStateSub = rx.fromEvent(this.pc, 'signalingstatechange').subscribe(_ => {
      console.log(`[call = ${this.callId}]: onSignalingStateChange: signalingState=`, this.pc.signalingState)
    })

    this.connectionStateSub = rx.fromEvent(this.pc, 'connectionstatechange').subscribe(_ => {
      console.log(`[call = ${this.callId}]: onConnectionStateChange: connectionState=`, this.pc.connectionState)
    })

    this.pc.ondatachannel = (ev) => {
      this.dataChannel = ev.channel;

      this.dataChannel.onmessage = async (ev) => {
        let buffer: ArrayBufferLike
        if (ev.data instanceof Blob) {
          buffer = await ev.data.arrayBuffer();
        } else if (ev.data instanceof ArrayBuffer) {
          buffer = ev.data
        } else {
          console.log("Error unsupported message: ", ev.data);
          return;
        }

        const message = sciezka_messages.PeerMessage.deserializeBinary(new Uint8Array(buffer));

        console.log("Incoming PeerMessage: ", message.toObject());

        this.dataChannelMessageSubject.next(message);
      };
    };

    const iceCandidateObservable = rx.connectable(rx.fromEvent<RTCPeerConnectionIceEvent>(this.pc, 'icecandidate').pipe(
      rxops.filter(x => x.candidate !== null),
      rxops.map(ev => {
        console.log(`[call = ${this.callId}]: onIceCandidate: candidate=`, ev.candidate)
        return ev.candidate!.toJSON()!;
      }),
    ));

    this.iceCandidateSub = iceCandidateObservable.connect();
    this.iceCandidateObservable = iceCandidateObservable;

    const iceRestartRequiredObservable = rx.connectable(rx.fromEvent(this.pc, 'iceconnectionstatechange').pipe(
      rxops.tap(ev => {
        let pc = ev.target as RTCPeerConnection;
        console.log(`[call = ${this.callId}]: onIceConnectionStateChange: iceConnectionState=`, pc.iceConnectionState)
      }),
      rxops.switchMap(ev => {
        let pc = ev.target as RTCPeerConnection;
        switch (pc.iceConnectionState) {
          case 'new':
          case 'checking':
          case 'connected':
          case 'completed':
          case 'closed':
            return rx.EMPTY
          case 'disconnected':
            return rx.of(void 0).pipe(rx.delay(5000))
          case 'failed':
            return rx.of(void 0)
        }
      })
    ))
    this.iceRestartRequiredSub = iceRestartRequiredObservable.connect();
    this.iceRestartRequiredObservable = iceRestartRequiredObservable;

    this.iceErrorSub = rx.fromEvent<RTCPeerConnectionIceErrorEvent>(this.pc, 'icecandidateerror').subscribe(ev => {
      console.log(`[call = ${this.callId}]: onIceCandidateError: code=`, ev.errorCode, `, text=`, ev.errorText)
    })

    this.iceGatheringSub = rx.fromEvent(this.pc, 'icegatheringstatechange').subscribe(ev => {
      console.log(`[call = ${this.callId}]: onIceGatheringStateChange: iceGatheringState=`, this.pc.iceGatheringState)
    })

    this.negotiationNeededSub = rx.fromEvent(this.pc, 'negotiationneeded').subscribe(ev => {
      console.log(`[call = ${this.callId}]: onNegotiationNeeded: `)
    })

    this.pc.ontrack = (ev) => {

      ev.transceiver.direction = "sendrecv";

      if (ev.transceiver.mid === this.getMidBySource(configuration.followerHello, sciezka_messages.TrackSource.MIC)) {
        this.audioTransceiver = ev.transceiver;
        this.audioTransceiver.sender.setStreams(this.localCameraStream);
        this.cameraStream = ev.streams[0];
        console.log(`[call = ${this.callId}]: set camera stream: ${this.cameraStream}`)
      }

      if (ev.transceiver.mid === this.getMidBySource(configuration.followerHello, sciezka_messages.TrackSource.CAMERA)) {
        this.cameraTransceiver = ev.transceiver;
        this.cameraTransceiver.sender.setStreams(this.localCameraStream);
        this.cameraStream = ev.streams[0];
        console.log(`[call = ${this.callId}]: set camera stream: ${this.cameraStream}`)
      }

      if (ev.transceiver.mid === this.getMidBySource(configuration.followerHello, sciezka_messages.TrackSource.SCREEN_VIDEO)) {
        this.screenVideoTransceiver = ev.transceiver;
        this.screenVideoTransceiver.sender.setStreams(this.localScreenStream);
        this.screenStream = ev.streams[0];
        console.log(`[call = ${this.callId}]: set screen stream: ${this.screenStream}`)
      }

      if (ev.transceiver.mid === this.getMidBySource(configuration.followerHello, sciezka_messages.TrackSource.SCREEN_AUDIO)) {
        this.screenAudioTransceiver = ev.transceiver;
        this.screenAudioTransceiver.sender.setStreams(this.localScreenStream);
        this.screenStream = ev.streams[0];
        console.log(`[call = ${this.callId}]: set screen stream: ${this.screenStream}`)
      }
    }
  }

  public get id(): string {
    return this.peerConnectionId;
  }

  private getMidBySource(message: sciezka_messages.FollowerHello, source: sciezka_messages.TrackSource) {
    return message.transceivers.find(x => x.source === source)?.mid ?? null;
  }

  public get remoteCameraStream(): MediaStream {
    if (!this.cameraStream) {
      throw Error("no camera stream")
    }

    return this.cameraStream;
  }

  public get remoteScreenStream(): MediaStream {
    if (!this.screenStream) {
      throw Error("no screen stream")
    }

    return this.screenStream;
  }

  public get microphoneSendTransceiver(): RTCRtpTransceiver {
    if (!this.audioTransceiver) {
      throw Error("no audio transceiver")
    }

    return this.audioTransceiver;
  }

  public get cameraSendTransceiver(): RTCRtpTransceiver {
    if (!this.cameraTransceiver) {
      throw Error("no camera transceiver")
    }

    return this.cameraTransceiver
  }

  public get screenVideoSendTransceiver(): RTCRtpTransceiver {
    if (!this.screenVideoTransceiver) {
      throw Error("no screen video transceiver")
    }

    return this.screenVideoTransceiver
  }

  public get screenAudioSendTransceiver(): RTCRtpTransceiver {
    if (!this.screenAudioTransceiver) {
      throw Error("no screen audio transceiver")
    }

    return this.screenAudioTransceiver
  }

  public get iceRestartRequired(): rx.Observable<void> {
    return this.iceRestartRequiredObservable
  }

  public get iceCandidate(): rx.Observable<RTCIceCandidateInit> {
    return this.iceCandidateObservable;
  }

  public getStats(): Promise<RTCStatsReport> {
    return this.pc.getStats()
  }

  public async createAnswer(): Promise<string | null> {
    try {
      await this.pc.setLocalDescription();
      let sessionDescription = this.pc.localDescription!;

      console.log(`Created answer: ${sessionDescription.sdp}`)
      
      return sessionDescription.sdp!
    } catch (error) {
      console.log(`Failed to create answer, error is ${error}`)

      return null;
    }
  }

  public get messageReceived(): rx.Observable<sciezka_messages.PeerMessage> {
    return this.dataChannelMessageSubject.asObservable();
  }

  public send(message: sciezka_messages.PeerMessage) {
    console.log("Outgoing PeerMessage: ", message.toObject());

    this.dataChannel?.send(message.serializeBinary());
  }

  public async applyRemoteSdpOffer(offer: string): Promise<boolean> {
    try {
      await this.pc.setRemoteDescription({sdp: offer, type: "offer"})
      console.log("Effective remote description", this.pc.remoteDescription?.sdp);

      while (this.remoteIceCandidates.length) {
        this.applyCandidate(this.remoteIceCandidates.pop()!);
      }

      return true;
    } catch(error) {
      console.log(`applyRemoteSdpOffer failed: ${error}`)
      return false;
    }
  }

  public async applyCandidate(candidate: RTCIceCandidateInit) {
    if (this.pc.remoteDescription) {
      await this.pc.addIceCandidate(candidate);
    } else {
      this.remoteIceCandidates.push(candidate);
    }
  }

  public get connectionState() {
    return this.pc.connectionState;
  }

  public async terminate() {
    this.connectionStateSub.unsubscribe();
    this.signalingStateSub.unsubscribe();
    this.iceErrorSub.unsubscribe();
    this.iceGatheringSub.unsubscribe();
    this.negotiationNeededSub.unsubscribe();
    this.iceRestartRequiredSub.unsubscribe();
    this.pc.close();
  }
}

export default LeaderSession