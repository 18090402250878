import "../generated/submodules/sciezka-messages/client_messages"
import { sciezka_messages } from "../generated/submodules/sciezka-messages/client_messages"


type PeerTrackState = {
    mid: string,
    status: sciezka_messages.TrackState,
    track: MediaStreamTrack,
}

type MemberState = {
    video: PeerTrackState,
    audio: PeerTrackState,
    screenVideo: PeerTrackState,
    screenAudio: PeerTrackState,
    cameraStream: MediaStream,
    screenStream: MediaStream,
}

type AudioTrackViewState = {
    status: sciezka_messages.TrackState,
}

type VideoTrackViewState = {
    status: sciezka_messages.TrackState,
}

export type MemberViewState = {
    cameraStream: MediaStream
    screenStream: MediaStream
    screenVideoTrack: VideoTrackViewState
    screenAudioTrack: AudioTrackViewState
    audioTrack: AudioTrackViewState
    videoTrack: VideoTrackViewState
}

export const encodeAccountAndDisplayName = (accountId: string, displayName: string) => {
    return JSON.stringify({a: accountId, n: displayName})
}

export class PeerState {
    private peers : Map<string, MemberState> = new Map<string, MemberState>();
    private active : string | null = null;

    public setPeer(id: string, peerData : {
        video: PeerTrackState,
        audio: PeerTrackState,
        screenVideo: PeerTrackState,
        screenAudio: PeerTrackState,
        cameraStream: MediaStream,
        screenStream: MediaStream,
    }) {
        this.peers.set(id, peerData);
    }

    public setActive(id: string) {
        this.active = id;
    }

    public get viewState(): MemberViewState | null {
        if (!this.active) {
            return null;
        }

        const peer = this.peers.get(this.active);

        if (!peer) {
            return null;
        }

        return {
            audioTrack: {
                status: peer.audio.status,
            },
            videoTrack: {
                status: peer.video.status,
            },
            screenAudioTrack: {
                status: peer.screenAudio.status,
            },
            screenVideoTrack: {
                status: peer.screenVideo.status,
            },
            cameraStream: peer.cameraStream,
            screenStream: peer.screenStream,
        }
    }

    public updateTrackState(update: sciezka_messages.TrackUpdate) {
        if (!this.active) {
            return;
        }

        const peer = this.peers.get(this.active);

        if (!peer) {
            return;
        }

        if (update.direction === sciezka_messages.TrackDirection.OUT) {
            switch (update.source) {
                case sciezka_messages.TrackSource.CAMERA:
                    peer.video.status = update.state;
                    break;
                case sciezka_messages.TrackSource.MIC:
                    peer.audio.status = update.state;
                    break;
                case sciezka_messages.TrackSource.SCREEN_AUDIO:
                    peer.screenAudio.status = update.state;
                    break;
                case sciezka_messages.TrackSource.SCREEN_VIDEO:
                    peer.screenVideo.status = update.state;
                    break;
            }
        }
    }

    public clearAllExceptActive() {
        if (this.active) {
            const peer = this.peers.get(this.active);
            this.peers.clear();

            if (peer) {
                this.peers.set(this.active, peer);
            }
        } else {
            this.peers.clear();
        }
    }

    public clear() {
        this.peers.clear();
    }
}