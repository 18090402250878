import React from 'react'
import SentMediaView from './SentMediaView'
import GridView from './GridView'
import { OutgoingMediaViewState } from '../client/Domain'
import { FollowerMemberViewState } from '../client/FollowerController'
import { LeaderMemberViewState } from '../client/LeaderController'
import RecvMediaView from './RecvMediaView'
import { sciezka_messages } from '../generated/submodules/sciezka-messages/client_messages'

type Props = {
    peer: FollowerMemberViewState | LeaderMemberViewState | null,
    onViewPortChanged: (source: sciezka_messages.TrackSource, size: { width: number, height: number }) => void;
    outgoingMedia: null | OutgoingMediaViewState,
}

export default class CallMembersView extends React.Component<Props, {}> {
    render() {
        let outgoingCameraView = null;
        let outgoingScreenView = null;
        if (this.props.outgoingMedia) {
            if (this.props.outgoingMedia.microphoneAndCamera.stream.getVideoTracks().length > 0) {
                outgoingCameraView = <SentMediaView
                    displayName={"Self camera"}
                    {...this.props.outgoingMedia.microphoneAndCamera} />
            }

            if (this.props.outgoingMedia.screen.stream.getVideoTracks().length > 0) {
                outgoingScreenView = <SentMediaView
                    displayName={"Self screen"}
                    {...this.props.outgoingMedia.screen} />
            }
        }

        let incomingCameraView = null;
        let incomingScreenView = null;

        if (this.props.peer) {
            if (this.props.peer.videoTrack.status === sciezka_messages.TrackState.ON) {
                incomingCameraView = <RecvMediaView
                                        key={this.props.peer.cameraStream.id}
                                        stream={this.props.peer.cameraStream}
                                        onViewPortChanged={x => this.props.onViewPortChanged(sciezka_messages.TrackSource.CAMERA, x)}
                                        />
            }
            
            if (this.props.peer.screenVideoTrack.status === sciezka_messages.TrackState.ON) {
                incomingScreenView = <RecvMediaView
                                        key={this.props.peer.cameraStream.id}
                                        stream={this.props.peer.screenStream}
                                        onViewPortChanged={x => this.props.onViewPortChanged(sciezka_messages.TrackSource.SCREEN_VIDEO, x)}
                                        />
            }
            
        }

        return (
            <div className="room-members-view">
                <GridView aspectRatio={16/9}>
                    {outgoingCameraView}
                    {outgoingScreenView}
                    {incomingCameraView}
                    {incomingScreenView}
                </GridView>
            </div>
        )
    }
}