import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { checkGarconUrl, checkTicket, joinToKaldunRoom, tryFetchTicket } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";
import { IcePolicy } from "../client/Domain";


export default class JoinToLiveParty implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.LiveParty,
        Services.Garcon,
        Services.Coturn,
        Services.Kvashanina,
        Services.Kaldun,
        Services.Mediasoup,
    ]);
    readonly name: string = "Join to live party";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        for(let icePolicy of [IcePolicy.All, IcePolicy.Relay, IcePolicy.TcpRelay, IcePolicy.TlsRelay, IcePolicy.UdpRelay]) {
            const user = {
                accountId: randomString(),
                clientId: randomString(),
                roomId: randomString(),
            };

            const ticket = await tryFetchTicket(client, {
                ...user,
                subject: KaldunTicketSubject.BroadcastAndView,
            });

            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                ...user,
                subject: KaldunTicketSubject.BroadcastAndView
            });

            if (!ticket) {
                return;
            }

            await joinToKaldunRoom({assert, ticket, user, icePolicy, subject: KaldunTicketSubject.BroadcastAndView});
        }
    }
}
