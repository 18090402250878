import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { checkConnectionToSignallingServer, checkGarconUrl, checkTicket, tryFetchTicket } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";
import RoomSignaling, { SignalingState } from "../client/RoomSignaling";
import * as rx from 'rxjs'
import * as rxops from 'rxjs/operators'


export default class WebSocketWithoutJoinIsTerminated implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.PushToTalk,
        Services.Garcon,
        Services.Kvashanina,
        Services.Kaldun,
    ]);
    readonly name: string = "Check WebSocket is terminated by rtc-kaldun if client does not send join request within 30 seconds";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        const accountId = randomString();
        const clientId = randomString();
        const roomId = randomString();

        const ticket = await tryFetchTicket(client, {
            accountId,
            clientId,
            roomId,
            subject: KaldunTicketSubject.BroadcastAndView,
        });

        const signaling = new RoomSignaling(123);
        signaling.connect(ticket!.getEndpoint())

        await rx.firstValueFrom(
            signaling.signalingStateChanged.pipe(
                rxops.filter(x => x == SignalingState.Connected),
            )
        );
        assert.true(true, "WebSocket was opened, keeping it idle without sending join or authenticate");

        await rx.firstValueFrom(
            signaling.signalingStateChanged.pipe(
                rxops.filter(x => x == SignalingState.Interrupted),
                rxops.timeout( { first: 30_000 })
            )
        );
        assert.true(true, "WebSocket was terminated by rtc-kaldun due to inactivity")
    }
}