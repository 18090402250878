import React from 'react'
import VideoResolutionView from './VideoResolutionView'


export type Props = {
  stream: MediaStream,
  onViewPortChanged: (size: { width: number, height: number }) => void,
}

type State = {
}

class RecvMediaView extends React.Component<Props, State> {
  private readonly videoNode = React.createRef<HTMLVideoElement>()
  
  private readonly onSizeChanged = () => {
      if (this.videoNode.current) {
          console.log(`Size changed ${this.videoNode.current.clientHeight}x${this.videoNode.current.clientWidth}`);
          this.setState({
              containerHeight: this.videoNode.current.clientHeight,
              containerWidth: this.videoNode.current.clientWidth,
          });
          this.props.onViewPortChanged({
            width: this.videoNode.current.clientWidth,
            height: this.videoNode.current.clientHeight,
          });
      }
  };

  private readonly sizeObserver = new ResizeObserver(this.onSizeChanged);

  private readonly onVideoSizeChanged = (e: Event) => {
    this.forceUpdate();
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      showStatistics: false,
    }
  }

  componentDidMount() {
    if (this.videoNode.current) {
      this.videoNode.current.addEventListener('resize', this.onVideoSizeChanged)
      this.videoNode.current.addEventListener('loadedmetadata', this.onVideoSizeChanged)
      this.sizeObserver.observe(this.videoNode.current);
    }
  }

  componentWillUnmount() {
    if (this.videoNode.current) {
      this.videoNode.current.removeEventListener('resize', this.onVideoSizeChanged)
      this.videoNode.current.removeEventListener('loadedmetadata', this.onVideoSizeChanged)
      this.sizeObserver.unobserve(this.videoNode.current);
    }
  }

  render() {
    if (this.videoNode.current && this.videoNode.current.srcObject !== this.props.stream) {
      this.videoNode.current.srcObject = this.props.stream
    }

    let width : number | undefined = 0;
    let height : number | undefined = 0;
    let fps : number | undefined = 0;

    const videoTracks = this.props.stream.getVideoTracks();
    if (videoTracks.length > 0) {
      const settings = videoTracks[0].getSettings();
      if (!height) {
        height = settings.height;
      }
      if (!width) {
        width = settings.width;
      }
      if (!fps) {
        fps = settings.frameRate
      }
    }

    return (
      <div className="peer-cell">
        <video
          ref={this.videoNode}
          autoPlay
          playsInline
          controls />

        {(width && height && fps) ? <VideoResolutionView icon="&#x23FA;" width={width} height={height} fps={fps} /> : null}
      </div>
    )
  }
}

export default RecvMediaView