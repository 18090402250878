

export enum KaldunTicketSubject {
    BroadcastAndView = "broadcast_and_view",
    Broadcast = "broadcast",
    View = "view",
    TalkAndListen = "talk_and_listen",
    Talk = "talk",
    Listen = "listen",
    DatingBroadcast = "dating_broadcast",
    DatingView = "dating_view",
    AudioRoomTalk = "audio_room_talk",
    AudioRoomListen = "audio_room_listen",
}

export enum SciezkaTicketSubject {
    Broadcast = "broadcast",
    View = "view",
    Caller = "caller",
    Callee = "callee",
}

export const CanSendVideo = (subj: KaldunTicketSubject) => {
    switch (subj) {
        case KaldunTicketSubject.Broadcast:
        case KaldunTicketSubject.BroadcastAndView:
        case KaldunTicketSubject.DatingBroadcast:
            return true;
        case KaldunTicketSubject.Listen:
        case KaldunTicketSubject.AudioRoomListen:
        case KaldunTicketSubject.Talk:
        case KaldunTicketSubject.AudioRoomTalk:
        case KaldunTicketSubject.DatingView:
        case KaldunTicketSubject.TalkAndListen:
        case KaldunTicketSubject.View:
            return false;
    }
}

export const CanRecvVideo = (subj: KaldunTicketSubject) => {
    switch (subj) {
        case KaldunTicketSubject.BroadcastAndView:
        case KaldunTicketSubject.View:
        case KaldunTicketSubject.DatingView:
            return true;
        case KaldunTicketSubject.Listen:
        case KaldunTicketSubject.AudioRoomListen:
        case KaldunTicketSubject.Talk:
        case KaldunTicketSubject.AudioRoomTalk:
        case KaldunTicketSubject.TalkAndListen:
        case KaldunTicketSubject.Broadcast:
        case KaldunTicketSubject.DatingBroadcast:
            return false;
    }
}

export const CanSendAudio = (subj: KaldunTicketSubject) => {
    switch (subj) {
        case KaldunTicketSubject.BroadcastAndView:
        case KaldunTicketSubject.Talk:
        case KaldunTicketSubject.AudioRoomTalk:
        case KaldunTicketSubject.TalkAndListen:
        case KaldunTicketSubject.Broadcast:
        case KaldunTicketSubject.DatingBroadcast:
            return true;
        case KaldunTicketSubject.Listen:
        case KaldunTicketSubject.AudioRoomListen:
        case KaldunTicketSubject.DatingView:
        case KaldunTicketSubject.View:
            return false;
    }
}

export default KaldunTicketSubject;