

export enum Features {
    LiveParty = "feature-live-party",
    PushToTalk = "feature-push-to-talk",
    Calls = "feature-calls",
    AudioRoom = "feature-audio-room",
    Dating = "feature-dating",
}

export enum Services {
    Garcon = "service-rtc-garcon",
    Coturn = "service-rtc-coturn",
    Kvashanina = "service-rtc-kvashanina",
    Kaldun = "service-rtc-kaldun",
    Mediasoup = "service-rtc-mediasoup",
    Sciezka = "service-rtc-sciezka",
}