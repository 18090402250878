import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { UserWithSubject, checkGarconUrl, checkSignallingServer, checkTicket, generateNUsers, tryFetchNTickets } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";


export default class GetPushToTalkTicketsInSingleRoom implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.PushToTalk,
        Services.Garcon,
    ]);
    readonly name: string = "Get push to talk tickets in single room";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        const roomId = randomString();

        const users : Array<UserWithSubject> = [];

        users.push(...generateNUsers(roomId, KaldunTicketSubject.Listen, 50));
        users.push(...generateNUsers(roomId, KaldunTicketSubject.Talk, 10));

        const tickets = await tryFetchNTickets(client, { users });

        tickets.forEach(({ user, ticket }) => {
            checkTicket(
                assert,
                {
                    ticket: ticket?.getTicket(),
                    ...user,
                }
            );
        });

        checkSignallingServer(assert, tickets.map(x => x.ticket?.getTicket()));
    }
}