import WebSocketToSignallingServerInLiveParty from "./WebSocketToSignallingServerInLiveParty";
import CoturnInLiveParty from "./CoturnInLiveParty";
import GetFourLivePartyTickets from "./GetFourLivePartyTickets";
import GetLivePartyTicket from "./GetLivePartyTicket";
import { ITestCase } from "./ITestCase";
import QUnit from "qunit";
import JoinToLiveParty from "./JoinToLiveParty";
import FourUsersJoinToLiveParty from "./FourUsersJoinToLiveParty";
import FourUsersJoinSeqToLiveParty from "./FourUsersJoinSeqToLiveParty";
import FourUsersJoinToLivePartyWithMedia from "./FourUsersJoinToLivePartyWithMedia";
import JoinToLivePartyWithReconnect from "./JoinToLivePartyWithReconnect";
import CoturnInPushToTalk from "./CoturnInPushToTalk";
import WebSocketToSignallingServerInPushToTalk from "./WebSocketToSignallingServerInPushToTalk";
import JoinToPushToTalk from "./JoinToPushToTalk";
import GetPushToTalkTickets from "./GetPushToTalkTickets";
import GetPushToTalkTicketsInSingleRoom from "./GetPushToTalkTicketsInSingleRoom";
import JoinToPushToTalkWithReconnect from "./JoinToPushToTalkWithReconnect";
import JoinToLivePartyWithSimulcast from "./JoinToLivePartyWithSimulcast";
import LivePartyTotalProducedBandwidthLimit from "./LivePartyTotalProducedBandwidthLimit";
import JoinToPushToTalkWithDifferentRoles from "./JoinToPushToTalkWithDifferentRoles";
import SamplePttRoom from "./SamplePttRoom";
import SamplePttRoomWithMedia from "./SamplePttRoomWithMedia";
import SamplePttRoomLimitsCheck from "./SamplePttRoomLimitsCheck";
import CoturnInCall from "./CoturnInCall";
import GetTicketsForCall from "./GetTicketsForCall";
import SampleCall from "./SampleCall";
import WebSocketToSignallingServerInCall from "./WebSocketToSignallingServerInCall";
import SampleCallCalleeReconnect from "./SampleCallCalleeReconnect";
import SampleCallCallerReconnect from "./SampleCallCallerReconnect";
import SampleCallWithTwoCalleeDevices from "./SampleCallWithTwoCalleeDevices";
import VerifyIceServersGeneration from "./VerifyIceServersGeneration";
import GetAudioRoomListenTicket from "./GetAudioRoomListenTicket";
import GetAudioRoomTalkTicket from "./GetAudioRoomTalkTicket";
import GetDatingBroadcastTicket from "./GetDatingBroadcastTicket";
import GetDatingViewTicket from "./GetDatingViewTicket";
import SampleAudioRoom from "./SampleAudioRoom";
import SampleAudioRoomLimitsCheck from "./SampleAudioRoomLimitsCheck";
import SampleAudioRoomWithMedia from "./SampleAudioRoomWithMedia";
import SampleDatingRoom from "./SampleDatingRoom";
import SampleDatingRoomWithMedia from "./SampleDatingRoomWithMedia";
import PttTalkBandwidthEstimatorActive from "./PttTalkBandwidthEstimatorActive";
import WebSocketWithoutJoinIsTerminated from "./WebSocketWithoutJoinIsTerminated"


export default function Entrypoint() {
    const testCases : Array<ITestCase> = [
        new GetLivePartyTicket(),
        new GetFourLivePartyTickets(),
        new CoturnInLiveParty(),
        new WebSocketToSignallingServerInLiveParty(),
        new JoinToLiveParty(),
        new FourUsersJoinToLiveParty(),
        new FourUsersJoinSeqToLiveParty(),
        new FourUsersJoinToLivePartyWithMedia(),
        new JoinToLivePartyWithReconnect(),
        new JoinToLivePartyWithSimulcast({allowAutomaticSimulcast: true}),
        new JoinToLivePartyWithSimulcast({allowAutomaticSimulcast: false}),
        new LivePartyTotalProducedBandwidthLimit({videoProducerBandwidthLimitKbps: null}),
        new LivePartyTotalProducedBandwidthLimit({videoProducerBandwidthLimitKbps: 120}),
        new CoturnInPushToTalk(),
        new WebSocketToSignallingServerInPushToTalk(),
        new JoinToPushToTalk(),
        new GetPushToTalkTickets(),
        new GetPushToTalkTicketsInSingleRoom(),
        new JoinToPushToTalkWithReconnect(),
        new JoinToPushToTalkWithDifferentRoles(),
        new SamplePttRoom(),
        new SamplePttRoomWithMedia(),
        new SamplePttRoomLimitsCheck(),
        new CoturnInCall(),
        new GetTicketsForCall(),
        new WebSocketToSignallingServerInCall(),
        new SampleCall(),
        new SampleCallCalleeReconnect(),
        new SampleCallCallerReconnect(),
        new SampleCallWithTwoCalleeDevices(),
        new VerifyIceServersGeneration(),
        new GetAudioRoomListenTicket(),
        new GetAudioRoomTalkTicket(),
        new GetDatingBroadcastTicket(),
        new GetDatingViewTicket(),
        new SampleAudioRoom(),
        new SampleAudioRoomLimitsCheck(),
        new SampleAudioRoomWithMedia(),
        new SampleDatingRoom(),
        new SampleDatingRoomWithMedia(),
        new PttTalkBandwidthEstimatorActive(),
        new WebSocketWithoutJoinIsTerminated(),
    ];

    const modules = new Set<string>(testCases.flatMap(x => [...x.tags]));

    (QUnit as any).jUnitDone((report: { xml: string }) => {
        const reportElement = document.createElement("div");
        reportElement.setAttribute("id", "junit-report");
        reportElement.setAttribute("hidden", "");
        reportElement.appendChild(document.createTextNode(report.xml));
        document.body.appendChild(reportElement);
    });

    modules.forEach(module => {
        QUnit.module(module, function () {
            testCases.forEach(testCase => {
                if (testCase.tags.has(module)) {
                    QUnit.test(testCase.name, testCase.callback.bind(testCase));
                }
            });
        });
    });
}
