import TimeSeries from "./TimeSeries";

export default class CounterMetric {
    private ts: number | undefined = undefined;
    private val: number | undefined = undefined;
    private deriv: number | undefined = undefined;
    private inc: number | undefined = undefined;
    private derivTs: TimeSeries;

    constructor(name: string, capacity: number) {
        this.derivTs = new TimeSeries(name, capacity);
    }

    public update(value: number, timestamp: number) {
        const old_ts = this.ts;
        const old_val = this.val;
        this.ts = timestamp;
        this.val = value;
        if (typeof old_ts !== 'undefined' && typeof old_val !== 'undefined') {
            this.inc = (this.val - old_val);
            const deriv = this.inc / ((this.ts - old_ts)/1000);
            this.derivTs.addValue(deriv, timestamp);
            this.deriv = deriv;
        }
    }

    public get isEmpty() {
        return typeof this.val === 'undefined';
    }

    public get value(): number {
        return this.val || 0;
    }

    public get derivative(): number {
        return this.deriv || 0;
    }

    public get increase(): number {
        return this.inc || 0
    }

    public get derivativeTimeSeries(): TimeSeries {
        return this.derivTs;
    }
}