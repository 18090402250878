import React from 'react'
import * as messages from '../client/Messages'

type Props = {
  src?: messages.MediaSourceV
  status?: messages.TrackStatus
  paused?: boolean
  forwarded_layer?: messages.ConsumedVideoLayer
  score?: messages.ConsumedTrackScore
}

class ReceivedVideoTrackInfoView extends React.PureComponent<Props, {}> {
    render() {
        let sourceText = null;
        switch (this.props.src?.vid) {
          case messages.VideoSource.Camera: {
            sourceText = "camera";
            break;
          }
          case messages.VideoSource.Screen: {
            sourceText = "screen";
            break;
          }
          default: {
            sourceText = "unknown";
            break;
          } 
        }
        let scoreText = null;
        let statusText = null;
        let layersText = null;
        if (this.props.paused) {
          statusText = "you've chosen not to receive it"
        } else {
          switch (this.props.status) {
            case messages.TrackStatus.On: {
              statusText = "live";
              let score = this.props.score
              if (score) {
                scoreText = `score: ${score.score}, producer_score: ${score.producer_score}, producer_scores: ${score.producer_scores}`
              }
              let fwdLayer = this.props.forwarded_layer
              if (fwdLayer) {
                if ('none' in fwdLayer) {
                  layersText = `none`
                } else {
                  layersText = `S ${fwdLayer.layer.spatial} T ${fwdLayer.layer.temporal}`
                }
              }
              break;
            }
            case messages.TrackStatus.Muted: {
              statusText = "muted by sender";
              break;
            }
            case messages.TrackStatus.Off: {
              statusText = "turned off by sender";
              break;
            }
          }
        }
    
        return <span className="track-info">video from {sourceText} source, {statusText}, layers {layersText}, {scoreText}</span>
    }
};

export default ReceivedVideoTrackInfoView;