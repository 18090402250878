import React from 'react'
import Button from '@mui/material/Button'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';

type State = {
  hidden: boolean
}

type Props = {
  cameraEnabled: boolean
  toggleCameraEnabled: (enable: boolean) => void

  microphoneEnabled: boolean
  toggleMicrophoneEnabled: (enable: boolean) => void

  screenEnabled: boolean
  toggleScreenEnabled: (enable: boolean) => void

  closeWebSocket: () => void

  restartIce: () => void

  switchCamera: () => void;
}

class SentMediaControls extends React.Component<Props, State> {
  private timerId = 0

  constructor(props: Props) {
    super(props)

    this.state = {
      hidden: true
    }
  }

  private onMouseMove() {
    if (this.timerId !== 0) {
      return
    }

    this.setState({hidden: false})

    this.timerId = window.setTimeout(() => {
      this.setState({hidden: true})
      this.timerId = 0
    }, 2000)
  }


  render() {
    return (
      <div className = {`button-box ${this.state.hidden ? "hidden" : ""}`} onMouseMove = {() => {this.onMouseMove()}}>
        <Tooltip title={`Turn ${this.props.cameraEnabled ? "off" : "on"} your camera`}>
          <Button variant="contained"  onClick={() => this.props.toggleCameraEnabled(!this.props.cameraEnabled)}>
            <VideoCameraFrontIcon opacity={this.props.cameraEnabled ? 1 : 0.15} />
          </Button>
        </Tooltip>

        <Tooltip title={`Turn ${this.props.microphoneEnabled ? "off" : "on"} your microphone recording`}>
          <Button variant="contained"  onClick={() => this.props.toggleMicrophoneEnabled(!this.props.microphoneEnabled)}>
            {this.props.microphoneEnabled ? <MicIcon /> : <MicOffIcon />}
          </Button>
        </Tooltip>

        <Tooltip title={`Turn ${this.props.screenEnabled ? "off" : "on"} sharing your screen`}>
          <Button variant="contained"  onClick={() => this.props.toggleScreenEnabled(!this.props.screenEnabled)}>
            {this.props.screenEnabled ? <ScreenShareIcon /> : <StopScreenShareIcon />}
          </Button>
        </Tooltip>

        <Tooltip title="Force close WebSocket">
          <Button variant="contained"  onClick={() => this.props.closeWebSocket()}>
            <PortableWifiOffIcon />
          </Button>
        </Tooltip>

        <Tooltip title="Force restart ICE">
          <Button variant="contained"  onClick={() => this.props.restartIce()}>
            <RestartAltIcon />
          </Button>
        </Tooltip>

        <Tooltip title="Switch camera">
          <Button variant="contained"  onClick={() => { if (this.props.cameraEnabled) { this.props.switchCamera(); } }}>
            <CameraswitchIcon />
          </Button>
        </Tooltip>
      </div>
    )
  }
}

export default SentMediaControls