import React from 'react'
import * as rx from 'rxjs'
import {
    RoomController,
    RoomMemberViewState
} from '../client/RoomController'
import SentMediaControls from './SentMediaControls'
import RoomMembersView from './RoomMembersView'
import TransportStatisticsView from './TransportStatisticsView'
import {KaldunTicketSubject, CanSendAudio, CanSendVideo} from '../client/TicketSubject'
import { IcePolicy, OutgoingMediaViewState, TransportStatisticsViewState } from '../client/Domain'
import { getGarconUrl } from '../Utils'

type RoomViewProps = {
    roomId: string,
    accountId: string,
    clientId: string,
    region: string | null,
    subject: KaldunTicketSubject,
    displayName: string,
    enableTcp: boolean,
    icePolicy: IcePolicy,
    maxCameraCaptureDimension: number | null,
    allowAutomaticSimulcast: boolean | null,
    videoProducerBandwidthLimitKbps: number | null,
}

type RoomViewState = {
    members: Array<RoomMemberViewState>,
    outgoingMedia: null | OutgoingMediaViewState,
    transportStatistics: null | TransportStatisticsViewState
}

export default class RoomView extends React.Component<RoomViewProps, RoomViewState> {
    private roomController: RoomController | null = null;
    private viewStateUpdatedSub: rx.SubscriptionLike | null = null;
    private facingMode: "user" | "environment" = "user";

    constructor(props: RoomViewProps) {
        super(props)
        this.state = {
            members: [],
            outgoingMedia: null,
            transportStatistics: null,
        }
    }

    private readonly handleTabClose = () => {
        if (this.roomController != null) {
            this.roomController.leave();
        }
    }

    private readonly switchCamera = () => {
        if (this.facingMode === "user") {
            this.facingMode = "environment";
        } else {
            this.facingMode = "user";
        }
        console.log(`Switch facing mode to ${this.facingMode}`);
        this.roomController?.startCameraTrack(this.facingMode);
        this.roomController?.sendCameraVideo();
    };

    private readonly toggleCameraEnabled = (enable: boolean) => {
        if (this.roomController) {
            if (enable) {
                this.roomController.startCameraTrack(this.facingMode);
                this.roomController.sendCameraVideo();
            } else {
                this.roomController.stopCameraTrack();
            }
            this.roomController.updateProducedMedia();
        }
    }

    private readonly toggleScreenEnabled = (enable: boolean) => {
        if (this.roomController) {
            if (enable) {
                this.roomController.startScreenTracks();
                this.roomController.sendScreenVideoAndAudio();
            } else {
                this.roomController.stopScreenTracks();
            }
            this.roomController.updateProducedMedia();
        }
    }

    private readonly toggleMicrophoneEnabled = (enabled: boolean) => {
        if (this.roomController) {
            if (enabled) {
                this.roomController.startMicrophoneTrack();
                this.roomController.sendMicrophoneTrack();
            } else {
                this.roomController.stopMicrophoneTrack();
            }
            this.roomController.updateProducedMedia();
        }
    }

    private readonly closeWebSocket = () => {
        if (this.roomController) {
            this.roomController.closeWebSocket()
        }
    }

    private readonly restartIce = () => {
        if (this.roomController) {
            this.roomController.restartIce()
        }
    }

    async componentDidMount() {
        const garconUrl = await getGarconUrl();
        if (!garconUrl) {
            console.error("Cannot get garcon url");
            return;
        }

        let roomController = new RoomController({
            accountId: this.props.accountId,
            clientId: this.props.clientId,
            roomId: this.props.roomId,
            displayName: this.props.displayName,
            region: this.props.region,
            garconUrl: garconUrl,
            subject: this.props.subject,
            enableTcp: this.props.enableTcp,
            icePolicy: this.props.icePolicy,
            maxCameraCaptureDimension: this.props.maxCameraCaptureDimension,
            allowAutomaticSimulcast: this.props.allowAutomaticSimulcast,
            videoProducerBandwidthLimitKbps: this.props.videoProducerBandwidthLimitKbps
        });
        let viewStateUpdatedSub = roomController.viewStateChanged.subscribe(viewState => {
            this.setState({
                members: viewState.members,
                outgoingMedia: viewState.outgoingMedia,
                transportStatistics: viewState.transportStatistics,
            })
        })
        await roomController.join();

        if (CanSendAudio(this.props.subject) || CanSendVideo(this.props.subject)) {
            if (CanSendAudio(this.props.subject)) {
                await roomController.startMicrophoneTrack();
                await roomController.sendMicrophoneTrack();
            }
            if (CanSendVideo(this.props.subject)) {
                await roomController.startCameraTrack(this.facingMode);
                await roomController.sendCameraVideo();
            }

            await roomController.updateProducedMedia();
        }

        this.roomController = roomController;
        this.viewStateUpdatedSub = viewStateUpdatedSub;

        window.addEventListener('beforeunload', this.handleTabClose);
    }

    async componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleTabClose);

        if (this.viewStateUpdatedSub !== null) {
            this.viewStateUpdatedSub.unsubscribe();
            this.viewStateUpdatedSub = null;
        }
        if (this.roomController !== null) {
            await this.roomController.leave();
            this.roomController = null;
        }
    }

    render() {
        let outgoingMediaControls = null;
        if (this.state.outgoingMedia) {
            outgoingMediaControls = <SentMediaControls
                cameraEnabled={this.state.outgoingMedia.microphoneAndCamera.stream.getVideoTracks().length > 0}
                microphoneEnabled={this.state.outgoingMedia.microphoneAndCamera.stream.getAudioTracks().length > 0}
                screenEnabled={this.state.outgoingMedia.screen.stream.getVideoTracks().length > 0}
                toggleCameraEnabled={this.toggleCameraEnabled}
                toggleMicrophoneEnabled={this.toggleMicrophoneEnabled}
                toggleScreenEnabled={this.toggleScreenEnabled}
                closeWebSocket={this.closeWebSocket}
                restartIce={this.restartIce}
                switchCamera={this.switchCamera} />
        }
        let transportStatisticsView = null;
        if (this.state.transportStatistics) {
            transportStatisticsView = <TransportStatisticsView {...this.state.transportStatistics} />
        }

        return (
            <div className="room-view">
                {transportStatisticsView}
                <RoomMembersView
                    members={this.state.members}
                    setPausedForIncomingTracks={(tracks) => {
                        this.roomController?.setPausedForIncomingTracks(tracks);
                    }}
                    updatePrefferedLayer={(mid, layer) => {
                        this.roomController?.updatePrefferedLayer(mid, layer)
                    }}
                    outgoingMedia={this.state.outgoingMedia}
                    displayName={this.props.displayName} />
                {outgoingMediaControls}
            </div>
        )
    }
}