import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { checkGarconUrl, checkTicket, tryFetchTicket } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";


export default class GetPushToTalkTickets implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.PushToTalk,
        Services.Garcon,
    ]);
    readonly name: string = "Get single ticket for push to talk for each role(talk, listen, talk and listen)";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        for (let subject of [KaldunTicketSubject.Listen, KaldunTicketSubject.Talk, KaldunTicketSubject.TalkAndListen]) {
            const accountId = randomString();
            const clientId = randomString();
            const roomId = randomString();

            const ticket = await tryFetchTicket(client, {
                accountId,
                clientId,
                roomId,
                subject,
            });
    
            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                accountId,
                clientId,
                roomId,
                subject,
            });
        }

        
    }
}