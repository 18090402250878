import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { Ticket } from "../generated/submodules/garcon-api/tickets_pb";
import { User, checkGarconUrl, checkSignallingServer, checkTicket, generateNUsers, tryFetchNTickets } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";


export default class GetFourLivePartyTickets implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.LiveParty,
        Services.Garcon,
    ]);
    readonly name: string = "Get four tickets for single Live Party";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        const roomId = randomString();

        const users = generateNUsers(roomId, KaldunTicketSubject.BroadcastAndView, 4);
        
        const tickets = await tryFetchNTickets(client, { users });

        tickets.forEach(({ user, ticket }) => {
            checkTicket(
                assert,
                {
                    ticket: ticket?.getTicket(),
                    accountId: user.accountId,
                    clientId: user.clientId,
                    roomId,
                    subject: KaldunTicketSubject.BroadcastAndView
                }
            );
        });

        const verifiedTickets = tickets as Array<{ ticket: Ticket, user: User }>;

        checkSignallingServer(assert, verifiedTickets.map(x => x.ticket.getTicket()));
    }
}