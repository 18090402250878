import React from 'react'
import memoizeOne from 'memoize-one'

type Props = {
    aspectRatio: number,
    children: React.ReactNode[] | React.ReactNode
}

type State = {
    containerHeight: number,
    containerWidth: number,
}

interface ContainerLayout {
    area: number
    rows: number
    cols: number
    width: number
    height: number
}

export default class GridView extends React.Component<Props, State> {

    private readonly rootRef = React.createRef<HTMLDivElement>();

    private readonly onSizeChanged = () => {
        if (this.rootRef.current) {
            console.log(`Size changed ${this.rootRef.current.clientHeight}x${this.rootRef.current.clientWidth}`);
            this.setState({
                containerHeight: this.rootRef.current.clientHeight,
                containerWidth: this.rootRef.current.clientWidth,
            });
        }
    };

    private readonly sizeObserver = new ResizeObserver(this.onSizeChanged);

    // https://github.com/fzembow/rect-scaler
    private readonly calculateLayout = memoizeOne((containerWidth: number, containerHeight: number, count: number, aspectRatio: number): ContainerLayout => {
        console.log(`Calculating new grid layout for container ${containerWidth}x${containerHeight} and ${count} items with aspect ration ${aspectRatio}`)

        let layout: ContainerLayout = {
            area: 0,
            rows: 0,
            cols: 0,
            width: 0,
            height: 0
        }

        // brute-force search layout where video occupy the largest area of the container
        for (let cols = 1; cols <= count; ++cols) {
            const rows = Math.ceil(count / cols)
            const hScale = containerWidth / (cols * aspectRatio)
            const vScale = containerHeight / rows

            let width = 0
            let height = 0

            if (hScale <= vScale) {
                width = Math.floor(containerWidth / cols)
                height = Math.floor(width / aspectRatio)
            } else {
                height = Math.floor(containerHeight / rows)
                width = Math.floor(height * aspectRatio)
            }

            const area = width * height
            if (area > layout.area) {
                layout = {
                    area: area,
                    rows: rows,
                    cols: cols,
                    width: width,
                    height: height
                }
            }
        }

        return layout
    })

    constructor(props: Props) {
        super(props)
        this.state = {
            containerHeight: 0,
            containerWidth: 0,
        }
    }

    componentDidMount() {
        if (this.rootRef.current) {
            this.onSizeChanged();
            this.sizeObserver.observe(this.rootRef.current);
        }
    }

    componentWillUnmount() {
        if (this.rootRef.current) {
            this.sizeObserver.unobserve(this.rootRef.current);
        }
    }

    render() {
        const children = React.Children.toArray(this.props.children).filter(ch => ch !== null);
        const count = children.length;
        const layout = this.calculateLayout(this.state.containerWidth, this.state.containerHeight, count, this.props.aspectRatio)

        const style: React.CSSProperties = {
            ["--width" as any]: `${layout.width}px`,
            ["--height" as any]: `${layout.height}px`,
            ["--cols" as any]: layout.cols,
        }
        return (
            <div ref={this.rootRef} style={style} className={"grid-items-view-root"}>
                <div className={"grid-items-view"}>
                    {children.map(ch => {
                        return (<div className={"grid-items-cell"}>
                            {ch}
                        </div>)
                    })}
                </div>
            </div>
        )
    }
}