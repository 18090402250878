import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { SciezkaTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { RegionalIceServers } from "../generated/submodules/garcon-api/tickets_pb";
import { checkGarconUrl, checkRegionalCoturnServers, checkTicket, tryFetchSciezkaTicket } from "./Utils";
import { SciezkaTicketSubject } from "../client/TicketSubject";
import { IcePolicy } from "../client/Domain";


export default class CoturnInCall implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.Calls,
        Services.Garcon,
        Services.Coturn,
    ]);
    readonly name: string = "Check connection for turn servers from Call tickets";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new SciezkaTicketsClient(garconUrl);

        async function getServers(subject: SciezkaTicketSubject): Promise<Array<RegionalIceServers> | undefined> {
            const accountId = randomString();
            const clientId = randomString();
            const roomId = randomString();

            const ticket = await tryFetchSciezkaTicket(client, {
                accountId,
                clientId,
                roomId,
                subject,
            });

            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                accountId,
                clientId,
                roomId,
                subject,
            });

            return ticket?.getIceServersList();
        }

        const subjects = [
            SciezkaTicketSubject.Caller,
            SciezkaTicketSubject.Callee,
        ];

        for (let subject of subjects) {
            const initiatorServers = await getServers(subject);
            const receiverServers = await getServers(subject);
            
            assert.true(!!initiatorServers, `Initiator with subject = ${subject} coturn servers exists`);
            assert.true(!!receiverServers, `Receiver with subcjet = ${subject} coturn servers exists`);

            if (!initiatorServers || !receiverServers) {
                return;
            }

            await checkRegionalCoturnServers(
                assert,
                subject,
                initiatorServers,
                receiverServers,
                [
                    IcePolicy.All,
                    IcePolicy.Relay,
                    IcePolicy.TcpRelay,
                    IcePolicy.TlsRelay,
                    IcePolicy.UdpRelay,
                ]
            );
        }
        
    }
}