export default class TaskCompletionSource<T> {
    private readonly promise: Promise<T>;
    private resolve: null | ((value: T | PromiseLike<T>) => void) = null;
    private reject: null | ((reason?: any) => void) = null;

    public constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    public get task(): Promise<T> {
        return this.promise;
    }

    public trySetResult(result: T): boolean {
        if (!this.resolve) {
            return false;
        }
        this.resolve(result);
        this.resolve = null;
        return true;
    }

    public trySetException(error: Error): boolean {
        if (!this.reject) {
            return false;
        }
        this.reject(error);
        this.reject = null;
        return true;
    }
}
