import { PeerState, MemberViewState } from './PeerState'
import { AwaitQueue } from 'awaitqueue'
import { Delay } from './Delay'
import * as rx from 'rxjs'
import * as rxops from 'rxjs/operators'
import * as stat from './WebRTCStatistics'
import TimeSeries from './TimeSeries'
import CounterMetric from './CounterMetric'
import { SciezkaTicketSubject } from './TicketSubject'
import { IcePolicy, OutgoingMediaStreamStatsState, OutgoingMediaViewState, TransportStatisticsViewState, VideoLayerStat } from './Domain';
import CallSignaling from './CallSignaling';
import '../generated/submodules/sciezka-messages/client_messages'
import FollowerSession from './FollowerSession'
import { compressData, createProtoRTCStatsReport, getDictionary } from './StatsConverter'
import { sciezka_messages } from '../generated/submodules/sciezka-messages/client_messages'
import { SciezkaTicketsClient } from '../generated/submodules/garcon-api/TicketsServiceClientPb'
import { Ticket, TicketRequest, TicketResponse } from '../generated/submodules/garcon-api/tickets_pb'

interface FollowerConfiguration {
    callId: string;
    accountId: string;
    clientId: string;
    garconUrl: string;
    subject: SciezkaTicketSubject,
    icePolicy: IcePolicy,
    maxCameraCaptureDimension: number | null,
    region: string | null,
}

export type FollowerMemberViewState = MemberViewState;

export type FollowerViewState = {
    peer: MemberViewState | null,
    outgoingMedia: null | OutgoingMediaViewState,
    transportStatistics: null | TransportStatisticsViewState
}

export class FollowerController {
    private zstdDictionary : { etag: string, buffer: ArrayBuffer } | null = null;

    private readonly queue = new AwaitQueue();
    private readonly configuration: FollowerConfiguration;
    private readonly iceServers : Array<RTCIceServer> = [];
    private readonly cameraAndMicrophone: OutgoingMediaStreamStatsState = {
        stream: new MediaStream(),
        audioTrackStat: {
            audioLevel: new TimeSeries("audioLevel", 60),
            echoReturnLoss: new TimeSeries("echoReturnLoss", 60),
            bytesSent: new CounterMetric("bytesSent", 60),
            packetsSent: new CounterMetric("packetsSent", 60),
            headerBytesSent: new CounterMetric("headerBytesSent", 60),
            bytesDiscardedOnSend: new CounterMetric("bytesDiscardedOnSend", 60),
            packetsDiscardedOnSend: new CounterMetric("packetsDiscardedOnSend", 60),
            targetBitrate: new TimeSeries("targetBitrate", 60),
            retransmittedBytesSent: new CounterMetric("retransmittedBytesSent", 60),
            retransmittedPacketsSent: new CounterMetric("retransmittedPacketsSent", 60),
            nackCount: new CounterMetric("nackCount", 60),
            jitter: new TimeSeries("jitter", 60),
            packetsLost: new CounterMetric("packetsLost", 60),
            fractionLost: new TimeSeries("fractionLost", 60),
            roundTripTime: new TimeSeries("roundTripTime", 60),
            roundTripTimeMeasurements: new CounterMetric("roundTripTimeMeasurements", 60),
            totalRoundTripTime: new CounterMetric("totalRoundTripTime", 60),
        },
        videoTrackStat: {
            layers: new Map()
        }
    };

    private readonly screenAndAudio: OutgoingMediaStreamStatsState = {
        stream: new MediaStream(),
        audioTrackStat: {
            audioLevel: new TimeSeries("audioLevel", 60),
            echoReturnLoss: new TimeSeries("echoReturnLoss", 60),
            bytesSent: new CounterMetric("bytesSent", 60),
            packetsSent: new CounterMetric("packetsSent", 60),
            headerBytesSent: new CounterMetric("headerBytesSent", 60),
            bytesDiscardedOnSend: new CounterMetric("bytesDiscardedOnSend", 60),
            packetsDiscardedOnSend: new CounterMetric("packetsDiscardedOnSend", 60),
            targetBitrate: new TimeSeries("targetBitrate", 60),
            retransmittedBytesSent: new CounterMetric("retransmittedBytesSent", 60),
            retransmittedPacketsSent: new CounterMetric("retransmittedPacketsSent", 60),
            nackCount: new CounterMetric("nackCount", 60),
            jitter: new TimeSeries("jitter", 60),
            packetsLost: new CounterMetric("packetsLost", 60),
            fractionLost: new TimeSeries("fractionLost", 60),
            roundTripTime: new TimeSeries("roundTripTime", 60),
            roundTripTimeMeasurements: new CounterMetric("roundTripTimeMeasurements", 60),
            totalRoundTripTime: new CounterMetric("totalRoundTripTime", 60),
        },
        videoTrackStat: {
            layers: new Map()
        }
    };

    private readonly transportStatistics: {
        iceState?: RTCIceTransportState
        bytesSent: CounterMetric;
        bytesReceived: CounterMetric;
        packetsSent: CounterMetric;
        packetsReceived: CounterMetric;
        candidatePair?: {
            id: string;
            bytesSent: CounterMetric;
            bytesReceived: CounterMetric;
            packetsSent: CounterMetric;
            packetsReceived: CounterMetric;
            currentRoundTripTime: TimeSeries;
            availableOutgoingBitrate: TimeSeries;
            availableIncomingBitrate: TimeSeries;
            local: {
                address?: string
                port?: number
                protocol?: string
                relayProtocol?: string
                candidateType?: string
            }
            remote: {
                address?: string
                port?: number
                protocol?: string
            }
        }
    } = {
        bytesSent: new CounterMetric("transportBytesSent", 60),
        bytesReceived: new CounterMetric("transportBytesReceived", 60),
        packetsSent: new CounterMetric("transportPacketsSent", 60),
        packetsReceived: new CounterMetric("transportPacketsReceived", 60),
    }

    private readonly signaling = new CallSignaling();
    private readonly messageReceivedSub: rx.SubscriptionLike;

    private session: null | {
        obj: FollowerSession,
        iceRestartSub: rx.SubscriptionLike
        statisticsSub: rx.SubscriptionLike
        iceCandidateSub: rx.SubscriptionLike
        messageSub: rx.SubscriptionLike
    } = null;

    private viewStateChangedSubj = new rx.Subject<void>();
    private peerState = new PeerState();

    private get cameraTrack(): MediaStreamTrack | null {
        const videoTracks = this.cameraAndMicrophone.stream.getVideoTracks();
        if (videoTracks.length === 0) {
            return null;
        }
        return videoTracks[0];
    }

    private set cameraTrack(track: MediaStreamTrack | null) {
        if (track && track.kind !== "video") {
            throw new Error("Wrong track kind");
        }
        const videoTracks = this.cameraAndMicrophone.stream.getVideoTracks();
        for (const vt of videoTracks) {
            if (track !== vt) {
                vt.stop();
            }
            this.cameraAndMicrophone.stream.removeTrack(vt);
        }
        if (track !== null) {
            this.cameraAndMicrophone.stream.addTrack(track);
        }
        this.viewStateChangedSubj.next();
    }

    private get screenVideoTrack(): MediaStreamTrack | null {
        const videoTracks = this.screenAndAudio.stream.getVideoTracks();
        if (videoTracks.length === 0) {
            return null;
        }
        return videoTracks[0];
    }

    private set screenVideoTrack(track: MediaStreamTrack | null) {
        if (track && track.kind !== "video") {
            throw new Error("Wrong track kind");
        }
        const videoTracks = this.screenAndAudio.stream.getVideoTracks();
        for (const vt of videoTracks) {
            if (track !== vt) {
                vt.stop();
            }
            this.screenAndAudio.stream.removeTrack(vt);
        }
        if (track !== null) {
            this.screenAndAudio.stream.addTrack(track);
        }
        this.viewStateChangedSubj.next();
    }

    private get screenAudioTrack(): MediaStreamTrack | null {
        const audioTracks = this.screenAndAudio.stream.getAudioTracks();
        if (audioTracks.length === 0) {
            return null;
        }
        return audioTracks[0];
    }

    private set screenAudioTrack(track: MediaStreamTrack | null) {
        if (track && track.kind !== "audio") {
            throw new Error("Wrong track kind 'audio'");
        }
        const audioTracks = this.screenAndAudio.stream.getAudioTracks();
        for (const at of audioTracks) {
            if (track !== at) {
                at.stop();
            }
            this.screenAndAudio.stream.removeTrack(at);
        }
        if (track !== null) {
            this.screenAndAudio.stream.addTrack(track);
        }
        this.viewStateChangedSubj.next();
    }

    private get microphoneTrack(): MediaStreamTrack | null {
        const audioTracks = this.cameraAndMicrophone.stream.getAudioTracks();
        if (audioTracks.length === 0) {
            return null;
        }
        return audioTracks[0];
    }

    private set microphoneTrack(track: MediaStreamTrack | null) {
        if (track && track.kind !== "audio") {
            throw new Error("Wrong track kind 'audio'");
        }
        const audioTracks = this.cameraAndMicrophone.stream.getAudioTracks();
        for (const at of audioTracks) {
            if (track !== at) {
                at.stop();
            }
            this.cameraAndMicrophone.stream.removeTrack(at);
        }
        if (track !== null) {
            this.cameraAndMicrophone.stream.addTrack(track);
        }
        this.viewStateChangedSubj.next();
    }

    constructor(configuration: FollowerConfiguration) {
        this.configuration = configuration;
        this.messageReceivedSub = this.signaling.messageReceived.subscribe(s => this.onMessageReceived(s));
    }

    private get transportStatisticsViewState(): null | TransportStatisticsViewState {
        return this.session != null ? {
            bytesReceived: {
                total: this.transportStatistics.bytesReceived.value,
                derivative: this.transportStatistics.bytesReceived.derivative,
            },
            bytesSent: {
                total: this.transportStatistics.bytesSent.value,
                derivative: this.transportStatistics.bytesSent.derivative,
            },
            packetsReceived: {
                total: this.transportStatistics.packetsReceived.value,
                derivative: this.transportStatistics.packetsReceived.derivative,
            },
            packetsSent: {
                total: this.transportStatistics.packetsSent.value,
                derivative: this.transportStatistics.packetsSent.derivative,
            },
            candidatePair: this.transportStatistics.candidatePair ? {
                bytesReceived: {
                    total: this.transportStatistics.candidatePair.bytesReceived.value,
                    derivative: this.transportStatistics.candidatePair.bytesReceived.derivative,
                },
                bytesSent: {
                    total: this.transportStatistics.candidatePair.bytesSent.value,
                    derivative: this.transportStatistics.candidatePair.bytesSent.derivative,
                },
                packetsReceived: {
                    total: this.transportStatistics.candidatePair.packetsReceived.value,
                    derivative: this.transportStatistics.candidatePair.packetsReceived.derivative,
                },
                packetsSent: {
                    total: this.transportStatistics.candidatePair.packetsSent.value,
                    derivative: this.transportStatistics.candidatePair.packetsSent.derivative,
                },
                availableIncomingBitrate: this.transportStatistics.candidatePair.availableIncomingBitrate.lastValue,
                availableOutgoingBitrate: this.transportStatistics.candidatePair.availableOutgoingBitrate.lastValue,
                currentRoundTripTime: this.transportStatistics.candidatePair.currentRoundTripTime.lastValue,
                local: {
                    address: this.transportStatistics.candidatePair.local.address,
                    port: this.transportStatistics.candidatePair.local.port,
                    protocol: this.transportStatistics.candidatePair.local.protocol,
                    relayProtocol: this.transportStatistics.candidatePair.local.relayProtocol,
                    candidateType: this.transportStatistics.candidatePair.local.candidateType,
                },
                remote: {
                    address: this.transportStatistics.candidatePair.remote.address,
                    port: this.transportStatistics.candidatePair.remote.port,
                    protocol: this.transportStatistics.candidatePair.remote.protocol,
                }
            } : undefined
        } : null;
    }

    private get outgoingMediaViewState(): null | OutgoingMediaViewState {
        const micTack = this.microphoneTrack;
        const camTrack = this.cameraTrack;
        const screenVideoTrack = this.screenVideoTrack;
        const screenAudioTrack = this.screenAudioTrack;

        const createVideoTrackStats = (track: MediaStreamTrack | null, streamState: OutgoingMediaStreamStatsState) => {
            return track ? {
                captureFps: streamState.videoTrackStat.captureFps,
                captureHeight: streamState.videoTrackStat.captureHeight,
                captureWidth: streamState.videoTrackStat.captureWidth,
                layers: Array.from(streamState.videoTrackStat.layers.values()).map(layer => {
                    return ({
                        ssrc: layer.ssrc,
                        rtxSsrc: layer.rtxSsrc,
                        codec: layer.codec,
                        codecPayloadType: layer.codecPayloadType,
                        rid: layer.rid,
                        packetsSent: {
                            total: layer.packetsSent.value,
                            derivative: layer.packetsSent.derivative,
                        },
                        bytesSent: {
                            total: layer.bytesSent.value,
                            derivative: layer.bytesSent.derivative,
                        },
                        headerBytesSent: {
                            total: layer.headerBytesSent.value,
                            derivative: layer.headerBytesSent.derivative,
                        },
                        packetsDiscardedOnSend: {
                            total: layer.packetsDiscardedOnSend.value,
                            derivative: layer.packetsDiscardedOnSend.derivative,
                        },
                        bytesDiscardedOnSend: {
                            total: layer.bytesDiscardedOnSend.value,
                            derivative: layer.bytesDiscardedOnSend.derivative,
                        },
                        retransmittedPacketsSent: {
                            total: layer.retransmittedPacketsSent.value,
                            derivative: layer.retransmittedPacketsSent.derivative,
                        },
                        retransmittedBytesSent: {
                            total: layer.retransmittedBytesSent.value,
                            derivative: layer.retransmittedBytesSent.derivative,
                        },
                        targetBitrate: {
                            value: layer.targetBitrate.lastValue || -1,
                        },
                        encodedFramesPerSecond: {
                            value: layer.encodedFramesPerSecond.lastValue || -1,
                        },
                        framesSent: {
                            total: layer.framesSent.value,
                            derivative: layer.framesSent.derivative,
                        },
                        framesEncoded: {
                            total: layer.framesEncoded.value,
                            derivative: layer.framesEncoded.derivative,
                        },
                        keyFramesEncoded: {
                            total: layer.keyFramesEncoded.value,
                            derivative: layer.keyFramesEncoded.derivative,
                        },
                        framesDiscardedOnSend: {
                            total: layer.framesDiscardedOnSend.value,
                            derivative: layer.framesDiscardedOnSend.derivative,
                        },
                        hugeFramesSent: {
                            total: layer.hugeFramesSent.value,
                            derivative: layer.hugeFramesSent.derivative,
                        },
                        firCount:  {
                            total: layer.firCount.value,
                            derivative: layer.firCount.derivative,
                        },
                        pliCount:  {
                            total: layer.pliCount.value,
                            derivative: layer.pliCount.derivative,
                        },
                        nackCount:  {
                            total: layer.nackCount.value,
                            derivative: layer.nackCount.derivative,
                        },
                        width: layer.frameWidth.lastValue || 0,
                        height: layer.frameHeight.lastValue || 0,
                        qualityLimitationReason: layer.qualityLimitationReason,
                        qualityLimitationDuration: {
                            none: layer.qualityLimitationDuration.none.value / 1000,
                            cpu: layer.qualityLimitationDuration.cpu.value / 1000,
                            bandwidth: layer.qualityLimitationDuration.bandwidth.value / 1000,
                            other: layer.qualityLimitationDuration.other.value / 1000,
                        },
                        encodeTime: {
                            total: layer.totalEncodeTime.value,
                            msPerFrame: layer.framesEncoded.derivative / layer.totalEncodeTime.increase
                        },
                        packetSendDelay: {
                            total: layer.totalPacketSendDelay.value,
                            derivative: layer.totalPacketSendDelay.derivative
                        },
                        jitter: layer.jitter.isEmpty ? null : {
                            value: (layer.jitter.lastValue || 0),
                        },
                        rtt: (layer.roundTripTime.isEmpty || layer.roundTripTimeMeasurements.value === 0) ? null : {
                            current: (layer.roundTripTime.lastValue || 0),
                            average: layer.totalRoundTripTime.value / layer.roundTripTimeMeasurements.value,
                        },
                        estimatedPacketsLoss: (layer.packetsLost.isEmpty || layer.fractionLost.isEmpty) ? null : {
                            fractionLost: layer.fractionLost.lastValue || 0,
                            packetsLost: layer.packetsLost.value || 0,
                        }
                    })
                }).sort((x, y) => x.width * x.height - y.width * y.height)
            } : undefined;
        }

        const createAudioTrackStats = (track: MediaStreamTrack | null, streamState: OutgoingMediaStreamStatsState) => {
            return track ? {
                codec: streamState.audioTrackStat.codec || "unknown",
                codecPayloadType: streamState.audioTrackStat.codecPayloadType || -1,
                ssrc: streamState.audioTrackStat.ssrc || -1,
                packetsSent: {
                    total: streamState.audioTrackStat.packetsSent.value,
                    derivative: streamState.audioTrackStat.packetsSent.derivative,
                },
                bytesSent: {
                    total: streamState.audioTrackStat.bytesSent.value,
                    derivative: streamState.audioTrackStat.bytesSent.derivative,
                },
                retransmittedBytesSent: {
                    total: streamState.audioTrackStat.retransmittedBytesSent.value,
                    derivative: streamState.audioTrackStat.retransmittedBytesSent.derivative,
                },
                retransmittedPacketsSent: {
                    total: streamState.audioTrackStat.retransmittedPacketsSent.value,
                    derivative: streamState.audioTrackStat.retransmittedPacketsSent.derivative,
                },
                headerBytesSent: {
                    total: streamState.audioTrackStat.headerBytesSent.value,
                    derivative: streamState.audioTrackStat.headerBytesSent.derivative,
                },
                bytesDiscardedOnSend: {
                    total: streamState.audioTrackStat.bytesDiscardedOnSend.value,
                    derivative: streamState.audioTrackStat.bytesDiscardedOnSend.derivative,
                },
                packetsDiscardedOnSend: {
                    total: streamState.audioTrackStat.packetsDiscardedOnSend.value,
                    derivative: streamState.audioTrackStat.packetsDiscardedOnSend.derivative,
                },
                nackCount: {
                    total: streamState.audioTrackStat.nackCount.value,
                    derivative: streamState.audioTrackStat.nackCount.derivative,
                },
                audioLevel: {
                    value: streamState.audioTrackStat.audioLevel.lastValue || 0,
                },
                echoReturnLoss: {
                    value: streamState.audioTrackStat.echoReturnLoss.lastValue || 0,
                },
                targetBitrate: {
                    value: streamState.audioTrackStat.targetBitrate.lastValue || 0
                },
                jitter: streamState.audioTrackStat.jitter.isEmpty ? null : {
                    value: (streamState.audioTrackStat.jitter.lastValue || 0),
                },
                rtt: (streamState.audioTrackStat.roundTripTime.isEmpty || streamState.audioTrackStat.roundTripTimeMeasurements.value === 0) ? null : {
                    current: (streamState.audioTrackStat.roundTripTime.lastValue || 0),
                    average: streamState.audioTrackStat.totalRoundTripTime.value / streamState.audioTrackStat.roundTripTimeMeasurements.value,
                },
                estimatedPacketsLoss: (streamState.audioTrackStat.packetsLost.isEmpty || streamState.audioTrackStat.fractionLost.isEmpty) ? null : {
                    fractionLost: streamState.audioTrackStat.fractionLost.lastValue || 0,
                    packetsLost: streamState.audioTrackStat.packetsLost.value || 0,
                }
            } : undefined
        }

        return {
            screen: {
                stream: this.screenAndAudio.stream,
                audioTrackStat: createAudioTrackStats(screenAudioTrack, this.screenAndAudio),
                videoTrackStat: createVideoTrackStats(screenVideoTrack, this.screenAndAudio),
            },
            microphoneAndCamera: {
                stream: this.cameraAndMicrophone.stream,
                audioTrackStat: createAudioTrackStats(micTack, this.cameraAndMicrophone),
                videoTrackStat: createVideoTrackStats(camTrack, this.cameraAndMicrophone),
            }
        }
    }

    public get viewState(): FollowerViewState {
        return {
            peer: this.peerState.viewState,
            outgoingMedia: this.outgoingMediaViewState,
            transportStatistics: this.transportStatisticsViewState
        };
    }

    public get viewStateChanged(): rx.Observable<FollowerViewState> {
        return this.viewStateChangedSubj.pipe(
            rxops.map(_ => this.viewState)
        )
    }

    public async join(): Promise<void> {
        await this.queue.push(async () => {
            await this.joinCall();
        });
    }

    public async leave() {
        this.queue.push(async () => {
            await this.leaveCall();
        });
    }

    public async closeWebSocket(): Promise<void> {
        await this.queue.push(async () => {
            this.signaling.close()
        });
    }

    public async restartIce(): Promise<void> {
        await this.queue.push(async () => {
            await this.joinCall();
        })
    }

    private getInitialTrackState(message: sciezka_messages.LeaderHello, source: sciezka_messages.TrackSource) {
        return message.initial_track_states.find(x => x.source === source && x.direction === sciezka_messages.TrackDirection.OUT)?.state ?? sciezka_messages.TrackState.OFF;
    }

    private async onMessageReceived(msg: sciezka_messages.OutgoingSciezkaMessage) {
        await this.queue.push(async () => {
            if (!this.session) {
                console.log("The session is already terminated");
                return;
            }

            const enableRtcReporting = msg.message.has_enable_rtc_reporting ? msg.message.enable_rtc_reporting : null;
            if (enableRtcReporting) {
                if (this.zstdDictionary?.etag !== enableRtcReporting.etag) {
                    this.zstdDictionary = await getDictionary(enableRtcReporting.dictionary_download_url);
                }
            }

            if (msg.message.has_disconnect) {
                this.destroyFollowerSession();
            }

            const leaderHello = msg.message.has_leader_hello ? msg.message.leader_hello : null;
            if (leaderHello) {
                await this.session.obj.applyRemoteSdpAnswer(leaderHello.sdp_answer);
                this.peerState.setPeer(msg.source_client_id, {
                    cameraStream: this.session.obj.remoteCameraStream,
                    screenStream: this.session.obj.remoteScreenStream,
                    audio: {
                        track: this.session.obj.microphoneSendTransceiver.receiver.track,
                        mid: this.session.obj.microphoneSendTransceiver.mid!,
                        status: this.getInitialTrackState(leaderHello, sciezka_messages.TrackSource.MIC),
                    },
                    video: {
                        track: this.session.obj.cameraSendTransceiver.receiver.track,
                        mid: this.session.obj.cameraSendTransceiver.mid!,
                        status: this.getInitialTrackState(leaderHello, sciezka_messages.TrackSource.CAMERA),
                    },
                    screenAudio: {
                        track: this.session.obj.screenAudioSendTransceiver.receiver.track,
                        mid: this.session.obj.screenAudioSendTransceiver.mid!,
                        status: this.getInitialTrackState(leaderHello, sciezka_messages.TrackSource.SCREEN_AUDIO),
                    },
                    screenVideo: {
                        track: this.session.obj.screenVideoSendTransceiver.receiver.track,
                        mid: this.session.obj.screenVideoSendTransceiver.mid!,
                        status: this.getInitialTrackState(leaderHello, sciezka_messages.TrackSource.SCREEN_VIDEO),
                    }
                });

                this.peerState.setActive(msg.source_client_id);

                if (this.configuration.subject === SciezkaTicketSubject.Callee) {
                    this.startCameraTrack("user");
                    this.sendCameraVideo();
                    this.startMicrophoneTrack();
                    this.sendMicrophoneTrack();
                }

                const message = new sciezka_messages.SciezkaMessage({
                    select: new sciezka_messages.Select(),
                });
                this.signaling.send(message);
            }

            const iceMessage = msg.message.has_ice_candidates ? msg.message.ice_candidates : null;
            if (iceMessage) {
                for(let candidateObj of iceMessage.ice_candidates) {
                    const candidate : RTCIceCandidateInit = {
                        candidate: candidateObj.has_candidate ? candidateObj.candidate : undefined,
                        sdpMLineIndex: candidateObj.has_sdp_m_line_index ? candidateObj.sdp_m_line_index : null,
                        sdpMid: candidateObj.has_sdp_mid ? candidateObj.sdp_mid : null,
                        usernameFragment: candidateObj.has_username_fragment ? candidateObj.username_fragment : null,
                    };

                    await this.session.obj.applyCandidate(candidate);
                }
            }

            if (msg.message.has_leader_switched) {
                this.destroyFollowerSession();
                await this.joinCall();
            }
        })
    }

    private async onIceRestartRequired() {
        for (let i = 0; i < 5; i++) {
            try {
                this.destroyFollowerSession();
                await this.joinCall();
                return
            }
            catch (e) {
                console.error("Failed to initiate ICE restart", e);
                await Delay(1000 * 1.4)
            }
        }
    }

    private async onPeerConnectionStatisticsReport(report: RTCStatsReport) {
        if (!this.session) {
            console.log("No active session ignore report");
            return;
        }


        if (this.zstdDictionary !== null) {
            
            const data = createProtoRTCStatsReport(report).serializeBinary();
    
            const compressedData = await compressData(data, this.zstdDictionary.buffer);
            const message = new sciezka_messages.SciezkaMessage({
                rtc_report: new sciezka_messages.CompressedRTCStatsReport({
                    etag: this.zstdDictionary.etag,
                    report: compressedData,
                    peer_connection_id: this.session.obj.id,
                }),
            });
            this.signaling.send(message);
        }

        for (const rep of report.values()) {

            const stats = rep as stat.RTCStatsReport
            switch (stats.type) {
                case "transport": {
                    if (typeof stats.bytesReceived !== 'undefined') {
                        this.transportStatistics.bytesReceived.update(stats.bytesReceived, stats.timestamp);
                    }
                    if (typeof stats.packetsReceived !== 'undefined') {
                        this.transportStatistics.packetsReceived.update(stats.packetsReceived, stats.timestamp);
                    }
                    if (typeof stats.bytesSent !== 'undefined') {
                        this.transportStatistics.bytesSent.update(stats.bytesSent, stats.timestamp);
                    }
                    if (typeof stats.packetsSent !== 'undefined') {
                        this.transportStatistics.packetsSent.update(stats.packetsSent, stats.timestamp);
                    }
                    if (typeof stats.iceState !== 'undefined') {
                        this.transportStatistics.iceState = stats.iceState
                    }
                    if (typeof stats.selectedCandidatePairId !== 'undefined') {
                        const pairStats = report.get(stats.selectedCandidatePairId) as (stat.RTCStatsReport | undefined);
                        if (pairStats && pairStats.type === 'candidate-pair') {
                            if (!this.transportStatistics.candidatePair || pairStats.id !== this.transportStatistics.candidatePair.id) {
                                this.transportStatistics.candidatePair = {
                                    id: pairStats.id,
                                    bytesSent: new CounterMetric("candidatePairBytesSent", 60),
                                    bytesReceived: new CounterMetric("candidatePairBytesReceived", 60),
                                    packetsSent: new CounterMetric("candidatePairPacketsSent", 60),
                                    packetsReceived: new CounterMetric("candidatePairPacketsReceived", 60),
                                    currentRoundTripTime: new TimeSeries("candidatePairCurrentRtt", 60),
                                    availableOutgoingBitrate: new TimeSeries("candidatePairAvailableOutgoingBitrate", 60),
                                    availableIncomingBitrate: new TimeSeries("candidatePairAvailableIncomingBitrate", 60),
                                    local: {
                                    },
                                    remote: {
                                    }
                                }
                            }
                            if (typeof pairStats.bytesSent !== 'undefined') {
                                this.transportStatistics.candidatePair.bytesSent.update(pairStats.bytesSent, pairStats.timestamp);
                            }
                            if (typeof pairStats.bytesReceived !== 'undefined') {
                                this.transportStatistics.candidatePair.bytesReceived.update(pairStats.bytesReceived, pairStats.timestamp);
                            }
                            if (typeof pairStats.packetsSent !== 'undefined') {
                                this.transportStatistics.candidatePair.packetsSent.update(pairStats.packetsSent, pairStats.timestamp);
                            }
                            if (typeof pairStats.packetsReceived !== 'undefined') {
                                this.transportStatistics.candidatePair.packetsReceived.update(pairStats.packetsReceived, pairStats.timestamp);
                            }
                            if (typeof pairStats.currentRoundTripTime !== 'undefined') {
                                this.transportStatistics.candidatePair.currentRoundTripTime.addValue(pairStats.currentRoundTripTime, pairStats.timestamp);
                            }
                            if (typeof pairStats.availableIncomingBitrate !== 'undefined') {
                                this.transportStatistics.candidatePair.availableIncomingBitrate.addValue(pairStats.availableIncomingBitrate, pairStats.timestamp);
                            }
                            if (typeof pairStats.availableOutgoingBitrate !== 'undefined') {
                                this.transportStatistics.candidatePair.availableOutgoingBitrate.addValue(pairStats.availableOutgoingBitrate, pairStats.timestamp);
                            }

                            const localCandidate = report.get(pairStats.localCandidateId) as (stat.RTCStatsReport | undefined);
                            if (localCandidate && localCandidate.type === 'local-candidate') {
                                if (typeof localCandidate.address !== 'undefined') {
                                    this.transportStatistics.candidatePair.local.address = localCandidate.address;
                                }
                                if (typeof localCandidate.protocol !== 'undefined') {
                                    this.transportStatistics.candidatePair.local.protocol = localCandidate.protocol;
                                }
                                if (typeof localCandidate.port !== 'undefined') {
                                    this.transportStatistics.candidatePair.local.port = localCandidate.port;
                                }
                                if (typeof localCandidate.candidateType !== 'undefined') {
                                    this.transportStatistics.candidatePair.local.candidateType = localCandidate.candidateType;
                                }
                                if (typeof localCandidate.relayProtocol !== 'undefined') {
                                    this.transportStatistics.candidatePair.local.relayProtocol = localCandidate.relayProtocol;
                                }
                            }
                            const remoteCandidate = report.get(pairStats.remoteCandidateId) as (stat.RTCStatsReport | undefined);
                            if (remoteCandidate && remoteCandidate.type === 'remote-candidate') {
                                if (typeof remoteCandidate.address !== 'undefined') {
                                    this.transportStatistics.candidatePair.remote.address = remoteCandidate.address;
                                }
                                if (typeof remoteCandidate.protocol !== 'undefined') {
                                    this.transportStatistics.candidatePair.remote.protocol = remoteCandidate.protocol;
                                }
                                if (typeof remoteCandidate.port !== 'undefined') {
                                    this.transportStatistics.candidatePair.remote.port = remoteCandidate.port;
                                }
                            }
                        }

                    }
                    break;
                }
                case "media-source": {
                    break;
                }
                case "sender": {
                    console.log("Sender report", report);
                    break;
                }
                case "receiver": {
                    console.log("Receiver report", report);
                    break;
                }
                case "outbound-rtp": {
                    if ('mediaSourceId' in stats && stats.mediaSourceId) {
                        const mediaSource = report.get(stats.mediaSourceId) as stat.RTCStatsReport | undefined;
                        if (!mediaSource || mediaSource.type !== 'media-source') {
                            console.log("Unable to find media-source stat by id:", stats.mediaSourceId)
                            break;
                        }
                        if (mediaSource.kind === 'audio' && stats.kind === 'audio') {
                            for (const outgoingStream of [this.cameraAndMicrophone, this.screenAndAudio]) {
                                if (!outgoingStream.stream.getTrackById(mediaSource.trackIdentifier)){
                                    continue;
                                }
                                if (typeof mediaSource.audioLevel !== 'undefined') {
                                    outgoingStream.audioTrackStat.audioLevel.addValue(mediaSource.audioLevel, stats.timestamp)
                                }
                                if (typeof mediaSource.echoReturnLoss !== 'undefined') {
                                    outgoingStream.audioTrackStat.echoReturnLoss.addValue(mediaSource.echoReturnLoss, stats.timestamp)
                                }
                                if (typeof stats.codecId !== 'undefined') {
                                    const codec = report.get(stats.codecId) as stat.RTCStatsReport | undefined;
                                    if (codec && codec.type === 'codec') {
                                        outgoingStream.audioTrackStat.codec = codec.mimeType;
                                        outgoingStream.audioTrackStat.codecPayloadType = codec.payloadType;
                                    }
                                }
                                if (typeof stats.remoteId !== 'undefined') {
                                    const rtcpStat = report.get(stats.remoteId) as stat.RTCStatsReport | undefined;
                                    if (rtcpStat && rtcpStat.type === 'remote-inbound-rtp') {
                                        if (typeof rtcpStat.jitter !== 'undefined') {
                                            outgoingStream.audioTrackStat.jitter.addValue(rtcpStat.jitter, rtcpStat.timestamp)
                                        }
                                        if (typeof rtcpStat.packetsLost !== 'undefined') {
                                            outgoingStream.audioTrackStat.packetsLost.update(rtcpStat.packetsLost, rtcpStat.timestamp)
                                        }
                                        if (typeof rtcpStat.fractionLost !== 'undefined') {
                                            outgoingStream.audioTrackStat.fractionLost.addValue(rtcpStat.fractionLost, rtcpStat.timestamp)
                                        }
                                        if (typeof rtcpStat.roundTripTime !== 'undefined') {
                                            outgoingStream.audioTrackStat.roundTripTime.addValue(rtcpStat.roundTripTime, rtcpStat.timestamp)
                                        }
                                        if (typeof rtcpStat.roundTripTimeMeasurements !== 'undefined') {
                                            outgoingStream.audioTrackStat.roundTripTimeMeasurements.update(rtcpStat.roundTripTimeMeasurements, rtcpStat.timestamp)
                                        }
                                        if (typeof rtcpStat.totalRoundTripTime !== 'undefined') {
                                            outgoingStream.audioTrackStat.totalRoundTripTime.update(rtcpStat.totalRoundTripTime, rtcpStat.timestamp)
                                        }
                                    }
                                }

                                outgoingStream.audioTrackStat.ssrc = stats.ssrc;
                                if (typeof stats.bytesSent !== 'undefined') {
                                    outgoingStream.audioTrackStat.bytesSent.update(stats.bytesSent, stats.timestamp);
                                } 
                                if (typeof stats.headerBytesSent !== 'undefined') {
                                    outgoingStream.audioTrackStat.headerBytesSent.update(stats.headerBytesSent, stats.timestamp);
                                }
                                if (typeof stats.packetsSent !== 'undefined') {
                                    outgoingStream.audioTrackStat.packetsSent.update(stats.packetsSent, stats.timestamp);
                                }
                                break;
                            }
                        } else if (mediaSource.kind === 'video' && stats.kind === 'video') {
                            for (const outgoingStream of [this.cameraAndMicrophone, this.screenAndAudio]) {
                                if (!outgoingStream.stream.getTrackById(mediaSource.trackIdentifier)){
                                    continue;
                                }
                                if (typeof mediaSource.width !== 'undefined') {
                                    outgoingStream.videoTrackStat.captureWidth = mediaSource.width;
                                }
                                if (typeof mediaSource.height !== 'undefined') {
                                    outgoingStream.videoTrackStat.captureHeight = mediaSource.height;
                                }
                                if (typeof mediaSource.framesPerSecond !== 'undefined') {
                                    outgoingStream.videoTrackStat.captureFps = mediaSource.framesPerSecond;
                                }
                                let layer_stat: VideoLayerStat;
                                if (!outgoingStream.videoTrackStat.layers.has(stats.ssrc)) {
                                    layer_stat = {
                                        ssrc: stats.ssrc,
                                        frameHeight: new TimeSeries("frameHeight", 60),
                                        frameWidth: new TimeSeries("frameWidth", 60),
                                        bytesSent: new CounterMetric("bytesSent", 60),
                                        packetsSent: new CounterMetric("packetsSent", 60),
                                        bytesDiscardedOnSend: new CounterMetric("bytesDiscardedOnSend", 60),
                                        framesDiscardedOnSend: new CounterMetric("framesDiscardedOnSend", 60),
                                        packetsDiscardedOnSend: new CounterMetric("packetsDiscardedOnSend", 60),
                                        firCount: new CounterMetric("firCount", 60),
                                        pliCount: new CounterMetric("pliCount", 60),
                                        nackCount: new CounterMetric("nackCount", 60),
                                        framesEncoded: new CounterMetric("framesEncoded", 60),
                                        keyFramesEncoded: new CounterMetric("keyFramesEncoded", 60),
                                        framesSent: new CounterMetric("framesSent", 60),
                                        headerBytesSent: new CounterMetric("headerBytesSent", 60),
                                        hugeFramesSent: new CounterMetric("hugeFramesSent", 60),
                                        retransmittedBytesSent: new CounterMetric("retransmittedBytesSent", 60),
                                        retransmittedPacketsSent: new CounterMetric("retransmittedPacketsSent", 60),
                                        encodedFramesPerSecond: new TimeSeries("encodedFramesPerSecond", 60),
                                        targetBitrate: new TimeSeries("targetBitrate", 60),
                                        qualityLimitationReason: "none",
                                        qualityLimitationDuration: {
                                            bandwidth: new CounterMetric("qualityLimitationDurationBandwidth", 60),
                                            cpu: new CounterMetric("qualityLimitationDurationCpu", 60),
                                            none: new CounterMetric("qualityLimitationDurationNone", 60),
                                            other: new CounterMetric("qualityLimitationDurationOther", 60),
                                        },
                                        totalEncodeTime: new CounterMetric("totalEncodeTime", 60),
                                        totalPacketSendDelay: new CounterMetric("totalPacketSendDelay", 60),
                                        jitter: new TimeSeries("jitter", 60),
                                        packetsLost: new CounterMetric("packetsLost", 60),
                                        fractionLost: new TimeSeries("fractionLost", 60),
                                        roundTripTime: new TimeSeries("roundTripTime", 60),
                                        roundTripTimeMeasurements: new CounterMetric("roundTripTimeMeasurements", 60),
                                        totalRoundTripTime: new CounterMetric("totalRoundTripTime", 60),
                                    };
                                    outgoingStream.videoTrackStat.layers.set(stats.ssrc, layer_stat);
                                } else {
                                    layer_stat = outgoingStream.videoTrackStat.layers.get(stats.ssrc) as VideoLayerStat;
                                }
                                if (stats.codecId) {
                                    const codec = report.get(stats.codecId) as stat.RTCStatsReport | undefined;
                                    if (codec && codec.type === 'codec') {
                                        layer_stat.codec = codec.mimeType;
                                        layer_stat.codecPayloadType = codec.payloadType;
                                    }
                                }
                                if (stats.remoteId) {
                                    const rtcpStat = report.get(stats.remoteId) as stat.RTCStatsReport | undefined;
                                    if (rtcpStat && rtcpStat.type === 'remote-inbound-rtp') {
                                        if (typeof rtcpStat.jitter !== 'undefined') {
                                            layer_stat.jitter.addValue(rtcpStat.jitter, rtcpStat.timestamp)
                                        }
                                        if (typeof rtcpStat.packetsLost !== 'undefined') {
                                            layer_stat.packetsLost.update(rtcpStat.packetsLost, rtcpStat.timestamp)
                                        }
                                        if (typeof rtcpStat.fractionLost !== 'undefined') {
                                            layer_stat.fractionLost.addValue(rtcpStat.fractionLost, rtcpStat.timestamp)
                                        }
                                        if (typeof rtcpStat.roundTripTime !== 'undefined') {
                                            layer_stat.roundTripTime.addValue(rtcpStat.roundTripTime, rtcpStat.timestamp)
                                        }
                                        if (typeof rtcpStat.roundTripTimeMeasurements !== 'undefined') {
                                            layer_stat.roundTripTimeMeasurements.update(rtcpStat.roundTripTimeMeasurements, rtcpStat.timestamp)
                                        }
                                        if (typeof rtcpStat.totalRoundTripTime !== 'undefined') {
                                            layer_stat.totalRoundTripTime.update(rtcpStat.totalRoundTripTime, rtcpStat.timestamp)
                                        }
                                    }
                                }

                                layer_stat.ssrc = stats.ssrc;
                                layer_stat.rid = stats.rid;
                                layer_stat.rtxSsrc = stats.rtxSsrc;
                                if (typeof stats.frameWidth !== 'undefined') {
                                    layer_stat.frameWidth.addValue(stats.frameWidth, stats.timestamp);
                                }
                                if (typeof stats.frameHeight !== 'undefined') {
                                    layer_stat.frameHeight.addValue(stats.frameHeight, stats.timestamp);
                                }
                                if (typeof stats.bytesSent !== 'undefined') {
                                    layer_stat.bytesSent.update(stats.bytesSent, stats.timestamp);
                                }
                                if (typeof stats.packetsSent !== 'undefined') {
                                    layer_stat.packetsSent.update(stats.packetsSent, stats.timestamp);
                                }
                                if (typeof stats.bytesDiscardedOnSend !== 'undefined') {
                                    layer_stat.bytesDiscardedOnSend.update(stats.bytesDiscardedOnSend, stats.timestamp);
                                }
                                if (typeof stats.framesDiscardedOnSend !== 'undefined') {
                                    layer_stat.framesDiscardedOnSend.update(stats.framesDiscardedOnSend, stats.timestamp);
                                }
                                if (typeof stats.packetsDiscardedOnSend !== 'undefined') {
                                    layer_stat.packetsDiscardedOnSend.update(stats.packetsDiscardedOnSend, stats.timestamp);
                                }
                                if (typeof stats.firCount !== 'undefined') {
                                    layer_stat.firCount.update(stats.firCount, stats.timestamp);
                                }
                                if (typeof stats.pliCount !== 'undefined') {
                                    layer_stat.pliCount.update(stats.pliCount, stats.timestamp);
                                }
                                if (typeof stats.nackCount !== 'undefined') {
                                    layer_stat.nackCount.update(stats.nackCount, stats.timestamp);
                                }
                                if (typeof stats.framesEncoded !== 'undefined') {
                                    layer_stat.framesEncoded.update(stats.framesEncoded, stats.timestamp);
                                }
                                if (typeof stats.keyFramesEncoded !== 'undefined') {
                                    layer_stat.keyFramesEncoded.update(stats.keyFramesEncoded, stats.timestamp);
                                }
                                if (typeof stats.framesSent !== 'undefined') {
                                    layer_stat.framesSent.update(stats.framesSent, stats.timestamp);
                                }
                                if (typeof stats.headerBytesSent !== 'undefined') {
                                    layer_stat.headerBytesSent.update(stats.headerBytesSent, stats.timestamp);
                                }
                                if (typeof stats.hugeFramesSent !== 'undefined') {
                                    layer_stat.hugeFramesSent.update(stats.hugeFramesSent, stats.timestamp);
                                }
                                if (typeof stats.retransmittedBytesSent !== 'undefined') {
                                    layer_stat.retransmittedBytesSent.update(stats.retransmittedBytesSent, stats.timestamp);
                                }
                                if (typeof stats.retransmittedPacketsSent !== 'undefined') {
                                    layer_stat.retransmittedPacketsSent.update(stats.retransmittedPacketsSent, stats.timestamp);
                                }
                                if (typeof stats.framesPerSecond !== 'undefined') {
                                    layer_stat.encodedFramesPerSecond.addValue(stats.framesPerSecond, stats.timestamp);
                                }
                                if (typeof stats.targetBitrate !== 'undefined') {
                                    layer_stat.targetBitrate.addValue(stats.targetBitrate, stats.timestamp);
                                }
                                if (typeof stats.qualityLimitationReason !== 'undefined') {
                                    layer_stat.qualityLimitationReason = stats.qualityLimitationReason;
                                }
                                if (typeof stats.qualityLimitationDurations !== 'undefined') {
                                    layer_stat.qualityLimitationDuration.bandwidth.update(stats.qualityLimitationDurations['bandwidth'], stats.timestamp);
                                    layer_stat.qualityLimitationDuration.cpu.update(stats.qualityLimitationDurations['cpu'], stats.timestamp);
                                    layer_stat.qualityLimitationDuration.none.update(stats.qualityLimitationDurations['none'], stats.timestamp);
                                    layer_stat.qualityLimitationDuration.other.update(stats.qualityLimitationDurations['other'], stats.timestamp);
                                }
                                if (typeof stats.totalEncodeTime !== 'undefined') {
                                    layer_stat.totalEncodeTime.update(stats.totalEncodeTime, stats.timestamp);
                                }
                                if (typeof stats.totalPacketSendDelay !== 'undefined') {
                                    layer_stat.totalPacketSendDelay.update(stats.totalPacketSendDelay, stats.timestamp);
                                }
                                break;
                            }

                        } else {
                            console.error("outbound-rtp and media-source reports has different kind's: ", stats, mediaSource);
                        }
                    } else {
                        if ((stats.kind === 'audio' && (this.session.obj.microphoneSendTransceiver.currentDirection !== "inactive" || this.session.obj.screenAudioSendTransceiver?.currentDirection !== "inactive")) ||
                            (stats.kind === 'video' && ((this.session.obj.cameraSendTransceiver && this.session.obj.cameraSendTransceiver?.currentDirection !== "inactive") || (this.session.obj.screenVideoSendTransceiver && this.session.obj.screenVideoSendTransceiver?.currentDirection !== "inactive")))) {
                            //console.warn("Unable to correlate outbound-rtp to medias source", stats);
                        }
                    }
                    break;
                }
                case "remote-inbound-rtp": {
                    break;
                }
                case "inbound-rtp": {
                    if (stats.kind === 'video') {
                        //report.frameBitDepth
                    }
                    break;
                }
                case "remote-outbound-rtp": {
                    break;
                }
                case "codec": {
                    break;
                }
                case "csrc": {
                    break;
                }
                case "peer-connection": {
                    break;
                }
            }
        }
        this.viewStateChangedSubj.next()
    }

    public async startMicrophoneTrack() {
        await this.queue.push(async () => {
            console.log("startMicrophoneTrack");
            if (this.microphoneTrack != null) {
                this.microphoneTrack.stop();
                this.microphoneTrack = null;
            }

            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        autoGainControl: true,
                        echoCancellation: true,
                        noiseSuppression: true,
                        sampleRate: 48000,
                        latency: 0.01,
                        channel: 1,
                    }
                } as any);

                const audioTrack = stream.getAudioTracks()[0];

                this.microphoneTrack = audioTrack;

                console.log("microphone track settings: ", this.microphoneTrack.getSettings())

                const message = new sciezka_messages.PeerMessage({
                    track_update: new sciezka_messages.TrackUpdate({
                        source: sciezka_messages.TrackSource.MIC,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.ON,
                    }),
                });
    
                this.session?.obj.send(message);
            } catch (error) {
                console.log(`Failed to build audio track error is ${error}`);
            }
        })
    }

    public async stopMicrophoneTrack() {
        await this.queue.push(async () => {
            console.log("stopMicrophoneTrack")
            if (this.microphoneTrack) {
                this.microphoneTrack.stop();
                this.microphoneTrack = null;
            }

            if (this.session) {
                await this.session.obj.microphoneSendTransceiver.sender.replaceTrack(null);

                const message = new sciezka_messages.PeerMessage({
                    track_update: new sciezka_messages.TrackUpdate({
                        source: sciezka_messages.TrackSource.MIC,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.OFF,
                    }),
                });
    
                this.session?.obj.send(message);
            }
        })
    }

    public async sendMicrophoneTrack() {
        await this.queue.push(async () => {
            console.log("sendMicrophoneTrack");
            if (!this.microphoneTrack) {
                console.warn("No microphone track to send");
                return;
            }
            if (!this.session) {
                console.warn("No active session");
                return;
            }
            await this.session.obj.microphoneSendTransceiver.sender.replaceTrack(this.microphoneTrack);
        })
    }

    public async startCameraTrack(facingMode: "user" | "environment") {
        await this.queue.push(async () => {
            console.log("startCameraTrack");
            if (this.cameraTrack) {
                this.cameraTrack.stop();
                this.cameraTrack = null;
            }

            let constraints = {
                video: {
                    facingMode: { ideal: facingMode },
                    aspectRatio: { ideal: 16.0 / 9.0 }
                }
            }
            if (this.configuration.maxCameraCaptureDimension === null) {
                Object.assign(constraints.video, { 
                    height: { ideal: facingMode === "user" ? 1080 : 2160 },
                    width: { ideal: facingMode === "user" ? 1920 : 3840 },
                })
            } else {
                Object.assign(constraints.video, { 
                    height: { 
                        max: this.configuration.maxCameraCaptureDimension,
                        ideal: this.configuration.maxCameraCaptureDimension 
                    },
                    width: { 
                        max: this.configuration.maxCameraCaptureDimension,
                        ideal: this.configuration.maxCameraCaptureDimension 
                    },
                })
            }
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);

                const cameraTrack = stream.getVideoTracks()[0];
                console.log("Camera track settings: ", cameraTrack.getSettings());
                console.log("Camera track constraints: ", cameraTrack.getConstraints());

                this.cameraTrack = cameraTrack;

                const message = new sciezka_messages.PeerMessage({
                    track_update: new sciezka_messages.TrackUpdate({
                        source: sciezka_messages.TrackSource.CAMERA,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.ON,
                    }),
                });
    
                this.session?.obj.send(message);

            } catch (error) {
                console.log(`Failed to build video track, error is ${error}`);
            }
        });
    }

    public async startScreenTracks() {
        await this.queue.push(async () => {
            console.log("startScreenTracks");
            if (this.screenVideoTrack) {
                this.screenVideoTrack.stop();
                this.screenVideoTrack = null;
            }

            if (this.screenAudioTrack) {
                this.screenAudioTrack.stop();
                this.screenAudioTrack = null;
            }

            try {
                const stream = await navigator.mediaDevices.getDisplayMedia({
                    audio: true,
                    video: {
                        height: { ideal: 1080 },
                        width: { ideal: 1920 },
                        aspectRatio: { ideal: 16.0 / 9.0 }
                    }
                });

                const screenVideoTrack = stream.getVideoTracks()[0];
                screenVideoTrack.contentHint = "text";
                console.log("Screen video track settings: ", screenVideoTrack.getSettings());
                console.log("Screen video track constraints: ", screenVideoTrack.getConstraints());
                this.screenVideoTrack = screenVideoTrack;

                if (stream.getAudioTracks().length > 0) {
                    const screenAudioTrack = stream.getAudioTracks()[0];
                    console.log("Screen audio track settings: ", screenAudioTrack.getSettings());
                    console.log("Screen audio track constraints: ", screenAudioTrack.getConstraints());
                    this.screenAudioTrack = screenAudioTrack;
                }

                let message = new sciezka_messages.PeerMessage({
                    track_update: new sciezka_messages.TrackUpdate({
                        source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.ON,
                    }),
                });
    
                this.session?.obj.send(message);

                message = new sciezka_messages.PeerMessage({
                    track_update: new sciezka_messages.TrackUpdate({
                        source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.ON,
                    }),
                });
    
                this.session?.obj.send(message);
            } catch (error) {
                console.log(`Failed to build video track, error is ${error}`);
            }
        });
    }

    public async stopScreenTracks() {
        await this.queue.push(async () => {
            console.log("stopScreenTracks");
            if (this.screenVideoTrack) {
                this.screenVideoTrack.stop();
                this.screenVideoTrack = null;
            }

            if (this.screenAudioTrack) {
                this.screenAudioTrack.stop();
                this.screenAudioTrack = null;
            }

            if (this.session) {
                const screenSendTransceiver = this.session.obj.screenVideoSendTransceiver;
                if (screenSendTransceiver) {
                    await screenSendTransceiver.sender.replaceTrack(null);
                }

                const screenAudioSendTransceiver = this.session.obj.screenAudioSendTransceiver;
                if (screenAudioSendTransceiver) {
                    await screenAudioSendTransceiver.sender.replaceTrack(null);
                }

                let message = new sciezka_messages.PeerMessage({
                    track_update: new sciezka_messages.TrackUpdate({
                        source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.OFF,
                    }),
                });
    
                this.session.obj.send(message);

                message = new sciezka_messages.PeerMessage({
                    track_update: new sciezka_messages.TrackUpdate({
                        source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.OFF,
                    }),
                });
    
                this.session.obj.send(message);
            }
        })
    }

    public async stopCameraTrack() {
        await this.queue.push(async () => {
            console.log("stopCameraTrack");
            if (this.cameraTrack) {
                this.cameraTrack.stop();
                this.cameraTrack = null;
            }

            if (this.session) {
                const cameraSendTransceiver = this.session.obj.cameraSendTransceiver;
                if (cameraSendTransceiver) {
                    await cameraSendTransceiver.sender.replaceTrack(null);
                }

                const message = new sciezka_messages.PeerMessage({
                    track_update: new sciezka_messages.TrackUpdate({
                        source: sciezka_messages.TrackSource.CAMERA,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.OFF,
                    }),
                });
    
                this.session.obj.send(message);
            }
        })
    }

    public async sendScreenVideoAndAudio() {
        await this.queue.push(async () => {
            console.log("sendScreenVideoAndAudio");
            if (!this.screenVideoTrack) {
                console.warn("No screen track to send");
                return;
            }
            if (!this.session) {
                console.warn("No active session");
                return;
            }
            const screenSendTransceiver = this.session.obj.screenVideoSendTransceiver;
            if (screenSendTransceiver) {
                await screenSendTransceiver.sender.replaceTrack(this.screenVideoTrack);
            }

            const screenAudioSendTransceiver = this.session.obj.screenAudioSendTransceiver;
            if (screenAudioSendTransceiver && this.screenAudioTrack) {
                await screenAudioSendTransceiver.sender.replaceTrack(this.screenAudioTrack);
            }
        })
    }

    public async sendCameraVideo() {
        await this.queue.push(async () => {
            console.log("sendCameraVideo");
            if (!this.cameraTrack) {
                console.warn("No camera track to send");
                return;
            }
            if (!this.session) {
                console.warn("No active session");
                return;
            }
            const cameraSendTransceiver = this.session.obj.cameraSendTransceiver;
            if (cameraSendTransceiver) {
                await cameraSendTransceiver.sender.replaceTrack(this.cameraTrack);
            }
        })
    }

    private async joinCall() {
        console.log(`[call = ${this.configuration.callId}]: join call`)

        try {
            if (!this.signaling.isConnected()) {
                const ticketAndEndpoint = await this.acquireTicketWithRetry(3);
                if (!ticketAndEndpoint) {
                    console.error("Unable to obtain ticket");
                    return;
                }

                this.iceServers.splice(0, this.iceServers.length);
                this.iceServers.push(...ticketAndEndpoint
                    .getIceServersList()
                    .flatMap(x => x.getIceServersList())
                    .map(x => {
                        return {
                            credential: x.getCredential(),
                            urls: x.getUrlsList(),
                            username: x.getUsername(),
                        };
                    })
                );

                const region = this.configuration.region ?? "unknown";

                if (!await this.signaling.connect(`${ticketAndEndpoint.getEndpoint()}?ticket=${ticketAndEndpoint.getTicket()}&region=${region}`)) {
                    console.error(`[call = ${this.configuration.callId}]: cannot connect sciezka`);
                    return;
                }
            }

            this.newFollowerSession(this.iceServers);

            if (!this.session) {
                console.error("Failed to create room session");
                return;
            }

            const initialOffer = await this.session.obj.createInitialOffer();
            if (!initialOffer) {
                return;
            }
            const followerHello = new sciezka_messages.FollowerHello({
                sdp_offer: initialOffer,
                transceivers: [
                    new sciezka_messages.TransceiverEntry({
                        mid: this.session.obj.microphoneSendTransceiver.mid!,
                        source: sciezka_messages.TrackSource.MIC,
                    }),
                    new sciezka_messages.TransceiverEntry({
                        mid: this.session.obj.cameraSendTransceiver.mid!,
                        source: sciezka_messages.TrackSource.CAMERA,
                    }),
                    new sciezka_messages.TransceiverEntry({
                        mid: this.session.obj.screenAudioSendTransceiver.mid!,
                        source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                    }),
                    new sciezka_messages.TransceiverEntry({
                        mid: this.session.obj.screenVideoSendTransceiver.mid!,
                        source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                    }),
                ]
            });

            if (this.configuration.subject === SciezkaTicketSubject.Callee) {
                followerHello.initial_track_states = [
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.CAMERA,
                        direction: sciezka_messages.TrackDirection.IN,
                        state: sciezka_messages.TrackState.ON,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.MIC,
                        direction: sciezka_messages.TrackDirection.IN,
                        state: sciezka_messages.TrackState.ON,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                        direction: sciezka_messages.TrackDirection.IN,
                        state: sciezka_messages.TrackState.ON,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                        direction: sciezka_messages.TrackDirection.IN,
                        state: sciezka_messages.TrackState.ON,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.CAMERA,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.ON,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.MIC,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.ON,
                    }),                        
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.OFF,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.OFF,
                    }),
                ];
            } else if (this.configuration.subject === SciezkaTicketSubject.View) {
                followerHello.initial_track_states = [
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.CAMERA,
                        direction: sciezka_messages.TrackDirection.IN,
                        state: sciezka_messages.TrackState.ON,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.MIC,
                        direction: sciezka_messages.TrackDirection.IN,
                        state: sciezka_messages.TrackState.ON,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                        direction: sciezka_messages.TrackDirection.IN,
                        state: sciezka_messages.TrackState.ON,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                        direction: sciezka_messages.TrackDirection.IN,
                        state: sciezka_messages.TrackState.ON,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.CAMERA,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.OFF,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.MIC,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.OFF,
                    }),                        
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.OFF,
                    }),
                    new sciezka_messages.TrackInitialState({
                        source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                        direction: sciezka_messages.TrackDirection.OUT,
                        state: sciezka_messages.TrackState.OFF,
                    }),
                ];
            }

            const message = new sciezka_messages.SciezkaMessage({
                follower_hello: followerHello,
            });

            this.signaling.send(message);

        } catch (e) {
            console.error(`Follower ${this.configuration.accountId} failed to join the room ${this.configuration.callId}`, e);
            this.destroyFollowerSession();
        }
    }

    private async leaveCall() {
        console.log(`[call = ${this.configuration.callId}]: leave call`);
        const message = new sciezka_messages.SciezkaMessage({
            disconnect: new sciezka_messages.Disconnect(),
        });
        this.signaling.send(message);
        this.signaling.close();
        this.destroyFollowerSession();
        this.stopCameraTrack();
        this.stopMicrophoneTrack();
    }

    private async acquireTicket(): Promise<null | Ticket> {
        const client = new SciezkaTicketsClient(this.configuration.garconUrl);
        const request = new TicketRequest();
        request.setAccountId(this.configuration.accountId);
        request.setClientId(this.configuration.clientId);
        request.setRoomId(this.configuration.callId);

        async function makeRequest(subject: SciezkaTicketSubject, request: TicketRequest, client: SciezkaTicketsClient): Promise<TicketResponse | null> {
            switch(subject) {
                case SciezkaTicketSubject.Broadcast:
                    return await client.getBroadcastTicket(request, {});
                case SciezkaTicketSubject.View:
                    return await client.getViewTicket(request, {});
                case SciezkaTicketSubject.Caller:
                    return await client.getCallerTicket(request, {});
                case SciezkaTicketSubject.Callee:
                    return await client.getCalleeTicket(request, {});
                default:
                    console.error(`unknown subject: ${subject}`);
                    return null;
            }
        }

        const ticketResponse = await makeRequest(this.configuration.subject, request, client);

        if (!ticketResponse) {
            console.error("Failed to obtain ticket");
            return null;
        }

        return ticketResponse.getTicket() ?? null;
    }

    private async acquireTicketWithRetry(trials: number): Promise<null | Ticket> {
        for (let i = 0; i < trials; i++) {
            const ticket = this.acquireTicket();
            if (ticket !== null) {
                return ticket;
            }
            await Delay(i * 300);
        }
        console.log(`Failed to obtain ticket with ${trials} trials`);
        return null;
    }

    private destroyFollowerSession() {
        if (this.session) {
            console.log(`[call = ${this.configuration.callId}]: destroying call session`);
            this.peerState.clear();
            this.session.iceRestartSub.unsubscribe();
            this.session.statisticsSub.unsubscribe();
            this.session.obj.terminate();
            this.session = null;
        }
    }

    private async onIceCandidate(candidate: RTCIceCandidateInit) {
        await this.queue.push(async () => {
            const iceCandidate = new sciezka_messages.IceCandidate();

            if (candidate.candidate) {
                iceCandidate.candidate = candidate.candidate;
            }
            
            if (candidate.sdpMLineIndex) {
                iceCandidate.sdp_m_line_index = candidate.sdpMLineIndex;
            }

            if (candidate.sdpMid) {
                iceCandidate.sdp_mid = candidate.sdpMid;
            }

            if (candidate.usernameFragment) {
                iceCandidate.username_fragment = candidate.usernameFragment;
            }

            const message = new sciezka_messages.SciezkaMessage({
                ice_candidates: new sciezka_messages.IceCandidates({
                    ice_candidates: [ iceCandidate ]
                }),
            });

            this.signaling.send(message);
        });
    }

    public async updateViewPort(source: sciezka_messages.TrackSource, size: { width: number, height: number }) {
        await this.queue.push(async () => {
            const message = new sciezka_messages.PeerMessage({
                view_port_changed: new sciezka_messages.ViewPortChanged({
                    source,
                    width: size.width,
                    height: size.height,
                }),
            });

            this.session?.obj.send(message);
        })
    }

    private async onDataChannelMessageReceived(message: sciezka_messages.PeerMessage) {
        await this.queue.push(async () => {
            const trackUpdate = message.has_track_update ? message.track_update : null;
            if (trackUpdate) {
                this.peerState.updateTrackState(trackUpdate);
                this.viewStateChangedSubj.next();
                return;
            }

            const viewPortChanged = message.has_view_port_changed ? message.view_port_changed : null;
            if (viewPortChanged) {
                switch (viewPortChanged.source) {
                    case sciezka_messages.TrackSource.CAMERA:
                        await this.cameraTrack?.applyConstraints({
                            width: { ideal: viewPortChanged.width, min: viewPortChanged.width },
                            height: { ideal: viewPortChanged.height, min: viewPortChanged.height },
                        })
                        break;
                    case sciezka_messages.TrackSource.SCREEN_VIDEO:
                        await this.screenVideoTrack?.applyConstraints({
                            width: { ideal: viewPortChanged.width, min: viewPortChanged.width },
                            height: { ideal: viewPortChanged.height, min: viewPortChanged.height },
                        })
                        break;
                }

                return;
            }
        })
    }

    private newFollowerSession(iceServers: Array<RTCIceServer>) {
        if (this.session) {
            throw new Error("Attempt to start new session while old one exist");
        }

        const session = new FollowerSession({
            callId: this.configuration.callId,
            iceServers: iceServers,
            icePolicy: this.configuration.icePolicy,
        });
        const iceRestartSub = session.iceRestartRequired.subscribe(_ => this.onIceRestartRequired());
        const statisticsSub = rx.interval(1000).pipe(
            rxops.switchMap(_ => rx.from(session.getStats())),
        ).subscribe(report => this.onPeerConnectionStatisticsReport(report));
        const iceCandidateSub = session.iceCandidate.subscribe(x => this.onIceCandidate(x));
        const messageSub = session.messageReceived.subscribe(x => this.onDataChannelMessageReceived(x));

        this.session = {
            obj: session,
            iceRestartSub,
            statisticsSub,
            iceCandidateSub,
            messageSub,
        }
    }
}