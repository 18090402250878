/**
 * @fileoverview gRPC-Web generated client stub for tickets
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v5.26.1
// source: tickets.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as tickets_pb from './tickets_pb';


export class KaldunTicketsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetBroadcastAndViewTicket = new grpcWeb.MethodDescriptor(
    '/tickets.KaldunTickets/GetBroadcastAndViewTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getBroadcastAndViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getBroadcastAndViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getBroadcastAndViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.KaldunTickets/GetBroadcastAndViewTicket',
        request,
        metadata || {},
        this.methodDescriptorGetBroadcastAndViewTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.KaldunTickets/GetBroadcastAndViewTicket',
    request,
    metadata || {},
    this.methodDescriptorGetBroadcastAndViewTicket);
  }

  methodDescriptorGetBroadcastTicket = new grpcWeb.MethodDescriptor(
    '/tickets.KaldunTickets/GetBroadcastTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getBroadcastTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getBroadcastTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getBroadcastTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.KaldunTickets/GetBroadcastTicket',
        request,
        metadata || {},
        this.methodDescriptorGetBroadcastTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.KaldunTickets/GetBroadcastTicket',
    request,
    metadata || {},
    this.methodDescriptorGetBroadcastTicket);
  }

  methodDescriptorGetViewTicket = new grpcWeb.MethodDescriptor(
    '/tickets.KaldunTickets/GetViewTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.KaldunTickets/GetViewTicket',
        request,
        metadata || {},
        this.methodDescriptorGetViewTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.KaldunTickets/GetViewTicket',
    request,
    metadata || {},
    this.methodDescriptorGetViewTicket);
  }

  methodDescriptorGetTalkAndListenTicket = new grpcWeb.MethodDescriptor(
    '/tickets.KaldunTickets/GetTalkAndListenTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getTalkAndListenTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getTalkAndListenTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getTalkAndListenTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.KaldunTickets/GetTalkAndListenTicket',
        request,
        metadata || {},
        this.methodDescriptorGetTalkAndListenTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.KaldunTickets/GetTalkAndListenTicket',
    request,
    metadata || {},
    this.methodDescriptorGetTalkAndListenTicket);
  }

  methodDescriptorGetTalkTicket = new grpcWeb.MethodDescriptor(
    '/tickets.KaldunTickets/GetTalkTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getTalkTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getTalkTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getTalkTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.KaldunTickets/GetTalkTicket',
        request,
        metadata || {},
        this.methodDescriptorGetTalkTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.KaldunTickets/GetTalkTicket',
    request,
    metadata || {},
    this.methodDescriptorGetTalkTicket);
  }

  methodDescriptorGetListenTicket = new grpcWeb.MethodDescriptor(
    '/tickets.KaldunTickets/GetListenTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getListenTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getListenTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getListenTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.KaldunTickets/GetListenTicket',
        request,
        metadata || {},
        this.methodDescriptorGetListenTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.KaldunTickets/GetListenTicket',
    request,
    metadata || {},
    this.methodDescriptorGetListenTicket);
  }

  methodDescriptorGetDatingBroadcastTicket = new grpcWeb.MethodDescriptor(
    '/tickets.KaldunTickets/GetDatingBroadcastTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getDatingBroadcastTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getDatingBroadcastTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getDatingBroadcastTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.KaldunTickets/GetDatingBroadcastTicket',
        request,
        metadata || {},
        this.methodDescriptorGetDatingBroadcastTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.KaldunTickets/GetDatingBroadcastTicket',
    request,
    metadata || {},
    this.methodDescriptorGetDatingBroadcastTicket);
  }

  methodDescriptorGetDatingViewTicket = new grpcWeb.MethodDescriptor(
    '/tickets.KaldunTickets/GetDatingViewTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getDatingViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getDatingViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getDatingViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.KaldunTickets/GetDatingViewTicket',
        request,
        metadata || {},
        this.methodDescriptorGetDatingViewTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.KaldunTickets/GetDatingViewTicket',
    request,
    metadata || {},
    this.methodDescriptorGetDatingViewTicket);
  }

  methodDescriptorGetAudioRoomTalkTicket = new grpcWeb.MethodDescriptor(
    '/tickets.KaldunTickets/GetAudioRoomTalkTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getAudioRoomTalkTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getAudioRoomTalkTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getAudioRoomTalkTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.KaldunTickets/GetAudioRoomTalkTicket',
        request,
        metadata || {},
        this.methodDescriptorGetAudioRoomTalkTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.KaldunTickets/GetAudioRoomTalkTicket',
    request,
    metadata || {},
    this.methodDescriptorGetAudioRoomTalkTicket);
  }

  methodDescriptorGetAudioRoomListenTicket = new grpcWeb.MethodDescriptor(
    '/tickets.KaldunTickets/GetAudioRoomListenTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getAudioRoomListenTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getAudioRoomListenTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getAudioRoomListenTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.KaldunTickets/GetAudioRoomListenTicket',
        request,
        metadata || {},
        this.methodDescriptorGetAudioRoomListenTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.KaldunTickets/GetAudioRoomListenTicket',
    request,
    metadata || {},
    this.methodDescriptorGetAudioRoomListenTicket);
  }

}

export class SciezkaTicketsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetBroadcastTicket = new grpcWeb.MethodDescriptor(
    '/tickets.SciezkaTickets/GetBroadcastTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getBroadcastTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getBroadcastTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getBroadcastTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.SciezkaTickets/GetBroadcastTicket',
        request,
        metadata || {},
        this.methodDescriptorGetBroadcastTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.SciezkaTickets/GetBroadcastTicket',
    request,
    metadata || {},
    this.methodDescriptorGetBroadcastTicket);
  }

  methodDescriptorGetViewTicket = new grpcWeb.MethodDescriptor(
    '/tickets.SciezkaTickets/GetViewTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getViewTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.SciezkaTickets/GetViewTicket',
        request,
        metadata || {},
        this.methodDescriptorGetViewTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.SciezkaTickets/GetViewTicket',
    request,
    metadata || {},
    this.methodDescriptorGetViewTicket);
  }

  methodDescriptorGetCallerTicket = new grpcWeb.MethodDescriptor(
    '/tickets.SciezkaTickets/GetCallerTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getCallerTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getCallerTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getCallerTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.SciezkaTickets/GetCallerTicket',
        request,
        metadata || {},
        this.methodDescriptorGetCallerTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.SciezkaTickets/GetCallerTicket',
    request,
    metadata || {},
    this.methodDescriptorGetCallerTicket);
  }

  methodDescriptorGetCalleeTicket = new grpcWeb.MethodDescriptor(
    '/tickets.SciezkaTickets/GetCalleeTicket',
    grpcWeb.MethodType.UNARY,
    tickets_pb.TicketRequest,
    tickets_pb.TicketResponse,
    (request: tickets_pb.TicketRequest) => {
      return request.serializeBinary();
    },
    tickets_pb.TicketResponse.deserializeBinary
  );

  getCalleeTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null): Promise<tickets_pb.TicketResponse>;

  getCalleeTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void): grpcWeb.ClientReadableStream<tickets_pb.TicketResponse>;

  getCalleeTicket(
    request: tickets_pb.TicketRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: tickets_pb.TicketResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/tickets.SciezkaTickets/GetCalleeTicket',
        request,
        metadata || {},
        this.methodDescriptorGetCalleeTicket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/tickets.SciezkaTickets/GetCalleeTicket',
    request,
    metadata || {},
    this.methodDescriptorGetCalleeTicket);
  }

}

