import CounterMetric from "./CounterMetric"
import TimeSeries from "./TimeSeries"

export type RTCIceServerWithHostname = RTCIceServer & { hostname: string };

export enum IcePolicy {
    All = "all",
    UdpRelay = "udp-relay",
    TcpRelay = "tcp-relay",
    Relay = "relay",
    TlsRelay = "tcp-tls-relay",
    DtlsRelay = "udp-tls-relay",
}

export interface RegionalIceServers {
  region?: string,
  iceServers: Array<RTCIceServer>,
}

export type TicketAndEndpoint = {
  ticket: string,
  endpoint: string,
  iceServers: Array<RegionalIceServers>,
}

export type TransportStatisticsViewState = {
    iceState?: string,
    bytesSent: {
        total: number,
        derivative: number,
    },
    bytesReceived: {
        total: number,
        derivative: number,
    },
    packetsSent: {
        total: number,
        derivative: number,
    },
    packetsReceived: {
        total: number,
        derivative: number,
    },
    candidatePair?: {
        bytesSent: {
            total: number,
            derivative: number,
        },
        bytesReceived: {
            total: number,
            derivative: number,
        },
        packetsSent: {
            total: number,
            derivative: number,
        },
        packetsReceived: {
            total: number,
            derivative: number,
        },
        currentRoundTripTime?: number,
        availableOutgoingBitrate?: number,
        availableIncomingBitrate?: number,
        local: {
            address?: string,
            port?: number,
            protocol?: string,
            relayProtocol?: string,
            candidateType?: string,
        }
        remote: {
            address?: string,
            port?: number,
            protocol?: string,
        }
    }
}

export type VideoLayerStat = {
    ssrc: number,
    rtxSsrc?: number,
    codec?: string,
    codecPayloadType?: number,
    rid?: string,
    packetsSent: CounterMetric,
    bytesSent: CounterMetric,
    headerBytesSent: CounterMetric,
    packetsDiscardedOnSend: CounterMetric,
    bytesDiscardedOnSend: CounterMetric,
    retransmittedPacketsSent: CounterMetric,
    retransmittedBytesSent: CounterMetric,
    targetBitrate: TimeSeries,
    encodedFramesPerSecond: TimeSeries,
    framesSent: CounterMetric,
    framesEncoded: CounterMetric,
    keyFramesEncoded: CounterMetric,
    framesDiscardedOnSend: CounterMetric,
    hugeFramesSent: CounterMetric,
    firCount: CounterMetric,
    pliCount: CounterMetric,
    nackCount: CounterMetric,
    frameWidth: TimeSeries,
    frameHeight: TimeSeries,
    qualityLimitationReason: "none" | "cpu" | "bandwidth" | "other",
    qualityLimitationDuration: {
        none: CounterMetric,
        cpu: CounterMetric,
        bandwidth: CounterMetric,
        other: CounterMetric,
    },
    totalEncodeTime: CounterMetric,
    totalPacketSendDelay: CounterMetric,
    jitter: TimeSeries,
    packetsLost: CounterMetric,
    fractionLost: TimeSeries,
    roundTripTime: TimeSeries,
    roundTripTimeMeasurements: CounterMetric,
    totalRoundTripTime: CounterMetric,
};

export type OutgoingMediaStreamStatsState = {
    stream: MediaStream
    audioTrackStat: {
        packetsSent: CounterMetric,
        bytesSent: CounterMetric,
        retransmittedBytesSent: CounterMetric,
        retransmittedPacketsSent: CounterMetric,
        ssrc?: number,
        codec?: string,
        codecPayloadType?: number,
        headerBytesSent: CounterMetric,
        packetsDiscardedOnSend: CounterMetric,
        bytesDiscardedOnSend: CounterMetric,
        targetBitrate: TimeSeries,
        nackCount: CounterMetric,
        audioLevel: TimeSeries
        echoReturnLoss: TimeSeries
        jitter: TimeSeries,
        packetsLost: CounterMetric,
        fractionLost: TimeSeries,
        roundTripTime: TimeSeries,
        roundTripTimeMeasurements: CounterMetric,
        totalRoundTripTime: CounterMetric,
    },
    videoTrackStat: {
        captureWidth?: number
        captureHeight?: number
        captureFps?: number
        layers: Map<number, VideoLayerStat>
    }
}

export type OutgoingVideoTrackStatViewState = {
    captureWidth?: number
    captureHeight?: number,
    captureFps?: number,
    layers: Array<{
        ssrc: number,
        rtxSsrc?: number,
        codec?: string,
        codecPayloadType?: number,
        rid?: string,
        packetsSent: {
            total: number,
            derivative: number,
        },
        bytesSent: {
            total: number,
            derivative: number,
        },
        headerBytesSent: {
            total: number,
            derivative: number,
        },
        packetsDiscardedOnSend: {
            total: number,
            derivative: number,
        },
        bytesDiscardedOnSend: {
            total: number,
            derivative: number,
        },
        retransmittedPacketsSent: {
            total: number,
            derivative: number,
        },
        retransmittedBytesSent: {
            total: number,
            derivative: number,
        },
        targetBitrate: {
            value: number,
        },
        encodedFramesPerSecond: {
            value: number
        },
        framesSent: {
            total: number,
            derivative: number,
        },
        framesEncoded: {
            total: number,
            derivative: number,
        },
        keyFramesEncoded: {
            total: number,
            derivative: number,
        },
        framesDiscardedOnSend: {
            total: number,
            derivative: number,
        },
        hugeFramesSent: {
            total: number,
            derivative: number,
        },
        firCount:  {
            total: number,
            derivative: number,
        },
        pliCount:  {
            total: number,
            derivative: number,
        },
        nackCount:  {
            total: number,
            derivative: number,
        },
        width?: number,
        height?: number,
        qualityLimitationReason: "none" | "cpu" | "bandwidth" | "other",
        qualityLimitationDuration: {
            none: number,
            cpu: number,
            bandwidth: number,
            other: number,
        },
        encodeTime: {
            total: number,
            msPerFrame: number,
        },
        packetSendDelay: {
            total: number,
            derivative: number,
        },
        jitter: {
            value: number
        } | null,
        rtt: {
            current: number,
            average: number,
        } | null,
        estimatedPacketsLoss: {
            packetsLost: number,
            fractionLost: number,
        } | null,
    }>
}

export type OutgoingAudioTrackStatViewState = {
    codec: string,
    codecPayloadType: number,
    ssrc: number,
    packetsSent: {
        total: number,
        derivative: number,
    },
    bytesSent: {
        total: number,
        derivative: number,
    },
    retransmittedBytesSent: {
        total: number,
        derivative: number,
    },
    retransmittedPacketsSent: {
        total: number,
        derivative: number,
    },
    headerBytesSent: {
        total: number,
        derivative: number,
    },
    packetsDiscardedOnSend: {
        total: number,
        derivative: number,
    },
    bytesDiscardedOnSend: {
        total: number,
        derivative: number,
    },
    nackCount: {
        total: number,
        derivative: number,
    }
    audioLevel: {
        value: number
    }
    echoReturnLoss: {
        value: number,
    },
    targetBitrate: {
        value: number
    } | null,
    jitter: {
        value: number
    } | null,
    rtt: {
        current: number,
        average: number,
    } | null,
    estimatedPacketsLoss: {
        packetsLost: number,
        fractionLost: number,
    } | null,
}

export type OutgoingMediaStatViewState = {
    videoTrackStat?: OutgoingVideoTrackStatViewState
    audioTrackStat?: OutgoingAudioTrackStatViewState
}

export type OutgoingMediaStreamViewState = {
    stream: MediaStream,
} & OutgoingMediaStatViewState

export type OutgoingMediaViewState = {
    microphoneAndCamera: OutgoingMediaStreamViewState
    screen: OutgoingMediaStreamViewState
}