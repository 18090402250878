import React from 'react';

type Props = {
    icon: string,
    width: number,
    height: number,
    fps: number | null
}

const VideoResolutionView = (props: Props) => {
    return (<div className="video-resolution">
        <span>{props.icon} {props.width}x{props.height}</span>
        {props.fps ? <span>@{props.fps} fps</span> : null}
    </div>)
}

export default VideoResolutionView;