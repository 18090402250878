import * as rx from 'rxjs'
import '../generated/submodules/sciezka-messages/client_messages'
import { sciezka_messages } from '../generated/submodules/sciezka-messages/client_messages'

class CallSignaling {
  private readonly messageSubj = new rx.Subject<sciezka_messages.OutgoingSciezkaMessage>()
  private socket : WebSocket | null = null;

  get messageReceived(): rx.Observable<sciezka_messages.OutgoingSciezkaMessage> {
    return this.messageSubj.asObservable()
  }

  public connect(url: string) : Promise<boolean> {
    return new Promise((resolver) => {
      this.socket = new WebSocket(url);
      this.socket.onmessage = async (ev) => {
        let buffer: ArrayBufferLike
        if (ev.data instanceof Blob) {
          buffer = await ev.data.arrayBuffer();
        } else if (ev.data instanceof ArrayBuffer) {
          buffer = ev.data
        } else {
          console.log("Error unsupported message: ", ev.data);
          return;
        }

        const message = sciezka_messages.OutgoingSciezkaMessage.deserializeBinary(new Uint8Array(buffer));

        console.log("OutgoingSciezkaMessage: ", message.toObject());

        this.messageSubj.next(message);
      };

      this.socket.onclose = (ev) => {
        console.log(`WebSocket closed with code ${ev.code} reason ${ev.reason}`);
      }

      this.socket.onerror = (ev) => {
        resolver(false);
        console.log(`WebSocket error happen`);
      }

      this.socket.onopen = (ev) => {
        resolver(true);
      }
    });
    
  }

  public isConnected() : boolean {
    return this.socket?.readyState === WebSocket.OPEN;
  }

  public send(message: sciezka_messages.SciezkaMessage, id?: string) {
    const incomingMessage = new sciezka_messages.IncomingSciezkaMessage({
      message,
    });
    if (id) {
      incomingMessage.destination_client_id = id;
    }

    console.log("IncomingSciezkaMessage: ", incomingMessage.toObject());

    this.socket?.send(incomingMessage.serializeBinary());
  }

  public close() {
    this.socket?.close();
  }
}

export default CallSignaling
