import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { checkGarconUrl, checkTicket, tryFetchTicket } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";


export default class GetDatingViewTicket implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.Dating,
        Services.Garcon,
    ]);
    readonly name: string = "Get single ticket for dating view";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        const accountId = randomString();
        const clientId = randomString();
        const roomId = randomString();

        const ticket = await tryFetchTicket(client, {
            accountId,
            clientId,
            roomId,
            subject: KaldunTicketSubject.DatingView,
        });

        checkTicket(assert, {
            ticket: ticket?.getTicket(),
            accountId,
            clientId,
            roomId,
            subject: KaldunTicketSubject.DatingView,
        });
    }
}