/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.26.1
 * source: rtc_types.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export namespace rtc_types {
    export enum RTCQualityLimitationReason {
        None = 0,
        Cpu = 1,
        Bandwidth = 2,
        Other = 3
    }
    export enum RTCCodecType {
        Encode = 0,
        Decode = 1
    }
    export enum RTCIceRole {
        Unknown = 0,
        Controlling = 1,
        Controlled = 2
    }
    export enum RTCStatsIceCandidatePairState {
        Frozen = 0,
        Waiting = 1,
        InProgress = 2,
        Failed = 3,
        Succeeded = 4
    }
    export enum RTCMediaKind {
        Audio = 0,
        Video = 1
    }
    export enum RTCDataChannelState {
        Connecting = 0,
        Open = 1,
        Closing = 2,
        Closed = 3
    }
    export enum RTCDtlsTransportState {
        DtlsNew = 0,
        DtlsConnecting = 1,
        DtlsConnected = 2,
        DtlsClosed = 3,
        DtlsFailed = 4
    }
    export enum RTCIceTransportState {
        IceNew = 0,
        IceChecking = 1,
        IceConnected = 2,
        IceCompleted = 3,
        IceDisconnected = 4,
        IceFailed = 5,
        IceClosed = 6
    }
    export enum RTCIceCandidateType {
        Host = 0,
        Srflx = 1,
        Prflx = 2,
        Relay = 3
    }
    export class RTCAudioSourceStats extends pb_1.Message {
        #one_of_decls: number[][] = [[2], [3], [4], [5], [6], [7]];
        constructor(data?: any[] | ({
            track_identifier?: string;
        } & (({
            relayed_source?: boolean;
        }) | ({
            audio_level?: number;
        }) | ({
            total_audio_energy?: number;
        }) | ({
            total_samples_duration?: number;
        }) | ({
            echo_return_loss?: number;
        }) | ({
            echo_return_loss_enhancement?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("track_identifier" in data && data.track_identifier != undefined) {
                    this.track_identifier = data.track_identifier;
                }
                if ("relayed_source" in data && data.relayed_source != undefined) {
                    this.relayed_source = data.relayed_source;
                }
                if ("audio_level" in data && data.audio_level != undefined) {
                    this.audio_level = data.audio_level;
                }
                if ("total_audio_energy" in data && data.total_audio_energy != undefined) {
                    this.total_audio_energy = data.total_audio_energy;
                }
                if ("total_samples_duration" in data && data.total_samples_duration != undefined) {
                    this.total_samples_duration = data.total_samples_duration;
                }
                if ("echo_return_loss" in data && data.echo_return_loss != undefined) {
                    this.echo_return_loss = data.echo_return_loss;
                }
                if ("echo_return_loss_enhancement" in data && data.echo_return_loss_enhancement != undefined) {
                    this.echo_return_loss_enhancement = data.echo_return_loss_enhancement;
                }
            }
        }
        get track_identifier() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set track_identifier(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get relayed_source() {
            return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
        }
        set relayed_source(value: boolean) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
        }
        get has_relayed_source() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get audio_level() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set audio_level(value: number) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[1], value);
        }
        get has_audio_level() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get total_audio_energy() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set total_audio_energy(value: number) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[2], value);
        }
        get has_total_audio_energy() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get total_samples_duration() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set total_samples_duration(value: number) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[3], value);
        }
        get has_total_samples_duration() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get echo_return_loss() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set echo_return_loss(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[4], value);
        }
        get has_echo_return_loss() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get echo_return_loss_enhancement() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set echo_return_loss_enhancement(value: number) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[5], value);
        }
        get has_echo_return_loss_enhancement() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get _relayed_source() {
            const cases: {
                [index: number]: "none" | "relayed_source";
            } = {
                0: "none",
                2: "relayed_source"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _audio_level() {
            const cases: {
                [index: number]: "none" | "audio_level";
            } = {
                0: "none",
                3: "audio_level"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _total_audio_energy() {
            const cases: {
                [index: number]: "none" | "total_audio_energy";
            } = {
                0: "none",
                4: "total_audio_energy"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        get _total_samples_duration() {
            const cases: {
                [index: number]: "none" | "total_samples_duration";
            } = {
                0: "none",
                5: "total_samples_duration"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _echo_return_loss() {
            const cases: {
                [index: number]: "none" | "echo_return_loss";
            } = {
                0: "none",
                6: "echo_return_loss"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _echo_return_loss_enhancement() {
            const cases: {
                [index: number]: "none" | "echo_return_loss_enhancement";
            } = {
                0: "none",
                7: "echo_return_loss_enhancement"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        static fromObject(data: {
            track_identifier?: string;
            relayed_source?: boolean;
            audio_level?: number;
            total_audio_energy?: number;
            total_samples_duration?: number;
            echo_return_loss?: number;
            echo_return_loss_enhancement?: number;
        }): RTCAudioSourceStats {
            const message = new RTCAudioSourceStats({});
            if (data.track_identifier != null) {
                message.track_identifier = data.track_identifier;
            }
            if (data.relayed_source != null) {
                message.relayed_source = data.relayed_source;
            }
            if (data.audio_level != null) {
                message.audio_level = data.audio_level;
            }
            if (data.total_audio_energy != null) {
                message.total_audio_energy = data.total_audio_energy;
            }
            if (data.total_samples_duration != null) {
                message.total_samples_duration = data.total_samples_duration;
            }
            if (data.echo_return_loss != null) {
                message.echo_return_loss = data.echo_return_loss;
            }
            if (data.echo_return_loss_enhancement != null) {
                message.echo_return_loss_enhancement = data.echo_return_loss_enhancement;
            }
            return message;
        }
        toObject() {
            const data: {
                track_identifier?: string;
                relayed_source?: boolean;
                audio_level?: number;
                total_audio_energy?: number;
                total_samples_duration?: number;
                echo_return_loss?: number;
                echo_return_loss_enhancement?: number;
            } = {};
            if (this.track_identifier != null) {
                data.track_identifier = this.track_identifier;
            }
            if (this.relayed_source != null) {
                data.relayed_source = this.relayed_source;
            }
            if (this.audio_level != null) {
                data.audio_level = this.audio_level;
            }
            if (this.total_audio_energy != null) {
                data.total_audio_energy = this.total_audio_energy;
            }
            if (this.total_samples_duration != null) {
                data.total_samples_duration = this.total_samples_duration;
            }
            if (this.echo_return_loss != null) {
                data.echo_return_loss = this.echo_return_loss;
            }
            if (this.echo_return_loss_enhancement != null) {
                data.echo_return_loss_enhancement = this.echo_return_loss_enhancement;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.track_identifier.length)
                writer.writeString(1, this.track_identifier);
            if (this.has_relayed_source)
                writer.writeBool(2, this.relayed_source);
            if (this.has_audio_level)
                writer.writeFloat(3, this.audio_level);
            if (this.has_total_audio_energy)
                writer.writeFloat(4, this.total_audio_energy);
            if (this.has_total_samples_duration)
                writer.writeFloat(5, this.total_samples_duration);
            if (this.has_echo_return_loss)
                writer.writeFloat(6, this.echo_return_loss);
            if (this.has_echo_return_loss_enhancement)
                writer.writeFloat(7, this.echo_return_loss_enhancement);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCAudioSourceStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCAudioSourceStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.track_identifier = reader.readString();
                        break;
                    case 2:
                        message.relayed_source = reader.readBool();
                        break;
                    case 3:
                        message.audio_level = reader.readFloat();
                        break;
                    case 4:
                        message.total_audio_energy = reader.readFloat();
                        break;
                    case 5:
                        message.total_samples_duration = reader.readFloat();
                        break;
                    case 6:
                        message.echo_return_loss = reader.readFloat();
                        break;
                    case 7:
                        message.echo_return_loss_enhancement = reader.readFloat();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCAudioSourceStats {
            return RTCAudioSourceStats.deserialize(bytes);
        }
    }
    export class RTCVideoSourceStats extends pb_1.Message {
        #one_of_decls: number[][] = [[2], [3], [4], [5], [6], [7]];
        constructor(data?: any[] | ({
            track_identifier?: string;
        } & (({
            relayed_source?: boolean;
        }) | ({
            width?: number;
        }) | ({
            height?: number;
        }) | ({
            bit_depth?: number;
        }) | ({
            frames?: number;
        }) | ({
            frames_per_second?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("track_identifier" in data && data.track_identifier != undefined) {
                    this.track_identifier = data.track_identifier;
                }
                if ("relayed_source" in data && data.relayed_source != undefined) {
                    this.relayed_source = data.relayed_source;
                }
                if ("width" in data && data.width != undefined) {
                    this.width = data.width;
                }
                if ("height" in data && data.height != undefined) {
                    this.height = data.height;
                }
                if ("bit_depth" in data && data.bit_depth != undefined) {
                    this.bit_depth = data.bit_depth;
                }
                if ("frames" in data && data.frames != undefined) {
                    this.frames = data.frames;
                }
                if ("frames_per_second" in data && data.frames_per_second != undefined) {
                    this.frames_per_second = data.frames_per_second;
                }
            }
        }
        get track_identifier() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set track_identifier(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get relayed_source() {
            return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
        }
        set relayed_source(value: boolean) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
        }
        get has_relayed_source() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get width() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set width(value: number) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[1], value);
        }
        get has_width() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get height() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set height(value: number) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[2], value);
        }
        get has_height() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get bit_depth() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set bit_depth(value: number) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[3], value);
        }
        get has_bit_depth() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get frames() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set frames(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[4], value);
        }
        get has_frames() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get frames_per_second() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set frames_per_second(value: number) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[5], value);
        }
        get has_frames_per_second() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get _relayed_source() {
            const cases: {
                [index: number]: "none" | "relayed_source";
            } = {
                0: "none",
                2: "relayed_source"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _width() {
            const cases: {
                [index: number]: "none" | "width";
            } = {
                0: "none",
                3: "width"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _height() {
            const cases: {
                [index: number]: "none" | "height";
            } = {
                0: "none",
                4: "height"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        get _bit_depth() {
            const cases: {
                [index: number]: "none" | "bit_depth";
            } = {
                0: "none",
                5: "bit_depth"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _frames() {
            const cases: {
                [index: number]: "none" | "frames";
            } = {
                0: "none",
                6: "frames"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _frames_per_second() {
            const cases: {
                [index: number]: "none" | "frames_per_second";
            } = {
                0: "none",
                7: "frames_per_second"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        static fromObject(data: {
            track_identifier?: string;
            relayed_source?: boolean;
            width?: number;
            height?: number;
            bit_depth?: number;
            frames?: number;
            frames_per_second?: number;
        }): RTCVideoSourceStats {
            const message = new RTCVideoSourceStats({});
            if (data.track_identifier != null) {
                message.track_identifier = data.track_identifier;
            }
            if (data.relayed_source != null) {
                message.relayed_source = data.relayed_source;
            }
            if (data.width != null) {
                message.width = data.width;
            }
            if (data.height != null) {
                message.height = data.height;
            }
            if (data.bit_depth != null) {
                message.bit_depth = data.bit_depth;
            }
            if (data.frames != null) {
                message.frames = data.frames;
            }
            if (data.frames_per_second != null) {
                message.frames_per_second = data.frames_per_second;
            }
            return message;
        }
        toObject() {
            const data: {
                track_identifier?: string;
                relayed_source?: boolean;
                width?: number;
                height?: number;
                bit_depth?: number;
                frames?: number;
                frames_per_second?: number;
            } = {};
            if (this.track_identifier != null) {
                data.track_identifier = this.track_identifier;
            }
            if (this.relayed_source != null) {
                data.relayed_source = this.relayed_source;
            }
            if (this.width != null) {
                data.width = this.width;
            }
            if (this.height != null) {
                data.height = this.height;
            }
            if (this.bit_depth != null) {
                data.bit_depth = this.bit_depth;
            }
            if (this.frames != null) {
                data.frames = this.frames;
            }
            if (this.frames_per_second != null) {
                data.frames_per_second = this.frames_per_second;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.track_identifier.length)
                writer.writeString(1, this.track_identifier);
            if (this.has_relayed_source)
                writer.writeBool(2, this.relayed_source);
            if (this.has_width)
                writer.writeFixed32(3, this.width);
            if (this.has_height)
                writer.writeFixed32(4, this.height);
            if (this.has_bit_depth)
                writer.writeFixed32(5, this.bit_depth);
            if (this.has_frames)
                writer.writeFixed32(6, this.frames);
            if (this.has_frames_per_second)
                writer.writeFloat(7, this.frames_per_second);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCVideoSourceStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCVideoSourceStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.track_identifier = reader.readString();
                        break;
                    case 2:
                        message.relayed_source = reader.readBool();
                        break;
                    case 3:
                        message.width = reader.readFixed32();
                        break;
                    case 4:
                        message.height = reader.readFixed32();
                        break;
                    case 5:
                        message.bit_depth = reader.readFixed32();
                        break;
                    case 6:
                        message.frames = reader.readFixed32();
                        break;
                    case 7:
                        message.frames_per_second = reader.readFloat();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCVideoSourceStats {
            return RTCVideoSourceStats.deserialize(bytes);
        }
    }
    export class RTCVideoSenderStats extends pb_1.Message {
        #one_of_decls: number[][] = [[1], [2], [3]];
        constructor(data?: any[] | ({} & (({
            track_identifier?: string;
        }) | ({
            ended?: boolean;
        }) | ({
            media_source_id?: string;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("track_identifier" in data && data.track_identifier != undefined) {
                    this.track_identifier = data.track_identifier;
                }
                if ("ended" in data && data.ended != undefined) {
                    this.ended = data.ended;
                }
                if ("media_source_id" in data && data.media_source_id != undefined) {
                    this.media_source_id = data.media_source_id;
                }
            }
        }
        get track_identifier() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set track_identifier(value: string) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_track_identifier() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get ended() {
            return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
        }
        set ended(value: boolean) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
        }
        get has_ended() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get media_source_id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set media_source_id(value: string) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
        }
        get has_media_source_id() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get _track_identifier() {
            const cases: {
                [index: number]: "none" | "track_identifier";
            } = {
                0: "none",
                1: "track_identifier"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        get _ended() {
            const cases: {
                [index: number]: "none" | "ended";
            } = {
                0: "none",
                2: "ended"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _media_source_id() {
            const cases: {
                [index: number]: "none" | "media_source_id";
            } = {
                0: "none",
                3: "media_source_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        static fromObject(data: {
            track_identifier?: string;
            ended?: boolean;
            media_source_id?: string;
        }): RTCVideoSenderStats {
            const message = new RTCVideoSenderStats({});
            if (data.track_identifier != null) {
                message.track_identifier = data.track_identifier;
            }
            if (data.ended != null) {
                message.ended = data.ended;
            }
            if (data.media_source_id != null) {
                message.media_source_id = data.media_source_id;
            }
            return message;
        }
        toObject() {
            const data: {
                track_identifier?: string;
                ended?: boolean;
                media_source_id?: string;
            } = {};
            if (this.track_identifier != null) {
                data.track_identifier = this.track_identifier;
            }
            if (this.ended != null) {
                data.ended = this.ended;
            }
            if (this.media_source_id != null) {
                data.media_source_id = this.media_source_id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_track_identifier)
                writer.writeString(1, this.track_identifier);
            if (this.has_ended)
                writer.writeBool(2, this.ended);
            if (this.has_media_source_id)
                writer.writeString(3, this.media_source_id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCVideoSenderStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCVideoSenderStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.track_identifier = reader.readString();
                        break;
                    case 2:
                        message.ended = reader.readBool();
                        break;
                    case 3:
                        message.media_source_id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCVideoSenderStats {
            return RTCVideoSenderStats.deserialize(bytes);
        }
    }
    export class RTCVideoReceiverStats extends pb_1.Message {
        #one_of_decls: number[][] = [[1], [2]];
        constructor(data?: any[] | ({} & (({
            track_identifier?: string;
        }) | ({
            ended?: boolean;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("track_identifier" in data && data.track_identifier != undefined) {
                    this.track_identifier = data.track_identifier;
                }
                if ("ended" in data && data.ended != undefined) {
                    this.ended = data.ended;
                }
            }
        }
        get track_identifier() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set track_identifier(value: string) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_track_identifier() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get ended() {
            return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
        }
        set ended(value: boolean) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
        }
        get has_ended() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get _track_identifier() {
            const cases: {
                [index: number]: "none" | "track_identifier";
            } = {
                0: "none",
                1: "track_identifier"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        get _ended() {
            const cases: {
                [index: number]: "none" | "ended";
            } = {
                0: "none",
                2: "ended"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        static fromObject(data: {
            track_identifier?: string;
            ended?: boolean;
        }): RTCVideoReceiverStats {
            const message = new RTCVideoReceiverStats({});
            if (data.track_identifier != null) {
                message.track_identifier = data.track_identifier;
            }
            if (data.ended != null) {
                message.ended = data.ended;
            }
            return message;
        }
        toObject() {
            const data: {
                track_identifier?: string;
                ended?: boolean;
            } = {};
            if (this.track_identifier != null) {
                data.track_identifier = this.track_identifier;
            }
            if (this.ended != null) {
                data.ended = this.ended;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_track_identifier)
                writer.writeString(1, this.track_identifier);
            if (this.has_ended)
                writer.writeBool(2, this.ended);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCVideoReceiverStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCVideoReceiverStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.track_identifier = reader.readString();
                        break;
                    case 2:
                        message.ended = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCVideoReceiverStats {
            return RTCVideoReceiverStats.deserialize(bytes);
        }
    }
    export class RTCAudioSenderStats extends pb_1.Message {
        #one_of_decls: number[][] = [[1], [2], [3]];
        constructor(data?: any[] | ({} & (({
            track_identifier?: string;
        }) | ({
            ended?: boolean;
        }) | ({
            media_source_id?: string;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("track_identifier" in data && data.track_identifier != undefined) {
                    this.track_identifier = data.track_identifier;
                }
                if ("ended" in data && data.ended != undefined) {
                    this.ended = data.ended;
                }
                if ("media_source_id" in data && data.media_source_id != undefined) {
                    this.media_source_id = data.media_source_id;
                }
            }
        }
        get track_identifier() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set track_identifier(value: string) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_track_identifier() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get ended() {
            return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
        }
        set ended(value: boolean) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
        }
        get has_ended() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get media_source_id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set media_source_id(value: string) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
        }
        get has_media_source_id() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get _track_identifier() {
            const cases: {
                [index: number]: "none" | "track_identifier";
            } = {
                0: "none",
                1: "track_identifier"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        get _ended() {
            const cases: {
                [index: number]: "none" | "ended";
            } = {
                0: "none",
                2: "ended"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _media_source_id() {
            const cases: {
                [index: number]: "none" | "media_source_id";
            } = {
                0: "none",
                3: "media_source_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        static fromObject(data: {
            track_identifier?: string;
            ended?: boolean;
            media_source_id?: string;
        }): RTCAudioSenderStats {
            const message = new RTCAudioSenderStats({});
            if (data.track_identifier != null) {
                message.track_identifier = data.track_identifier;
            }
            if (data.ended != null) {
                message.ended = data.ended;
            }
            if (data.media_source_id != null) {
                message.media_source_id = data.media_source_id;
            }
            return message;
        }
        toObject() {
            const data: {
                track_identifier?: string;
                ended?: boolean;
                media_source_id?: string;
            } = {};
            if (this.track_identifier != null) {
                data.track_identifier = this.track_identifier;
            }
            if (this.ended != null) {
                data.ended = this.ended;
            }
            if (this.media_source_id != null) {
                data.media_source_id = this.media_source_id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_track_identifier)
                writer.writeString(1, this.track_identifier);
            if (this.has_ended)
                writer.writeBool(2, this.ended);
            if (this.has_media_source_id)
                writer.writeString(3, this.media_source_id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCAudioSenderStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCAudioSenderStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.track_identifier = reader.readString();
                        break;
                    case 2:
                        message.ended = reader.readBool();
                        break;
                    case 3:
                        message.media_source_id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCAudioSenderStats {
            return RTCAudioSenderStats.deserialize(bytes);
        }
    }
    export class RTCAudioReceiverStats extends pb_1.Message {
        #one_of_decls: number[][] = [[1], [2]];
        constructor(data?: any[] | ({} & (({
            track_identifier?: string;
        }) | ({
            ended?: boolean;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("track_identifier" in data && data.track_identifier != undefined) {
                    this.track_identifier = data.track_identifier;
                }
                if ("ended" in data && data.ended != undefined) {
                    this.ended = data.ended;
                }
            }
        }
        get track_identifier() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set track_identifier(value: string) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_track_identifier() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get ended() {
            return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
        }
        set ended(value: boolean) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
        }
        get has_ended() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get _track_identifier() {
            const cases: {
                [index: number]: "none" | "track_identifier";
            } = {
                0: "none",
                1: "track_identifier"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        get _ended() {
            const cases: {
                [index: number]: "none" | "ended";
            } = {
                0: "none",
                2: "ended"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        static fromObject(data: {
            track_identifier?: string;
            ended?: boolean;
        }): RTCAudioReceiverStats {
            const message = new RTCAudioReceiverStats({});
            if (data.track_identifier != null) {
                message.track_identifier = data.track_identifier;
            }
            if (data.ended != null) {
                message.ended = data.ended;
            }
            return message;
        }
        toObject() {
            const data: {
                track_identifier?: string;
                ended?: boolean;
            } = {};
            if (this.track_identifier != null) {
                data.track_identifier = this.track_identifier;
            }
            if (this.ended != null) {
                data.ended = this.ended;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_track_identifier)
                writer.writeString(1, this.track_identifier);
            if (this.has_ended)
                writer.writeBool(2, this.ended);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCAudioReceiverStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCAudioReceiverStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.track_identifier = reader.readString();
                        break;
                    case 2:
                        message.ended = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCAudioReceiverStats {
            return RTCAudioReceiverStats.deserialize(bytes);
        }
    }
    export class RTCOutboundRtpStreamStats extends pb_1.Message {
        #one_of_decls: number[][] = [[3], [4], [5], [6], [7], [8], [9], [10], [11], [12], [13], [14], [15], [16], [17], [18], [19], [20], [21], [22], [23], [24], [25], [26], [27], [28], [29], [30], [31], [32], [33], [34], [35], [36], [37], [38], [40], [42], [43], [44], [45], [46]];
        constructor(data?: any[] | ({
            ssrc?: number;
            kind?: RTCMediaKind;
            quality_limitation_durations?: Map<number, number>;
            per_dscp_packets_sent?: Map<string, number>;
        } & (({
            transport_id?: string;
        }) | ({
            codec_id?: string;
        }) | ({
            packets_sent?: number;
        }) | ({
            bytes_sent?: number;
        }) | ({
            rtx_ssrc?: number;
        }) | ({
            media_source_id?: string;
        }) | ({
            sender_id?: string;
        }) | ({
            remote_id?: string;
        }) | ({
            rid?: string;
        }) | ({
            last_packet_sent_timestamp?: number;
        }) | ({
            header_bytes_sent?: number;
        }) | ({
            packets_discarded_on_send?: number;
        }) | ({
            bytes_discarded_on_send?: number;
        }) | ({
            fec_packets_sent?: number;
        }) | ({
            retransmitted_packets_sent?: number;
        }) | ({
            retransmitted_bytes_sent?: number;
        }) | ({
            target_bitrate?: number;
        }) | ({
            total_encoded_bytes_target?: number;
        }) | ({
            frame_width?: number;
        }) | ({
            frame_height?: number;
        }) | ({
            frame_bit_depth?: number;
        }) | ({
            frames_per_second?: number;
        }) | ({
            frames_sent?: number;
        }) | ({
            huge_frames_sent?: number;
        }) | ({
            frames_encoded?: number;
        }) | ({
            key_frames_encoded?: number;
        }) | ({
            frames_discarded_on_send?: number;
        }) | ({
            qp_sum?: number;
        }) | ({
            total_samples_sent?: number;
        }) | ({
            samples_encoded_with_silk?: number;
        }) | ({
            samples_encoded_with_celt?: number;
        }) | ({
            voice_activity_flag?: boolean;
        }) | ({
            total_encode_time?: number;
        }) | ({
            total_packet_send_delay?: number;
        }) | ({
            average_rtcp_interval?: number;
        }) | ({
            quality_limitation_reason?: RTCQualityLimitationReason;
        }) | ({
            quality_limitation_resolution_changes?: number;
        }) | ({
            nack_count?: number;
        }) | ({
            fir_count?: number;
        }) | ({
            pli_count?: number;
        }) | ({
            sli_count?: number;
        }) | ({
            encoder_implementation?: string;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("ssrc" in data && data.ssrc != undefined) {
                    this.ssrc = data.ssrc;
                }
                if ("kind" in data && data.kind != undefined) {
                    this.kind = data.kind;
                }
                if ("transport_id" in data && data.transport_id != undefined) {
                    this.transport_id = data.transport_id;
                }
                if ("codec_id" in data && data.codec_id != undefined) {
                    this.codec_id = data.codec_id;
                }
                if ("packets_sent" in data && data.packets_sent != undefined) {
                    this.packets_sent = data.packets_sent;
                }
                if ("bytes_sent" in data && data.bytes_sent != undefined) {
                    this.bytes_sent = data.bytes_sent;
                }
                if ("rtx_ssrc" in data && data.rtx_ssrc != undefined) {
                    this.rtx_ssrc = data.rtx_ssrc;
                }
                if ("media_source_id" in data && data.media_source_id != undefined) {
                    this.media_source_id = data.media_source_id;
                }
                if ("sender_id" in data && data.sender_id != undefined) {
                    this.sender_id = data.sender_id;
                }
                if ("remote_id" in data && data.remote_id != undefined) {
                    this.remote_id = data.remote_id;
                }
                if ("rid" in data && data.rid != undefined) {
                    this.rid = data.rid;
                }
                if ("last_packet_sent_timestamp" in data && data.last_packet_sent_timestamp != undefined) {
                    this.last_packet_sent_timestamp = data.last_packet_sent_timestamp;
                }
                if ("header_bytes_sent" in data && data.header_bytes_sent != undefined) {
                    this.header_bytes_sent = data.header_bytes_sent;
                }
                if ("packets_discarded_on_send" in data && data.packets_discarded_on_send != undefined) {
                    this.packets_discarded_on_send = data.packets_discarded_on_send;
                }
                if ("bytes_discarded_on_send" in data && data.bytes_discarded_on_send != undefined) {
                    this.bytes_discarded_on_send = data.bytes_discarded_on_send;
                }
                if ("fec_packets_sent" in data && data.fec_packets_sent != undefined) {
                    this.fec_packets_sent = data.fec_packets_sent;
                }
                if ("retransmitted_packets_sent" in data && data.retransmitted_packets_sent != undefined) {
                    this.retransmitted_packets_sent = data.retransmitted_packets_sent;
                }
                if ("retransmitted_bytes_sent" in data && data.retransmitted_bytes_sent != undefined) {
                    this.retransmitted_bytes_sent = data.retransmitted_bytes_sent;
                }
                if ("target_bitrate" in data && data.target_bitrate != undefined) {
                    this.target_bitrate = data.target_bitrate;
                }
                if ("total_encoded_bytes_target" in data && data.total_encoded_bytes_target != undefined) {
                    this.total_encoded_bytes_target = data.total_encoded_bytes_target;
                }
                if ("frame_width" in data && data.frame_width != undefined) {
                    this.frame_width = data.frame_width;
                }
                if ("frame_height" in data && data.frame_height != undefined) {
                    this.frame_height = data.frame_height;
                }
                if ("frame_bit_depth" in data && data.frame_bit_depth != undefined) {
                    this.frame_bit_depth = data.frame_bit_depth;
                }
                if ("frames_per_second" in data && data.frames_per_second != undefined) {
                    this.frames_per_second = data.frames_per_second;
                }
                if ("frames_sent" in data && data.frames_sent != undefined) {
                    this.frames_sent = data.frames_sent;
                }
                if ("huge_frames_sent" in data && data.huge_frames_sent != undefined) {
                    this.huge_frames_sent = data.huge_frames_sent;
                }
                if ("frames_encoded" in data && data.frames_encoded != undefined) {
                    this.frames_encoded = data.frames_encoded;
                }
                if ("key_frames_encoded" in data && data.key_frames_encoded != undefined) {
                    this.key_frames_encoded = data.key_frames_encoded;
                }
                if ("frames_discarded_on_send" in data && data.frames_discarded_on_send != undefined) {
                    this.frames_discarded_on_send = data.frames_discarded_on_send;
                }
                if ("qp_sum" in data && data.qp_sum != undefined) {
                    this.qp_sum = data.qp_sum;
                }
                if ("total_samples_sent" in data && data.total_samples_sent != undefined) {
                    this.total_samples_sent = data.total_samples_sent;
                }
                if ("samples_encoded_with_silk" in data && data.samples_encoded_with_silk != undefined) {
                    this.samples_encoded_with_silk = data.samples_encoded_with_silk;
                }
                if ("samples_encoded_with_celt" in data && data.samples_encoded_with_celt != undefined) {
                    this.samples_encoded_with_celt = data.samples_encoded_with_celt;
                }
                if ("voice_activity_flag" in data && data.voice_activity_flag != undefined) {
                    this.voice_activity_flag = data.voice_activity_flag;
                }
                if ("total_encode_time" in data && data.total_encode_time != undefined) {
                    this.total_encode_time = data.total_encode_time;
                }
                if ("total_packet_send_delay" in data && data.total_packet_send_delay != undefined) {
                    this.total_packet_send_delay = data.total_packet_send_delay;
                }
                if ("average_rtcp_interval" in data && data.average_rtcp_interval != undefined) {
                    this.average_rtcp_interval = data.average_rtcp_interval;
                }
                if ("quality_limitation_reason" in data && data.quality_limitation_reason != undefined) {
                    this.quality_limitation_reason = data.quality_limitation_reason;
                }
                if ("quality_limitation_durations" in data && data.quality_limitation_durations != undefined) {
                    this.quality_limitation_durations = data.quality_limitation_durations;
                }
                if ("quality_limitation_resolution_changes" in data && data.quality_limitation_resolution_changes != undefined) {
                    this.quality_limitation_resolution_changes = data.quality_limitation_resolution_changes;
                }
                if ("per_dscp_packets_sent" in data && data.per_dscp_packets_sent != undefined) {
                    this.per_dscp_packets_sent = data.per_dscp_packets_sent;
                }
                if ("nack_count" in data && data.nack_count != undefined) {
                    this.nack_count = data.nack_count;
                }
                if ("fir_count" in data && data.fir_count != undefined) {
                    this.fir_count = data.fir_count;
                }
                if ("pli_count" in data && data.pli_count != undefined) {
                    this.pli_count = data.pli_count;
                }
                if ("sli_count" in data && data.sli_count != undefined) {
                    this.sli_count = data.sli_count;
                }
                if ("encoder_implementation" in data && data.encoder_implementation != undefined) {
                    this.encoder_implementation = data.encoder_implementation;
                }
            }
            if (!this.quality_limitation_durations)
                this.quality_limitation_durations = new Map();
            if (!this.per_dscp_packets_sent)
                this.per_dscp_packets_sent = new Map();
        }
        get ssrc() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set ssrc(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get kind() {
            return pb_1.Message.getFieldWithDefault(this, 2, RTCMediaKind.Audio) as RTCMediaKind;
        }
        set kind(value: RTCMediaKind) {
            pb_1.Message.setField(this, 2, value);
        }
        get transport_id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set transport_id(value: string) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0], value);
        }
        get has_transport_id() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get codec_id() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set codec_id(value: string) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[1], value);
        }
        get has_codec_id() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get packets_sent() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set packets_sent(value: number) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[2], value);
        }
        get has_packets_sent() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get bytes_sent() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set bytes_sent(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[3], value);
        }
        get has_bytes_sent() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get rtx_ssrc() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set rtx_ssrc(value: number) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[4], value);
        }
        get has_rtx_ssrc() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get media_source_id() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set media_source_id(value: string) {
            pb_1.Message.setOneofField(this, 8, this.#one_of_decls[5], value);
        }
        get has_media_source_id() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get sender_id() {
            return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
        }
        set sender_id(value: string) {
            pb_1.Message.setOneofField(this, 9, this.#one_of_decls[6], value);
        }
        get has_sender_id() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get remote_id() {
            return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
        }
        set remote_id(value: string) {
            pb_1.Message.setOneofField(this, 10, this.#one_of_decls[7], value);
        }
        get has_remote_id() {
            return pb_1.Message.getField(this, 10) != null;
        }
        get rid() {
            return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
        }
        set rid(value: string) {
            pb_1.Message.setOneofField(this, 11, this.#one_of_decls[8], value);
        }
        get has_rid() {
            return pb_1.Message.getField(this, 11) != null;
        }
        get last_packet_sent_timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
        }
        set last_packet_sent_timestamp(value: number) {
            pb_1.Message.setOneofField(this, 12, this.#one_of_decls[9], value);
        }
        get has_last_packet_sent_timestamp() {
            return pb_1.Message.getField(this, 12) != null;
        }
        get header_bytes_sent() {
            return pb_1.Message.getFieldWithDefault(this, 13, 0) as number;
        }
        set header_bytes_sent(value: number) {
            pb_1.Message.setOneofField(this, 13, this.#one_of_decls[10], value);
        }
        get has_header_bytes_sent() {
            return pb_1.Message.getField(this, 13) != null;
        }
        get packets_discarded_on_send() {
            return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
        }
        set packets_discarded_on_send(value: number) {
            pb_1.Message.setOneofField(this, 14, this.#one_of_decls[11], value);
        }
        get has_packets_discarded_on_send() {
            return pb_1.Message.getField(this, 14) != null;
        }
        get bytes_discarded_on_send() {
            return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
        }
        set bytes_discarded_on_send(value: number) {
            pb_1.Message.setOneofField(this, 15, this.#one_of_decls[12], value);
        }
        get has_bytes_discarded_on_send() {
            return pb_1.Message.getField(this, 15) != null;
        }
        get fec_packets_sent() {
            return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
        }
        set fec_packets_sent(value: number) {
            pb_1.Message.setOneofField(this, 16, this.#one_of_decls[13], value);
        }
        get has_fec_packets_sent() {
            return pb_1.Message.getField(this, 16) != null;
        }
        get retransmitted_packets_sent() {
            return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
        }
        set retransmitted_packets_sent(value: number) {
            pb_1.Message.setOneofField(this, 17, this.#one_of_decls[14], value);
        }
        get has_retransmitted_packets_sent() {
            return pb_1.Message.getField(this, 17) != null;
        }
        get retransmitted_bytes_sent() {
            return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
        }
        set retransmitted_bytes_sent(value: number) {
            pb_1.Message.setOneofField(this, 18, this.#one_of_decls[15], value);
        }
        get has_retransmitted_bytes_sent() {
            return pb_1.Message.getField(this, 18) != null;
        }
        get target_bitrate() {
            return pb_1.Message.getFieldWithDefault(this, 19, 0) as number;
        }
        set target_bitrate(value: number) {
            pb_1.Message.setOneofField(this, 19, this.#one_of_decls[16], value);
        }
        get has_target_bitrate() {
            return pb_1.Message.getField(this, 19) != null;
        }
        get total_encoded_bytes_target() {
            return pb_1.Message.getFieldWithDefault(this, 20, 0) as number;
        }
        set total_encoded_bytes_target(value: number) {
            pb_1.Message.setOneofField(this, 20, this.#one_of_decls[17], value);
        }
        get has_total_encoded_bytes_target() {
            return pb_1.Message.getField(this, 20) != null;
        }
        get frame_width() {
            return pb_1.Message.getFieldWithDefault(this, 21, 0) as number;
        }
        set frame_width(value: number) {
            pb_1.Message.setOneofField(this, 21, this.#one_of_decls[18], value);
        }
        get has_frame_width() {
            return pb_1.Message.getField(this, 21) != null;
        }
        get frame_height() {
            return pb_1.Message.getFieldWithDefault(this, 22, 0) as number;
        }
        set frame_height(value: number) {
            pb_1.Message.setOneofField(this, 22, this.#one_of_decls[19], value);
        }
        get has_frame_height() {
            return pb_1.Message.getField(this, 22) != null;
        }
        get frame_bit_depth() {
            return pb_1.Message.getFieldWithDefault(this, 23, 0) as number;
        }
        set frame_bit_depth(value: number) {
            pb_1.Message.setOneofField(this, 23, this.#one_of_decls[20], value);
        }
        get has_frame_bit_depth() {
            return pb_1.Message.getField(this, 23) != null;
        }
        get frames_per_second() {
            return pb_1.Message.getFieldWithDefault(this, 24, 0) as number;
        }
        set frames_per_second(value: number) {
            pb_1.Message.setOneofField(this, 24, this.#one_of_decls[21], value);
        }
        get has_frames_per_second() {
            return pb_1.Message.getField(this, 24) != null;
        }
        get frames_sent() {
            return pb_1.Message.getFieldWithDefault(this, 25, 0) as number;
        }
        set frames_sent(value: number) {
            pb_1.Message.setOneofField(this, 25, this.#one_of_decls[22], value);
        }
        get has_frames_sent() {
            return pb_1.Message.getField(this, 25) != null;
        }
        get huge_frames_sent() {
            return pb_1.Message.getFieldWithDefault(this, 26, 0) as number;
        }
        set huge_frames_sent(value: number) {
            pb_1.Message.setOneofField(this, 26, this.#one_of_decls[23], value);
        }
        get has_huge_frames_sent() {
            return pb_1.Message.getField(this, 26) != null;
        }
        get frames_encoded() {
            return pb_1.Message.getFieldWithDefault(this, 27, 0) as number;
        }
        set frames_encoded(value: number) {
            pb_1.Message.setOneofField(this, 27, this.#one_of_decls[24], value);
        }
        get has_frames_encoded() {
            return pb_1.Message.getField(this, 27) != null;
        }
        get key_frames_encoded() {
            return pb_1.Message.getFieldWithDefault(this, 28, 0) as number;
        }
        set key_frames_encoded(value: number) {
            pb_1.Message.setOneofField(this, 28, this.#one_of_decls[25], value);
        }
        get has_key_frames_encoded() {
            return pb_1.Message.getField(this, 28) != null;
        }
        get frames_discarded_on_send() {
            return pb_1.Message.getFieldWithDefault(this, 29, 0) as number;
        }
        set frames_discarded_on_send(value: number) {
            pb_1.Message.setOneofField(this, 29, this.#one_of_decls[26], value);
        }
        get has_frames_discarded_on_send() {
            return pb_1.Message.getField(this, 29) != null;
        }
        get qp_sum() {
            return pb_1.Message.getFieldWithDefault(this, 30, 0) as number;
        }
        set qp_sum(value: number) {
            pb_1.Message.setOneofField(this, 30, this.#one_of_decls[27], value);
        }
        get has_qp_sum() {
            return pb_1.Message.getField(this, 30) != null;
        }
        get total_samples_sent() {
            return pb_1.Message.getFieldWithDefault(this, 31, 0) as number;
        }
        set total_samples_sent(value: number) {
            pb_1.Message.setOneofField(this, 31, this.#one_of_decls[28], value);
        }
        get has_total_samples_sent() {
            return pb_1.Message.getField(this, 31) != null;
        }
        get samples_encoded_with_silk() {
            return pb_1.Message.getFieldWithDefault(this, 32, 0) as number;
        }
        set samples_encoded_with_silk(value: number) {
            pb_1.Message.setOneofField(this, 32, this.#one_of_decls[29], value);
        }
        get has_samples_encoded_with_silk() {
            return pb_1.Message.getField(this, 32) != null;
        }
        get samples_encoded_with_celt() {
            return pb_1.Message.getFieldWithDefault(this, 33, 0) as number;
        }
        set samples_encoded_with_celt(value: number) {
            pb_1.Message.setOneofField(this, 33, this.#one_of_decls[30], value);
        }
        get has_samples_encoded_with_celt() {
            return pb_1.Message.getField(this, 33) != null;
        }
        get voice_activity_flag() {
            return pb_1.Message.getFieldWithDefault(this, 34, false) as boolean;
        }
        set voice_activity_flag(value: boolean) {
            pb_1.Message.setOneofField(this, 34, this.#one_of_decls[31], value);
        }
        get has_voice_activity_flag() {
            return pb_1.Message.getField(this, 34) != null;
        }
        get total_encode_time() {
            return pb_1.Message.getFieldWithDefault(this, 35, 0) as number;
        }
        set total_encode_time(value: number) {
            pb_1.Message.setOneofField(this, 35, this.#one_of_decls[32], value);
        }
        get has_total_encode_time() {
            return pb_1.Message.getField(this, 35) != null;
        }
        get total_packet_send_delay() {
            return pb_1.Message.getFieldWithDefault(this, 36, 0) as number;
        }
        set total_packet_send_delay(value: number) {
            pb_1.Message.setOneofField(this, 36, this.#one_of_decls[33], value);
        }
        get has_total_packet_send_delay() {
            return pb_1.Message.getField(this, 36) != null;
        }
        get average_rtcp_interval() {
            return pb_1.Message.getFieldWithDefault(this, 37, 0) as number;
        }
        set average_rtcp_interval(value: number) {
            pb_1.Message.setOneofField(this, 37, this.#one_of_decls[34], value);
        }
        get has_average_rtcp_interval() {
            return pb_1.Message.getField(this, 37) != null;
        }
        get quality_limitation_reason() {
            return pb_1.Message.getFieldWithDefault(this, 38, RTCQualityLimitationReason.None) as RTCQualityLimitationReason;
        }
        set quality_limitation_reason(value: RTCQualityLimitationReason) {
            pb_1.Message.setOneofField(this, 38, this.#one_of_decls[35], value);
        }
        get has_quality_limitation_reason() {
            return pb_1.Message.getField(this, 38) != null;
        }
        get quality_limitation_durations() {
            return pb_1.Message.getField(this, 39) as any as Map<number, number>;
        }
        set quality_limitation_durations(value: Map<number, number>) {
            pb_1.Message.setField(this, 39, value as any);
        }
        get quality_limitation_resolution_changes() {
            return pb_1.Message.getFieldWithDefault(this, 40, 0) as number;
        }
        set quality_limitation_resolution_changes(value: number) {
            pb_1.Message.setOneofField(this, 40, this.#one_of_decls[36], value);
        }
        get has_quality_limitation_resolution_changes() {
            return pb_1.Message.getField(this, 40) != null;
        }
        get per_dscp_packets_sent() {
            return pb_1.Message.getField(this, 41) as any as Map<string, number>;
        }
        set per_dscp_packets_sent(value: Map<string, number>) {
            pb_1.Message.setField(this, 41, value as any);
        }
        get nack_count() {
            return pb_1.Message.getFieldWithDefault(this, 42, 0) as number;
        }
        set nack_count(value: number) {
            pb_1.Message.setOneofField(this, 42, this.#one_of_decls[37], value);
        }
        get has_nack_count() {
            return pb_1.Message.getField(this, 42) != null;
        }
        get fir_count() {
            return pb_1.Message.getFieldWithDefault(this, 43, 0) as number;
        }
        set fir_count(value: number) {
            pb_1.Message.setOneofField(this, 43, this.#one_of_decls[38], value);
        }
        get has_fir_count() {
            return pb_1.Message.getField(this, 43) != null;
        }
        get pli_count() {
            return pb_1.Message.getFieldWithDefault(this, 44, 0) as number;
        }
        set pli_count(value: number) {
            pb_1.Message.setOneofField(this, 44, this.#one_of_decls[39], value);
        }
        get has_pli_count() {
            return pb_1.Message.getField(this, 44) != null;
        }
        get sli_count() {
            return pb_1.Message.getFieldWithDefault(this, 45, 0) as number;
        }
        set sli_count(value: number) {
            pb_1.Message.setOneofField(this, 45, this.#one_of_decls[40], value);
        }
        get has_sli_count() {
            return pb_1.Message.getField(this, 45) != null;
        }
        get encoder_implementation() {
            return pb_1.Message.getFieldWithDefault(this, 46, "") as string;
        }
        set encoder_implementation(value: string) {
            pb_1.Message.setOneofField(this, 46, this.#one_of_decls[41], value);
        }
        get has_encoder_implementation() {
            return pb_1.Message.getField(this, 46) != null;
        }
        get _transport_id() {
            const cases: {
                [index: number]: "none" | "transport_id";
            } = {
                0: "none",
                3: "transport_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _codec_id() {
            const cases: {
                [index: number]: "none" | "codec_id";
            } = {
                0: "none",
                4: "codec_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        get _packets_sent() {
            const cases: {
                [index: number]: "none" | "packets_sent";
            } = {
                0: "none",
                5: "packets_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _bytes_sent() {
            const cases: {
                [index: number]: "none" | "bytes_sent";
            } = {
                0: "none",
                6: "bytes_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _rtx_ssrc() {
            const cases: {
                [index: number]: "none" | "rtx_ssrc";
            } = {
                0: "none",
                7: "rtx_ssrc"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        get _media_source_id() {
            const cases: {
                [index: number]: "none" | "media_source_id";
            } = {
                0: "none",
                8: "media_source_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [8])];
        }
        get _sender_id() {
            const cases: {
                [index: number]: "none" | "sender_id";
            } = {
                0: "none",
                9: "sender_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [9])];
        }
        get _remote_id() {
            const cases: {
                [index: number]: "none" | "remote_id";
            } = {
                0: "none",
                10: "remote_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [10])];
        }
        get _rid() {
            const cases: {
                [index: number]: "none" | "rid";
            } = {
                0: "none",
                11: "rid"
            };
            return cases[pb_1.Message.computeOneofCase(this, [11])];
        }
        get _last_packet_sent_timestamp() {
            const cases: {
                [index: number]: "none" | "last_packet_sent_timestamp";
            } = {
                0: "none",
                12: "last_packet_sent_timestamp"
            };
            return cases[pb_1.Message.computeOneofCase(this, [12])];
        }
        get _header_bytes_sent() {
            const cases: {
                [index: number]: "none" | "header_bytes_sent";
            } = {
                0: "none",
                13: "header_bytes_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [13])];
        }
        get _packets_discarded_on_send() {
            const cases: {
                [index: number]: "none" | "packets_discarded_on_send";
            } = {
                0: "none",
                14: "packets_discarded_on_send"
            };
            return cases[pb_1.Message.computeOneofCase(this, [14])];
        }
        get _bytes_discarded_on_send() {
            const cases: {
                [index: number]: "none" | "bytes_discarded_on_send";
            } = {
                0: "none",
                15: "bytes_discarded_on_send"
            };
            return cases[pb_1.Message.computeOneofCase(this, [15])];
        }
        get _fec_packets_sent() {
            const cases: {
                [index: number]: "none" | "fec_packets_sent";
            } = {
                0: "none",
                16: "fec_packets_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [16])];
        }
        get _retransmitted_packets_sent() {
            const cases: {
                [index: number]: "none" | "retransmitted_packets_sent";
            } = {
                0: "none",
                17: "retransmitted_packets_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [17])];
        }
        get _retransmitted_bytes_sent() {
            const cases: {
                [index: number]: "none" | "retransmitted_bytes_sent";
            } = {
                0: "none",
                18: "retransmitted_bytes_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [18])];
        }
        get _target_bitrate() {
            const cases: {
                [index: number]: "none" | "target_bitrate";
            } = {
                0: "none",
                19: "target_bitrate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [19])];
        }
        get _total_encoded_bytes_target() {
            const cases: {
                [index: number]: "none" | "total_encoded_bytes_target";
            } = {
                0: "none",
                20: "total_encoded_bytes_target"
            };
            return cases[pb_1.Message.computeOneofCase(this, [20])];
        }
        get _frame_width() {
            const cases: {
                [index: number]: "none" | "frame_width";
            } = {
                0: "none",
                21: "frame_width"
            };
            return cases[pb_1.Message.computeOneofCase(this, [21])];
        }
        get _frame_height() {
            const cases: {
                [index: number]: "none" | "frame_height";
            } = {
                0: "none",
                22: "frame_height"
            };
            return cases[pb_1.Message.computeOneofCase(this, [22])];
        }
        get _frame_bit_depth() {
            const cases: {
                [index: number]: "none" | "frame_bit_depth";
            } = {
                0: "none",
                23: "frame_bit_depth"
            };
            return cases[pb_1.Message.computeOneofCase(this, [23])];
        }
        get _frames_per_second() {
            const cases: {
                [index: number]: "none" | "frames_per_second";
            } = {
                0: "none",
                24: "frames_per_second"
            };
            return cases[pb_1.Message.computeOneofCase(this, [24])];
        }
        get _frames_sent() {
            const cases: {
                [index: number]: "none" | "frames_sent";
            } = {
                0: "none",
                25: "frames_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [25])];
        }
        get _huge_frames_sent() {
            const cases: {
                [index: number]: "none" | "huge_frames_sent";
            } = {
                0: "none",
                26: "huge_frames_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [26])];
        }
        get _frames_encoded() {
            const cases: {
                [index: number]: "none" | "frames_encoded";
            } = {
                0: "none",
                27: "frames_encoded"
            };
            return cases[pb_1.Message.computeOneofCase(this, [27])];
        }
        get _key_frames_encoded() {
            const cases: {
                [index: number]: "none" | "key_frames_encoded";
            } = {
                0: "none",
                28: "key_frames_encoded"
            };
            return cases[pb_1.Message.computeOneofCase(this, [28])];
        }
        get _frames_discarded_on_send() {
            const cases: {
                [index: number]: "none" | "frames_discarded_on_send";
            } = {
                0: "none",
                29: "frames_discarded_on_send"
            };
            return cases[pb_1.Message.computeOneofCase(this, [29])];
        }
        get _qp_sum() {
            const cases: {
                [index: number]: "none" | "qp_sum";
            } = {
                0: "none",
                30: "qp_sum"
            };
            return cases[pb_1.Message.computeOneofCase(this, [30])];
        }
        get _total_samples_sent() {
            const cases: {
                [index: number]: "none" | "total_samples_sent";
            } = {
                0: "none",
                31: "total_samples_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [31])];
        }
        get _samples_encoded_with_silk() {
            const cases: {
                [index: number]: "none" | "samples_encoded_with_silk";
            } = {
                0: "none",
                32: "samples_encoded_with_silk"
            };
            return cases[pb_1.Message.computeOneofCase(this, [32])];
        }
        get _samples_encoded_with_celt() {
            const cases: {
                [index: number]: "none" | "samples_encoded_with_celt";
            } = {
                0: "none",
                33: "samples_encoded_with_celt"
            };
            return cases[pb_1.Message.computeOneofCase(this, [33])];
        }
        get _voice_activity_flag() {
            const cases: {
                [index: number]: "none" | "voice_activity_flag";
            } = {
                0: "none",
                34: "voice_activity_flag"
            };
            return cases[pb_1.Message.computeOneofCase(this, [34])];
        }
        get _total_encode_time() {
            const cases: {
                [index: number]: "none" | "total_encode_time";
            } = {
                0: "none",
                35: "total_encode_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [35])];
        }
        get _total_packet_send_delay() {
            const cases: {
                [index: number]: "none" | "total_packet_send_delay";
            } = {
                0: "none",
                36: "total_packet_send_delay"
            };
            return cases[pb_1.Message.computeOneofCase(this, [36])];
        }
        get _average_rtcp_interval() {
            const cases: {
                [index: number]: "none" | "average_rtcp_interval";
            } = {
                0: "none",
                37: "average_rtcp_interval"
            };
            return cases[pb_1.Message.computeOneofCase(this, [37])];
        }
        get _quality_limitation_reason() {
            const cases: {
                [index: number]: "none" | "quality_limitation_reason";
            } = {
                0: "none",
                38: "quality_limitation_reason"
            };
            return cases[pb_1.Message.computeOneofCase(this, [38])];
        }
        get _quality_limitation_resolution_changes() {
            const cases: {
                [index: number]: "none" | "quality_limitation_resolution_changes";
            } = {
                0: "none",
                40: "quality_limitation_resolution_changes"
            };
            return cases[pb_1.Message.computeOneofCase(this, [40])];
        }
        get _nack_count() {
            const cases: {
                [index: number]: "none" | "nack_count";
            } = {
                0: "none",
                42: "nack_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [42])];
        }
        get _fir_count() {
            const cases: {
                [index: number]: "none" | "fir_count";
            } = {
                0: "none",
                43: "fir_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [43])];
        }
        get _pli_count() {
            const cases: {
                [index: number]: "none" | "pli_count";
            } = {
                0: "none",
                44: "pli_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [44])];
        }
        get _sli_count() {
            const cases: {
                [index: number]: "none" | "sli_count";
            } = {
                0: "none",
                45: "sli_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [45])];
        }
        get _encoder_implementation() {
            const cases: {
                [index: number]: "none" | "encoder_implementation";
            } = {
                0: "none",
                46: "encoder_implementation"
            };
            return cases[pb_1.Message.computeOneofCase(this, [46])];
        }
        static fromObject(data: {
            ssrc?: number;
            kind?: RTCMediaKind;
            transport_id?: string;
            codec_id?: string;
            packets_sent?: number;
            bytes_sent?: number;
            rtx_ssrc?: number;
            media_source_id?: string;
            sender_id?: string;
            remote_id?: string;
            rid?: string;
            last_packet_sent_timestamp?: number;
            header_bytes_sent?: number;
            packets_discarded_on_send?: number;
            bytes_discarded_on_send?: number;
            fec_packets_sent?: number;
            retransmitted_packets_sent?: number;
            retransmitted_bytes_sent?: number;
            target_bitrate?: number;
            total_encoded_bytes_target?: number;
            frame_width?: number;
            frame_height?: number;
            frame_bit_depth?: number;
            frames_per_second?: number;
            frames_sent?: number;
            huge_frames_sent?: number;
            frames_encoded?: number;
            key_frames_encoded?: number;
            frames_discarded_on_send?: number;
            qp_sum?: number;
            total_samples_sent?: number;
            samples_encoded_with_silk?: number;
            samples_encoded_with_celt?: number;
            voice_activity_flag?: boolean;
            total_encode_time?: number;
            total_packet_send_delay?: number;
            average_rtcp_interval?: number;
            quality_limitation_reason?: RTCQualityLimitationReason;
            quality_limitation_durations?: {
                [key: number]: number;
            };
            quality_limitation_resolution_changes?: number;
            per_dscp_packets_sent?: {
                [key: string]: number;
            };
            nack_count?: number;
            fir_count?: number;
            pli_count?: number;
            sli_count?: number;
            encoder_implementation?: string;
        }): RTCOutboundRtpStreamStats {
            const message = new RTCOutboundRtpStreamStats({});
            if (data.ssrc != null) {
                message.ssrc = data.ssrc;
            }
            if (data.kind != null) {
                message.kind = data.kind;
            }
            if (data.transport_id != null) {
                message.transport_id = data.transport_id;
            }
            if (data.codec_id != null) {
                message.codec_id = data.codec_id;
            }
            if (data.packets_sent != null) {
                message.packets_sent = data.packets_sent;
            }
            if (data.bytes_sent != null) {
                message.bytes_sent = data.bytes_sent;
            }
            if (data.rtx_ssrc != null) {
                message.rtx_ssrc = data.rtx_ssrc;
            }
            if (data.media_source_id != null) {
                message.media_source_id = data.media_source_id;
            }
            if (data.sender_id != null) {
                message.sender_id = data.sender_id;
            }
            if (data.remote_id != null) {
                message.remote_id = data.remote_id;
            }
            if (data.rid != null) {
                message.rid = data.rid;
            }
            if (data.last_packet_sent_timestamp != null) {
                message.last_packet_sent_timestamp = data.last_packet_sent_timestamp;
            }
            if (data.header_bytes_sent != null) {
                message.header_bytes_sent = data.header_bytes_sent;
            }
            if (data.packets_discarded_on_send != null) {
                message.packets_discarded_on_send = data.packets_discarded_on_send;
            }
            if (data.bytes_discarded_on_send != null) {
                message.bytes_discarded_on_send = data.bytes_discarded_on_send;
            }
            if (data.fec_packets_sent != null) {
                message.fec_packets_sent = data.fec_packets_sent;
            }
            if (data.retransmitted_packets_sent != null) {
                message.retransmitted_packets_sent = data.retransmitted_packets_sent;
            }
            if (data.retransmitted_bytes_sent != null) {
                message.retransmitted_bytes_sent = data.retransmitted_bytes_sent;
            }
            if (data.target_bitrate != null) {
                message.target_bitrate = data.target_bitrate;
            }
            if (data.total_encoded_bytes_target != null) {
                message.total_encoded_bytes_target = data.total_encoded_bytes_target;
            }
            if (data.frame_width != null) {
                message.frame_width = data.frame_width;
            }
            if (data.frame_height != null) {
                message.frame_height = data.frame_height;
            }
            if (data.frame_bit_depth != null) {
                message.frame_bit_depth = data.frame_bit_depth;
            }
            if (data.frames_per_second != null) {
                message.frames_per_second = data.frames_per_second;
            }
            if (data.frames_sent != null) {
                message.frames_sent = data.frames_sent;
            }
            if (data.huge_frames_sent != null) {
                message.huge_frames_sent = data.huge_frames_sent;
            }
            if (data.frames_encoded != null) {
                message.frames_encoded = data.frames_encoded;
            }
            if (data.key_frames_encoded != null) {
                message.key_frames_encoded = data.key_frames_encoded;
            }
            if (data.frames_discarded_on_send != null) {
                message.frames_discarded_on_send = data.frames_discarded_on_send;
            }
            if (data.qp_sum != null) {
                message.qp_sum = data.qp_sum;
            }
            if (data.total_samples_sent != null) {
                message.total_samples_sent = data.total_samples_sent;
            }
            if (data.samples_encoded_with_silk != null) {
                message.samples_encoded_with_silk = data.samples_encoded_with_silk;
            }
            if (data.samples_encoded_with_celt != null) {
                message.samples_encoded_with_celt = data.samples_encoded_with_celt;
            }
            if (data.voice_activity_flag != null) {
                message.voice_activity_flag = data.voice_activity_flag;
            }
            if (data.total_encode_time != null) {
                message.total_encode_time = data.total_encode_time;
            }
            if (data.total_packet_send_delay != null) {
                message.total_packet_send_delay = data.total_packet_send_delay;
            }
            if (data.average_rtcp_interval != null) {
                message.average_rtcp_interval = data.average_rtcp_interval;
            }
            if (data.quality_limitation_reason != null) {
                message.quality_limitation_reason = data.quality_limitation_reason;
            }
            if (typeof data.quality_limitation_durations == "object") {
                message.quality_limitation_durations = new Map(Object.entries(data.quality_limitation_durations).map(([key, value]) => [Number(key), value]));
            }
            if (data.quality_limitation_resolution_changes != null) {
                message.quality_limitation_resolution_changes = data.quality_limitation_resolution_changes;
            }
            if (typeof data.per_dscp_packets_sent == "object") {
                message.per_dscp_packets_sent = new Map(Object.entries(data.per_dscp_packets_sent));
            }
            if (data.nack_count != null) {
                message.nack_count = data.nack_count;
            }
            if (data.fir_count != null) {
                message.fir_count = data.fir_count;
            }
            if (data.pli_count != null) {
                message.pli_count = data.pli_count;
            }
            if (data.sli_count != null) {
                message.sli_count = data.sli_count;
            }
            if (data.encoder_implementation != null) {
                message.encoder_implementation = data.encoder_implementation;
            }
            return message;
        }
        toObject() {
            const data: {
                ssrc?: number;
                kind?: RTCMediaKind;
                transport_id?: string;
                codec_id?: string;
                packets_sent?: number;
                bytes_sent?: number;
                rtx_ssrc?: number;
                media_source_id?: string;
                sender_id?: string;
                remote_id?: string;
                rid?: string;
                last_packet_sent_timestamp?: number;
                header_bytes_sent?: number;
                packets_discarded_on_send?: number;
                bytes_discarded_on_send?: number;
                fec_packets_sent?: number;
                retransmitted_packets_sent?: number;
                retransmitted_bytes_sent?: number;
                target_bitrate?: number;
                total_encoded_bytes_target?: number;
                frame_width?: number;
                frame_height?: number;
                frame_bit_depth?: number;
                frames_per_second?: number;
                frames_sent?: number;
                huge_frames_sent?: number;
                frames_encoded?: number;
                key_frames_encoded?: number;
                frames_discarded_on_send?: number;
                qp_sum?: number;
                total_samples_sent?: number;
                samples_encoded_with_silk?: number;
                samples_encoded_with_celt?: number;
                voice_activity_flag?: boolean;
                total_encode_time?: number;
                total_packet_send_delay?: number;
                average_rtcp_interval?: number;
                quality_limitation_reason?: RTCQualityLimitationReason;
                quality_limitation_durations?: {
                    [key: number]: number;
                };
                quality_limitation_resolution_changes?: number;
                per_dscp_packets_sent?: {
                    [key: string]: number;
                };
                nack_count?: number;
                fir_count?: number;
                pli_count?: number;
                sli_count?: number;
                encoder_implementation?: string;
            } = {};
            if (this.ssrc != null) {
                data.ssrc = this.ssrc;
            }
            if (this.kind != null) {
                data.kind = this.kind;
            }
            if (this.transport_id != null) {
                data.transport_id = this.transport_id;
            }
            if (this.codec_id != null) {
                data.codec_id = this.codec_id;
            }
            if (this.packets_sent != null) {
                data.packets_sent = this.packets_sent;
            }
            if (this.bytes_sent != null) {
                data.bytes_sent = this.bytes_sent;
            }
            if (this.rtx_ssrc != null) {
                data.rtx_ssrc = this.rtx_ssrc;
            }
            if (this.media_source_id != null) {
                data.media_source_id = this.media_source_id;
            }
            if (this.sender_id != null) {
                data.sender_id = this.sender_id;
            }
            if (this.remote_id != null) {
                data.remote_id = this.remote_id;
            }
            if (this.rid != null) {
                data.rid = this.rid;
            }
            if (this.last_packet_sent_timestamp != null) {
                data.last_packet_sent_timestamp = this.last_packet_sent_timestamp;
            }
            if (this.header_bytes_sent != null) {
                data.header_bytes_sent = this.header_bytes_sent;
            }
            if (this.packets_discarded_on_send != null) {
                data.packets_discarded_on_send = this.packets_discarded_on_send;
            }
            if (this.bytes_discarded_on_send != null) {
                data.bytes_discarded_on_send = this.bytes_discarded_on_send;
            }
            if (this.fec_packets_sent != null) {
                data.fec_packets_sent = this.fec_packets_sent;
            }
            if (this.retransmitted_packets_sent != null) {
                data.retransmitted_packets_sent = this.retransmitted_packets_sent;
            }
            if (this.retransmitted_bytes_sent != null) {
                data.retransmitted_bytes_sent = this.retransmitted_bytes_sent;
            }
            if (this.target_bitrate != null) {
                data.target_bitrate = this.target_bitrate;
            }
            if (this.total_encoded_bytes_target != null) {
                data.total_encoded_bytes_target = this.total_encoded_bytes_target;
            }
            if (this.frame_width != null) {
                data.frame_width = this.frame_width;
            }
            if (this.frame_height != null) {
                data.frame_height = this.frame_height;
            }
            if (this.frame_bit_depth != null) {
                data.frame_bit_depth = this.frame_bit_depth;
            }
            if (this.frames_per_second != null) {
                data.frames_per_second = this.frames_per_second;
            }
            if (this.frames_sent != null) {
                data.frames_sent = this.frames_sent;
            }
            if (this.huge_frames_sent != null) {
                data.huge_frames_sent = this.huge_frames_sent;
            }
            if (this.frames_encoded != null) {
                data.frames_encoded = this.frames_encoded;
            }
            if (this.key_frames_encoded != null) {
                data.key_frames_encoded = this.key_frames_encoded;
            }
            if (this.frames_discarded_on_send != null) {
                data.frames_discarded_on_send = this.frames_discarded_on_send;
            }
            if (this.qp_sum != null) {
                data.qp_sum = this.qp_sum;
            }
            if (this.total_samples_sent != null) {
                data.total_samples_sent = this.total_samples_sent;
            }
            if (this.samples_encoded_with_silk != null) {
                data.samples_encoded_with_silk = this.samples_encoded_with_silk;
            }
            if (this.samples_encoded_with_celt != null) {
                data.samples_encoded_with_celt = this.samples_encoded_with_celt;
            }
            if (this.voice_activity_flag != null) {
                data.voice_activity_flag = this.voice_activity_flag;
            }
            if (this.total_encode_time != null) {
                data.total_encode_time = this.total_encode_time;
            }
            if (this.total_packet_send_delay != null) {
                data.total_packet_send_delay = this.total_packet_send_delay;
            }
            if (this.average_rtcp_interval != null) {
                data.average_rtcp_interval = this.average_rtcp_interval;
            }
            if (this.quality_limitation_reason != null) {
                data.quality_limitation_reason = this.quality_limitation_reason;
            }
            if (this.quality_limitation_durations != null) {
                data.quality_limitation_durations = (Object.fromEntries)(this.quality_limitation_durations);
            }
            if (this.quality_limitation_resolution_changes != null) {
                data.quality_limitation_resolution_changes = this.quality_limitation_resolution_changes;
            }
            if (this.per_dscp_packets_sent != null) {
                data.per_dscp_packets_sent = (Object.fromEntries)(this.per_dscp_packets_sent);
            }
            if (this.nack_count != null) {
                data.nack_count = this.nack_count;
            }
            if (this.fir_count != null) {
                data.fir_count = this.fir_count;
            }
            if (this.pli_count != null) {
                data.pli_count = this.pli_count;
            }
            if (this.sli_count != null) {
                data.sli_count = this.sli_count;
            }
            if (this.encoder_implementation != null) {
                data.encoder_implementation = this.encoder_implementation;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.ssrc != 0)
                writer.writeFixed32(1, this.ssrc);
            if (this.kind != RTCMediaKind.Audio)
                writer.writeEnum(2, this.kind);
            if (this.has_transport_id)
                writer.writeString(3, this.transport_id);
            if (this.has_codec_id)
                writer.writeString(4, this.codec_id);
            if (this.has_packets_sent)
                writer.writeFixed32(5, this.packets_sent);
            if (this.has_bytes_sent)
                writer.writeFixed64(6, this.bytes_sent);
            if (this.has_rtx_ssrc)
                writer.writeFixed32(7, this.rtx_ssrc);
            if (this.has_media_source_id)
                writer.writeString(8, this.media_source_id);
            if (this.has_sender_id)
                writer.writeString(9, this.sender_id);
            if (this.has_remote_id)
                writer.writeString(10, this.remote_id);
            if (this.has_rid)
                writer.writeString(11, this.rid);
            if (this.has_last_packet_sent_timestamp)
                writer.writeDouble(12, this.last_packet_sent_timestamp);
            if (this.has_header_bytes_sent)
                writer.writeFixed64(13, this.header_bytes_sent);
            if (this.has_packets_discarded_on_send)
                writer.writeFixed32(14, this.packets_discarded_on_send);
            if (this.has_bytes_discarded_on_send)
                writer.writeFixed64(15, this.bytes_discarded_on_send);
            if (this.has_fec_packets_sent)
                writer.writeFixed32(16, this.fec_packets_sent);
            if (this.has_retransmitted_packets_sent)
                writer.writeFixed64(17, this.retransmitted_packets_sent);
            if (this.has_retransmitted_bytes_sent)
                writer.writeFixed64(18, this.retransmitted_bytes_sent);
            if (this.has_target_bitrate)
                writer.writeFloat(19, this.target_bitrate);
            if (this.has_total_encoded_bytes_target)
                writer.writeFixed64(20, this.total_encoded_bytes_target);
            if (this.has_frame_width)
                writer.writeFixed32(21, this.frame_width);
            if (this.has_frame_height)
                writer.writeFixed32(22, this.frame_height);
            if (this.has_frame_bit_depth)
                writer.writeFixed32(23, this.frame_bit_depth);
            if (this.has_frames_per_second)
                writer.writeFloat(24, this.frames_per_second);
            if (this.has_frames_sent)
                writer.writeFixed32(25, this.frames_sent);
            if (this.has_huge_frames_sent)
                writer.writeFixed32(26, this.huge_frames_sent);
            if (this.has_frames_encoded)
                writer.writeFixed32(27, this.frames_encoded);
            if (this.has_key_frames_encoded)
                writer.writeFixed32(28, this.key_frames_encoded);
            if (this.has_frames_discarded_on_send)
                writer.writeFixed32(29, this.frames_discarded_on_send);
            if (this.has_qp_sum)
                writer.writeFixed64(30, this.qp_sum);
            if (this.has_total_samples_sent)
                writer.writeFixed64(31, this.total_samples_sent);
            if (this.has_samples_encoded_with_silk)
                writer.writeFixed64(32, this.samples_encoded_with_silk);
            if (this.has_samples_encoded_with_celt)
                writer.writeFixed64(33, this.samples_encoded_with_celt);
            if (this.has_voice_activity_flag)
                writer.writeBool(34, this.voice_activity_flag);
            if (this.has_total_encode_time)
                writer.writeFloat(35, this.total_encode_time);
            if (this.has_total_packet_send_delay)
                writer.writeFloat(36, this.total_packet_send_delay);
            if (this.has_average_rtcp_interval)
                writer.writeFloat(37, this.average_rtcp_interval);
            if (this.has_quality_limitation_reason)
                writer.writeEnum(38, this.quality_limitation_reason);
            for (const [key, value] of this.quality_limitation_durations) {
                writer.writeMessage(39, this.quality_limitation_durations, () => {
                    writer.writeUint32(1, key);
                    writer.writeFloat(2, value);
                });
            }
            if (this.has_quality_limitation_resolution_changes)
                writer.writeFixed32(40, this.quality_limitation_resolution_changes);
            for (const [key, value] of this.per_dscp_packets_sent) {
                writer.writeMessage(41, this.per_dscp_packets_sent, () => {
                    writer.writeString(1, key);
                    writer.writeFixed64(2, value);
                });
            }
            if (this.has_nack_count)
                writer.writeFixed32(42, this.nack_count);
            if (this.has_fir_count)
                writer.writeFixed32(43, this.fir_count);
            if (this.has_pli_count)
                writer.writeFixed32(44, this.pli_count);
            if (this.has_sli_count)
                writer.writeFixed32(45, this.sli_count);
            if (this.has_encoder_implementation)
                writer.writeString(46, this.encoder_implementation);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCOutboundRtpStreamStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCOutboundRtpStreamStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.ssrc = reader.readFixed32();
                        break;
                    case 2:
                        message.kind = reader.readEnum();
                        break;
                    case 3:
                        message.transport_id = reader.readString();
                        break;
                    case 4:
                        message.codec_id = reader.readString();
                        break;
                    case 5:
                        message.packets_sent = reader.readFixed32();
                        break;
                    case 6:
                        message.bytes_sent = reader.readFixed64();
                        break;
                    case 7:
                        message.rtx_ssrc = reader.readFixed32();
                        break;
                    case 8:
                        message.media_source_id = reader.readString();
                        break;
                    case 9:
                        message.sender_id = reader.readString();
                        break;
                    case 10:
                        message.remote_id = reader.readString();
                        break;
                    case 11:
                        message.rid = reader.readString();
                        break;
                    case 12:
                        message.last_packet_sent_timestamp = reader.readDouble();
                        break;
                    case 13:
                        message.header_bytes_sent = reader.readFixed64();
                        break;
                    case 14:
                        message.packets_discarded_on_send = reader.readFixed32();
                        break;
                    case 15:
                        message.bytes_discarded_on_send = reader.readFixed64();
                        break;
                    case 16:
                        message.fec_packets_sent = reader.readFixed32();
                        break;
                    case 17:
                        message.retransmitted_packets_sent = reader.readFixed64();
                        break;
                    case 18:
                        message.retransmitted_bytes_sent = reader.readFixed64();
                        break;
                    case 19:
                        message.target_bitrate = reader.readFloat();
                        break;
                    case 20:
                        message.total_encoded_bytes_target = reader.readFixed64();
                        break;
                    case 21:
                        message.frame_width = reader.readFixed32();
                        break;
                    case 22:
                        message.frame_height = reader.readFixed32();
                        break;
                    case 23:
                        message.frame_bit_depth = reader.readFixed32();
                        break;
                    case 24:
                        message.frames_per_second = reader.readFloat();
                        break;
                    case 25:
                        message.frames_sent = reader.readFixed32();
                        break;
                    case 26:
                        message.huge_frames_sent = reader.readFixed32();
                        break;
                    case 27:
                        message.frames_encoded = reader.readFixed32();
                        break;
                    case 28:
                        message.key_frames_encoded = reader.readFixed32();
                        break;
                    case 29:
                        message.frames_discarded_on_send = reader.readFixed32();
                        break;
                    case 30:
                        message.qp_sum = reader.readFixed64();
                        break;
                    case 31:
                        message.total_samples_sent = reader.readFixed64();
                        break;
                    case 32:
                        message.samples_encoded_with_silk = reader.readFixed64();
                        break;
                    case 33:
                        message.samples_encoded_with_celt = reader.readFixed64();
                        break;
                    case 34:
                        message.voice_activity_flag = reader.readBool();
                        break;
                    case 35:
                        message.total_encode_time = reader.readFloat();
                        break;
                    case 36:
                        message.total_packet_send_delay = reader.readFloat();
                        break;
                    case 37:
                        message.average_rtcp_interval = reader.readFloat();
                        break;
                    case 38:
                        message.quality_limitation_reason = reader.readEnum();
                        break;
                    case 39:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.quality_limitation_durations as any, reader, reader.readUint32, reader.readFloat));
                        break;
                    case 40:
                        message.quality_limitation_resolution_changes = reader.readFixed32();
                        break;
                    case 41:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.per_dscp_packets_sent as any, reader, reader.readString, reader.readFixed64));
                        break;
                    case 42:
                        message.nack_count = reader.readFixed32();
                        break;
                    case 43:
                        message.fir_count = reader.readFixed32();
                        break;
                    case 44:
                        message.pli_count = reader.readFixed32();
                        break;
                    case 45:
                        message.sli_count = reader.readFixed32();
                        break;
                    case 46:
                        message.encoder_implementation = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCOutboundRtpStreamStats {
            return RTCOutboundRtpStreamStats.deserialize(bytes);
        }
    }
    export class RTCRemoteOutboundRtpStreamStats extends pb_1.Message {
        #one_of_decls: number[][] = [[3], [4], [5], [6], [7], [8], [9], [10], [11], [12]];
        constructor(data?: any[] | ({
            ssrc?: number;
            kind?: RTCMediaKind;
        } & (({
            transport_id?: string;
        }) | ({
            codec_id?: string;
        }) | ({
            packets_sent?: number;
        }) | ({
            bytes_sent?: number;
        }) | ({
            local_id?: string;
        }) | ({
            remote_timestamp?: number;
        }) | ({
            reports_sent?: number;
        }) | ({
            round_trip_time?: number;
        }) | ({
            total_round_trip_time?: number;
        }) | ({
            round_trip_time_measurements?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("ssrc" in data && data.ssrc != undefined) {
                    this.ssrc = data.ssrc;
                }
                if ("kind" in data && data.kind != undefined) {
                    this.kind = data.kind;
                }
                if ("transport_id" in data && data.transport_id != undefined) {
                    this.transport_id = data.transport_id;
                }
                if ("codec_id" in data && data.codec_id != undefined) {
                    this.codec_id = data.codec_id;
                }
                if ("packets_sent" in data && data.packets_sent != undefined) {
                    this.packets_sent = data.packets_sent;
                }
                if ("bytes_sent" in data && data.bytes_sent != undefined) {
                    this.bytes_sent = data.bytes_sent;
                }
                if ("local_id" in data && data.local_id != undefined) {
                    this.local_id = data.local_id;
                }
                if ("remote_timestamp" in data && data.remote_timestamp != undefined) {
                    this.remote_timestamp = data.remote_timestamp;
                }
                if ("reports_sent" in data && data.reports_sent != undefined) {
                    this.reports_sent = data.reports_sent;
                }
                if ("round_trip_time" in data && data.round_trip_time != undefined) {
                    this.round_trip_time = data.round_trip_time;
                }
                if ("total_round_trip_time" in data && data.total_round_trip_time != undefined) {
                    this.total_round_trip_time = data.total_round_trip_time;
                }
                if ("round_trip_time_measurements" in data && data.round_trip_time_measurements != undefined) {
                    this.round_trip_time_measurements = data.round_trip_time_measurements;
                }
            }
        }
        get ssrc() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set ssrc(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get kind() {
            return pb_1.Message.getFieldWithDefault(this, 2, RTCMediaKind.Audio) as RTCMediaKind;
        }
        set kind(value: RTCMediaKind) {
            pb_1.Message.setField(this, 2, value);
        }
        get transport_id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set transport_id(value: string) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0], value);
        }
        get has_transport_id() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get codec_id() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set codec_id(value: string) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[1], value);
        }
        get has_codec_id() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get packets_sent() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set packets_sent(value: number) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[2], value);
        }
        get has_packets_sent() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get bytes_sent() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set bytes_sent(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[3], value);
        }
        get has_bytes_sent() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get local_id() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set local_id(value: string) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[4], value);
        }
        get has_local_id() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get remote_timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set remote_timestamp(value: number) {
            pb_1.Message.setOneofField(this, 8, this.#one_of_decls[5], value);
        }
        get has_remote_timestamp() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get reports_sent() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set reports_sent(value: number) {
            pb_1.Message.setOneofField(this, 9, this.#one_of_decls[6], value);
        }
        get has_reports_sent() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get round_trip_time() {
            return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
        }
        set round_trip_time(value: number) {
            pb_1.Message.setOneofField(this, 10, this.#one_of_decls[7], value);
        }
        get has_round_trip_time() {
            return pb_1.Message.getField(this, 10) != null;
        }
        get total_round_trip_time() {
            return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
        }
        set total_round_trip_time(value: number) {
            pb_1.Message.setOneofField(this, 11, this.#one_of_decls[8], value);
        }
        get has_total_round_trip_time() {
            return pb_1.Message.getField(this, 11) != null;
        }
        get round_trip_time_measurements() {
            return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
        }
        set round_trip_time_measurements(value: number) {
            pb_1.Message.setOneofField(this, 12, this.#one_of_decls[9], value);
        }
        get has_round_trip_time_measurements() {
            return pb_1.Message.getField(this, 12) != null;
        }
        get _transport_id() {
            const cases: {
                [index: number]: "none" | "transport_id";
            } = {
                0: "none",
                3: "transport_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _codec_id() {
            const cases: {
                [index: number]: "none" | "codec_id";
            } = {
                0: "none",
                4: "codec_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        get _packets_sent() {
            const cases: {
                [index: number]: "none" | "packets_sent";
            } = {
                0: "none",
                5: "packets_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _bytes_sent() {
            const cases: {
                [index: number]: "none" | "bytes_sent";
            } = {
                0: "none",
                6: "bytes_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _local_id() {
            const cases: {
                [index: number]: "none" | "local_id";
            } = {
                0: "none",
                7: "local_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        get _remote_timestamp() {
            const cases: {
                [index: number]: "none" | "remote_timestamp";
            } = {
                0: "none",
                8: "remote_timestamp"
            };
            return cases[pb_1.Message.computeOneofCase(this, [8])];
        }
        get _reports_sent() {
            const cases: {
                [index: number]: "none" | "reports_sent";
            } = {
                0: "none",
                9: "reports_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [9])];
        }
        get _round_trip_time() {
            const cases: {
                [index: number]: "none" | "round_trip_time";
            } = {
                0: "none",
                10: "round_trip_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [10])];
        }
        get _total_round_trip_time() {
            const cases: {
                [index: number]: "none" | "total_round_trip_time";
            } = {
                0: "none",
                11: "total_round_trip_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [11])];
        }
        get _round_trip_time_measurements() {
            const cases: {
                [index: number]: "none" | "round_trip_time_measurements";
            } = {
                0: "none",
                12: "round_trip_time_measurements"
            };
            return cases[pb_1.Message.computeOneofCase(this, [12])];
        }
        static fromObject(data: {
            ssrc?: number;
            kind?: RTCMediaKind;
            transport_id?: string;
            codec_id?: string;
            packets_sent?: number;
            bytes_sent?: number;
            local_id?: string;
            remote_timestamp?: number;
            reports_sent?: number;
            round_trip_time?: number;
            total_round_trip_time?: number;
            round_trip_time_measurements?: number;
        }): RTCRemoteOutboundRtpStreamStats {
            const message = new RTCRemoteOutboundRtpStreamStats({});
            if (data.ssrc != null) {
                message.ssrc = data.ssrc;
            }
            if (data.kind != null) {
                message.kind = data.kind;
            }
            if (data.transport_id != null) {
                message.transport_id = data.transport_id;
            }
            if (data.codec_id != null) {
                message.codec_id = data.codec_id;
            }
            if (data.packets_sent != null) {
                message.packets_sent = data.packets_sent;
            }
            if (data.bytes_sent != null) {
                message.bytes_sent = data.bytes_sent;
            }
            if (data.local_id != null) {
                message.local_id = data.local_id;
            }
            if (data.remote_timestamp != null) {
                message.remote_timestamp = data.remote_timestamp;
            }
            if (data.reports_sent != null) {
                message.reports_sent = data.reports_sent;
            }
            if (data.round_trip_time != null) {
                message.round_trip_time = data.round_trip_time;
            }
            if (data.total_round_trip_time != null) {
                message.total_round_trip_time = data.total_round_trip_time;
            }
            if (data.round_trip_time_measurements != null) {
                message.round_trip_time_measurements = data.round_trip_time_measurements;
            }
            return message;
        }
        toObject() {
            const data: {
                ssrc?: number;
                kind?: RTCMediaKind;
                transport_id?: string;
                codec_id?: string;
                packets_sent?: number;
                bytes_sent?: number;
                local_id?: string;
                remote_timestamp?: number;
                reports_sent?: number;
                round_trip_time?: number;
                total_round_trip_time?: number;
                round_trip_time_measurements?: number;
            } = {};
            if (this.ssrc != null) {
                data.ssrc = this.ssrc;
            }
            if (this.kind != null) {
                data.kind = this.kind;
            }
            if (this.transport_id != null) {
                data.transport_id = this.transport_id;
            }
            if (this.codec_id != null) {
                data.codec_id = this.codec_id;
            }
            if (this.packets_sent != null) {
                data.packets_sent = this.packets_sent;
            }
            if (this.bytes_sent != null) {
                data.bytes_sent = this.bytes_sent;
            }
            if (this.local_id != null) {
                data.local_id = this.local_id;
            }
            if (this.remote_timestamp != null) {
                data.remote_timestamp = this.remote_timestamp;
            }
            if (this.reports_sent != null) {
                data.reports_sent = this.reports_sent;
            }
            if (this.round_trip_time != null) {
                data.round_trip_time = this.round_trip_time;
            }
            if (this.total_round_trip_time != null) {
                data.total_round_trip_time = this.total_round_trip_time;
            }
            if (this.round_trip_time_measurements != null) {
                data.round_trip_time_measurements = this.round_trip_time_measurements;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.ssrc != 0)
                writer.writeFixed32(1, this.ssrc);
            if (this.kind != RTCMediaKind.Audio)
                writer.writeEnum(2, this.kind);
            if (this.has_transport_id)
                writer.writeString(3, this.transport_id);
            if (this.has_codec_id)
                writer.writeString(4, this.codec_id);
            if (this.has_packets_sent)
                writer.writeFixed32(5, this.packets_sent);
            if (this.has_bytes_sent)
                writer.writeFixed64(6, this.bytes_sent);
            if (this.has_local_id)
                writer.writeString(7, this.local_id);
            if (this.has_remote_timestamp)
                writer.writeDouble(8, this.remote_timestamp);
            if (this.has_reports_sent)
                writer.writeFixed64(9, this.reports_sent);
            if (this.has_round_trip_time)
                writer.writeFloat(10, this.round_trip_time);
            if (this.has_total_round_trip_time)
                writer.writeFloat(11, this.total_round_trip_time);
            if (this.has_round_trip_time_measurements)
                writer.writeFixed64(12, this.round_trip_time_measurements);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCRemoteOutboundRtpStreamStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCRemoteOutboundRtpStreamStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.ssrc = reader.readFixed32();
                        break;
                    case 2:
                        message.kind = reader.readEnum();
                        break;
                    case 3:
                        message.transport_id = reader.readString();
                        break;
                    case 4:
                        message.codec_id = reader.readString();
                        break;
                    case 5:
                        message.packets_sent = reader.readFixed32();
                        break;
                    case 6:
                        message.bytes_sent = reader.readFixed64();
                        break;
                    case 7:
                        message.local_id = reader.readString();
                        break;
                    case 8:
                        message.remote_timestamp = reader.readDouble();
                        break;
                    case 9:
                        message.reports_sent = reader.readFixed64();
                        break;
                    case 10:
                        message.round_trip_time = reader.readFloat();
                        break;
                    case 11:
                        message.total_round_trip_time = reader.readFloat();
                        break;
                    case 12:
                        message.round_trip_time_measurements = reader.readFixed64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCRemoteOutboundRtpStreamStats {
            return RTCRemoteOutboundRtpStreamStats.deserialize(bytes);
        }
    }
    export class RTCInboundRtpStreamStats extends pb_1.Message {
        #one_of_decls: number[][] = [[3], [4], [5], [6], [7], [8], [9], [10], [11], [12], [13], [14], [15], [16], [17], [18], [19], [20], [22], [23], [24], [25], [26], [27], [28], [29], [30], [31], [32], [33], [34], [35], [36], [37], [38], [39], [40], [41], [43], [44], [45], [46], [47], [48], [49], [50], [51], [52], [53], [54], [55], [56], [57], [58], [59], [60], [61], [62], [63], [64], [65]];
        constructor(data?: any[] | ({
            ssrc?: number;
            kind?: RTCMediaKind;
            receiver_id?: string;
            per_dscp_packets_received?: Map<string, number>;
        } & (({
            transport_id?: string;
        }) | ({
            codec_id?: string;
        }) | ({
            packets_received?: number;
        }) | ({
            packets_lost?: number;
        }) | ({
            jitter?: number;
        }) | ({
            packets_discarded?: number;
        }) | ({
            packets_repaired?: number;
        }) | ({
            burst_packets_lost?: number;
        }) | ({
            burst_packets_discarded?: number;
        }) | ({
            burst_loss_count?: number;
        }) | ({
            burst_discard_count?: number;
        }) | ({
            burst_loss_rate?: number;
        }) | ({
            burst_discard_rate?: number;
        }) | ({
            gap_loss_rate?: number;
        }) | ({
            gap_discard_rate?: number;
        }) | ({
            frames_dropped?: number;
        }) | ({
            partial_frames_lost?: number;
        }) | ({
            full_frames_lost?: number;
        }) | ({
            remote_id?: string;
        }) | ({
            frames_decoded?: number;
        }) | ({
            key_frames_decoded?: number;
        }) | ({
            frame_width?: number;
        }) | ({
            frame_height?: number;
        }) | ({
            frame_bit_depth?: number;
        }) | ({
            frames_per_second?: number;
        }) | ({
            qp_sum?: number;
        }) | ({
            total_decode_time?: number;
        }) | ({
            total_inter_frame_delay?: number;
        }) | ({
            total_squared_inter_frame_delay?: number;
        }) | ({
            voice_activity_flag?: boolean;
        }) | ({
            last_packet_received_timestamp?: number;
        }) | ({
            average_rtcp_interval?: number;
        }) | ({
            header_bytes_received?: number;
        }) | ({
            fec_packets_received?: number;
        }) | ({
            fec_packets_discarded?: number;
        }) | ({
            bytes_received?: number;
        }) | ({
            packets_failed_decryption?: number;
        }) | ({
            packets_duplicated?: number;
        }) | ({
            nack_count?: number;
        }) | ({
            fir_count?: number;
        }) | ({
            pli_count?: number;
        }) | ({
            sli_count?: number;
        }) | ({
            total_processing_delay?: number;
        }) | ({
            estimated_playout_timestamp?: number;
        }) | ({
            jitter_buffer_delay?: number;
        }) | ({
            jitter_buffer_emitted_count?: number;
        }) | ({
            total_samples_received?: number;
        }) | ({
            total_samples_decoded?: number;
        }) | ({
            samples_decoded_with_silk?: number;
        }) | ({
            samples_decoded_with_celt?: number;
        }) | ({
            concealed_samples?: number;
        }) | ({
            silent_concealed_samples?: number;
        }) | ({
            concealment_events?: number;
        }) | ({
            inserted_samples_for_deceleration?: number;
        }) | ({
            removed_samples_for_acceleration?: number;
        }) | ({
            audio_level?: number;
        }) | ({
            total_audio_energy?: number;
        }) | ({
            total_samples_duration?: number;
        }) | ({
            frames_received?: number;
        }) | ({
            decoder_implementation?: string;
        }) | ({
            mid?: string;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("ssrc" in data && data.ssrc != undefined) {
                    this.ssrc = data.ssrc;
                }
                if ("kind" in data && data.kind != undefined) {
                    this.kind = data.kind;
                }
                if ("transport_id" in data && data.transport_id != undefined) {
                    this.transport_id = data.transport_id;
                }
                if ("codec_id" in data && data.codec_id != undefined) {
                    this.codec_id = data.codec_id;
                }
                if ("packets_received" in data && data.packets_received != undefined) {
                    this.packets_received = data.packets_received;
                }
                if ("packets_lost" in data && data.packets_lost != undefined) {
                    this.packets_lost = data.packets_lost;
                }
                if ("jitter" in data && data.jitter != undefined) {
                    this.jitter = data.jitter;
                }
                if ("packets_discarded" in data && data.packets_discarded != undefined) {
                    this.packets_discarded = data.packets_discarded;
                }
                if ("packets_repaired" in data && data.packets_repaired != undefined) {
                    this.packets_repaired = data.packets_repaired;
                }
                if ("burst_packets_lost" in data && data.burst_packets_lost != undefined) {
                    this.burst_packets_lost = data.burst_packets_lost;
                }
                if ("burst_packets_discarded" in data && data.burst_packets_discarded != undefined) {
                    this.burst_packets_discarded = data.burst_packets_discarded;
                }
                if ("burst_loss_count" in data && data.burst_loss_count != undefined) {
                    this.burst_loss_count = data.burst_loss_count;
                }
                if ("burst_discard_count" in data && data.burst_discard_count != undefined) {
                    this.burst_discard_count = data.burst_discard_count;
                }
                if ("burst_loss_rate" in data && data.burst_loss_rate != undefined) {
                    this.burst_loss_rate = data.burst_loss_rate;
                }
                if ("burst_discard_rate" in data && data.burst_discard_rate != undefined) {
                    this.burst_discard_rate = data.burst_discard_rate;
                }
                if ("gap_loss_rate" in data && data.gap_loss_rate != undefined) {
                    this.gap_loss_rate = data.gap_loss_rate;
                }
                if ("gap_discard_rate" in data && data.gap_discard_rate != undefined) {
                    this.gap_discard_rate = data.gap_discard_rate;
                }
                if ("frames_dropped" in data && data.frames_dropped != undefined) {
                    this.frames_dropped = data.frames_dropped;
                }
                if ("partial_frames_lost" in data && data.partial_frames_lost != undefined) {
                    this.partial_frames_lost = data.partial_frames_lost;
                }
                if ("full_frames_lost" in data && data.full_frames_lost != undefined) {
                    this.full_frames_lost = data.full_frames_lost;
                }
                if ("receiver_id" in data && data.receiver_id != undefined) {
                    this.receiver_id = data.receiver_id;
                }
                if ("remote_id" in data && data.remote_id != undefined) {
                    this.remote_id = data.remote_id;
                }
                if ("frames_decoded" in data && data.frames_decoded != undefined) {
                    this.frames_decoded = data.frames_decoded;
                }
                if ("key_frames_decoded" in data && data.key_frames_decoded != undefined) {
                    this.key_frames_decoded = data.key_frames_decoded;
                }
                if ("frame_width" in data && data.frame_width != undefined) {
                    this.frame_width = data.frame_width;
                }
                if ("frame_height" in data && data.frame_height != undefined) {
                    this.frame_height = data.frame_height;
                }
                if ("frame_bit_depth" in data && data.frame_bit_depth != undefined) {
                    this.frame_bit_depth = data.frame_bit_depth;
                }
                if ("frames_per_second" in data && data.frames_per_second != undefined) {
                    this.frames_per_second = data.frames_per_second;
                }
                if ("qp_sum" in data && data.qp_sum != undefined) {
                    this.qp_sum = data.qp_sum;
                }
                if ("total_decode_time" in data && data.total_decode_time != undefined) {
                    this.total_decode_time = data.total_decode_time;
                }
                if ("total_inter_frame_delay" in data && data.total_inter_frame_delay != undefined) {
                    this.total_inter_frame_delay = data.total_inter_frame_delay;
                }
                if ("total_squared_inter_frame_delay" in data && data.total_squared_inter_frame_delay != undefined) {
                    this.total_squared_inter_frame_delay = data.total_squared_inter_frame_delay;
                }
                if ("voice_activity_flag" in data && data.voice_activity_flag != undefined) {
                    this.voice_activity_flag = data.voice_activity_flag;
                }
                if ("last_packet_received_timestamp" in data && data.last_packet_received_timestamp != undefined) {
                    this.last_packet_received_timestamp = data.last_packet_received_timestamp;
                }
                if ("average_rtcp_interval" in data && data.average_rtcp_interval != undefined) {
                    this.average_rtcp_interval = data.average_rtcp_interval;
                }
                if ("header_bytes_received" in data && data.header_bytes_received != undefined) {
                    this.header_bytes_received = data.header_bytes_received;
                }
                if ("fec_packets_received" in data && data.fec_packets_received != undefined) {
                    this.fec_packets_received = data.fec_packets_received;
                }
                if ("fec_packets_discarded" in data && data.fec_packets_discarded != undefined) {
                    this.fec_packets_discarded = data.fec_packets_discarded;
                }
                if ("bytes_received" in data && data.bytes_received != undefined) {
                    this.bytes_received = data.bytes_received;
                }
                if ("packets_failed_decryption" in data && data.packets_failed_decryption != undefined) {
                    this.packets_failed_decryption = data.packets_failed_decryption;
                }
                if ("packets_duplicated" in data && data.packets_duplicated != undefined) {
                    this.packets_duplicated = data.packets_duplicated;
                }
                if ("per_dscp_packets_received" in data && data.per_dscp_packets_received != undefined) {
                    this.per_dscp_packets_received = data.per_dscp_packets_received;
                }
                if ("nack_count" in data && data.nack_count != undefined) {
                    this.nack_count = data.nack_count;
                }
                if ("fir_count" in data && data.fir_count != undefined) {
                    this.fir_count = data.fir_count;
                }
                if ("pli_count" in data && data.pli_count != undefined) {
                    this.pli_count = data.pli_count;
                }
                if ("sli_count" in data && data.sli_count != undefined) {
                    this.sli_count = data.sli_count;
                }
                if ("total_processing_delay" in data && data.total_processing_delay != undefined) {
                    this.total_processing_delay = data.total_processing_delay;
                }
                if ("estimated_playout_timestamp" in data && data.estimated_playout_timestamp != undefined) {
                    this.estimated_playout_timestamp = data.estimated_playout_timestamp;
                }
                if ("jitter_buffer_delay" in data && data.jitter_buffer_delay != undefined) {
                    this.jitter_buffer_delay = data.jitter_buffer_delay;
                }
                if ("jitter_buffer_emitted_count" in data && data.jitter_buffer_emitted_count != undefined) {
                    this.jitter_buffer_emitted_count = data.jitter_buffer_emitted_count;
                }
                if ("total_samples_received" in data && data.total_samples_received != undefined) {
                    this.total_samples_received = data.total_samples_received;
                }
                if ("total_samples_decoded" in data && data.total_samples_decoded != undefined) {
                    this.total_samples_decoded = data.total_samples_decoded;
                }
                if ("samples_decoded_with_silk" in data && data.samples_decoded_with_silk != undefined) {
                    this.samples_decoded_with_silk = data.samples_decoded_with_silk;
                }
                if ("samples_decoded_with_celt" in data && data.samples_decoded_with_celt != undefined) {
                    this.samples_decoded_with_celt = data.samples_decoded_with_celt;
                }
                if ("concealed_samples" in data && data.concealed_samples != undefined) {
                    this.concealed_samples = data.concealed_samples;
                }
                if ("silent_concealed_samples" in data && data.silent_concealed_samples != undefined) {
                    this.silent_concealed_samples = data.silent_concealed_samples;
                }
                if ("concealment_events" in data && data.concealment_events != undefined) {
                    this.concealment_events = data.concealment_events;
                }
                if ("inserted_samples_for_deceleration" in data && data.inserted_samples_for_deceleration != undefined) {
                    this.inserted_samples_for_deceleration = data.inserted_samples_for_deceleration;
                }
                if ("removed_samples_for_acceleration" in data && data.removed_samples_for_acceleration != undefined) {
                    this.removed_samples_for_acceleration = data.removed_samples_for_acceleration;
                }
                if ("audio_level" in data && data.audio_level != undefined) {
                    this.audio_level = data.audio_level;
                }
                if ("total_audio_energy" in data && data.total_audio_energy != undefined) {
                    this.total_audio_energy = data.total_audio_energy;
                }
                if ("total_samples_duration" in data && data.total_samples_duration != undefined) {
                    this.total_samples_duration = data.total_samples_duration;
                }
                if ("frames_received" in data && data.frames_received != undefined) {
                    this.frames_received = data.frames_received;
                }
                if ("decoder_implementation" in data && data.decoder_implementation != undefined) {
                    this.decoder_implementation = data.decoder_implementation;
                }
                if ("mid" in data && data.mid != undefined) {
                    this.mid = data.mid;
                }
            }
            if (!this.per_dscp_packets_received)
                this.per_dscp_packets_received = new Map();
        }
        get ssrc() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set ssrc(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get kind() {
            return pb_1.Message.getFieldWithDefault(this, 2, RTCMediaKind.Audio) as RTCMediaKind;
        }
        set kind(value: RTCMediaKind) {
            pb_1.Message.setField(this, 2, value);
        }
        get transport_id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set transport_id(value: string) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0], value);
        }
        get has_transport_id() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get codec_id() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set codec_id(value: string) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[1], value);
        }
        get has_codec_id() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get packets_received() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set packets_received(value: number) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[2], value);
        }
        get has_packets_received() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get packets_lost() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set packets_lost(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[3], value);
        }
        get has_packets_lost() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get jitter() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set jitter(value: number) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[4], value);
        }
        get has_jitter() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get packets_discarded() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set packets_discarded(value: number) {
            pb_1.Message.setOneofField(this, 8, this.#one_of_decls[5], value);
        }
        get has_packets_discarded() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get packets_repaired() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set packets_repaired(value: number) {
            pb_1.Message.setOneofField(this, 9, this.#one_of_decls[6], value);
        }
        get has_packets_repaired() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get burst_packets_lost() {
            return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
        }
        set burst_packets_lost(value: number) {
            pb_1.Message.setOneofField(this, 10, this.#one_of_decls[7], value);
        }
        get has_burst_packets_lost() {
            return pb_1.Message.getField(this, 10) != null;
        }
        get burst_packets_discarded() {
            return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
        }
        set burst_packets_discarded(value: number) {
            pb_1.Message.setOneofField(this, 11, this.#one_of_decls[8], value);
        }
        get has_burst_packets_discarded() {
            return pb_1.Message.getField(this, 11) != null;
        }
        get burst_loss_count() {
            return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
        }
        set burst_loss_count(value: number) {
            pb_1.Message.setOneofField(this, 12, this.#one_of_decls[9], value);
        }
        get has_burst_loss_count() {
            return pb_1.Message.getField(this, 12) != null;
        }
        get burst_discard_count() {
            return pb_1.Message.getFieldWithDefault(this, 13, 0) as number;
        }
        set burst_discard_count(value: number) {
            pb_1.Message.setOneofField(this, 13, this.#one_of_decls[10], value);
        }
        get has_burst_discard_count() {
            return pb_1.Message.getField(this, 13) != null;
        }
        get burst_loss_rate() {
            return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
        }
        set burst_loss_rate(value: number) {
            pb_1.Message.setOneofField(this, 14, this.#one_of_decls[11], value);
        }
        get has_burst_loss_rate() {
            return pb_1.Message.getField(this, 14) != null;
        }
        get burst_discard_rate() {
            return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
        }
        set burst_discard_rate(value: number) {
            pb_1.Message.setOneofField(this, 15, this.#one_of_decls[12], value);
        }
        get has_burst_discard_rate() {
            return pb_1.Message.getField(this, 15) != null;
        }
        get gap_loss_rate() {
            return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
        }
        set gap_loss_rate(value: number) {
            pb_1.Message.setOneofField(this, 16, this.#one_of_decls[13], value);
        }
        get has_gap_loss_rate() {
            return pb_1.Message.getField(this, 16) != null;
        }
        get gap_discard_rate() {
            return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
        }
        set gap_discard_rate(value: number) {
            pb_1.Message.setOneofField(this, 17, this.#one_of_decls[14], value);
        }
        get has_gap_discard_rate() {
            return pb_1.Message.getField(this, 17) != null;
        }
        get frames_dropped() {
            return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
        }
        set frames_dropped(value: number) {
            pb_1.Message.setOneofField(this, 18, this.#one_of_decls[15], value);
        }
        get has_frames_dropped() {
            return pb_1.Message.getField(this, 18) != null;
        }
        get partial_frames_lost() {
            return pb_1.Message.getFieldWithDefault(this, 19, 0) as number;
        }
        set partial_frames_lost(value: number) {
            pb_1.Message.setOneofField(this, 19, this.#one_of_decls[16], value);
        }
        get has_partial_frames_lost() {
            return pb_1.Message.getField(this, 19) != null;
        }
        get full_frames_lost() {
            return pb_1.Message.getFieldWithDefault(this, 20, 0) as number;
        }
        set full_frames_lost(value: number) {
            pb_1.Message.setOneofField(this, 20, this.#one_of_decls[17], value);
        }
        get has_full_frames_lost() {
            return pb_1.Message.getField(this, 20) != null;
        }
        get receiver_id() {
            return pb_1.Message.getFieldWithDefault(this, 21, "") as string;
        }
        set receiver_id(value: string) {
            pb_1.Message.setField(this, 21, value);
        }
        get remote_id() {
            return pb_1.Message.getFieldWithDefault(this, 22, "") as string;
        }
        set remote_id(value: string) {
            pb_1.Message.setOneofField(this, 22, this.#one_of_decls[18], value);
        }
        get has_remote_id() {
            return pb_1.Message.getField(this, 22) != null;
        }
        get frames_decoded() {
            return pb_1.Message.getFieldWithDefault(this, 23, 0) as number;
        }
        set frames_decoded(value: number) {
            pb_1.Message.setOneofField(this, 23, this.#one_of_decls[19], value);
        }
        get has_frames_decoded() {
            return pb_1.Message.getField(this, 23) != null;
        }
        get key_frames_decoded() {
            return pb_1.Message.getFieldWithDefault(this, 24, 0) as number;
        }
        set key_frames_decoded(value: number) {
            pb_1.Message.setOneofField(this, 24, this.#one_of_decls[20], value);
        }
        get has_key_frames_decoded() {
            return pb_1.Message.getField(this, 24) != null;
        }
        get frame_width() {
            return pb_1.Message.getFieldWithDefault(this, 25, 0) as number;
        }
        set frame_width(value: number) {
            pb_1.Message.setOneofField(this, 25, this.#one_of_decls[21], value);
        }
        get has_frame_width() {
            return pb_1.Message.getField(this, 25) != null;
        }
        get frame_height() {
            return pb_1.Message.getFieldWithDefault(this, 26, 0) as number;
        }
        set frame_height(value: number) {
            pb_1.Message.setOneofField(this, 26, this.#one_of_decls[22], value);
        }
        get has_frame_height() {
            return pb_1.Message.getField(this, 26) != null;
        }
        get frame_bit_depth() {
            return pb_1.Message.getFieldWithDefault(this, 27, 0) as number;
        }
        set frame_bit_depth(value: number) {
            pb_1.Message.setOneofField(this, 27, this.#one_of_decls[23], value);
        }
        get has_frame_bit_depth() {
            return pb_1.Message.getField(this, 27) != null;
        }
        get frames_per_second() {
            return pb_1.Message.getFieldWithDefault(this, 28, 0) as number;
        }
        set frames_per_second(value: number) {
            pb_1.Message.setOneofField(this, 28, this.#one_of_decls[24], value);
        }
        get has_frames_per_second() {
            return pb_1.Message.getField(this, 28) != null;
        }
        get qp_sum() {
            return pb_1.Message.getFieldWithDefault(this, 29, 0) as number;
        }
        set qp_sum(value: number) {
            pb_1.Message.setOneofField(this, 29, this.#one_of_decls[25], value);
        }
        get has_qp_sum() {
            return pb_1.Message.getField(this, 29) != null;
        }
        get total_decode_time() {
            return pb_1.Message.getFieldWithDefault(this, 30, 0) as number;
        }
        set total_decode_time(value: number) {
            pb_1.Message.setOneofField(this, 30, this.#one_of_decls[26], value);
        }
        get has_total_decode_time() {
            return pb_1.Message.getField(this, 30) != null;
        }
        get total_inter_frame_delay() {
            return pb_1.Message.getFieldWithDefault(this, 31, 0) as number;
        }
        set total_inter_frame_delay(value: number) {
            pb_1.Message.setOneofField(this, 31, this.#one_of_decls[27], value);
        }
        get has_total_inter_frame_delay() {
            return pb_1.Message.getField(this, 31) != null;
        }
        get total_squared_inter_frame_delay() {
            return pb_1.Message.getFieldWithDefault(this, 32, 0) as number;
        }
        set total_squared_inter_frame_delay(value: number) {
            pb_1.Message.setOneofField(this, 32, this.#one_of_decls[28], value);
        }
        get has_total_squared_inter_frame_delay() {
            return pb_1.Message.getField(this, 32) != null;
        }
        get voice_activity_flag() {
            return pb_1.Message.getFieldWithDefault(this, 33, false) as boolean;
        }
        set voice_activity_flag(value: boolean) {
            pb_1.Message.setOneofField(this, 33, this.#one_of_decls[29], value);
        }
        get has_voice_activity_flag() {
            return pb_1.Message.getField(this, 33) != null;
        }
        get last_packet_received_timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 34, 0) as number;
        }
        set last_packet_received_timestamp(value: number) {
            pb_1.Message.setOneofField(this, 34, this.#one_of_decls[30], value);
        }
        get has_last_packet_received_timestamp() {
            return pb_1.Message.getField(this, 34) != null;
        }
        get average_rtcp_interval() {
            return pb_1.Message.getFieldWithDefault(this, 35, 0) as number;
        }
        set average_rtcp_interval(value: number) {
            pb_1.Message.setOneofField(this, 35, this.#one_of_decls[31], value);
        }
        get has_average_rtcp_interval() {
            return pb_1.Message.getField(this, 35) != null;
        }
        get header_bytes_received() {
            return pb_1.Message.getFieldWithDefault(this, 36, 0) as number;
        }
        set header_bytes_received(value: number) {
            pb_1.Message.setOneofField(this, 36, this.#one_of_decls[32], value);
        }
        get has_header_bytes_received() {
            return pb_1.Message.getField(this, 36) != null;
        }
        get fec_packets_received() {
            return pb_1.Message.getFieldWithDefault(this, 37, 0) as number;
        }
        set fec_packets_received(value: number) {
            pb_1.Message.setOneofField(this, 37, this.#one_of_decls[33], value);
        }
        get has_fec_packets_received() {
            return pb_1.Message.getField(this, 37) != null;
        }
        get fec_packets_discarded() {
            return pb_1.Message.getFieldWithDefault(this, 38, 0) as number;
        }
        set fec_packets_discarded(value: number) {
            pb_1.Message.setOneofField(this, 38, this.#one_of_decls[34], value);
        }
        get has_fec_packets_discarded() {
            return pb_1.Message.getField(this, 38) != null;
        }
        get bytes_received() {
            return pb_1.Message.getFieldWithDefault(this, 39, 0) as number;
        }
        set bytes_received(value: number) {
            pb_1.Message.setOneofField(this, 39, this.#one_of_decls[35], value);
        }
        get has_bytes_received() {
            return pb_1.Message.getField(this, 39) != null;
        }
        get packets_failed_decryption() {
            return pb_1.Message.getFieldWithDefault(this, 40, 0) as number;
        }
        set packets_failed_decryption(value: number) {
            pb_1.Message.setOneofField(this, 40, this.#one_of_decls[36], value);
        }
        get has_packets_failed_decryption() {
            return pb_1.Message.getField(this, 40) != null;
        }
        get packets_duplicated() {
            return pb_1.Message.getFieldWithDefault(this, 41, 0) as number;
        }
        set packets_duplicated(value: number) {
            pb_1.Message.setOneofField(this, 41, this.#one_of_decls[37], value);
        }
        get has_packets_duplicated() {
            return pb_1.Message.getField(this, 41) != null;
        }
        get per_dscp_packets_received() {
            return pb_1.Message.getField(this, 42) as any as Map<string, number>;
        }
        set per_dscp_packets_received(value: Map<string, number>) {
            pb_1.Message.setField(this, 42, value as any);
        }
        get nack_count() {
            return pb_1.Message.getFieldWithDefault(this, 43, 0) as number;
        }
        set nack_count(value: number) {
            pb_1.Message.setOneofField(this, 43, this.#one_of_decls[38], value);
        }
        get has_nack_count() {
            return pb_1.Message.getField(this, 43) != null;
        }
        get fir_count() {
            return pb_1.Message.getFieldWithDefault(this, 44, 0) as number;
        }
        set fir_count(value: number) {
            pb_1.Message.setOneofField(this, 44, this.#one_of_decls[39], value);
        }
        get has_fir_count() {
            return pb_1.Message.getField(this, 44) != null;
        }
        get pli_count() {
            return pb_1.Message.getFieldWithDefault(this, 45, 0) as number;
        }
        set pli_count(value: number) {
            pb_1.Message.setOneofField(this, 45, this.#one_of_decls[40], value);
        }
        get has_pli_count() {
            return pb_1.Message.getField(this, 45) != null;
        }
        get sli_count() {
            return pb_1.Message.getFieldWithDefault(this, 46, 0) as number;
        }
        set sli_count(value: number) {
            pb_1.Message.setOneofField(this, 46, this.#one_of_decls[41], value);
        }
        get has_sli_count() {
            return pb_1.Message.getField(this, 46) != null;
        }
        get total_processing_delay() {
            return pb_1.Message.getFieldWithDefault(this, 47, 0) as number;
        }
        set total_processing_delay(value: number) {
            pb_1.Message.setOneofField(this, 47, this.#one_of_decls[42], value);
        }
        get has_total_processing_delay() {
            return pb_1.Message.getField(this, 47) != null;
        }
        get estimated_playout_timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 48, 0) as number;
        }
        set estimated_playout_timestamp(value: number) {
            pb_1.Message.setOneofField(this, 48, this.#one_of_decls[43], value);
        }
        get has_estimated_playout_timestamp() {
            return pb_1.Message.getField(this, 48) != null;
        }
        get jitter_buffer_delay() {
            return pb_1.Message.getFieldWithDefault(this, 49, 0) as number;
        }
        set jitter_buffer_delay(value: number) {
            pb_1.Message.setOneofField(this, 49, this.#one_of_decls[44], value);
        }
        get has_jitter_buffer_delay() {
            return pb_1.Message.getField(this, 49) != null;
        }
        get jitter_buffer_emitted_count() {
            return pb_1.Message.getFieldWithDefault(this, 50, 0) as number;
        }
        set jitter_buffer_emitted_count(value: number) {
            pb_1.Message.setOneofField(this, 50, this.#one_of_decls[45], value);
        }
        get has_jitter_buffer_emitted_count() {
            return pb_1.Message.getField(this, 50) != null;
        }
        get total_samples_received() {
            return pb_1.Message.getFieldWithDefault(this, 51, 0) as number;
        }
        set total_samples_received(value: number) {
            pb_1.Message.setOneofField(this, 51, this.#one_of_decls[46], value);
        }
        get has_total_samples_received() {
            return pb_1.Message.getField(this, 51) != null;
        }
        get total_samples_decoded() {
            return pb_1.Message.getFieldWithDefault(this, 52, 0) as number;
        }
        set total_samples_decoded(value: number) {
            pb_1.Message.setOneofField(this, 52, this.#one_of_decls[47], value);
        }
        get has_total_samples_decoded() {
            return pb_1.Message.getField(this, 52) != null;
        }
        get samples_decoded_with_silk() {
            return pb_1.Message.getFieldWithDefault(this, 53, 0) as number;
        }
        set samples_decoded_with_silk(value: number) {
            pb_1.Message.setOneofField(this, 53, this.#one_of_decls[48], value);
        }
        get has_samples_decoded_with_silk() {
            return pb_1.Message.getField(this, 53) != null;
        }
        get samples_decoded_with_celt() {
            return pb_1.Message.getFieldWithDefault(this, 54, 0) as number;
        }
        set samples_decoded_with_celt(value: number) {
            pb_1.Message.setOneofField(this, 54, this.#one_of_decls[49], value);
        }
        get has_samples_decoded_with_celt() {
            return pb_1.Message.getField(this, 54) != null;
        }
        get concealed_samples() {
            return pb_1.Message.getFieldWithDefault(this, 55, 0) as number;
        }
        set concealed_samples(value: number) {
            pb_1.Message.setOneofField(this, 55, this.#one_of_decls[50], value);
        }
        get has_concealed_samples() {
            return pb_1.Message.getField(this, 55) != null;
        }
        get silent_concealed_samples() {
            return pb_1.Message.getFieldWithDefault(this, 56, 0) as number;
        }
        set silent_concealed_samples(value: number) {
            pb_1.Message.setOneofField(this, 56, this.#one_of_decls[51], value);
        }
        get has_silent_concealed_samples() {
            return pb_1.Message.getField(this, 56) != null;
        }
        get concealment_events() {
            return pb_1.Message.getFieldWithDefault(this, 57, 0) as number;
        }
        set concealment_events(value: number) {
            pb_1.Message.setOneofField(this, 57, this.#one_of_decls[52], value);
        }
        get has_concealment_events() {
            return pb_1.Message.getField(this, 57) != null;
        }
        get inserted_samples_for_deceleration() {
            return pb_1.Message.getFieldWithDefault(this, 58, 0) as number;
        }
        set inserted_samples_for_deceleration(value: number) {
            pb_1.Message.setOneofField(this, 58, this.#one_of_decls[53], value);
        }
        get has_inserted_samples_for_deceleration() {
            return pb_1.Message.getField(this, 58) != null;
        }
        get removed_samples_for_acceleration() {
            return pb_1.Message.getFieldWithDefault(this, 59, 0) as number;
        }
        set removed_samples_for_acceleration(value: number) {
            pb_1.Message.setOneofField(this, 59, this.#one_of_decls[54], value);
        }
        get has_removed_samples_for_acceleration() {
            return pb_1.Message.getField(this, 59) != null;
        }
        get audio_level() {
            return pb_1.Message.getFieldWithDefault(this, 60, 0) as number;
        }
        set audio_level(value: number) {
            pb_1.Message.setOneofField(this, 60, this.#one_of_decls[55], value);
        }
        get has_audio_level() {
            return pb_1.Message.getField(this, 60) != null;
        }
        get total_audio_energy() {
            return pb_1.Message.getFieldWithDefault(this, 61, 0) as number;
        }
        set total_audio_energy(value: number) {
            pb_1.Message.setOneofField(this, 61, this.#one_of_decls[56], value);
        }
        get has_total_audio_energy() {
            return pb_1.Message.getField(this, 61) != null;
        }
        get total_samples_duration() {
            return pb_1.Message.getFieldWithDefault(this, 62, 0) as number;
        }
        set total_samples_duration(value: number) {
            pb_1.Message.setOneofField(this, 62, this.#one_of_decls[57], value);
        }
        get has_total_samples_duration() {
            return pb_1.Message.getField(this, 62) != null;
        }
        get frames_received() {
            return pb_1.Message.getFieldWithDefault(this, 63, 0) as number;
        }
        set frames_received(value: number) {
            pb_1.Message.setOneofField(this, 63, this.#one_of_decls[58], value);
        }
        get has_frames_received() {
            return pb_1.Message.getField(this, 63) != null;
        }
        get decoder_implementation() {
            return pb_1.Message.getFieldWithDefault(this, 64, "") as string;
        }
        set decoder_implementation(value: string) {
            pb_1.Message.setOneofField(this, 64, this.#one_of_decls[59], value);
        }
        get has_decoder_implementation() {
            return pb_1.Message.getField(this, 64) != null;
        }
        get mid() {
            return pb_1.Message.getFieldWithDefault(this, 65, "") as string;
        }
        set mid(value: string) {
            pb_1.Message.setOneofField(this, 65, this.#one_of_decls[60], value);
        }
        get has_mid() {
            return pb_1.Message.getField(this, 65) != null;
        }
        get _transport_id() {
            const cases: {
                [index: number]: "none" | "transport_id";
            } = {
                0: "none",
                3: "transport_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _codec_id() {
            const cases: {
                [index: number]: "none" | "codec_id";
            } = {
                0: "none",
                4: "codec_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        get _packets_received() {
            const cases: {
                [index: number]: "none" | "packets_received";
            } = {
                0: "none",
                5: "packets_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _packets_lost() {
            const cases: {
                [index: number]: "none" | "packets_lost";
            } = {
                0: "none",
                6: "packets_lost"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _jitter() {
            const cases: {
                [index: number]: "none" | "jitter";
            } = {
                0: "none",
                7: "jitter"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        get _packets_discarded() {
            const cases: {
                [index: number]: "none" | "packets_discarded";
            } = {
                0: "none",
                8: "packets_discarded"
            };
            return cases[pb_1.Message.computeOneofCase(this, [8])];
        }
        get _packets_repaired() {
            const cases: {
                [index: number]: "none" | "packets_repaired";
            } = {
                0: "none",
                9: "packets_repaired"
            };
            return cases[pb_1.Message.computeOneofCase(this, [9])];
        }
        get _burst_packets_lost() {
            const cases: {
                [index: number]: "none" | "burst_packets_lost";
            } = {
                0: "none",
                10: "burst_packets_lost"
            };
            return cases[pb_1.Message.computeOneofCase(this, [10])];
        }
        get _burst_packets_discarded() {
            const cases: {
                [index: number]: "none" | "burst_packets_discarded";
            } = {
                0: "none",
                11: "burst_packets_discarded"
            };
            return cases[pb_1.Message.computeOneofCase(this, [11])];
        }
        get _burst_loss_count() {
            const cases: {
                [index: number]: "none" | "burst_loss_count";
            } = {
                0: "none",
                12: "burst_loss_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [12])];
        }
        get _burst_discard_count() {
            const cases: {
                [index: number]: "none" | "burst_discard_count";
            } = {
                0: "none",
                13: "burst_discard_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [13])];
        }
        get _burst_loss_rate() {
            const cases: {
                [index: number]: "none" | "burst_loss_rate";
            } = {
                0: "none",
                14: "burst_loss_rate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [14])];
        }
        get _burst_discard_rate() {
            const cases: {
                [index: number]: "none" | "burst_discard_rate";
            } = {
                0: "none",
                15: "burst_discard_rate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [15])];
        }
        get _gap_loss_rate() {
            const cases: {
                [index: number]: "none" | "gap_loss_rate";
            } = {
                0: "none",
                16: "gap_loss_rate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [16])];
        }
        get _gap_discard_rate() {
            const cases: {
                [index: number]: "none" | "gap_discard_rate";
            } = {
                0: "none",
                17: "gap_discard_rate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [17])];
        }
        get _frames_dropped() {
            const cases: {
                [index: number]: "none" | "frames_dropped";
            } = {
                0: "none",
                18: "frames_dropped"
            };
            return cases[pb_1.Message.computeOneofCase(this, [18])];
        }
        get _partial_frames_lost() {
            const cases: {
                [index: number]: "none" | "partial_frames_lost";
            } = {
                0: "none",
                19: "partial_frames_lost"
            };
            return cases[pb_1.Message.computeOneofCase(this, [19])];
        }
        get _full_frames_lost() {
            const cases: {
                [index: number]: "none" | "full_frames_lost";
            } = {
                0: "none",
                20: "full_frames_lost"
            };
            return cases[pb_1.Message.computeOneofCase(this, [20])];
        }
        get _remote_id() {
            const cases: {
                [index: number]: "none" | "remote_id";
            } = {
                0: "none",
                22: "remote_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [22])];
        }
        get _frames_decoded() {
            const cases: {
                [index: number]: "none" | "frames_decoded";
            } = {
                0: "none",
                23: "frames_decoded"
            };
            return cases[pb_1.Message.computeOneofCase(this, [23])];
        }
        get _key_frames_decoded() {
            const cases: {
                [index: number]: "none" | "key_frames_decoded";
            } = {
                0: "none",
                24: "key_frames_decoded"
            };
            return cases[pb_1.Message.computeOneofCase(this, [24])];
        }
        get _frame_width() {
            const cases: {
                [index: number]: "none" | "frame_width";
            } = {
                0: "none",
                25: "frame_width"
            };
            return cases[pb_1.Message.computeOneofCase(this, [25])];
        }
        get _frame_height() {
            const cases: {
                [index: number]: "none" | "frame_height";
            } = {
                0: "none",
                26: "frame_height"
            };
            return cases[pb_1.Message.computeOneofCase(this, [26])];
        }
        get _frame_bit_depth() {
            const cases: {
                [index: number]: "none" | "frame_bit_depth";
            } = {
                0: "none",
                27: "frame_bit_depth"
            };
            return cases[pb_1.Message.computeOneofCase(this, [27])];
        }
        get _frames_per_second() {
            const cases: {
                [index: number]: "none" | "frames_per_second";
            } = {
                0: "none",
                28: "frames_per_second"
            };
            return cases[pb_1.Message.computeOneofCase(this, [28])];
        }
        get _qp_sum() {
            const cases: {
                [index: number]: "none" | "qp_sum";
            } = {
                0: "none",
                29: "qp_sum"
            };
            return cases[pb_1.Message.computeOneofCase(this, [29])];
        }
        get _total_decode_time() {
            const cases: {
                [index: number]: "none" | "total_decode_time";
            } = {
                0: "none",
                30: "total_decode_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [30])];
        }
        get _total_inter_frame_delay() {
            const cases: {
                [index: number]: "none" | "total_inter_frame_delay";
            } = {
                0: "none",
                31: "total_inter_frame_delay"
            };
            return cases[pb_1.Message.computeOneofCase(this, [31])];
        }
        get _total_squared_inter_frame_delay() {
            const cases: {
                [index: number]: "none" | "total_squared_inter_frame_delay";
            } = {
                0: "none",
                32: "total_squared_inter_frame_delay"
            };
            return cases[pb_1.Message.computeOneofCase(this, [32])];
        }
        get _voice_activity_flag() {
            const cases: {
                [index: number]: "none" | "voice_activity_flag";
            } = {
                0: "none",
                33: "voice_activity_flag"
            };
            return cases[pb_1.Message.computeOneofCase(this, [33])];
        }
        get _last_packet_received_timestamp() {
            const cases: {
                [index: number]: "none" | "last_packet_received_timestamp";
            } = {
                0: "none",
                34: "last_packet_received_timestamp"
            };
            return cases[pb_1.Message.computeOneofCase(this, [34])];
        }
        get _average_rtcp_interval() {
            const cases: {
                [index: number]: "none" | "average_rtcp_interval";
            } = {
                0: "none",
                35: "average_rtcp_interval"
            };
            return cases[pb_1.Message.computeOneofCase(this, [35])];
        }
        get _header_bytes_received() {
            const cases: {
                [index: number]: "none" | "header_bytes_received";
            } = {
                0: "none",
                36: "header_bytes_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [36])];
        }
        get _fec_packets_received() {
            const cases: {
                [index: number]: "none" | "fec_packets_received";
            } = {
                0: "none",
                37: "fec_packets_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [37])];
        }
        get _fec_packets_discarded() {
            const cases: {
                [index: number]: "none" | "fec_packets_discarded";
            } = {
                0: "none",
                38: "fec_packets_discarded"
            };
            return cases[pb_1.Message.computeOneofCase(this, [38])];
        }
        get _bytes_received() {
            const cases: {
                [index: number]: "none" | "bytes_received";
            } = {
                0: "none",
                39: "bytes_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [39])];
        }
        get _packets_failed_decryption() {
            const cases: {
                [index: number]: "none" | "packets_failed_decryption";
            } = {
                0: "none",
                40: "packets_failed_decryption"
            };
            return cases[pb_1.Message.computeOneofCase(this, [40])];
        }
        get _packets_duplicated() {
            const cases: {
                [index: number]: "none" | "packets_duplicated";
            } = {
                0: "none",
                41: "packets_duplicated"
            };
            return cases[pb_1.Message.computeOneofCase(this, [41])];
        }
        get _nack_count() {
            const cases: {
                [index: number]: "none" | "nack_count";
            } = {
                0: "none",
                43: "nack_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [43])];
        }
        get _fir_count() {
            const cases: {
                [index: number]: "none" | "fir_count";
            } = {
                0: "none",
                44: "fir_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [44])];
        }
        get _pli_count() {
            const cases: {
                [index: number]: "none" | "pli_count";
            } = {
                0: "none",
                45: "pli_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [45])];
        }
        get _sli_count() {
            const cases: {
                [index: number]: "none" | "sli_count";
            } = {
                0: "none",
                46: "sli_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [46])];
        }
        get _total_processing_delay() {
            const cases: {
                [index: number]: "none" | "total_processing_delay";
            } = {
                0: "none",
                47: "total_processing_delay"
            };
            return cases[pb_1.Message.computeOneofCase(this, [47])];
        }
        get _estimated_playout_timestamp() {
            const cases: {
                [index: number]: "none" | "estimated_playout_timestamp";
            } = {
                0: "none",
                48: "estimated_playout_timestamp"
            };
            return cases[pb_1.Message.computeOneofCase(this, [48])];
        }
        get _jitter_buffer_delay() {
            const cases: {
                [index: number]: "none" | "jitter_buffer_delay";
            } = {
                0: "none",
                49: "jitter_buffer_delay"
            };
            return cases[pb_1.Message.computeOneofCase(this, [49])];
        }
        get _jitter_buffer_emitted_count() {
            const cases: {
                [index: number]: "none" | "jitter_buffer_emitted_count";
            } = {
                0: "none",
                50: "jitter_buffer_emitted_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [50])];
        }
        get _total_samples_received() {
            const cases: {
                [index: number]: "none" | "total_samples_received";
            } = {
                0: "none",
                51: "total_samples_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [51])];
        }
        get _total_samples_decoded() {
            const cases: {
                [index: number]: "none" | "total_samples_decoded";
            } = {
                0: "none",
                52: "total_samples_decoded"
            };
            return cases[pb_1.Message.computeOneofCase(this, [52])];
        }
        get _samples_decoded_with_silk() {
            const cases: {
                [index: number]: "none" | "samples_decoded_with_silk";
            } = {
                0: "none",
                53: "samples_decoded_with_silk"
            };
            return cases[pb_1.Message.computeOneofCase(this, [53])];
        }
        get _samples_decoded_with_celt() {
            const cases: {
                [index: number]: "none" | "samples_decoded_with_celt";
            } = {
                0: "none",
                54: "samples_decoded_with_celt"
            };
            return cases[pb_1.Message.computeOneofCase(this, [54])];
        }
        get _concealed_samples() {
            const cases: {
                [index: number]: "none" | "concealed_samples";
            } = {
                0: "none",
                55: "concealed_samples"
            };
            return cases[pb_1.Message.computeOneofCase(this, [55])];
        }
        get _silent_concealed_samples() {
            const cases: {
                [index: number]: "none" | "silent_concealed_samples";
            } = {
                0: "none",
                56: "silent_concealed_samples"
            };
            return cases[pb_1.Message.computeOneofCase(this, [56])];
        }
        get _concealment_events() {
            const cases: {
                [index: number]: "none" | "concealment_events";
            } = {
                0: "none",
                57: "concealment_events"
            };
            return cases[pb_1.Message.computeOneofCase(this, [57])];
        }
        get _inserted_samples_for_deceleration() {
            const cases: {
                [index: number]: "none" | "inserted_samples_for_deceleration";
            } = {
                0: "none",
                58: "inserted_samples_for_deceleration"
            };
            return cases[pb_1.Message.computeOneofCase(this, [58])];
        }
        get _removed_samples_for_acceleration() {
            const cases: {
                [index: number]: "none" | "removed_samples_for_acceleration";
            } = {
                0: "none",
                59: "removed_samples_for_acceleration"
            };
            return cases[pb_1.Message.computeOneofCase(this, [59])];
        }
        get _audio_level() {
            const cases: {
                [index: number]: "none" | "audio_level";
            } = {
                0: "none",
                60: "audio_level"
            };
            return cases[pb_1.Message.computeOneofCase(this, [60])];
        }
        get _total_audio_energy() {
            const cases: {
                [index: number]: "none" | "total_audio_energy";
            } = {
                0: "none",
                61: "total_audio_energy"
            };
            return cases[pb_1.Message.computeOneofCase(this, [61])];
        }
        get _total_samples_duration() {
            const cases: {
                [index: number]: "none" | "total_samples_duration";
            } = {
                0: "none",
                62: "total_samples_duration"
            };
            return cases[pb_1.Message.computeOneofCase(this, [62])];
        }
        get _frames_received() {
            const cases: {
                [index: number]: "none" | "frames_received";
            } = {
                0: "none",
                63: "frames_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [63])];
        }
        get _decoder_implementation() {
            const cases: {
                [index: number]: "none" | "decoder_implementation";
            } = {
                0: "none",
                64: "decoder_implementation"
            };
            return cases[pb_1.Message.computeOneofCase(this, [64])];
        }
        get _mid() {
            const cases: {
                [index: number]: "none" | "mid";
            } = {
                0: "none",
                65: "mid"
            };
            return cases[pb_1.Message.computeOneofCase(this, [65])];
        }
        static fromObject(data: {
            ssrc?: number;
            kind?: RTCMediaKind;
            transport_id?: string;
            codec_id?: string;
            packets_received?: number;
            packets_lost?: number;
            jitter?: number;
            packets_discarded?: number;
            packets_repaired?: number;
            burst_packets_lost?: number;
            burst_packets_discarded?: number;
            burst_loss_count?: number;
            burst_discard_count?: number;
            burst_loss_rate?: number;
            burst_discard_rate?: number;
            gap_loss_rate?: number;
            gap_discard_rate?: number;
            frames_dropped?: number;
            partial_frames_lost?: number;
            full_frames_lost?: number;
            receiver_id?: string;
            remote_id?: string;
            frames_decoded?: number;
            key_frames_decoded?: number;
            frame_width?: number;
            frame_height?: number;
            frame_bit_depth?: number;
            frames_per_second?: number;
            qp_sum?: number;
            total_decode_time?: number;
            total_inter_frame_delay?: number;
            total_squared_inter_frame_delay?: number;
            voice_activity_flag?: boolean;
            last_packet_received_timestamp?: number;
            average_rtcp_interval?: number;
            header_bytes_received?: number;
            fec_packets_received?: number;
            fec_packets_discarded?: number;
            bytes_received?: number;
            packets_failed_decryption?: number;
            packets_duplicated?: number;
            per_dscp_packets_received?: {
                [key: string]: number;
            };
            nack_count?: number;
            fir_count?: number;
            pli_count?: number;
            sli_count?: number;
            total_processing_delay?: number;
            estimated_playout_timestamp?: number;
            jitter_buffer_delay?: number;
            jitter_buffer_emitted_count?: number;
            total_samples_received?: number;
            total_samples_decoded?: number;
            samples_decoded_with_silk?: number;
            samples_decoded_with_celt?: number;
            concealed_samples?: number;
            silent_concealed_samples?: number;
            concealment_events?: number;
            inserted_samples_for_deceleration?: number;
            removed_samples_for_acceleration?: number;
            audio_level?: number;
            total_audio_energy?: number;
            total_samples_duration?: number;
            frames_received?: number;
            decoder_implementation?: string;
            mid?: string;
        }): RTCInboundRtpStreamStats {
            const message = new RTCInboundRtpStreamStats({});
            if (data.ssrc != null) {
                message.ssrc = data.ssrc;
            }
            if (data.kind != null) {
                message.kind = data.kind;
            }
            if (data.transport_id != null) {
                message.transport_id = data.transport_id;
            }
            if (data.codec_id != null) {
                message.codec_id = data.codec_id;
            }
            if (data.packets_received != null) {
                message.packets_received = data.packets_received;
            }
            if (data.packets_lost != null) {
                message.packets_lost = data.packets_lost;
            }
            if (data.jitter != null) {
                message.jitter = data.jitter;
            }
            if (data.packets_discarded != null) {
                message.packets_discarded = data.packets_discarded;
            }
            if (data.packets_repaired != null) {
                message.packets_repaired = data.packets_repaired;
            }
            if (data.burst_packets_lost != null) {
                message.burst_packets_lost = data.burst_packets_lost;
            }
            if (data.burst_packets_discarded != null) {
                message.burst_packets_discarded = data.burst_packets_discarded;
            }
            if (data.burst_loss_count != null) {
                message.burst_loss_count = data.burst_loss_count;
            }
            if (data.burst_discard_count != null) {
                message.burst_discard_count = data.burst_discard_count;
            }
            if (data.burst_loss_rate != null) {
                message.burst_loss_rate = data.burst_loss_rate;
            }
            if (data.burst_discard_rate != null) {
                message.burst_discard_rate = data.burst_discard_rate;
            }
            if (data.gap_loss_rate != null) {
                message.gap_loss_rate = data.gap_loss_rate;
            }
            if (data.gap_discard_rate != null) {
                message.gap_discard_rate = data.gap_discard_rate;
            }
            if (data.frames_dropped != null) {
                message.frames_dropped = data.frames_dropped;
            }
            if (data.partial_frames_lost != null) {
                message.partial_frames_lost = data.partial_frames_lost;
            }
            if (data.full_frames_lost != null) {
                message.full_frames_lost = data.full_frames_lost;
            }
            if (data.receiver_id != null) {
                message.receiver_id = data.receiver_id;
            }
            if (data.remote_id != null) {
                message.remote_id = data.remote_id;
            }
            if (data.frames_decoded != null) {
                message.frames_decoded = data.frames_decoded;
            }
            if (data.key_frames_decoded != null) {
                message.key_frames_decoded = data.key_frames_decoded;
            }
            if (data.frame_width != null) {
                message.frame_width = data.frame_width;
            }
            if (data.frame_height != null) {
                message.frame_height = data.frame_height;
            }
            if (data.frame_bit_depth != null) {
                message.frame_bit_depth = data.frame_bit_depth;
            }
            if (data.frames_per_second != null) {
                message.frames_per_second = data.frames_per_second;
            }
            if (data.qp_sum != null) {
                message.qp_sum = data.qp_sum;
            }
            if (data.total_decode_time != null) {
                message.total_decode_time = data.total_decode_time;
            }
            if (data.total_inter_frame_delay != null) {
                message.total_inter_frame_delay = data.total_inter_frame_delay;
            }
            if (data.total_squared_inter_frame_delay != null) {
                message.total_squared_inter_frame_delay = data.total_squared_inter_frame_delay;
            }
            if (data.voice_activity_flag != null) {
                message.voice_activity_flag = data.voice_activity_flag;
            }
            if (data.last_packet_received_timestamp != null) {
                message.last_packet_received_timestamp = data.last_packet_received_timestamp;
            }
            if (data.average_rtcp_interval != null) {
                message.average_rtcp_interval = data.average_rtcp_interval;
            }
            if (data.header_bytes_received != null) {
                message.header_bytes_received = data.header_bytes_received;
            }
            if (data.fec_packets_received != null) {
                message.fec_packets_received = data.fec_packets_received;
            }
            if (data.fec_packets_discarded != null) {
                message.fec_packets_discarded = data.fec_packets_discarded;
            }
            if (data.bytes_received != null) {
                message.bytes_received = data.bytes_received;
            }
            if (data.packets_failed_decryption != null) {
                message.packets_failed_decryption = data.packets_failed_decryption;
            }
            if (data.packets_duplicated != null) {
                message.packets_duplicated = data.packets_duplicated;
            }
            if (typeof data.per_dscp_packets_received == "object") {
                message.per_dscp_packets_received = new Map(Object.entries(data.per_dscp_packets_received));
            }
            if (data.nack_count != null) {
                message.nack_count = data.nack_count;
            }
            if (data.fir_count != null) {
                message.fir_count = data.fir_count;
            }
            if (data.pli_count != null) {
                message.pli_count = data.pli_count;
            }
            if (data.sli_count != null) {
                message.sli_count = data.sli_count;
            }
            if (data.total_processing_delay != null) {
                message.total_processing_delay = data.total_processing_delay;
            }
            if (data.estimated_playout_timestamp != null) {
                message.estimated_playout_timestamp = data.estimated_playout_timestamp;
            }
            if (data.jitter_buffer_delay != null) {
                message.jitter_buffer_delay = data.jitter_buffer_delay;
            }
            if (data.jitter_buffer_emitted_count != null) {
                message.jitter_buffer_emitted_count = data.jitter_buffer_emitted_count;
            }
            if (data.total_samples_received != null) {
                message.total_samples_received = data.total_samples_received;
            }
            if (data.total_samples_decoded != null) {
                message.total_samples_decoded = data.total_samples_decoded;
            }
            if (data.samples_decoded_with_silk != null) {
                message.samples_decoded_with_silk = data.samples_decoded_with_silk;
            }
            if (data.samples_decoded_with_celt != null) {
                message.samples_decoded_with_celt = data.samples_decoded_with_celt;
            }
            if (data.concealed_samples != null) {
                message.concealed_samples = data.concealed_samples;
            }
            if (data.silent_concealed_samples != null) {
                message.silent_concealed_samples = data.silent_concealed_samples;
            }
            if (data.concealment_events != null) {
                message.concealment_events = data.concealment_events;
            }
            if (data.inserted_samples_for_deceleration != null) {
                message.inserted_samples_for_deceleration = data.inserted_samples_for_deceleration;
            }
            if (data.removed_samples_for_acceleration != null) {
                message.removed_samples_for_acceleration = data.removed_samples_for_acceleration;
            }
            if (data.audio_level != null) {
                message.audio_level = data.audio_level;
            }
            if (data.total_audio_energy != null) {
                message.total_audio_energy = data.total_audio_energy;
            }
            if (data.total_samples_duration != null) {
                message.total_samples_duration = data.total_samples_duration;
            }
            if (data.frames_received != null) {
                message.frames_received = data.frames_received;
            }
            if (data.decoder_implementation != null) {
                message.decoder_implementation = data.decoder_implementation;
            }
            if (data.mid != null) {
                message.mid = data.mid;
            }
            return message;
        }
        toObject() {
            const data: {
                ssrc?: number;
                kind?: RTCMediaKind;
                transport_id?: string;
                codec_id?: string;
                packets_received?: number;
                packets_lost?: number;
                jitter?: number;
                packets_discarded?: number;
                packets_repaired?: number;
                burst_packets_lost?: number;
                burst_packets_discarded?: number;
                burst_loss_count?: number;
                burst_discard_count?: number;
                burst_loss_rate?: number;
                burst_discard_rate?: number;
                gap_loss_rate?: number;
                gap_discard_rate?: number;
                frames_dropped?: number;
                partial_frames_lost?: number;
                full_frames_lost?: number;
                receiver_id?: string;
                remote_id?: string;
                frames_decoded?: number;
                key_frames_decoded?: number;
                frame_width?: number;
                frame_height?: number;
                frame_bit_depth?: number;
                frames_per_second?: number;
                qp_sum?: number;
                total_decode_time?: number;
                total_inter_frame_delay?: number;
                total_squared_inter_frame_delay?: number;
                voice_activity_flag?: boolean;
                last_packet_received_timestamp?: number;
                average_rtcp_interval?: number;
                header_bytes_received?: number;
                fec_packets_received?: number;
                fec_packets_discarded?: number;
                bytes_received?: number;
                packets_failed_decryption?: number;
                packets_duplicated?: number;
                per_dscp_packets_received?: {
                    [key: string]: number;
                };
                nack_count?: number;
                fir_count?: number;
                pli_count?: number;
                sli_count?: number;
                total_processing_delay?: number;
                estimated_playout_timestamp?: number;
                jitter_buffer_delay?: number;
                jitter_buffer_emitted_count?: number;
                total_samples_received?: number;
                total_samples_decoded?: number;
                samples_decoded_with_silk?: number;
                samples_decoded_with_celt?: number;
                concealed_samples?: number;
                silent_concealed_samples?: number;
                concealment_events?: number;
                inserted_samples_for_deceleration?: number;
                removed_samples_for_acceleration?: number;
                audio_level?: number;
                total_audio_energy?: number;
                total_samples_duration?: number;
                frames_received?: number;
                decoder_implementation?: string;
                mid?: string;
            } = {};
            if (this.ssrc != null) {
                data.ssrc = this.ssrc;
            }
            if (this.kind != null) {
                data.kind = this.kind;
            }
            if (this.transport_id != null) {
                data.transport_id = this.transport_id;
            }
            if (this.codec_id != null) {
                data.codec_id = this.codec_id;
            }
            if (this.packets_received != null) {
                data.packets_received = this.packets_received;
            }
            if (this.packets_lost != null) {
                data.packets_lost = this.packets_lost;
            }
            if (this.jitter != null) {
                data.jitter = this.jitter;
            }
            if (this.packets_discarded != null) {
                data.packets_discarded = this.packets_discarded;
            }
            if (this.packets_repaired != null) {
                data.packets_repaired = this.packets_repaired;
            }
            if (this.burst_packets_lost != null) {
                data.burst_packets_lost = this.burst_packets_lost;
            }
            if (this.burst_packets_discarded != null) {
                data.burst_packets_discarded = this.burst_packets_discarded;
            }
            if (this.burst_loss_count != null) {
                data.burst_loss_count = this.burst_loss_count;
            }
            if (this.burst_discard_count != null) {
                data.burst_discard_count = this.burst_discard_count;
            }
            if (this.burst_loss_rate != null) {
                data.burst_loss_rate = this.burst_loss_rate;
            }
            if (this.burst_discard_rate != null) {
                data.burst_discard_rate = this.burst_discard_rate;
            }
            if (this.gap_loss_rate != null) {
                data.gap_loss_rate = this.gap_loss_rate;
            }
            if (this.gap_discard_rate != null) {
                data.gap_discard_rate = this.gap_discard_rate;
            }
            if (this.frames_dropped != null) {
                data.frames_dropped = this.frames_dropped;
            }
            if (this.partial_frames_lost != null) {
                data.partial_frames_lost = this.partial_frames_lost;
            }
            if (this.full_frames_lost != null) {
                data.full_frames_lost = this.full_frames_lost;
            }
            if (this.receiver_id != null) {
                data.receiver_id = this.receiver_id;
            }
            if (this.remote_id != null) {
                data.remote_id = this.remote_id;
            }
            if (this.frames_decoded != null) {
                data.frames_decoded = this.frames_decoded;
            }
            if (this.key_frames_decoded != null) {
                data.key_frames_decoded = this.key_frames_decoded;
            }
            if (this.frame_width != null) {
                data.frame_width = this.frame_width;
            }
            if (this.frame_height != null) {
                data.frame_height = this.frame_height;
            }
            if (this.frame_bit_depth != null) {
                data.frame_bit_depth = this.frame_bit_depth;
            }
            if (this.frames_per_second != null) {
                data.frames_per_second = this.frames_per_second;
            }
            if (this.qp_sum != null) {
                data.qp_sum = this.qp_sum;
            }
            if (this.total_decode_time != null) {
                data.total_decode_time = this.total_decode_time;
            }
            if (this.total_inter_frame_delay != null) {
                data.total_inter_frame_delay = this.total_inter_frame_delay;
            }
            if (this.total_squared_inter_frame_delay != null) {
                data.total_squared_inter_frame_delay = this.total_squared_inter_frame_delay;
            }
            if (this.voice_activity_flag != null) {
                data.voice_activity_flag = this.voice_activity_flag;
            }
            if (this.last_packet_received_timestamp != null) {
                data.last_packet_received_timestamp = this.last_packet_received_timestamp;
            }
            if (this.average_rtcp_interval != null) {
                data.average_rtcp_interval = this.average_rtcp_interval;
            }
            if (this.header_bytes_received != null) {
                data.header_bytes_received = this.header_bytes_received;
            }
            if (this.fec_packets_received != null) {
                data.fec_packets_received = this.fec_packets_received;
            }
            if (this.fec_packets_discarded != null) {
                data.fec_packets_discarded = this.fec_packets_discarded;
            }
            if (this.bytes_received != null) {
                data.bytes_received = this.bytes_received;
            }
            if (this.packets_failed_decryption != null) {
                data.packets_failed_decryption = this.packets_failed_decryption;
            }
            if (this.packets_duplicated != null) {
                data.packets_duplicated = this.packets_duplicated;
            }
            if (this.per_dscp_packets_received != null) {
                data.per_dscp_packets_received = (Object.fromEntries)(this.per_dscp_packets_received);
            }
            if (this.nack_count != null) {
                data.nack_count = this.nack_count;
            }
            if (this.fir_count != null) {
                data.fir_count = this.fir_count;
            }
            if (this.pli_count != null) {
                data.pli_count = this.pli_count;
            }
            if (this.sli_count != null) {
                data.sli_count = this.sli_count;
            }
            if (this.total_processing_delay != null) {
                data.total_processing_delay = this.total_processing_delay;
            }
            if (this.estimated_playout_timestamp != null) {
                data.estimated_playout_timestamp = this.estimated_playout_timestamp;
            }
            if (this.jitter_buffer_delay != null) {
                data.jitter_buffer_delay = this.jitter_buffer_delay;
            }
            if (this.jitter_buffer_emitted_count != null) {
                data.jitter_buffer_emitted_count = this.jitter_buffer_emitted_count;
            }
            if (this.total_samples_received != null) {
                data.total_samples_received = this.total_samples_received;
            }
            if (this.total_samples_decoded != null) {
                data.total_samples_decoded = this.total_samples_decoded;
            }
            if (this.samples_decoded_with_silk != null) {
                data.samples_decoded_with_silk = this.samples_decoded_with_silk;
            }
            if (this.samples_decoded_with_celt != null) {
                data.samples_decoded_with_celt = this.samples_decoded_with_celt;
            }
            if (this.concealed_samples != null) {
                data.concealed_samples = this.concealed_samples;
            }
            if (this.silent_concealed_samples != null) {
                data.silent_concealed_samples = this.silent_concealed_samples;
            }
            if (this.concealment_events != null) {
                data.concealment_events = this.concealment_events;
            }
            if (this.inserted_samples_for_deceleration != null) {
                data.inserted_samples_for_deceleration = this.inserted_samples_for_deceleration;
            }
            if (this.removed_samples_for_acceleration != null) {
                data.removed_samples_for_acceleration = this.removed_samples_for_acceleration;
            }
            if (this.audio_level != null) {
                data.audio_level = this.audio_level;
            }
            if (this.total_audio_energy != null) {
                data.total_audio_energy = this.total_audio_energy;
            }
            if (this.total_samples_duration != null) {
                data.total_samples_duration = this.total_samples_duration;
            }
            if (this.frames_received != null) {
                data.frames_received = this.frames_received;
            }
            if (this.decoder_implementation != null) {
                data.decoder_implementation = this.decoder_implementation;
            }
            if (this.mid != null) {
                data.mid = this.mid;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.ssrc != 0)
                writer.writeFixed32(1, this.ssrc);
            if (this.kind != RTCMediaKind.Audio)
                writer.writeEnum(2, this.kind);
            if (this.has_transport_id)
                writer.writeString(3, this.transport_id);
            if (this.has_codec_id)
                writer.writeString(4, this.codec_id);
            if (this.has_packets_received)
                writer.writeFixed64(5, this.packets_received);
            if (this.has_packets_lost)
                writer.writeSfixed64(6, this.packets_lost);
            if (this.has_jitter)
                writer.writeFloat(7, this.jitter);
            if (this.has_packets_discarded)
                writer.writeFixed64(8, this.packets_discarded);
            if (this.has_packets_repaired)
                writer.writeFixed64(9, this.packets_repaired);
            if (this.has_burst_packets_lost)
                writer.writeFixed64(10, this.burst_packets_lost);
            if (this.has_burst_packets_discarded)
                writer.writeFixed64(11, this.burst_packets_discarded);
            if (this.has_burst_loss_count)
                writer.writeFixed32(12, this.burst_loss_count);
            if (this.has_burst_discard_count)
                writer.writeFixed32(13, this.burst_discard_count);
            if (this.has_burst_loss_rate)
                writer.writeFloat(14, this.burst_loss_rate);
            if (this.has_burst_discard_rate)
                writer.writeFloat(15, this.burst_discard_rate);
            if (this.has_gap_loss_rate)
                writer.writeFloat(16, this.gap_loss_rate);
            if (this.has_gap_discard_rate)
                writer.writeFloat(17, this.gap_discard_rate);
            if (this.has_frames_dropped)
                writer.writeFixed32(18, this.frames_dropped);
            if (this.has_partial_frames_lost)
                writer.writeFixed32(19, this.partial_frames_lost);
            if (this.has_full_frames_lost)
                writer.writeFixed32(20, this.full_frames_lost);
            if (this.receiver_id.length)
                writer.writeString(21, this.receiver_id);
            if (this.has_remote_id)
                writer.writeString(22, this.remote_id);
            if (this.has_frames_decoded)
                writer.writeFixed32(23, this.frames_decoded);
            if (this.has_key_frames_decoded)
                writer.writeFixed32(24, this.key_frames_decoded);
            if (this.has_frame_width)
                writer.writeFixed32(25, this.frame_width);
            if (this.has_frame_height)
                writer.writeFixed32(26, this.frame_height);
            if (this.has_frame_bit_depth)
                writer.writeFixed32(27, this.frame_bit_depth);
            if (this.has_frames_per_second)
                writer.writeFloat(28, this.frames_per_second);
            if (this.has_qp_sum)
                writer.writeFixed64(29, this.qp_sum);
            if (this.has_total_decode_time)
                writer.writeFloat(30, this.total_decode_time);
            if (this.has_total_inter_frame_delay)
                writer.writeFloat(31, this.total_inter_frame_delay);
            if (this.has_total_squared_inter_frame_delay)
                writer.writeFloat(32, this.total_squared_inter_frame_delay);
            if (this.has_voice_activity_flag)
                writer.writeBool(33, this.voice_activity_flag);
            if (this.has_last_packet_received_timestamp)
                writer.writeDouble(34, this.last_packet_received_timestamp);
            if (this.has_average_rtcp_interval)
                writer.writeFloat(35, this.average_rtcp_interval);
            if (this.has_header_bytes_received)
                writer.writeFixed64(36, this.header_bytes_received);
            if (this.has_fec_packets_received)
                writer.writeFixed64(37, this.fec_packets_received);
            if (this.has_fec_packets_discarded)
                writer.writeFixed64(38, this.fec_packets_discarded);
            if (this.has_bytes_received)
                writer.writeFixed64(39, this.bytes_received);
            if (this.has_packets_failed_decryption)
                writer.writeFixed64(40, this.packets_failed_decryption);
            if (this.has_packets_duplicated)
                writer.writeFixed64(41, this.packets_duplicated);
            for (const [key, value] of this.per_dscp_packets_received) {
                writer.writeMessage(42, this.per_dscp_packets_received, () => {
                    writer.writeString(1, key);
                    writer.writeFixed64(2, value);
                });
            }
            if (this.has_nack_count)
                writer.writeFixed32(43, this.nack_count);
            if (this.has_fir_count)
                writer.writeFixed32(44, this.fir_count);
            if (this.has_pli_count)
                writer.writeFixed32(45, this.pli_count);
            if (this.has_sli_count)
                writer.writeFixed32(46, this.sli_count);
            if (this.has_total_processing_delay)
                writer.writeFloat(47, this.total_processing_delay);
            if (this.has_estimated_playout_timestamp)
                writer.writeDouble(48, this.estimated_playout_timestamp);
            if (this.has_jitter_buffer_delay)
                writer.writeFloat(49, this.jitter_buffer_delay);
            if (this.has_jitter_buffer_emitted_count)
                writer.writeFixed64(50, this.jitter_buffer_emitted_count);
            if (this.has_total_samples_received)
                writer.writeFixed64(51, this.total_samples_received);
            if (this.has_total_samples_decoded)
                writer.writeFixed64(52, this.total_samples_decoded);
            if (this.has_samples_decoded_with_silk)
                writer.writeFixed64(53, this.samples_decoded_with_silk);
            if (this.has_samples_decoded_with_celt)
                writer.writeFixed64(54, this.samples_decoded_with_celt);
            if (this.has_concealed_samples)
                writer.writeFixed64(55, this.concealed_samples);
            if (this.has_silent_concealed_samples)
                writer.writeFixed64(56, this.silent_concealed_samples);
            if (this.has_concealment_events)
                writer.writeFixed64(57, this.concealment_events);
            if (this.has_inserted_samples_for_deceleration)
                writer.writeFixed64(58, this.inserted_samples_for_deceleration);
            if (this.has_removed_samples_for_acceleration)
                writer.writeFixed64(59, this.removed_samples_for_acceleration);
            if (this.has_audio_level)
                writer.writeFloat(60, this.audio_level);
            if (this.has_total_audio_energy)
                writer.writeFloat(61, this.total_audio_energy);
            if (this.has_total_samples_duration)
                writer.writeFloat(62, this.total_samples_duration);
            if (this.has_frames_received)
                writer.writeFixed32(63, this.frames_received);
            if (this.has_decoder_implementation)
                writer.writeString(64, this.decoder_implementation);
            if (this.has_mid)
                writer.writeString(65, this.mid);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCInboundRtpStreamStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCInboundRtpStreamStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.ssrc = reader.readFixed32();
                        break;
                    case 2:
                        message.kind = reader.readEnum();
                        break;
                    case 3:
                        message.transport_id = reader.readString();
                        break;
                    case 4:
                        message.codec_id = reader.readString();
                        break;
                    case 5:
                        message.packets_received = reader.readFixed64();
                        break;
                    case 6:
                        message.packets_lost = reader.readSfixed64();
                        break;
                    case 7:
                        message.jitter = reader.readFloat();
                        break;
                    case 8:
                        message.packets_discarded = reader.readFixed64();
                        break;
                    case 9:
                        message.packets_repaired = reader.readFixed64();
                        break;
                    case 10:
                        message.burst_packets_lost = reader.readFixed64();
                        break;
                    case 11:
                        message.burst_packets_discarded = reader.readFixed64();
                        break;
                    case 12:
                        message.burst_loss_count = reader.readFixed32();
                        break;
                    case 13:
                        message.burst_discard_count = reader.readFixed32();
                        break;
                    case 14:
                        message.burst_loss_rate = reader.readFloat();
                        break;
                    case 15:
                        message.burst_discard_rate = reader.readFloat();
                        break;
                    case 16:
                        message.gap_loss_rate = reader.readFloat();
                        break;
                    case 17:
                        message.gap_discard_rate = reader.readFloat();
                        break;
                    case 18:
                        message.frames_dropped = reader.readFixed32();
                        break;
                    case 19:
                        message.partial_frames_lost = reader.readFixed32();
                        break;
                    case 20:
                        message.full_frames_lost = reader.readFixed32();
                        break;
                    case 21:
                        message.receiver_id = reader.readString();
                        break;
                    case 22:
                        message.remote_id = reader.readString();
                        break;
                    case 23:
                        message.frames_decoded = reader.readFixed32();
                        break;
                    case 24:
                        message.key_frames_decoded = reader.readFixed32();
                        break;
                    case 25:
                        message.frame_width = reader.readFixed32();
                        break;
                    case 26:
                        message.frame_height = reader.readFixed32();
                        break;
                    case 27:
                        message.frame_bit_depth = reader.readFixed32();
                        break;
                    case 28:
                        message.frames_per_second = reader.readFloat();
                        break;
                    case 29:
                        message.qp_sum = reader.readFixed64();
                        break;
                    case 30:
                        message.total_decode_time = reader.readFloat();
                        break;
                    case 31:
                        message.total_inter_frame_delay = reader.readFloat();
                        break;
                    case 32:
                        message.total_squared_inter_frame_delay = reader.readFloat();
                        break;
                    case 33:
                        message.voice_activity_flag = reader.readBool();
                        break;
                    case 34:
                        message.last_packet_received_timestamp = reader.readDouble();
                        break;
                    case 35:
                        message.average_rtcp_interval = reader.readFloat();
                        break;
                    case 36:
                        message.header_bytes_received = reader.readFixed64();
                        break;
                    case 37:
                        message.fec_packets_received = reader.readFixed64();
                        break;
                    case 38:
                        message.fec_packets_discarded = reader.readFixed64();
                        break;
                    case 39:
                        message.bytes_received = reader.readFixed64();
                        break;
                    case 40:
                        message.packets_failed_decryption = reader.readFixed64();
                        break;
                    case 41:
                        message.packets_duplicated = reader.readFixed64();
                        break;
                    case 42:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.per_dscp_packets_received as any, reader, reader.readString, reader.readFixed64));
                        break;
                    case 43:
                        message.nack_count = reader.readFixed32();
                        break;
                    case 44:
                        message.fir_count = reader.readFixed32();
                        break;
                    case 45:
                        message.pli_count = reader.readFixed32();
                        break;
                    case 46:
                        message.sli_count = reader.readFixed32();
                        break;
                    case 47:
                        message.total_processing_delay = reader.readFloat();
                        break;
                    case 48:
                        message.estimated_playout_timestamp = reader.readDouble();
                        break;
                    case 49:
                        message.jitter_buffer_delay = reader.readFloat();
                        break;
                    case 50:
                        message.jitter_buffer_emitted_count = reader.readFixed64();
                        break;
                    case 51:
                        message.total_samples_received = reader.readFixed64();
                        break;
                    case 52:
                        message.total_samples_decoded = reader.readFixed64();
                        break;
                    case 53:
                        message.samples_decoded_with_silk = reader.readFixed64();
                        break;
                    case 54:
                        message.samples_decoded_with_celt = reader.readFixed64();
                        break;
                    case 55:
                        message.concealed_samples = reader.readFixed64();
                        break;
                    case 56:
                        message.silent_concealed_samples = reader.readFixed64();
                        break;
                    case 57:
                        message.concealment_events = reader.readFixed64();
                        break;
                    case 58:
                        message.inserted_samples_for_deceleration = reader.readFixed64();
                        break;
                    case 59:
                        message.removed_samples_for_acceleration = reader.readFixed64();
                        break;
                    case 60:
                        message.audio_level = reader.readFloat();
                        break;
                    case 61:
                        message.total_audio_energy = reader.readFloat();
                        break;
                    case 62:
                        message.total_samples_duration = reader.readFloat();
                        break;
                    case 63:
                        message.frames_received = reader.readFixed32();
                        break;
                    case 64:
                        message.decoder_implementation = reader.readString();
                        break;
                    case 65:
                        message.mid = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCInboundRtpStreamStats {
            return RTCInboundRtpStreamStats.deserialize(bytes);
        }
    }
    export class RTCRemoteInboundRtpStreamStats extends pb_1.Message {
        #one_of_decls: number[][] = [[3], [4], [5], [6], [7], [8], [9], [10], [11], [12], [13], [14], [15], [16], [17], [18], [19], [20], [21], [22], [23], [24], [25], [26]];
        constructor(data?: any[] | ({
            ssrc?: number;
            kind?: RTCMediaKind;
        } & (({
            transport_id?: string;
        }) | ({
            codec_id?: string;
        }) | ({
            packets_received?: number;
        }) | ({
            packets_lost?: number;
        }) | ({
            jitter?: number;
        }) | ({
            packets_discarded?: number;
        }) | ({
            packets_repaired?: number;
        }) | ({
            burst_packets_lost?: number;
        }) | ({
            burst_packets_discarded?: number;
        }) | ({
            burst_loss_count?: number;
        }) | ({
            burst_discard_count?: number;
        }) | ({
            burst_loss_rate?: number;
        }) | ({
            burst_discard_rate?: number;
        }) | ({
            gap_loss_rate?: number;
        }) | ({
            gap_discard_rate?: number;
        }) | ({
            frames_dropped?: number;
        }) | ({
            partial_frames_lost?: number;
        }) | ({
            full_frames_lost?: number;
        }) | ({
            local_id?: string;
        }) | ({
            round_trip_time?: number;
        }) | ({
            total_round_trip_time?: number;
        }) | ({
            fraction_lost?: number;
        }) | ({
            reports_received?: number;
        }) | ({
            round_trip_time_measurements?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("ssrc" in data && data.ssrc != undefined) {
                    this.ssrc = data.ssrc;
                }
                if ("kind" in data && data.kind != undefined) {
                    this.kind = data.kind;
                }
                if ("transport_id" in data && data.transport_id != undefined) {
                    this.transport_id = data.transport_id;
                }
                if ("codec_id" in data && data.codec_id != undefined) {
                    this.codec_id = data.codec_id;
                }
                if ("packets_received" in data && data.packets_received != undefined) {
                    this.packets_received = data.packets_received;
                }
                if ("packets_lost" in data && data.packets_lost != undefined) {
                    this.packets_lost = data.packets_lost;
                }
                if ("jitter" in data && data.jitter != undefined) {
                    this.jitter = data.jitter;
                }
                if ("packets_discarded" in data && data.packets_discarded != undefined) {
                    this.packets_discarded = data.packets_discarded;
                }
                if ("packets_repaired" in data && data.packets_repaired != undefined) {
                    this.packets_repaired = data.packets_repaired;
                }
                if ("burst_packets_lost" in data && data.burst_packets_lost != undefined) {
                    this.burst_packets_lost = data.burst_packets_lost;
                }
                if ("burst_packets_discarded" in data && data.burst_packets_discarded != undefined) {
                    this.burst_packets_discarded = data.burst_packets_discarded;
                }
                if ("burst_loss_count" in data && data.burst_loss_count != undefined) {
                    this.burst_loss_count = data.burst_loss_count;
                }
                if ("burst_discard_count" in data && data.burst_discard_count != undefined) {
                    this.burst_discard_count = data.burst_discard_count;
                }
                if ("burst_loss_rate" in data && data.burst_loss_rate != undefined) {
                    this.burst_loss_rate = data.burst_loss_rate;
                }
                if ("burst_discard_rate" in data && data.burst_discard_rate != undefined) {
                    this.burst_discard_rate = data.burst_discard_rate;
                }
                if ("gap_loss_rate" in data && data.gap_loss_rate != undefined) {
                    this.gap_loss_rate = data.gap_loss_rate;
                }
                if ("gap_discard_rate" in data && data.gap_discard_rate != undefined) {
                    this.gap_discard_rate = data.gap_discard_rate;
                }
                if ("frames_dropped" in data && data.frames_dropped != undefined) {
                    this.frames_dropped = data.frames_dropped;
                }
                if ("partial_frames_lost" in data && data.partial_frames_lost != undefined) {
                    this.partial_frames_lost = data.partial_frames_lost;
                }
                if ("full_frames_lost" in data && data.full_frames_lost != undefined) {
                    this.full_frames_lost = data.full_frames_lost;
                }
                if ("local_id" in data && data.local_id != undefined) {
                    this.local_id = data.local_id;
                }
                if ("round_trip_time" in data && data.round_trip_time != undefined) {
                    this.round_trip_time = data.round_trip_time;
                }
                if ("total_round_trip_time" in data && data.total_round_trip_time != undefined) {
                    this.total_round_trip_time = data.total_round_trip_time;
                }
                if ("fraction_lost" in data && data.fraction_lost != undefined) {
                    this.fraction_lost = data.fraction_lost;
                }
                if ("reports_received" in data && data.reports_received != undefined) {
                    this.reports_received = data.reports_received;
                }
                if ("round_trip_time_measurements" in data && data.round_trip_time_measurements != undefined) {
                    this.round_trip_time_measurements = data.round_trip_time_measurements;
                }
            }
        }
        get ssrc() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set ssrc(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get kind() {
            return pb_1.Message.getFieldWithDefault(this, 2, RTCMediaKind.Audio) as RTCMediaKind;
        }
        set kind(value: RTCMediaKind) {
            pb_1.Message.setField(this, 2, value);
        }
        get transport_id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set transport_id(value: string) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0], value);
        }
        get has_transport_id() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get codec_id() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set codec_id(value: string) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[1], value);
        }
        get has_codec_id() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get packets_received() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set packets_received(value: number) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[2], value);
        }
        get has_packets_received() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get packets_lost() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set packets_lost(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[3], value);
        }
        get has_packets_lost() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get jitter() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set jitter(value: number) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[4], value);
        }
        get has_jitter() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get packets_discarded() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set packets_discarded(value: number) {
            pb_1.Message.setOneofField(this, 8, this.#one_of_decls[5], value);
        }
        get has_packets_discarded() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get packets_repaired() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set packets_repaired(value: number) {
            pb_1.Message.setOneofField(this, 9, this.#one_of_decls[6], value);
        }
        get has_packets_repaired() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get burst_packets_lost() {
            return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
        }
        set burst_packets_lost(value: number) {
            pb_1.Message.setOneofField(this, 10, this.#one_of_decls[7], value);
        }
        get has_burst_packets_lost() {
            return pb_1.Message.getField(this, 10) != null;
        }
        get burst_packets_discarded() {
            return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
        }
        set burst_packets_discarded(value: number) {
            pb_1.Message.setOneofField(this, 11, this.#one_of_decls[8], value);
        }
        get has_burst_packets_discarded() {
            return pb_1.Message.getField(this, 11) != null;
        }
        get burst_loss_count() {
            return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
        }
        set burst_loss_count(value: number) {
            pb_1.Message.setOneofField(this, 12, this.#one_of_decls[9], value);
        }
        get has_burst_loss_count() {
            return pb_1.Message.getField(this, 12) != null;
        }
        get burst_discard_count() {
            return pb_1.Message.getFieldWithDefault(this, 13, 0) as number;
        }
        set burst_discard_count(value: number) {
            pb_1.Message.setOneofField(this, 13, this.#one_of_decls[10], value);
        }
        get has_burst_discard_count() {
            return pb_1.Message.getField(this, 13) != null;
        }
        get burst_loss_rate() {
            return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
        }
        set burst_loss_rate(value: number) {
            pb_1.Message.setOneofField(this, 14, this.#one_of_decls[11], value);
        }
        get has_burst_loss_rate() {
            return pb_1.Message.getField(this, 14) != null;
        }
        get burst_discard_rate() {
            return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
        }
        set burst_discard_rate(value: number) {
            pb_1.Message.setOneofField(this, 15, this.#one_of_decls[12], value);
        }
        get has_burst_discard_rate() {
            return pb_1.Message.getField(this, 15) != null;
        }
        get gap_loss_rate() {
            return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
        }
        set gap_loss_rate(value: number) {
            pb_1.Message.setOneofField(this, 16, this.#one_of_decls[13], value);
        }
        get has_gap_loss_rate() {
            return pb_1.Message.getField(this, 16) != null;
        }
        get gap_discard_rate() {
            return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
        }
        set gap_discard_rate(value: number) {
            pb_1.Message.setOneofField(this, 17, this.#one_of_decls[14], value);
        }
        get has_gap_discard_rate() {
            return pb_1.Message.getField(this, 17) != null;
        }
        get frames_dropped() {
            return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
        }
        set frames_dropped(value: number) {
            pb_1.Message.setOneofField(this, 18, this.#one_of_decls[15], value);
        }
        get has_frames_dropped() {
            return pb_1.Message.getField(this, 18) != null;
        }
        get partial_frames_lost() {
            return pb_1.Message.getFieldWithDefault(this, 19, 0) as number;
        }
        set partial_frames_lost(value: number) {
            pb_1.Message.setOneofField(this, 19, this.#one_of_decls[16], value);
        }
        get has_partial_frames_lost() {
            return pb_1.Message.getField(this, 19) != null;
        }
        get full_frames_lost() {
            return pb_1.Message.getFieldWithDefault(this, 20, 0) as number;
        }
        set full_frames_lost(value: number) {
            pb_1.Message.setOneofField(this, 20, this.#one_of_decls[17], value);
        }
        get has_full_frames_lost() {
            return pb_1.Message.getField(this, 20) != null;
        }
        get local_id() {
            return pb_1.Message.getFieldWithDefault(this, 21, "") as string;
        }
        set local_id(value: string) {
            pb_1.Message.setOneofField(this, 21, this.#one_of_decls[18], value);
        }
        get has_local_id() {
            return pb_1.Message.getField(this, 21) != null;
        }
        get round_trip_time() {
            return pb_1.Message.getFieldWithDefault(this, 22, 0) as number;
        }
        set round_trip_time(value: number) {
            pb_1.Message.setOneofField(this, 22, this.#one_of_decls[19], value);
        }
        get has_round_trip_time() {
            return pb_1.Message.getField(this, 22) != null;
        }
        get total_round_trip_time() {
            return pb_1.Message.getFieldWithDefault(this, 23, 0) as number;
        }
        set total_round_trip_time(value: number) {
            pb_1.Message.setOneofField(this, 23, this.#one_of_decls[20], value);
        }
        get has_total_round_trip_time() {
            return pb_1.Message.getField(this, 23) != null;
        }
        get fraction_lost() {
            return pb_1.Message.getFieldWithDefault(this, 24, 0) as number;
        }
        set fraction_lost(value: number) {
            pb_1.Message.setOneofField(this, 24, this.#one_of_decls[21], value);
        }
        get has_fraction_lost() {
            return pb_1.Message.getField(this, 24) != null;
        }
        get reports_received() {
            return pb_1.Message.getFieldWithDefault(this, 25, 0) as number;
        }
        set reports_received(value: number) {
            pb_1.Message.setOneofField(this, 25, this.#one_of_decls[22], value);
        }
        get has_reports_received() {
            return pb_1.Message.getField(this, 25) != null;
        }
        get round_trip_time_measurements() {
            return pb_1.Message.getFieldWithDefault(this, 26, 0) as number;
        }
        set round_trip_time_measurements(value: number) {
            pb_1.Message.setOneofField(this, 26, this.#one_of_decls[23], value);
        }
        get has_round_trip_time_measurements() {
            return pb_1.Message.getField(this, 26) != null;
        }
        get _transport_id() {
            const cases: {
                [index: number]: "none" | "transport_id";
            } = {
                0: "none",
                3: "transport_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _codec_id() {
            const cases: {
                [index: number]: "none" | "codec_id";
            } = {
                0: "none",
                4: "codec_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        get _packets_received() {
            const cases: {
                [index: number]: "none" | "packets_received";
            } = {
                0: "none",
                5: "packets_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _packets_lost() {
            const cases: {
                [index: number]: "none" | "packets_lost";
            } = {
                0: "none",
                6: "packets_lost"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _jitter() {
            const cases: {
                [index: number]: "none" | "jitter";
            } = {
                0: "none",
                7: "jitter"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        get _packets_discarded() {
            const cases: {
                [index: number]: "none" | "packets_discarded";
            } = {
                0: "none",
                8: "packets_discarded"
            };
            return cases[pb_1.Message.computeOneofCase(this, [8])];
        }
        get _packets_repaired() {
            const cases: {
                [index: number]: "none" | "packets_repaired";
            } = {
                0: "none",
                9: "packets_repaired"
            };
            return cases[pb_1.Message.computeOneofCase(this, [9])];
        }
        get _burst_packets_lost() {
            const cases: {
                [index: number]: "none" | "burst_packets_lost";
            } = {
                0: "none",
                10: "burst_packets_lost"
            };
            return cases[pb_1.Message.computeOneofCase(this, [10])];
        }
        get _burst_packets_discarded() {
            const cases: {
                [index: number]: "none" | "burst_packets_discarded";
            } = {
                0: "none",
                11: "burst_packets_discarded"
            };
            return cases[pb_1.Message.computeOneofCase(this, [11])];
        }
        get _burst_loss_count() {
            const cases: {
                [index: number]: "none" | "burst_loss_count";
            } = {
                0: "none",
                12: "burst_loss_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [12])];
        }
        get _burst_discard_count() {
            const cases: {
                [index: number]: "none" | "burst_discard_count";
            } = {
                0: "none",
                13: "burst_discard_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [13])];
        }
        get _burst_loss_rate() {
            const cases: {
                [index: number]: "none" | "burst_loss_rate";
            } = {
                0: "none",
                14: "burst_loss_rate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [14])];
        }
        get _burst_discard_rate() {
            const cases: {
                [index: number]: "none" | "burst_discard_rate";
            } = {
                0: "none",
                15: "burst_discard_rate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [15])];
        }
        get _gap_loss_rate() {
            const cases: {
                [index: number]: "none" | "gap_loss_rate";
            } = {
                0: "none",
                16: "gap_loss_rate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [16])];
        }
        get _gap_discard_rate() {
            const cases: {
                [index: number]: "none" | "gap_discard_rate";
            } = {
                0: "none",
                17: "gap_discard_rate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [17])];
        }
        get _frames_dropped() {
            const cases: {
                [index: number]: "none" | "frames_dropped";
            } = {
                0: "none",
                18: "frames_dropped"
            };
            return cases[pb_1.Message.computeOneofCase(this, [18])];
        }
        get _partial_frames_lost() {
            const cases: {
                [index: number]: "none" | "partial_frames_lost";
            } = {
                0: "none",
                19: "partial_frames_lost"
            };
            return cases[pb_1.Message.computeOneofCase(this, [19])];
        }
        get _full_frames_lost() {
            const cases: {
                [index: number]: "none" | "full_frames_lost";
            } = {
                0: "none",
                20: "full_frames_lost"
            };
            return cases[pb_1.Message.computeOneofCase(this, [20])];
        }
        get _local_id() {
            const cases: {
                [index: number]: "none" | "local_id";
            } = {
                0: "none",
                21: "local_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [21])];
        }
        get _round_trip_time() {
            const cases: {
                [index: number]: "none" | "round_trip_time";
            } = {
                0: "none",
                22: "round_trip_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [22])];
        }
        get _total_round_trip_time() {
            const cases: {
                [index: number]: "none" | "total_round_trip_time";
            } = {
                0: "none",
                23: "total_round_trip_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [23])];
        }
        get _fraction_lost() {
            const cases: {
                [index: number]: "none" | "fraction_lost";
            } = {
                0: "none",
                24: "fraction_lost"
            };
            return cases[pb_1.Message.computeOneofCase(this, [24])];
        }
        get _reports_received() {
            const cases: {
                [index: number]: "none" | "reports_received";
            } = {
                0: "none",
                25: "reports_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [25])];
        }
        get _round_trip_time_measurements() {
            const cases: {
                [index: number]: "none" | "round_trip_time_measurements";
            } = {
                0: "none",
                26: "round_trip_time_measurements"
            };
            return cases[pb_1.Message.computeOneofCase(this, [26])];
        }
        static fromObject(data: {
            ssrc?: number;
            kind?: RTCMediaKind;
            transport_id?: string;
            codec_id?: string;
            packets_received?: number;
            packets_lost?: number;
            jitter?: number;
            packets_discarded?: number;
            packets_repaired?: number;
            burst_packets_lost?: number;
            burst_packets_discarded?: number;
            burst_loss_count?: number;
            burst_discard_count?: number;
            burst_loss_rate?: number;
            burst_discard_rate?: number;
            gap_loss_rate?: number;
            gap_discard_rate?: number;
            frames_dropped?: number;
            partial_frames_lost?: number;
            full_frames_lost?: number;
            local_id?: string;
            round_trip_time?: number;
            total_round_trip_time?: number;
            fraction_lost?: number;
            reports_received?: number;
            round_trip_time_measurements?: number;
        }): RTCRemoteInboundRtpStreamStats {
            const message = new RTCRemoteInboundRtpStreamStats({});
            if (data.ssrc != null) {
                message.ssrc = data.ssrc;
            }
            if (data.kind != null) {
                message.kind = data.kind;
            }
            if (data.transport_id != null) {
                message.transport_id = data.transport_id;
            }
            if (data.codec_id != null) {
                message.codec_id = data.codec_id;
            }
            if (data.packets_received != null) {
                message.packets_received = data.packets_received;
            }
            if (data.packets_lost != null) {
                message.packets_lost = data.packets_lost;
            }
            if (data.jitter != null) {
                message.jitter = data.jitter;
            }
            if (data.packets_discarded != null) {
                message.packets_discarded = data.packets_discarded;
            }
            if (data.packets_repaired != null) {
                message.packets_repaired = data.packets_repaired;
            }
            if (data.burst_packets_lost != null) {
                message.burst_packets_lost = data.burst_packets_lost;
            }
            if (data.burst_packets_discarded != null) {
                message.burst_packets_discarded = data.burst_packets_discarded;
            }
            if (data.burst_loss_count != null) {
                message.burst_loss_count = data.burst_loss_count;
            }
            if (data.burst_discard_count != null) {
                message.burst_discard_count = data.burst_discard_count;
            }
            if (data.burst_loss_rate != null) {
                message.burst_loss_rate = data.burst_loss_rate;
            }
            if (data.burst_discard_rate != null) {
                message.burst_discard_rate = data.burst_discard_rate;
            }
            if (data.gap_loss_rate != null) {
                message.gap_loss_rate = data.gap_loss_rate;
            }
            if (data.gap_discard_rate != null) {
                message.gap_discard_rate = data.gap_discard_rate;
            }
            if (data.frames_dropped != null) {
                message.frames_dropped = data.frames_dropped;
            }
            if (data.partial_frames_lost != null) {
                message.partial_frames_lost = data.partial_frames_lost;
            }
            if (data.full_frames_lost != null) {
                message.full_frames_lost = data.full_frames_lost;
            }
            if (data.local_id != null) {
                message.local_id = data.local_id;
            }
            if (data.round_trip_time != null) {
                message.round_trip_time = data.round_trip_time;
            }
            if (data.total_round_trip_time != null) {
                message.total_round_trip_time = data.total_round_trip_time;
            }
            if (data.fraction_lost != null) {
                message.fraction_lost = data.fraction_lost;
            }
            if (data.reports_received != null) {
                message.reports_received = data.reports_received;
            }
            if (data.round_trip_time_measurements != null) {
                message.round_trip_time_measurements = data.round_trip_time_measurements;
            }
            return message;
        }
        toObject() {
            const data: {
                ssrc?: number;
                kind?: RTCMediaKind;
                transport_id?: string;
                codec_id?: string;
                packets_received?: number;
                packets_lost?: number;
                jitter?: number;
                packets_discarded?: number;
                packets_repaired?: number;
                burst_packets_lost?: number;
                burst_packets_discarded?: number;
                burst_loss_count?: number;
                burst_discard_count?: number;
                burst_loss_rate?: number;
                burst_discard_rate?: number;
                gap_loss_rate?: number;
                gap_discard_rate?: number;
                frames_dropped?: number;
                partial_frames_lost?: number;
                full_frames_lost?: number;
                local_id?: string;
                round_trip_time?: number;
                total_round_trip_time?: number;
                fraction_lost?: number;
                reports_received?: number;
                round_trip_time_measurements?: number;
            } = {};
            if (this.ssrc != null) {
                data.ssrc = this.ssrc;
            }
            if (this.kind != null) {
                data.kind = this.kind;
            }
            if (this.transport_id != null) {
                data.transport_id = this.transport_id;
            }
            if (this.codec_id != null) {
                data.codec_id = this.codec_id;
            }
            if (this.packets_received != null) {
                data.packets_received = this.packets_received;
            }
            if (this.packets_lost != null) {
                data.packets_lost = this.packets_lost;
            }
            if (this.jitter != null) {
                data.jitter = this.jitter;
            }
            if (this.packets_discarded != null) {
                data.packets_discarded = this.packets_discarded;
            }
            if (this.packets_repaired != null) {
                data.packets_repaired = this.packets_repaired;
            }
            if (this.burst_packets_lost != null) {
                data.burst_packets_lost = this.burst_packets_lost;
            }
            if (this.burst_packets_discarded != null) {
                data.burst_packets_discarded = this.burst_packets_discarded;
            }
            if (this.burst_loss_count != null) {
                data.burst_loss_count = this.burst_loss_count;
            }
            if (this.burst_discard_count != null) {
                data.burst_discard_count = this.burst_discard_count;
            }
            if (this.burst_loss_rate != null) {
                data.burst_loss_rate = this.burst_loss_rate;
            }
            if (this.burst_discard_rate != null) {
                data.burst_discard_rate = this.burst_discard_rate;
            }
            if (this.gap_loss_rate != null) {
                data.gap_loss_rate = this.gap_loss_rate;
            }
            if (this.gap_discard_rate != null) {
                data.gap_discard_rate = this.gap_discard_rate;
            }
            if (this.frames_dropped != null) {
                data.frames_dropped = this.frames_dropped;
            }
            if (this.partial_frames_lost != null) {
                data.partial_frames_lost = this.partial_frames_lost;
            }
            if (this.full_frames_lost != null) {
                data.full_frames_lost = this.full_frames_lost;
            }
            if (this.local_id != null) {
                data.local_id = this.local_id;
            }
            if (this.round_trip_time != null) {
                data.round_trip_time = this.round_trip_time;
            }
            if (this.total_round_trip_time != null) {
                data.total_round_trip_time = this.total_round_trip_time;
            }
            if (this.fraction_lost != null) {
                data.fraction_lost = this.fraction_lost;
            }
            if (this.reports_received != null) {
                data.reports_received = this.reports_received;
            }
            if (this.round_trip_time_measurements != null) {
                data.round_trip_time_measurements = this.round_trip_time_measurements;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.ssrc != 0)
                writer.writeFixed32(1, this.ssrc);
            if (this.kind != RTCMediaKind.Audio)
                writer.writeEnum(2, this.kind);
            if (this.has_transport_id)
                writer.writeString(3, this.transport_id);
            if (this.has_codec_id)
                writer.writeString(4, this.codec_id);
            if (this.has_packets_received)
                writer.writeFixed64(5, this.packets_received);
            if (this.has_packets_lost)
                writer.writeSfixed64(6, this.packets_lost);
            if (this.has_jitter)
                writer.writeFloat(7, this.jitter);
            if (this.has_packets_discarded)
                writer.writeFixed64(8, this.packets_discarded);
            if (this.has_packets_repaired)
                writer.writeFixed64(9, this.packets_repaired);
            if (this.has_burst_packets_lost)
                writer.writeFixed64(10, this.burst_packets_lost);
            if (this.has_burst_packets_discarded)
                writer.writeFixed64(11, this.burst_packets_discarded);
            if (this.has_burst_loss_count)
                writer.writeFixed32(12, this.burst_loss_count);
            if (this.has_burst_discard_count)
                writer.writeFixed32(13, this.burst_discard_count);
            if (this.has_burst_loss_rate)
                writer.writeFloat(14, this.burst_loss_rate);
            if (this.has_burst_discard_rate)
                writer.writeFloat(15, this.burst_discard_rate);
            if (this.has_gap_loss_rate)
                writer.writeFloat(16, this.gap_loss_rate);
            if (this.has_gap_discard_rate)
                writer.writeFloat(17, this.gap_discard_rate);
            if (this.has_frames_dropped)
                writer.writeFixed32(18, this.frames_dropped);
            if (this.has_partial_frames_lost)
                writer.writeFixed32(19, this.partial_frames_lost);
            if (this.has_full_frames_lost)
                writer.writeFixed32(20, this.full_frames_lost);
            if (this.has_local_id)
                writer.writeString(21, this.local_id);
            if (this.has_round_trip_time)
                writer.writeFloat(22, this.round_trip_time);
            if (this.has_total_round_trip_time)
                writer.writeFloat(23, this.total_round_trip_time);
            if (this.has_fraction_lost)
                writer.writeFloat(24, this.fraction_lost);
            if (this.has_reports_received)
                writer.writeFixed64(25, this.reports_received);
            if (this.has_round_trip_time_measurements)
                writer.writeFixed64(26, this.round_trip_time_measurements);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCRemoteInboundRtpStreamStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCRemoteInboundRtpStreamStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.ssrc = reader.readFixed32();
                        break;
                    case 2:
                        message.kind = reader.readEnum();
                        break;
                    case 3:
                        message.transport_id = reader.readString();
                        break;
                    case 4:
                        message.codec_id = reader.readString();
                        break;
                    case 5:
                        message.packets_received = reader.readFixed64();
                        break;
                    case 6:
                        message.packets_lost = reader.readSfixed64();
                        break;
                    case 7:
                        message.jitter = reader.readFloat();
                        break;
                    case 8:
                        message.packets_discarded = reader.readFixed64();
                        break;
                    case 9:
                        message.packets_repaired = reader.readFixed64();
                        break;
                    case 10:
                        message.burst_packets_lost = reader.readFixed64();
                        break;
                    case 11:
                        message.burst_packets_discarded = reader.readFixed64();
                        break;
                    case 12:
                        message.burst_loss_count = reader.readFixed32();
                        break;
                    case 13:
                        message.burst_discard_count = reader.readFixed32();
                        break;
                    case 14:
                        message.burst_loss_rate = reader.readFloat();
                        break;
                    case 15:
                        message.burst_discard_rate = reader.readFloat();
                        break;
                    case 16:
                        message.gap_loss_rate = reader.readFloat();
                        break;
                    case 17:
                        message.gap_discard_rate = reader.readFloat();
                        break;
                    case 18:
                        message.frames_dropped = reader.readFixed32();
                        break;
                    case 19:
                        message.partial_frames_lost = reader.readFixed32();
                        break;
                    case 20:
                        message.full_frames_lost = reader.readFixed32();
                        break;
                    case 21:
                        message.local_id = reader.readString();
                        break;
                    case 22:
                        message.round_trip_time = reader.readFloat();
                        break;
                    case 23:
                        message.total_round_trip_time = reader.readFloat();
                        break;
                    case 24:
                        message.fraction_lost = reader.readFloat();
                        break;
                    case 25:
                        message.reports_received = reader.readFixed64();
                        break;
                    case 26:
                        message.round_trip_time_measurements = reader.readFixed64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCRemoteInboundRtpStreamStats {
            return RTCRemoteInboundRtpStreamStats.deserialize(bytes);
        }
    }
    export class RTCCodecStats extends pb_1.Message {
        #one_of_decls: number[][] = [[2], [5], [6], [7]];
        constructor(data?: any[] | ({
            payload_type?: number;
            transport_id?: string;
            mime_type?: string;
        } & (({
            codec_type?: RTCCodecType;
        }) | ({
            clock_rate?: number;
        }) | ({
            channels?: number;
        }) | ({
            sdp_fmtp_line?: string;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("payload_type" in data && data.payload_type != undefined) {
                    this.payload_type = data.payload_type;
                }
                if ("codec_type" in data && data.codec_type != undefined) {
                    this.codec_type = data.codec_type;
                }
                if ("transport_id" in data && data.transport_id != undefined) {
                    this.transport_id = data.transport_id;
                }
                if ("mime_type" in data && data.mime_type != undefined) {
                    this.mime_type = data.mime_type;
                }
                if ("clock_rate" in data && data.clock_rate != undefined) {
                    this.clock_rate = data.clock_rate;
                }
                if ("channels" in data && data.channels != undefined) {
                    this.channels = data.channels;
                }
                if ("sdp_fmtp_line" in data && data.sdp_fmtp_line != undefined) {
                    this.sdp_fmtp_line = data.sdp_fmtp_line;
                }
            }
        }
        get payload_type() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set payload_type(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get codec_type() {
            return pb_1.Message.getFieldWithDefault(this, 2, RTCCodecType.Encode) as RTCCodecType;
        }
        set codec_type(value: RTCCodecType) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
        }
        get has_codec_type() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get transport_id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set transport_id(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get mime_type() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set mime_type(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get clock_rate() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set clock_rate(value: number) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[1], value);
        }
        get has_clock_rate() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get channels() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set channels(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[2], value);
        }
        get has_channels() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get sdp_fmtp_line() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set sdp_fmtp_line(value: string) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[3], value);
        }
        get has_sdp_fmtp_line() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get _codec_type() {
            const cases: {
                [index: number]: "none" | "codec_type";
            } = {
                0: "none",
                2: "codec_type"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _clock_rate() {
            const cases: {
                [index: number]: "none" | "clock_rate";
            } = {
                0: "none",
                5: "clock_rate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _channels() {
            const cases: {
                [index: number]: "none" | "channels";
            } = {
                0: "none",
                6: "channels"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _sdp_fmtp_line() {
            const cases: {
                [index: number]: "none" | "sdp_fmtp_line";
            } = {
                0: "none",
                7: "sdp_fmtp_line"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        static fromObject(data: {
            payload_type?: number;
            codec_type?: RTCCodecType;
            transport_id?: string;
            mime_type?: string;
            clock_rate?: number;
            channels?: number;
            sdp_fmtp_line?: string;
        }): RTCCodecStats {
            const message = new RTCCodecStats({});
            if (data.payload_type != null) {
                message.payload_type = data.payload_type;
            }
            if (data.codec_type != null) {
                message.codec_type = data.codec_type;
            }
            if (data.transport_id != null) {
                message.transport_id = data.transport_id;
            }
            if (data.mime_type != null) {
                message.mime_type = data.mime_type;
            }
            if (data.clock_rate != null) {
                message.clock_rate = data.clock_rate;
            }
            if (data.channels != null) {
                message.channels = data.channels;
            }
            if (data.sdp_fmtp_line != null) {
                message.sdp_fmtp_line = data.sdp_fmtp_line;
            }
            return message;
        }
        toObject() {
            const data: {
                payload_type?: number;
                codec_type?: RTCCodecType;
                transport_id?: string;
                mime_type?: string;
                clock_rate?: number;
                channels?: number;
                sdp_fmtp_line?: string;
            } = {};
            if (this.payload_type != null) {
                data.payload_type = this.payload_type;
            }
            if (this.codec_type != null) {
                data.codec_type = this.codec_type;
            }
            if (this.transport_id != null) {
                data.transport_id = this.transport_id;
            }
            if (this.mime_type != null) {
                data.mime_type = this.mime_type;
            }
            if (this.clock_rate != null) {
                data.clock_rate = this.clock_rate;
            }
            if (this.channels != null) {
                data.channels = this.channels;
            }
            if (this.sdp_fmtp_line != null) {
                data.sdp_fmtp_line = this.sdp_fmtp_line;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.payload_type != 0)
                writer.writeFixed32(1, this.payload_type);
            if (this.has_codec_type)
                writer.writeEnum(2, this.codec_type);
            if (this.transport_id.length)
                writer.writeString(3, this.transport_id);
            if (this.mime_type.length)
                writer.writeString(4, this.mime_type);
            if (this.has_clock_rate)
                writer.writeFixed32(5, this.clock_rate);
            if (this.has_channels)
                writer.writeFixed32(6, this.channels);
            if (this.has_sdp_fmtp_line)
                writer.writeString(7, this.sdp_fmtp_line);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCCodecStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCCodecStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.payload_type = reader.readFixed32();
                        break;
                    case 2:
                        message.codec_type = reader.readEnum();
                        break;
                    case 3:
                        message.transport_id = reader.readString();
                        break;
                    case 4:
                        message.mime_type = reader.readString();
                        break;
                    case 5:
                        message.clock_rate = reader.readFixed32();
                        break;
                    case 6:
                        message.channels = reader.readFixed32();
                        break;
                    case 7:
                        message.sdp_fmtp_line = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCCodecStats {
            return RTCCodecStats.deserialize(bytes);
        }
    }
    export class RTCRtpContributingSourceStats extends pb_1.Message {
        #one_of_decls: number[][] = [[3], [4]];
        constructor(data?: any[] | ({
            contributor_ssrc?: number;
            inbound_rtp_stream_id?: string;
        } & (({
            packets_contributed_to?: number;
        }) | ({
            audio_level?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("contributor_ssrc" in data && data.contributor_ssrc != undefined) {
                    this.contributor_ssrc = data.contributor_ssrc;
                }
                if ("inbound_rtp_stream_id" in data && data.inbound_rtp_stream_id != undefined) {
                    this.inbound_rtp_stream_id = data.inbound_rtp_stream_id;
                }
                if ("packets_contributed_to" in data && data.packets_contributed_to != undefined) {
                    this.packets_contributed_to = data.packets_contributed_to;
                }
                if ("audio_level" in data && data.audio_level != undefined) {
                    this.audio_level = data.audio_level;
                }
            }
        }
        get contributor_ssrc() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set contributor_ssrc(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get inbound_rtp_stream_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set inbound_rtp_stream_id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get packets_contributed_to() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set packets_contributed_to(value: number) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0], value);
        }
        get has_packets_contributed_to() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get audio_level() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set audio_level(value: number) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[1], value);
        }
        get has_audio_level() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get _packets_contributed_to() {
            const cases: {
                [index: number]: "none" | "packets_contributed_to";
            } = {
                0: "none",
                3: "packets_contributed_to"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _audio_level() {
            const cases: {
                [index: number]: "none" | "audio_level";
            } = {
                0: "none",
                4: "audio_level"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        static fromObject(data: {
            contributor_ssrc?: number;
            inbound_rtp_stream_id?: string;
            packets_contributed_to?: number;
            audio_level?: number;
        }): RTCRtpContributingSourceStats {
            const message = new RTCRtpContributingSourceStats({});
            if (data.contributor_ssrc != null) {
                message.contributor_ssrc = data.contributor_ssrc;
            }
            if (data.inbound_rtp_stream_id != null) {
                message.inbound_rtp_stream_id = data.inbound_rtp_stream_id;
            }
            if (data.packets_contributed_to != null) {
                message.packets_contributed_to = data.packets_contributed_to;
            }
            if (data.audio_level != null) {
                message.audio_level = data.audio_level;
            }
            return message;
        }
        toObject() {
            const data: {
                contributor_ssrc?: number;
                inbound_rtp_stream_id?: string;
                packets_contributed_to?: number;
                audio_level?: number;
            } = {};
            if (this.contributor_ssrc != null) {
                data.contributor_ssrc = this.contributor_ssrc;
            }
            if (this.inbound_rtp_stream_id != null) {
                data.inbound_rtp_stream_id = this.inbound_rtp_stream_id;
            }
            if (this.packets_contributed_to != null) {
                data.packets_contributed_to = this.packets_contributed_to;
            }
            if (this.audio_level != null) {
                data.audio_level = this.audio_level;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.contributor_ssrc != 0)
                writer.writeFixed32(1, this.contributor_ssrc);
            if (this.inbound_rtp_stream_id.length)
                writer.writeString(2, this.inbound_rtp_stream_id);
            if (this.has_packets_contributed_to)
                writer.writeFixed32(3, this.packets_contributed_to);
            if (this.has_audio_level)
                writer.writeFloat(4, this.audio_level);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCRtpContributingSourceStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCRtpContributingSourceStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.contributor_ssrc = reader.readFixed32();
                        break;
                    case 2:
                        message.inbound_rtp_stream_id = reader.readString();
                        break;
                    case 3:
                        message.packets_contributed_to = reader.readFixed32();
                        break;
                    case 4:
                        message.audio_level = reader.readFloat();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCRtpContributingSourceStats {
            return RTCRtpContributingSourceStats.deserialize(bytes);
        }
    }
    export class RTCPeerConnectionStats extends pb_1.Message {
        #one_of_decls: number[][] = [[1], [2], [3], [4]];
        constructor(data?: any[] | ({} & (({
            data_channels_opened?: number;
        }) | ({
            data_channels_closed?: number;
        }) | ({
            data_channels_requested?: number;
        }) | ({
            data_channels_accepted?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("data_channels_opened" in data && data.data_channels_opened != undefined) {
                    this.data_channels_opened = data.data_channels_opened;
                }
                if ("data_channels_closed" in data && data.data_channels_closed != undefined) {
                    this.data_channels_closed = data.data_channels_closed;
                }
                if ("data_channels_requested" in data && data.data_channels_requested != undefined) {
                    this.data_channels_requested = data.data_channels_requested;
                }
                if ("data_channels_accepted" in data && data.data_channels_accepted != undefined) {
                    this.data_channels_accepted = data.data_channels_accepted;
                }
            }
        }
        get data_channels_opened() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set data_channels_opened(value: number) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_data_channels_opened() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get data_channels_closed() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set data_channels_closed(value: number) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
        }
        get has_data_channels_closed() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get data_channels_requested() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set data_channels_requested(value: number) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
        }
        get has_data_channels_requested() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get data_channels_accepted() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set data_channels_accepted(value: number) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
        }
        get has_data_channels_accepted() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get _data_channels_opened() {
            const cases: {
                [index: number]: "none" | "data_channels_opened";
            } = {
                0: "none",
                1: "data_channels_opened"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        get _data_channels_closed() {
            const cases: {
                [index: number]: "none" | "data_channels_closed";
            } = {
                0: "none",
                2: "data_channels_closed"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _data_channels_requested() {
            const cases: {
                [index: number]: "none" | "data_channels_requested";
            } = {
                0: "none",
                3: "data_channels_requested"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _data_channels_accepted() {
            const cases: {
                [index: number]: "none" | "data_channels_accepted";
            } = {
                0: "none",
                4: "data_channels_accepted"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        static fromObject(data: {
            data_channels_opened?: number;
            data_channels_closed?: number;
            data_channels_requested?: number;
            data_channels_accepted?: number;
        }): RTCPeerConnectionStats {
            const message = new RTCPeerConnectionStats({});
            if (data.data_channels_opened != null) {
                message.data_channels_opened = data.data_channels_opened;
            }
            if (data.data_channels_closed != null) {
                message.data_channels_closed = data.data_channels_closed;
            }
            if (data.data_channels_requested != null) {
                message.data_channels_requested = data.data_channels_requested;
            }
            if (data.data_channels_accepted != null) {
                message.data_channels_accepted = data.data_channels_accepted;
            }
            return message;
        }
        toObject() {
            const data: {
                data_channels_opened?: number;
                data_channels_closed?: number;
                data_channels_requested?: number;
                data_channels_accepted?: number;
            } = {};
            if (this.data_channels_opened != null) {
                data.data_channels_opened = this.data_channels_opened;
            }
            if (this.data_channels_closed != null) {
                data.data_channels_closed = this.data_channels_closed;
            }
            if (this.data_channels_requested != null) {
                data.data_channels_requested = this.data_channels_requested;
            }
            if (this.data_channels_accepted != null) {
                data.data_channels_accepted = this.data_channels_accepted;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_data_channels_opened)
                writer.writeFixed32(1, this.data_channels_opened);
            if (this.has_data_channels_closed)
                writer.writeFixed32(2, this.data_channels_closed);
            if (this.has_data_channels_requested)
                writer.writeFixed32(3, this.data_channels_requested);
            if (this.has_data_channels_accepted)
                writer.writeFixed32(4, this.data_channels_accepted);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCPeerConnectionStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCPeerConnectionStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.data_channels_opened = reader.readFixed32();
                        break;
                    case 2:
                        message.data_channels_closed = reader.readFixed32();
                        break;
                    case 3:
                        message.data_channels_requested = reader.readFixed32();
                        break;
                    case 4:
                        message.data_channels_accepted = reader.readFixed32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCPeerConnectionStats {
            return RTCPeerConnectionStats.deserialize(bytes);
        }
    }
    export class RTCDataChannelStats extends pb_1.Message {
        #one_of_decls: number[][] = [[1], [2], [3], [5], [6], [7], [8]];
        constructor(data?: any[] | ({
            state?: RTCDataChannelState;
        } & (({
            label?: string;
        }) | ({
            protocol?: string;
        }) | ({
            data_channel_identifier?: number;
        }) | ({
            messages_sent?: number;
        }) | ({
            bytes_sent?: number;
        }) | ({
            messages_received?: number;
        }) | ({
            bytes_received?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("label" in data && data.label != undefined) {
                    this.label = data.label;
                }
                if ("protocol" in data && data.protocol != undefined) {
                    this.protocol = data.protocol;
                }
                if ("data_channel_identifier" in data && data.data_channel_identifier != undefined) {
                    this.data_channel_identifier = data.data_channel_identifier;
                }
                if ("state" in data && data.state != undefined) {
                    this.state = data.state;
                }
                if ("messages_sent" in data && data.messages_sent != undefined) {
                    this.messages_sent = data.messages_sent;
                }
                if ("bytes_sent" in data && data.bytes_sent != undefined) {
                    this.bytes_sent = data.bytes_sent;
                }
                if ("messages_received" in data && data.messages_received != undefined) {
                    this.messages_received = data.messages_received;
                }
                if ("bytes_received" in data && data.bytes_received != undefined) {
                    this.bytes_received = data.bytes_received;
                }
            }
        }
        get label() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set label(value: string) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_label() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get protocol() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set protocol(value: string) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
        }
        get has_protocol() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get data_channel_identifier() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set data_channel_identifier(value: number) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
        }
        get has_data_channel_identifier() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get state() {
            return pb_1.Message.getFieldWithDefault(this, 4, RTCDataChannelState.Connecting) as RTCDataChannelState;
        }
        set state(value: RTCDataChannelState) {
            pb_1.Message.setField(this, 4, value);
        }
        get messages_sent() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set messages_sent(value: number) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[3], value);
        }
        get has_messages_sent() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get bytes_sent() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set bytes_sent(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[4], value);
        }
        get has_bytes_sent() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get messages_received() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set messages_received(value: number) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[5], value);
        }
        get has_messages_received() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get bytes_received() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set bytes_received(value: number) {
            pb_1.Message.setOneofField(this, 8, this.#one_of_decls[6], value);
        }
        get has_bytes_received() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get _label() {
            const cases: {
                [index: number]: "none" | "label";
            } = {
                0: "none",
                1: "label"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        get _protocol() {
            const cases: {
                [index: number]: "none" | "protocol";
            } = {
                0: "none",
                2: "protocol"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _data_channel_identifier() {
            const cases: {
                [index: number]: "none" | "data_channel_identifier";
            } = {
                0: "none",
                3: "data_channel_identifier"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _messages_sent() {
            const cases: {
                [index: number]: "none" | "messages_sent";
            } = {
                0: "none",
                5: "messages_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _bytes_sent() {
            const cases: {
                [index: number]: "none" | "bytes_sent";
            } = {
                0: "none",
                6: "bytes_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _messages_received() {
            const cases: {
                [index: number]: "none" | "messages_received";
            } = {
                0: "none",
                7: "messages_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        get _bytes_received() {
            const cases: {
                [index: number]: "none" | "bytes_received";
            } = {
                0: "none",
                8: "bytes_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [8])];
        }
        static fromObject(data: {
            label?: string;
            protocol?: string;
            data_channel_identifier?: number;
            state?: RTCDataChannelState;
            messages_sent?: number;
            bytes_sent?: number;
            messages_received?: number;
            bytes_received?: number;
        }): RTCDataChannelStats {
            const message = new RTCDataChannelStats({});
            if (data.label != null) {
                message.label = data.label;
            }
            if (data.protocol != null) {
                message.protocol = data.protocol;
            }
            if (data.data_channel_identifier != null) {
                message.data_channel_identifier = data.data_channel_identifier;
            }
            if (data.state != null) {
                message.state = data.state;
            }
            if (data.messages_sent != null) {
                message.messages_sent = data.messages_sent;
            }
            if (data.bytes_sent != null) {
                message.bytes_sent = data.bytes_sent;
            }
            if (data.messages_received != null) {
                message.messages_received = data.messages_received;
            }
            if (data.bytes_received != null) {
                message.bytes_received = data.bytes_received;
            }
            return message;
        }
        toObject() {
            const data: {
                label?: string;
                protocol?: string;
                data_channel_identifier?: number;
                state?: RTCDataChannelState;
                messages_sent?: number;
                bytes_sent?: number;
                messages_received?: number;
                bytes_received?: number;
            } = {};
            if (this.label != null) {
                data.label = this.label;
            }
            if (this.protocol != null) {
                data.protocol = this.protocol;
            }
            if (this.data_channel_identifier != null) {
                data.data_channel_identifier = this.data_channel_identifier;
            }
            if (this.state != null) {
                data.state = this.state;
            }
            if (this.messages_sent != null) {
                data.messages_sent = this.messages_sent;
            }
            if (this.bytes_sent != null) {
                data.bytes_sent = this.bytes_sent;
            }
            if (this.messages_received != null) {
                data.messages_received = this.messages_received;
            }
            if (this.bytes_received != null) {
                data.bytes_received = this.bytes_received;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_label)
                writer.writeString(1, this.label);
            if (this.has_protocol)
                writer.writeString(2, this.protocol);
            if (this.has_data_channel_identifier)
                writer.writeInt32(3, this.data_channel_identifier);
            if (this.state != RTCDataChannelState.Connecting)
                writer.writeEnum(4, this.state);
            if (this.has_messages_sent)
                writer.writeFixed32(5, this.messages_sent);
            if (this.has_bytes_sent)
                writer.writeFixed64(6, this.bytes_sent);
            if (this.has_messages_received)
                writer.writeFixed32(7, this.messages_received);
            if (this.has_bytes_received)
                writer.writeFixed64(8, this.bytes_received);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCDataChannelStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCDataChannelStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.label = reader.readString();
                        break;
                    case 2:
                        message.protocol = reader.readString();
                        break;
                    case 3:
                        message.data_channel_identifier = reader.readInt32();
                        break;
                    case 4:
                        message.state = reader.readEnum();
                        break;
                    case 5:
                        message.messages_sent = reader.readFixed32();
                        break;
                    case 6:
                        message.bytes_sent = reader.readFixed64();
                        break;
                    case 7:
                        message.messages_received = reader.readFixed32();
                        break;
                    case 8:
                        message.bytes_received = reader.readFixed64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCDataChannelStats {
            return RTCDataChannelStats.deserialize(bytes);
        }
    }
    export class RTCRtpTransceiverStats extends pb_1.Message {
        #one_of_decls: number[][] = [[3]];
        constructor(data?: any[] | ({
            sender_id?: string;
            receiver_id?: string;
        } & (({
            mid?: string;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sender_id" in data && data.sender_id != undefined) {
                    this.sender_id = data.sender_id;
                }
                if ("receiver_id" in data && data.receiver_id != undefined) {
                    this.receiver_id = data.receiver_id;
                }
                if ("mid" in data && data.mid != undefined) {
                    this.mid = data.mid;
                }
            }
        }
        get sender_id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sender_id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get receiver_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set receiver_id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get mid() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set mid(value: string) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0], value);
        }
        get has_mid() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get _mid() {
            const cases: {
                [index: number]: "none" | "mid";
            } = {
                0: "none",
                3: "mid"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        static fromObject(data: {
            sender_id?: string;
            receiver_id?: string;
            mid?: string;
        }): RTCRtpTransceiverStats {
            const message = new RTCRtpTransceiverStats({});
            if (data.sender_id != null) {
                message.sender_id = data.sender_id;
            }
            if (data.receiver_id != null) {
                message.receiver_id = data.receiver_id;
            }
            if (data.mid != null) {
                message.mid = data.mid;
            }
            return message;
        }
        toObject() {
            const data: {
                sender_id?: string;
                receiver_id?: string;
                mid?: string;
            } = {};
            if (this.sender_id != null) {
                data.sender_id = this.sender_id;
            }
            if (this.receiver_id != null) {
                data.receiver_id = this.receiver_id;
            }
            if (this.mid != null) {
                data.mid = this.mid;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sender_id.length)
                writer.writeString(1, this.sender_id);
            if (this.receiver_id.length)
                writer.writeString(2, this.receiver_id);
            if (this.has_mid)
                writer.writeString(3, this.mid);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCRtpTransceiverStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCRtpTransceiverStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sender_id = reader.readString();
                        break;
                    case 2:
                        message.receiver_id = reader.readString();
                        break;
                    case 3:
                        message.mid = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCRtpTransceiverStats {
            return RTCRtpTransceiverStats.deserialize(bytes);
        }
    }
    export class RTCTransportStats extends pb_1.Message {
        #one_of_decls: number[][] = [[1], [2], [3], [4], [5], [6], [7], [9], [10], [11], [12], [13], [14], [15], [16], [17]];
        constructor(data?: any[] | ({
            dtls_state?: RTCDtlsTransportState;
        } & (({
            packets_sent?: number;
        }) | ({
            packets_received?: number;
        }) | ({
            bytes_sent?: number;
        }) | ({
            bytes_received?: number;
        }) | ({
            rtcp_transport_stats_id?: string;
        }) | ({
            ice_role?: RTCIceRole;
        }) | ({
            ice_local_username_fragment?: string;
        }) | ({
            ice_state?: RTCIceTransportState;
        }) | ({
            selected_candidate_pair_id?: string;
        }) | ({
            local_certificate_id?: string;
        }) | ({
            remote_certificate_id?: string;
        }) | ({
            tls_version?: string;
        }) | ({
            dtls_cipher?: string;
        }) | ({
            srtp_cipher?: string;
        }) | ({
            tls_group?: string;
        }) | ({
            selected_candidate_pair_changes?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("packets_sent" in data && data.packets_sent != undefined) {
                    this.packets_sent = data.packets_sent;
                }
                if ("packets_received" in data && data.packets_received != undefined) {
                    this.packets_received = data.packets_received;
                }
                if ("bytes_sent" in data && data.bytes_sent != undefined) {
                    this.bytes_sent = data.bytes_sent;
                }
                if ("bytes_received" in data && data.bytes_received != undefined) {
                    this.bytes_received = data.bytes_received;
                }
                if ("rtcp_transport_stats_id" in data && data.rtcp_transport_stats_id != undefined) {
                    this.rtcp_transport_stats_id = data.rtcp_transport_stats_id;
                }
                if ("ice_role" in data && data.ice_role != undefined) {
                    this.ice_role = data.ice_role;
                }
                if ("ice_local_username_fragment" in data && data.ice_local_username_fragment != undefined) {
                    this.ice_local_username_fragment = data.ice_local_username_fragment;
                }
                if ("dtls_state" in data && data.dtls_state != undefined) {
                    this.dtls_state = data.dtls_state;
                }
                if ("ice_state" in data && data.ice_state != undefined) {
                    this.ice_state = data.ice_state;
                }
                if ("selected_candidate_pair_id" in data && data.selected_candidate_pair_id != undefined) {
                    this.selected_candidate_pair_id = data.selected_candidate_pair_id;
                }
                if ("local_certificate_id" in data && data.local_certificate_id != undefined) {
                    this.local_certificate_id = data.local_certificate_id;
                }
                if ("remote_certificate_id" in data && data.remote_certificate_id != undefined) {
                    this.remote_certificate_id = data.remote_certificate_id;
                }
                if ("tls_version" in data && data.tls_version != undefined) {
                    this.tls_version = data.tls_version;
                }
                if ("dtls_cipher" in data && data.dtls_cipher != undefined) {
                    this.dtls_cipher = data.dtls_cipher;
                }
                if ("srtp_cipher" in data && data.srtp_cipher != undefined) {
                    this.srtp_cipher = data.srtp_cipher;
                }
                if ("tls_group" in data && data.tls_group != undefined) {
                    this.tls_group = data.tls_group;
                }
                if ("selected_candidate_pair_changes" in data && data.selected_candidate_pair_changes != undefined) {
                    this.selected_candidate_pair_changes = data.selected_candidate_pair_changes;
                }
            }
        }
        get packets_sent() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set packets_sent(value: number) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_packets_sent() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get packets_received() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set packets_received(value: number) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
        }
        get has_packets_received() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get bytes_sent() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set bytes_sent(value: number) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
        }
        get has_bytes_sent() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get bytes_received() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set bytes_received(value: number) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
        }
        get has_bytes_received() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get rtcp_transport_stats_id() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set rtcp_transport_stats_id(value: string) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[4], value);
        }
        get has_rtcp_transport_stats_id() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get ice_role() {
            return pb_1.Message.getFieldWithDefault(this, 6, RTCIceRole.Unknown) as RTCIceRole;
        }
        set ice_role(value: RTCIceRole) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[5], value);
        }
        get has_ice_role() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get ice_local_username_fragment() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set ice_local_username_fragment(value: string) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[6], value);
        }
        get has_ice_local_username_fragment() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get dtls_state() {
            return pb_1.Message.getFieldWithDefault(this, 8, RTCDtlsTransportState.DtlsNew) as RTCDtlsTransportState;
        }
        set dtls_state(value: RTCDtlsTransportState) {
            pb_1.Message.setField(this, 8, value);
        }
        get ice_state() {
            return pb_1.Message.getFieldWithDefault(this, 9, RTCIceTransportState.IceNew) as RTCIceTransportState;
        }
        set ice_state(value: RTCIceTransportState) {
            pb_1.Message.setOneofField(this, 9, this.#one_of_decls[7], value);
        }
        get has_ice_state() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get selected_candidate_pair_id() {
            return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
        }
        set selected_candidate_pair_id(value: string) {
            pb_1.Message.setOneofField(this, 10, this.#one_of_decls[8], value);
        }
        get has_selected_candidate_pair_id() {
            return pb_1.Message.getField(this, 10) != null;
        }
        get local_certificate_id() {
            return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
        }
        set local_certificate_id(value: string) {
            pb_1.Message.setOneofField(this, 11, this.#one_of_decls[9], value);
        }
        get has_local_certificate_id() {
            return pb_1.Message.getField(this, 11) != null;
        }
        get remote_certificate_id() {
            return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
        }
        set remote_certificate_id(value: string) {
            pb_1.Message.setOneofField(this, 12, this.#one_of_decls[10], value);
        }
        get has_remote_certificate_id() {
            return pb_1.Message.getField(this, 12) != null;
        }
        get tls_version() {
            return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
        }
        set tls_version(value: string) {
            pb_1.Message.setOneofField(this, 13, this.#one_of_decls[11], value);
        }
        get has_tls_version() {
            return pb_1.Message.getField(this, 13) != null;
        }
        get dtls_cipher() {
            return pb_1.Message.getFieldWithDefault(this, 14, "") as string;
        }
        set dtls_cipher(value: string) {
            pb_1.Message.setOneofField(this, 14, this.#one_of_decls[12], value);
        }
        get has_dtls_cipher() {
            return pb_1.Message.getField(this, 14) != null;
        }
        get srtp_cipher() {
            return pb_1.Message.getFieldWithDefault(this, 15, "") as string;
        }
        set srtp_cipher(value: string) {
            pb_1.Message.setOneofField(this, 15, this.#one_of_decls[13], value);
        }
        get has_srtp_cipher() {
            return pb_1.Message.getField(this, 15) != null;
        }
        get tls_group() {
            return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
        }
        set tls_group(value: string) {
            pb_1.Message.setOneofField(this, 16, this.#one_of_decls[14], value);
        }
        get has_tls_group() {
            return pb_1.Message.getField(this, 16) != null;
        }
        get selected_candidate_pair_changes() {
            return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
        }
        set selected_candidate_pair_changes(value: number) {
            pb_1.Message.setOneofField(this, 17, this.#one_of_decls[15], value);
        }
        get has_selected_candidate_pair_changes() {
            return pb_1.Message.getField(this, 17) != null;
        }
        get _packets_sent() {
            const cases: {
                [index: number]: "none" | "packets_sent";
            } = {
                0: "none",
                1: "packets_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        get _packets_received() {
            const cases: {
                [index: number]: "none" | "packets_received";
            } = {
                0: "none",
                2: "packets_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _bytes_sent() {
            const cases: {
                [index: number]: "none" | "bytes_sent";
            } = {
                0: "none",
                3: "bytes_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _bytes_received() {
            const cases: {
                [index: number]: "none" | "bytes_received";
            } = {
                0: "none",
                4: "bytes_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        get _rtcp_transport_stats_id() {
            const cases: {
                [index: number]: "none" | "rtcp_transport_stats_id";
            } = {
                0: "none",
                5: "rtcp_transport_stats_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _ice_role() {
            const cases: {
                [index: number]: "none" | "ice_role";
            } = {
                0: "none",
                6: "ice_role"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _ice_local_username_fragment() {
            const cases: {
                [index: number]: "none" | "ice_local_username_fragment";
            } = {
                0: "none",
                7: "ice_local_username_fragment"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        get _ice_state() {
            const cases: {
                [index: number]: "none" | "ice_state";
            } = {
                0: "none",
                9: "ice_state"
            };
            return cases[pb_1.Message.computeOneofCase(this, [9])];
        }
        get _selected_candidate_pair_id() {
            const cases: {
                [index: number]: "none" | "selected_candidate_pair_id";
            } = {
                0: "none",
                10: "selected_candidate_pair_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [10])];
        }
        get _local_certificate_id() {
            const cases: {
                [index: number]: "none" | "local_certificate_id";
            } = {
                0: "none",
                11: "local_certificate_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [11])];
        }
        get _remote_certificate_id() {
            const cases: {
                [index: number]: "none" | "remote_certificate_id";
            } = {
                0: "none",
                12: "remote_certificate_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [12])];
        }
        get _tls_version() {
            const cases: {
                [index: number]: "none" | "tls_version";
            } = {
                0: "none",
                13: "tls_version"
            };
            return cases[pb_1.Message.computeOneofCase(this, [13])];
        }
        get _dtls_cipher() {
            const cases: {
                [index: number]: "none" | "dtls_cipher";
            } = {
                0: "none",
                14: "dtls_cipher"
            };
            return cases[pb_1.Message.computeOneofCase(this, [14])];
        }
        get _srtp_cipher() {
            const cases: {
                [index: number]: "none" | "srtp_cipher";
            } = {
                0: "none",
                15: "srtp_cipher"
            };
            return cases[pb_1.Message.computeOneofCase(this, [15])];
        }
        get _tls_group() {
            const cases: {
                [index: number]: "none" | "tls_group";
            } = {
                0: "none",
                16: "tls_group"
            };
            return cases[pb_1.Message.computeOneofCase(this, [16])];
        }
        get _selected_candidate_pair_changes() {
            const cases: {
                [index: number]: "none" | "selected_candidate_pair_changes";
            } = {
                0: "none",
                17: "selected_candidate_pair_changes"
            };
            return cases[pb_1.Message.computeOneofCase(this, [17])];
        }
        static fromObject(data: {
            packets_sent?: number;
            packets_received?: number;
            bytes_sent?: number;
            bytes_received?: number;
            rtcp_transport_stats_id?: string;
            ice_role?: RTCIceRole;
            ice_local_username_fragment?: string;
            dtls_state?: RTCDtlsTransportState;
            ice_state?: RTCIceTransportState;
            selected_candidate_pair_id?: string;
            local_certificate_id?: string;
            remote_certificate_id?: string;
            tls_version?: string;
            dtls_cipher?: string;
            srtp_cipher?: string;
            tls_group?: string;
            selected_candidate_pair_changes?: number;
        }): RTCTransportStats {
            const message = new RTCTransportStats({});
            if (data.packets_sent != null) {
                message.packets_sent = data.packets_sent;
            }
            if (data.packets_received != null) {
                message.packets_received = data.packets_received;
            }
            if (data.bytes_sent != null) {
                message.bytes_sent = data.bytes_sent;
            }
            if (data.bytes_received != null) {
                message.bytes_received = data.bytes_received;
            }
            if (data.rtcp_transport_stats_id != null) {
                message.rtcp_transport_stats_id = data.rtcp_transport_stats_id;
            }
            if (data.ice_role != null) {
                message.ice_role = data.ice_role;
            }
            if (data.ice_local_username_fragment != null) {
                message.ice_local_username_fragment = data.ice_local_username_fragment;
            }
            if (data.dtls_state != null) {
                message.dtls_state = data.dtls_state;
            }
            if (data.ice_state != null) {
                message.ice_state = data.ice_state;
            }
            if (data.selected_candidate_pair_id != null) {
                message.selected_candidate_pair_id = data.selected_candidate_pair_id;
            }
            if (data.local_certificate_id != null) {
                message.local_certificate_id = data.local_certificate_id;
            }
            if (data.remote_certificate_id != null) {
                message.remote_certificate_id = data.remote_certificate_id;
            }
            if (data.tls_version != null) {
                message.tls_version = data.tls_version;
            }
            if (data.dtls_cipher != null) {
                message.dtls_cipher = data.dtls_cipher;
            }
            if (data.srtp_cipher != null) {
                message.srtp_cipher = data.srtp_cipher;
            }
            if (data.tls_group != null) {
                message.tls_group = data.tls_group;
            }
            if (data.selected_candidate_pair_changes != null) {
                message.selected_candidate_pair_changes = data.selected_candidate_pair_changes;
            }
            return message;
        }
        toObject() {
            const data: {
                packets_sent?: number;
                packets_received?: number;
                bytes_sent?: number;
                bytes_received?: number;
                rtcp_transport_stats_id?: string;
                ice_role?: RTCIceRole;
                ice_local_username_fragment?: string;
                dtls_state?: RTCDtlsTransportState;
                ice_state?: RTCIceTransportState;
                selected_candidate_pair_id?: string;
                local_certificate_id?: string;
                remote_certificate_id?: string;
                tls_version?: string;
                dtls_cipher?: string;
                srtp_cipher?: string;
                tls_group?: string;
                selected_candidate_pair_changes?: number;
            } = {};
            if (this.packets_sent != null) {
                data.packets_sent = this.packets_sent;
            }
            if (this.packets_received != null) {
                data.packets_received = this.packets_received;
            }
            if (this.bytes_sent != null) {
                data.bytes_sent = this.bytes_sent;
            }
            if (this.bytes_received != null) {
                data.bytes_received = this.bytes_received;
            }
            if (this.rtcp_transport_stats_id != null) {
                data.rtcp_transport_stats_id = this.rtcp_transport_stats_id;
            }
            if (this.ice_role != null) {
                data.ice_role = this.ice_role;
            }
            if (this.ice_local_username_fragment != null) {
                data.ice_local_username_fragment = this.ice_local_username_fragment;
            }
            if (this.dtls_state != null) {
                data.dtls_state = this.dtls_state;
            }
            if (this.ice_state != null) {
                data.ice_state = this.ice_state;
            }
            if (this.selected_candidate_pair_id != null) {
                data.selected_candidate_pair_id = this.selected_candidate_pair_id;
            }
            if (this.local_certificate_id != null) {
                data.local_certificate_id = this.local_certificate_id;
            }
            if (this.remote_certificate_id != null) {
                data.remote_certificate_id = this.remote_certificate_id;
            }
            if (this.tls_version != null) {
                data.tls_version = this.tls_version;
            }
            if (this.dtls_cipher != null) {
                data.dtls_cipher = this.dtls_cipher;
            }
            if (this.srtp_cipher != null) {
                data.srtp_cipher = this.srtp_cipher;
            }
            if (this.tls_group != null) {
                data.tls_group = this.tls_group;
            }
            if (this.selected_candidate_pair_changes != null) {
                data.selected_candidate_pair_changes = this.selected_candidate_pair_changes;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_packets_sent)
                writer.writeFixed64(1, this.packets_sent);
            if (this.has_packets_received)
                writer.writeFixed64(2, this.packets_received);
            if (this.has_bytes_sent)
                writer.writeFixed64(3, this.bytes_sent);
            if (this.has_bytes_received)
                writer.writeFixed64(4, this.bytes_received);
            if (this.has_rtcp_transport_stats_id)
                writer.writeString(5, this.rtcp_transport_stats_id);
            if (this.has_ice_role)
                writer.writeEnum(6, this.ice_role);
            if (this.has_ice_local_username_fragment)
                writer.writeString(7, this.ice_local_username_fragment);
            if (this.dtls_state != RTCDtlsTransportState.DtlsNew)
                writer.writeEnum(8, this.dtls_state);
            if (this.has_ice_state)
                writer.writeEnum(9, this.ice_state);
            if (this.has_selected_candidate_pair_id)
                writer.writeString(10, this.selected_candidate_pair_id);
            if (this.has_local_certificate_id)
                writer.writeString(11, this.local_certificate_id);
            if (this.has_remote_certificate_id)
                writer.writeString(12, this.remote_certificate_id);
            if (this.has_tls_version)
                writer.writeString(13, this.tls_version);
            if (this.has_dtls_cipher)
                writer.writeString(14, this.dtls_cipher);
            if (this.has_srtp_cipher)
                writer.writeString(15, this.srtp_cipher);
            if (this.has_tls_group)
                writer.writeString(16, this.tls_group);
            if (this.has_selected_candidate_pair_changes)
                writer.writeFixed32(17, this.selected_candidate_pair_changes);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCTransportStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCTransportStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.packets_sent = reader.readFixed64();
                        break;
                    case 2:
                        message.packets_received = reader.readFixed64();
                        break;
                    case 3:
                        message.bytes_sent = reader.readFixed64();
                        break;
                    case 4:
                        message.bytes_received = reader.readFixed64();
                        break;
                    case 5:
                        message.rtcp_transport_stats_id = reader.readString();
                        break;
                    case 6:
                        message.ice_role = reader.readEnum();
                        break;
                    case 7:
                        message.ice_local_username_fragment = reader.readString();
                        break;
                    case 8:
                        message.dtls_state = reader.readEnum();
                        break;
                    case 9:
                        message.ice_state = reader.readEnum();
                        break;
                    case 10:
                        message.selected_candidate_pair_id = reader.readString();
                        break;
                    case 11:
                        message.local_certificate_id = reader.readString();
                        break;
                    case 12:
                        message.remote_certificate_id = reader.readString();
                        break;
                    case 13:
                        message.tls_version = reader.readString();
                        break;
                    case 14:
                        message.dtls_cipher = reader.readString();
                        break;
                    case 15:
                        message.srtp_cipher = reader.readString();
                        break;
                    case 16:
                        message.tls_group = reader.readString();
                        break;
                    case 17:
                        message.selected_candidate_pair_changes = reader.readFixed32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCTransportStats {
            return RTCTransportStats.deserialize(bytes);
        }
    }
    export class RTCSctpTransportStats extends pb_1.Message {
        #one_of_decls: number[][] = [[1], [2], [3], [4], [5], [6]];
        constructor(data?: any[] | ({} & (({
            transport_id?: string;
        }) | ({
            smoothed_round_trip_time?: number;
        }) | ({
            congestion_window?: number;
        }) | ({
            receiver_window?: number;
        }) | ({
            mtu?: number;
        }) | ({
            unack_data?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transport_id" in data && data.transport_id != undefined) {
                    this.transport_id = data.transport_id;
                }
                if ("smoothed_round_trip_time" in data && data.smoothed_round_trip_time != undefined) {
                    this.smoothed_round_trip_time = data.smoothed_round_trip_time;
                }
                if ("congestion_window" in data && data.congestion_window != undefined) {
                    this.congestion_window = data.congestion_window;
                }
                if ("receiver_window" in data && data.receiver_window != undefined) {
                    this.receiver_window = data.receiver_window;
                }
                if ("mtu" in data && data.mtu != undefined) {
                    this.mtu = data.mtu;
                }
                if ("unack_data" in data && data.unack_data != undefined) {
                    this.unack_data = data.unack_data;
                }
            }
        }
        get transport_id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set transport_id(value: string) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_transport_id() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get smoothed_round_trip_time() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set smoothed_round_trip_time(value: number) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
        }
        get has_smoothed_round_trip_time() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get congestion_window() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set congestion_window(value: number) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
        }
        get has_congestion_window() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get receiver_window() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set receiver_window(value: number) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
        }
        get has_receiver_window() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get mtu() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set mtu(value: number) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[4], value);
        }
        get has_mtu() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get unack_data() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set unack_data(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[5], value);
        }
        get has_unack_data() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get _transport_id() {
            const cases: {
                [index: number]: "none" | "transport_id";
            } = {
                0: "none",
                1: "transport_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        get _smoothed_round_trip_time() {
            const cases: {
                [index: number]: "none" | "smoothed_round_trip_time";
            } = {
                0: "none",
                2: "smoothed_round_trip_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _congestion_window() {
            const cases: {
                [index: number]: "none" | "congestion_window";
            } = {
                0: "none",
                3: "congestion_window"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _receiver_window() {
            const cases: {
                [index: number]: "none" | "receiver_window";
            } = {
                0: "none",
                4: "receiver_window"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        get _mtu() {
            const cases: {
                [index: number]: "none" | "mtu";
            } = {
                0: "none",
                5: "mtu"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _unack_data() {
            const cases: {
                [index: number]: "none" | "unack_data";
            } = {
                0: "none",
                6: "unack_data"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        static fromObject(data: {
            transport_id?: string;
            smoothed_round_trip_time?: number;
            congestion_window?: number;
            receiver_window?: number;
            mtu?: number;
            unack_data?: number;
        }): RTCSctpTransportStats {
            const message = new RTCSctpTransportStats({});
            if (data.transport_id != null) {
                message.transport_id = data.transport_id;
            }
            if (data.smoothed_round_trip_time != null) {
                message.smoothed_round_trip_time = data.smoothed_round_trip_time;
            }
            if (data.congestion_window != null) {
                message.congestion_window = data.congestion_window;
            }
            if (data.receiver_window != null) {
                message.receiver_window = data.receiver_window;
            }
            if (data.mtu != null) {
                message.mtu = data.mtu;
            }
            if (data.unack_data != null) {
                message.unack_data = data.unack_data;
            }
            return message;
        }
        toObject() {
            const data: {
                transport_id?: string;
                smoothed_round_trip_time?: number;
                congestion_window?: number;
                receiver_window?: number;
                mtu?: number;
                unack_data?: number;
            } = {};
            if (this.transport_id != null) {
                data.transport_id = this.transport_id;
            }
            if (this.smoothed_round_trip_time != null) {
                data.smoothed_round_trip_time = this.smoothed_round_trip_time;
            }
            if (this.congestion_window != null) {
                data.congestion_window = this.congestion_window;
            }
            if (this.receiver_window != null) {
                data.receiver_window = this.receiver_window;
            }
            if (this.mtu != null) {
                data.mtu = this.mtu;
            }
            if (this.unack_data != null) {
                data.unack_data = this.unack_data;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_transport_id)
                writer.writeString(1, this.transport_id);
            if (this.has_smoothed_round_trip_time)
                writer.writeFloat(2, this.smoothed_round_trip_time);
            if (this.has_congestion_window)
                writer.writeFixed32(3, this.congestion_window);
            if (this.has_receiver_window)
                writer.writeFixed32(4, this.receiver_window);
            if (this.has_mtu)
                writer.writeFixed32(5, this.mtu);
            if (this.has_unack_data)
                writer.writeFixed32(6, this.unack_data);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCSctpTransportStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCSctpTransportStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.transport_id = reader.readString();
                        break;
                    case 2:
                        message.smoothed_round_trip_time = reader.readFloat();
                        break;
                    case 3:
                        message.congestion_window = reader.readFixed32();
                        break;
                    case 4:
                        message.receiver_window = reader.readFixed32();
                        break;
                    case 5:
                        message.mtu = reader.readFixed32();
                        break;
                    case 6:
                        message.unack_data = reader.readFixed32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCSctpTransportStats {
            return RTCSctpTransportStats.deserialize(bytes);
        }
    }
    export class RTCIceCandidatePairStats extends pb_1.Message {
        #one_of_decls: number[][] = [[5], [6], [7], [8], [9], [10], [11], [12], [13], [14], [15], [16], [17], [18], [19], [20], [21], [22], [23], [24], [25], [26], [27], [28], [29], [30], [31], [32]];
        constructor(data?: any[] | ({
            transport_id?: string;
            local_candidate_id?: string;
            remote_candidate_id?: string;
            state?: RTCStatsIceCandidatePairState;
        } & (({
            nominated?: boolean;
        }) | ({
            packets_sent?: number;
        }) | ({
            packets_received?: number;
        }) | ({
            bytes_sent?: number;
        }) | ({
            bytes_received?: number;
        }) | ({
            last_packet_sent_timestamp?: number;
        }) | ({
            last_packet_received_timestamp?: number;
        }) | ({
            first_request_timestamp?: number;
        }) | ({
            last_request_timestamp?: number;
        }) | ({
            last_response_timestamp?: number;
        }) | ({
            total_round_trip_time?: number;
        }) | ({
            current_round_trip_time?: number;
        }) | ({
            available_outgoing_bitrate?: number;
        }) | ({
            available_incoming_bitrate?: number;
        }) | ({
            circuit_breaker_trigger_count?: number;
        }) | ({
            requests_received?: number;
        }) | ({
            requests_sent?: number;
        }) | ({
            responses_received?: number;
        }) | ({
            responses_sent?: number;
        }) | ({
            retransmissions_received?: number;
        }) | ({
            retransmissions_sent?: number;
        }) | ({
            consent_requests_sent?: number;
        }) | ({
            consent_expired_timestamp?: number;
        }) | ({
            packets_discarded_on_send?: number;
        }) | ({
            bytes_discarded_on_send?: number;
        }) | ({
            request_bytes_sent?: number;
        }) | ({
            consent_request_bytes_sent?: number;
        }) | ({
            response_bytes_sent?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transport_id" in data && data.transport_id != undefined) {
                    this.transport_id = data.transport_id;
                }
                if ("local_candidate_id" in data && data.local_candidate_id != undefined) {
                    this.local_candidate_id = data.local_candidate_id;
                }
                if ("remote_candidate_id" in data && data.remote_candidate_id != undefined) {
                    this.remote_candidate_id = data.remote_candidate_id;
                }
                if ("state" in data && data.state != undefined) {
                    this.state = data.state;
                }
                if ("nominated" in data && data.nominated != undefined) {
                    this.nominated = data.nominated;
                }
                if ("packets_sent" in data && data.packets_sent != undefined) {
                    this.packets_sent = data.packets_sent;
                }
                if ("packets_received" in data && data.packets_received != undefined) {
                    this.packets_received = data.packets_received;
                }
                if ("bytes_sent" in data && data.bytes_sent != undefined) {
                    this.bytes_sent = data.bytes_sent;
                }
                if ("bytes_received" in data && data.bytes_received != undefined) {
                    this.bytes_received = data.bytes_received;
                }
                if ("last_packet_sent_timestamp" in data && data.last_packet_sent_timestamp != undefined) {
                    this.last_packet_sent_timestamp = data.last_packet_sent_timestamp;
                }
                if ("last_packet_received_timestamp" in data && data.last_packet_received_timestamp != undefined) {
                    this.last_packet_received_timestamp = data.last_packet_received_timestamp;
                }
                if ("first_request_timestamp" in data && data.first_request_timestamp != undefined) {
                    this.first_request_timestamp = data.first_request_timestamp;
                }
                if ("last_request_timestamp" in data && data.last_request_timestamp != undefined) {
                    this.last_request_timestamp = data.last_request_timestamp;
                }
                if ("last_response_timestamp" in data && data.last_response_timestamp != undefined) {
                    this.last_response_timestamp = data.last_response_timestamp;
                }
                if ("total_round_trip_time" in data && data.total_round_trip_time != undefined) {
                    this.total_round_trip_time = data.total_round_trip_time;
                }
                if ("current_round_trip_time" in data && data.current_round_trip_time != undefined) {
                    this.current_round_trip_time = data.current_round_trip_time;
                }
                if ("available_outgoing_bitrate" in data && data.available_outgoing_bitrate != undefined) {
                    this.available_outgoing_bitrate = data.available_outgoing_bitrate;
                }
                if ("available_incoming_bitrate" in data && data.available_incoming_bitrate != undefined) {
                    this.available_incoming_bitrate = data.available_incoming_bitrate;
                }
                if ("circuit_breaker_trigger_count" in data && data.circuit_breaker_trigger_count != undefined) {
                    this.circuit_breaker_trigger_count = data.circuit_breaker_trigger_count;
                }
                if ("requests_received" in data && data.requests_received != undefined) {
                    this.requests_received = data.requests_received;
                }
                if ("requests_sent" in data && data.requests_sent != undefined) {
                    this.requests_sent = data.requests_sent;
                }
                if ("responses_received" in data && data.responses_received != undefined) {
                    this.responses_received = data.responses_received;
                }
                if ("responses_sent" in data && data.responses_sent != undefined) {
                    this.responses_sent = data.responses_sent;
                }
                if ("retransmissions_received" in data && data.retransmissions_received != undefined) {
                    this.retransmissions_received = data.retransmissions_received;
                }
                if ("retransmissions_sent" in data && data.retransmissions_sent != undefined) {
                    this.retransmissions_sent = data.retransmissions_sent;
                }
                if ("consent_requests_sent" in data && data.consent_requests_sent != undefined) {
                    this.consent_requests_sent = data.consent_requests_sent;
                }
                if ("consent_expired_timestamp" in data && data.consent_expired_timestamp != undefined) {
                    this.consent_expired_timestamp = data.consent_expired_timestamp;
                }
                if ("packets_discarded_on_send" in data && data.packets_discarded_on_send != undefined) {
                    this.packets_discarded_on_send = data.packets_discarded_on_send;
                }
                if ("bytes_discarded_on_send" in data && data.bytes_discarded_on_send != undefined) {
                    this.bytes_discarded_on_send = data.bytes_discarded_on_send;
                }
                if ("request_bytes_sent" in data && data.request_bytes_sent != undefined) {
                    this.request_bytes_sent = data.request_bytes_sent;
                }
                if ("consent_request_bytes_sent" in data && data.consent_request_bytes_sent != undefined) {
                    this.consent_request_bytes_sent = data.consent_request_bytes_sent;
                }
                if ("response_bytes_sent" in data && data.response_bytes_sent != undefined) {
                    this.response_bytes_sent = data.response_bytes_sent;
                }
            }
        }
        get transport_id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set transport_id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get local_candidate_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set local_candidate_id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get remote_candidate_id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set remote_candidate_id(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get state() {
            return pb_1.Message.getFieldWithDefault(this, 4, RTCStatsIceCandidatePairState.Frozen) as RTCStatsIceCandidatePairState;
        }
        set state(value: RTCStatsIceCandidatePairState) {
            pb_1.Message.setField(this, 4, value);
        }
        get nominated() {
            return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
        }
        set nominated(value: boolean) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0], value);
        }
        get has_nominated() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get packets_sent() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set packets_sent(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[1], value);
        }
        get has_packets_sent() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get packets_received() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set packets_received(value: number) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[2], value);
        }
        get has_packets_received() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get bytes_sent() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set bytes_sent(value: number) {
            pb_1.Message.setOneofField(this, 8, this.#one_of_decls[3], value);
        }
        get has_bytes_sent() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get bytes_received() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set bytes_received(value: number) {
            pb_1.Message.setOneofField(this, 9, this.#one_of_decls[4], value);
        }
        get has_bytes_received() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get last_packet_sent_timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
        }
        set last_packet_sent_timestamp(value: number) {
            pb_1.Message.setOneofField(this, 10, this.#one_of_decls[5], value);
        }
        get has_last_packet_sent_timestamp() {
            return pb_1.Message.getField(this, 10) != null;
        }
        get last_packet_received_timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
        }
        set last_packet_received_timestamp(value: number) {
            pb_1.Message.setOneofField(this, 11, this.#one_of_decls[6], value);
        }
        get has_last_packet_received_timestamp() {
            return pb_1.Message.getField(this, 11) != null;
        }
        get first_request_timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
        }
        set first_request_timestamp(value: number) {
            pb_1.Message.setOneofField(this, 12, this.#one_of_decls[7], value);
        }
        get has_first_request_timestamp() {
            return pb_1.Message.getField(this, 12) != null;
        }
        get last_request_timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 13, 0) as number;
        }
        set last_request_timestamp(value: number) {
            pb_1.Message.setOneofField(this, 13, this.#one_of_decls[8], value);
        }
        get has_last_request_timestamp() {
            return pb_1.Message.getField(this, 13) != null;
        }
        get last_response_timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
        }
        set last_response_timestamp(value: number) {
            pb_1.Message.setOneofField(this, 14, this.#one_of_decls[9], value);
        }
        get has_last_response_timestamp() {
            return pb_1.Message.getField(this, 14) != null;
        }
        get total_round_trip_time() {
            return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
        }
        set total_round_trip_time(value: number) {
            pb_1.Message.setOneofField(this, 15, this.#one_of_decls[10], value);
        }
        get has_total_round_trip_time() {
            return pb_1.Message.getField(this, 15) != null;
        }
        get current_round_trip_time() {
            return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
        }
        set current_round_trip_time(value: number) {
            pb_1.Message.setOneofField(this, 16, this.#one_of_decls[11], value);
        }
        get has_current_round_trip_time() {
            return pb_1.Message.getField(this, 16) != null;
        }
        get available_outgoing_bitrate() {
            return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
        }
        set available_outgoing_bitrate(value: number) {
            pb_1.Message.setOneofField(this, 17, this.#one_of_decls[12], value);
        }
        get has_available_outgoing_bitrate() {
            return pb_1.Message.getField(this, 17) != null;
        }
        get available_incoming_bitrate() {
            return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
        }
        set available_incoming_bitrate(value: number) {
            pb_1.Message.setOneofField(this, 18, this.#one_of_decls[13], value);
        }
        get has_available_incoming_bitrate() {
            return pb_1.Message.getField(this, 18) != null;
        }
        get circuit_breaker_trigger_count() {
            return pb_1.Message.getFieldWithDefault(this, 19, 0) as number;
        }
        set circuit_breaker_trigger_count(value: number) {
            pb_1.Message.setOneofField(this, 19, this.#one_of_decls[14], value);
        }
        get has_circuit_breaker_trigger_count() {
            return pb_1.Message.getField(this, 19) != null;
        }
        get requests_received() {
            return pb_1.Message.getFieldWithDefault(this, 20, 0) as number;
        }
        set requests_received(value: number) {
            pb_1.Message.setOneofField(this, 20, this.#one_of_decls[15], value);
        }
        get has_requests_received() {
            return pb_1.Message.getField(this, 20) != null;
        }
        get requests_sent() {
            return pb_1.Message.getFieldWithDefault(this, 21, 0) as number;
        }
        set requests_sent(value: number) {
            pb_1.Message.setOneofField(this, 21, this.#one_of_decls[16], value);
        }
        get has_requests_sent() {
            return pb_1.Message.getField(this, 21) != null;
        }
        get responses_received() {
            return pb_1.Message.getFieldWithDefault(this, 22, 0) as number;
        }
        set responses_received(value: number) {
            pb_1.Message.setOneofField(this, 22, this.#one_of_decls[17], value);
        }
        get has_responses_received() {
            return pb_1.Message.getField(this, 22) != null;
        }
        get responses_sent() {
            return pb_1.Message.getFieldWithDefault(this, 23, 0) as number;
        }
        set responses_sent(value: number) {
            pb_1.Message.setOneofField(this, 23, this.#one_of_decls[18], value);
        }
        get has_responses_sent() {
            return pb_1.Message.getField(this, 23) != null;
        }
        get retransmissions_received() {
            return pb_1.Message.getFieldWithDefault(this, 24, 0) as number;
        }
        set retransmissions_received(value: number) {
            pb_1.Message.setOneofField(this, 24, this.#one_of_decls[19], value);
        }
        get has_retransmissions_received() {
            return pb_1.Message.getField(this, 24) != null;
        }
        get retransmissions_sent() {
            return pb_1.Message.getFieldWithDefault(this, 25, 0) as number;
        }
        set retransmissions_sent(value: number) {
            pb_1.Message.setOneofField(this, 25, this.#one_of_decls[20], value);
        }
        get has_retransmissions_sent() {
            return pb_1.Message.getField(this, 25) != null;
        }
        get consent_requests_sent() {
            return pb_1.Message.getFieldWithDefault(this, 26, 0) as number;
        }
        set consent_requests_sent(value: number) {
            pb_1.Message.setOneofField(this, 26, this.#one_of_decls[21], value);
        }
        get has_consent_requests_sent() {
            return pb_1.Message.getField(this, 26) != null;
        }
        get consent_expired_timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 27, 0) as number;
        }
        set consent_expired_timestamp(value: number) {
            pb_1.Message.setOneofField(this, 27, this.#one_of_decls[22], value);
        }
        get has_consent_expired_timestamp() {
            return pb_1.Message.getField(this, 27) != null;
        }
        get packets_discarded_on_send() {
            return pb_1.Message.getFieldWithDefault(this, 28, 0) as number;
        }
        set packets_discarded_on_send(value: number) {
            pb_1.Message.setOneofField(this, 28, this.#one_of_decls[23], value);
        }
        get has_packets_discarded_on_send() {
            return pb_1.Message.getField(this, 28) != null;
        }
        get bytes_discarded_on_send() {
            return pb_1.Message.getFieldWithDefault(this, 29, 0) as number;
        }
        set bytes_discarded_on_send(value: number) {
            pb_1.Message.setOneofField(this, 29, this.#one_of_decls[24], value);
        }
        get has_bytes_discarded_on_send() {
            return pb_1.Message.getField(this, 29) != null;
        }
        get request_bytes_sent() {
            return pb_1.Message.getFieldWithDefault(this, 30, 0) as number;
        }
        set request_bytes_sent(value: number) {
            pb_1.Message.setOneofField(this, 30, this.#one_of_decls[25], value);
        }
        get has_request_bytes_sent() {
            return pb_1.Message.getField(this, 30) != null;
        }
        get consent_request_bytes_sent() {
            return pb_1.Message.getFieldWithDefault(this, 31, 0) as number;
        }
        set consent_request_bytes_sent(value: number) {
            pb_1.Message.setOneofField(this, 31, this.#one_of_decls[26], value);
        }
        get has_consent_request_bytes_sent() {
            return pb_1.Message.getField(this, 31) != null;
        }
        get response_bytes_sent() {
            return pb_1.Message.getFieldWithDefault(this, 32, 0) as number;
        }
        set response_bytes_sent(value: number) {
            pb_1.Message.setOneofField(this, 32, this.#one_of_decls[27], value);
        }
        get has_response_bytes_sent() {
            return pb_1.Message.getField(this, 32) != null;
        }
        get _nominated() {
            const cases: {
                [index: number]: "none" | "nominated";
            } = {
                0: "none",
                5: "nominated"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _packets_sent() {
            const cases: {
                [index: number]: "none" | "packets_sent";
            } = {
                0: "none",
                6: "packets_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _packets_received() {
            const cases: {
                [index: number]: "none" | "packets_received";
            } = {
                0: "none",
                7: "packets_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        get _bytes_sent() {
            const cases: {
                [index: number]: "none" | "bytes_sent";
            } = {
                0: "none",
                8: "bytes_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [8])];
        }
        get _bytes_received() {
            const cases: {
                [index: number]: "none" | "bytes_received";
            } = {
                0: "none",
                9: "bytes_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [9])];
        }
        get _last_packet_sent_timestamp() {
            const cases: {
                [index: number]: "none" | "last_packet_sent_timestamp";
            } = {
                0: "none",
                10: "last_packet_sent_timestamp"
            };
            return cases[pb_1.Message.computeOneofCase(this, [10])];
        }
        get _last_packet_received_timestamp() {
            const cases: {
                [index: number]: "none" | "last_packet_received_timestamp";
            } = {
                0: "none",
                11: "last_packet_received_timestamp"
            };
            return cases[pb_1.Message.computeOneofCase(this, [11])];
        }
        get _first_request_timestamp() {
            const cases: {
                [index: number]: "none" | "first_request_timestamp";
            } = {
                0: "none",
                12: "first_request_timestamp"
            };
            return cases[pb_1.Message.computeOneofCase(this, [12])];
        }
        get _last_request_timestamp() {
            const cases: {
                [index: number]: "none" | "last_request_timestamp";
            } = {
                0: "none",
                13: "last_request_timestamp"
            };
            return cases[pb_1.Message.computeOneofCase(this, [13])];
        }
        get _last_response_timestamp() {
            const cases: {
                [index: number]: "none" | "last_response_timestamp";
            } = {
                0: "none",
                14: "last_response_timestamp"
            };
            return cases[pb_1.Message.computeOneofCase(this, [14])];
        }
        get _total_round_trip_time() {
            const cases: {
                [index: number]: "none" | "total_round_trip_time";
            } = {
                0: "none",
                15: "total_round_trip_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [15])];
        }
        get _current_round_trip_time() {
            const cases: {
                [index: number]: "none" | "current_round_trip_time";
            } = {
                0: "none",
                16: "current_round_trip_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [16])];
        }
        get _available_outgoing_bitrate() {
            const cases: {
                [index: number]: "none" | "available_outgoing_bitrate";
            } = {
                0: "none",
                17: "available_outgoing_bitrate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [17])];
        }
        get _available_incoming_bitrate() {
            const cases: {
                [index: number]: "none" | "available_incoming_bitrate";
            } = {
                0: "none",
                18: "available_incoming_bitrate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [18])];
        }
        get _circuit_breaker_trigger_count() {
            const cases: {
                [index: number]: "none" | "circuit_breaker_trigger_count";
            } = {
                0: "none",
                19: "circuit_breaker_trigger_count"
            };
            return cases[pb_1.Message.computeOneofCase(this, [19])];
        }
        get _requests_received() {
            const cases: {
                [index: number]: "none" | "requests_received";
            } = {
                0: "none",
                20: "requests_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [20])];
        }
        get _requests_sent() {
            const cases: {
                [index: number]: "none" | "requests_sent";
            } = {
                0: "none",
                21: "requests_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [21])];
        }
        get _responses_received() {
            const cases: {
                [index: number]: "none" | "responses_received";
            } = {
                0: "none",
                22: "responses_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [22])];
        }
        get _responses_sent() {
            const cases: {
                [index: number]: "none" | "responses_sent";
            } = {
                0: "none",
                23: "responses_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [23])];
        }
        get _retransmissions_received() {
            const cases: {
                [index: number]: "none" | "retransmissions_received";
            } = {
                0: "none",
                24: "retransmissions_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [24])];
        }
        get _retransmissions_sent() {
            const cases: {
                [index: number]: "none" | "retransmissions_sent";
            } = {
                0: "none",
                25: "retransmissions_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [25])];
        }
        get _consent_requests_sent() {
            const cases: {
                [index: number]: "none" | "consent_requests_sent";
            } = {
                0: "none",
                26: "consent_requests_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [26])];
        }
        get _consent_expired_timestamp() {
            const cases: {
                [index: number]: "none" | "consent_expired_timestamp";
            } = {
                0: "none",
                27: "consent_expired_timestamp"
            };
            return cases[pb_1.Message.computeOneofCase(this, [27])];
        }
        get _packets_discarded_on_send() {
            const cases: {
                [index: number]: "none" | "packets_discarded_on_send";
            } = {
                0: "none",
                28: "packets_discarded_on_send"
            };
            return cases[pb_1.Message.computeOneofCase(this, [28])];
        }
        get _bytes_discarded_on_send() {
            const cases: {
                [index: number]: "none" | "bytes_discarded_on_send";
            } = {
                0: "none",
                29: "bytes_discarded_on_send"
            };
            return cases[pb_1.Message.computeOneofCase(this, [29])];
        }
        get _request_bytes_sent() {
            const cases: {
                [index: number]: "none" | "request_bytes_sent";
            } = {
                0: "none",
                30: "request_bytes_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [30])];
        }
        get _consent_request_bytes_sent() {
            const cases: {
                [index: number]: "none" | "consent_request_bytes_sent";
            } = {
                0: "none",
                31: "consent_request_bytes_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [31])];
        }
        get _response_bytes_sent() {
            const cases: {
                [index: number]: "none" | "response_bytes_sent";
            } = {
                0: "none",
                32: "response_bytes_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [32])];
        }
        static fromObject(data: {
            transport_id?: string;
            local_candidate_id?: string;
            remote_candidate_id?: string;
            state?: RTCStatsIceCandidatePairState;
            nominated?: boolean;
            packets_sent?: number;
            packets_received?: number;
            bytes_sent?: number;
            bytes_received?: number;
            last_packet_sent_timestamp?: number;
            last_packet_received_timestamp?: number;
            first_request_timestamp?: number;
            last_request_timestamp?: number;
            last_response_timestamp?: number;
            total_round_trip_time?: number;
            current_round_trip_time?: number;
            available_outgoing_bitrate?: number;
            available_incoming_bitrate?: number;
            circuit_breaker_trigger_count?: number;
            requests_received?: number;
            requests_sent?: number;
            responses_received?: number;
            responses_sent?: number;
            retransmissions_received?: number;
            retransmissions_sent?: number;
            consent_requests_sent?: number;
            consent_expired_timestamp?: number;
            packets_discarded_on_send?: number;
            bytes_discarded_on_send?: number;
            request_bytes_sent?: number;
            consent_request_bytes_sent?: number;
            response_bytes_sent?: number;
        }): RTCIceCandidatePairStats {
            const message = new RTCIceCandidatePairStats({});
            if (data.transport_id != null) {
                message.transport_id = data.transport_id;
            }
            if (data.local_candidate_id != null) {
                message.local_candidate_id = data.local_candidate_id;
            }
            if (data.remote_candidate_id != null) {
                message.remote_candidate_id = data.remote_candidate_id;
            }
            if (data.state != null) {
                message.state = data.state;
            }
            if (data.nominated != null) {
                message.nominated = data.nominated;
            }
            if (data.packets_sent != null) {
                message.packets_sent = data.packets_sent;
            }
            if (data.packets_received != null) {
                message.packets_received = data.packets_received;
            }
            if (data.bytes_sent != null) {
                message.bytes_sent = data.bytes_sent;
            }
            if (data.bytes_received != null) {
                message.bytes_received = data.bytes_received;
            }
            if (data.last_packet_sent_timestamp != null) {
                message.last_packet_sent_timestamp = data.last_packet_sent_timestamp;
            }
            if (data.last_packet_received_timestamp != null) {
                message.last_packet_received_timestamp = data.last_packet_received_timestamp;
            }
            if (data.first_request_timestamp != null) {
                message.first_request_timestamp = data.first_request_timestamp;
            }
            if (data.last_request_timestamp != null) {
                message.last_request_timestamp = data.last_request_timestamp;
            }
            if (data.last_response_timestamp != null) {
                message.last_response_timestamp = data.last_response_timestamp;
            }
            if (data.total_round_trip_time != null) {
                message.total_round_trip_time = data.total_round_trip_time;
            }
            if (data.current_round_trip_time != null) {
                message.current_round_trip_time = data.current_round_trip_time;
            }
            if (data.available_outgoing_bitrate != null) {
                message.available_outgoing_bitrate = data.available_outgoing_bitrate;
            }
            if (data.available_incoming_bitrate != null) {
                message.available_incoming_bitrate = data.available_incoming_bitrate;
            }
            if (data.circuit_breaker_trigger_count != null) {
                message.circuit_breaker_trigger_count = data.circuit_breaker_trigger_count;
            }
            if (data.requests_received != null) {
                message.requests_received = data.requests_received;
            }
            if (data.requests_sent != null) {
                message.requests_sent = data.requests_sent;
            }
            if (data.responses_received != null) {
                message.responses_received = data.responses_received;
            }
            if (data.responses_sent != null) {
                message.responses_sent = data.responses_sent;
            }
            if (data.retransmissions_received != null) {
                message.retransmissions_received = data.retransmissions_received;
            }
            if (data.retransmissions_sent != null) {
                message.retransmissions_sent = data.retransmissions_sent;
            }
            if (data.consent_requests_sent != null) {
                message.consent_requests_sent = data.consent_requests_sent;
            }
            if (data.consent_expired_timestamp != null) {
                message.consent_expired_timestamp = data.consent_expired_timestamp;
            }
            if (data.packets_discarded_on_send != null) {
                message.packets_discarded_on_send = data.packets_discarded_on_send;
            }
            if (data.bytes_discarded_on_send != null) {
                message.bytes_discarded_on_send = data.bytes_discarded_on_send;
            }
            if (data.request_bytes_sent != null) {
                message.request_bytes_sent = data.request_bytes_sent;
            }
            if (data.consent_request_bytes_sent != null) {
                message.consent_request_bytes_sent = data.consent_request_bytes_sent;
            }
            if (data.response_bytes_sent != null) {
                message.response_bytes_sent = data.response_bytes_sent;
            }
            return message;
        }
        toObject() {
            const data: {
                transport_id?: string;
                local_candidate_id?: string;
                remote_candidate_id?: string;
                state?: RTCStatsIceCandidatePairState;
                nominated?: boolean;
                packets_sent?: number;
                packets_received?: number;
                bytes_sent?: number;
                bytes_received?: number;
                last_packet_sent_timestamp?: number;
                last_packet_received_timestamp?: number;
                first_request_timestamp?: number;
                last_request_timestamp?: number;
                last_response_timestamp?: number;
                total_round_trip_time?: number;
                current_round_trip_time?: number;
                available_outgoing_bitrate?: number;
                available_incoming_bitrate?: number;
                circuit_breaker_trigger_count?: number;
                requests_received?: number;
                requests_sent?: number;
                responses_received?: number;
                responses_sent?: number;
                retransmissions_received?: number;
                retransmissions_sent?: number;
                consent_requests_sent?: number;
                consent_expired_timestamp?: number;
                packets_discarded_on_send?: number;
                bytes_discarded_on_send?: number;
                request_bytes_sent?: number;
                consent_request_bytes_sent?: number;
                response_bytes_sent?: number;
            } = {};
            if (this.transport_id != null) {
                data.transport_id = this.transport_id;
            }
            if (this.local_candidate_id != null) {
                data.local_candidate_id = this.local_candidate_id;
            }
            if (this.remote_candidate_id != null) {
                data.remote_candidate_id = this.remote_candidate_id;
            }
            if (this.state != null) {
                data.state = this.state;
            }
            if (this.nominated != null) {
                data.nominated = this.nominated;
            }
            if (this.packets_sent != null) {
                data.packets_sent = this.packets_sent;
            }
            if (this.packets_received != null) {
                data.packets_received = this.packets_received;
            }
            if (this.bytes_sent != null) {
                data.bytes_sent = this.bytes_sent;
            }
            if (this.bytes_received != null) {
                data.bytes_received = this.bytes_received;
            }
            if (this.last_packet_sent_timestamp != null) {
                data.last_packet_sent_timestamp = this.last_packet_sent_timestamp;
            }
            if (this.last_packet_received_timestamp != null) {
                data.last_packet_received_timestamp = this.last_packet_received_timestamp;
            }
            if (this.first_request_timestamp != null) {
                data.first_request_timestamp = this.first_request_timestamp;
            }
            if (this.last_request_timestamp != null) {
                data.last_request_timestamp = this.last_request_timestamp;
            }
            if (this.last_response_timestamp != null) {
                data.last_response_timestamp = this.last_response_timestamp;
            }
            if (this.total_round_trip_time != null) {
                data.total_round_trip_time = this.total_round_trip_time;
            }
            if (this.current_round_trip_time != null) {
                data.current_round_trip_time = this.current_round_trip_time;
            }
            if (this.available_outgoing_bitrate != null) {
                data.available_outgoing_bitrate = this.available_outgoing_bitrate;
            }
            if (this.available_incoming_bitrate != null) {
                data.available_incoming_bitrate = this.available_incoming_bitrate;
            }
            if (this.circuit_breaker_trigger_count != null) {
                data.circuit_breaker_trigger_count = this.circuit_breaker_trigger_count;
            }
            if (this.requests_received != null) {
                data.requests_received = this.requests_received;
            }
            if (this.requests_sent != null) {
                data.requests_sent = this.requests_sent;
            }
            if (this.responses_received != null) {
                data.responses_received = this.responses_received;
            }
            if (this.responses_sent != null) {
                data.responses_sent = this.responses_sent;
            }
            if (this.retransmissions_received != null) {
                data.retransmissions_received = this.retransmissions_received;
            }
            if (this.retransmissions_sent != null) {
                data.retransmissions_sent = this.retransmissions_sent;
            }
            if (this.consent_requests_sent != null) {
                data.consent_requests_sent = this.consent_requests_sent;
            }
            if (this.consent_expired_timestamp != null) {
                data.consent_expired_timestamp = this.consent_expired_timestamp;
            }
            if (this.packets_discarded_on_send != null) {
                data.packets_discarded_on_send = this.packets_discarded_on_send;
            }
            if (this.bytes_discarded_on_send != null) {
                data.bytes_discarded_on_send = this.bytes_discarded_on_send;
            }
            if (this.request_bytes_sent != null) {
                data.request_bytes_sent = this.request_bytes_sent;
            }
            if (this.consent_request_bytes_sent != null) {
                data.consent_request_bytes_sent = this.consent_request_bytes_sent;
            }
            if (this.response_bytes_sent != null) {
                data.response_bytes_sent = this.response_bytes_sent;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.transport_id.length)
                writer.writeString(1, this.transport_id);
            if (this.local_candidate_id.length)
                writer.writeString(2, this.local_candidate_id);
            if (this.remote_candidate_id.length)
                writer.writeString(3, this.remote_candidate_id);
            if (this.state != RTCStatsIceCandidatePairState.Frozen)
                writer.writeEnum(4, this.state);
            if (this.has_nominated)
                writer.writeBool(5, this.nominated);
            if (this.has_packets_sent)
                writer.writeFixed64(6, this.packets_sent);
            if (this.has_packets_received)
                writer.writeFixed64(7, this.packets_received);
            if (this.has_bytes_sent)
                writer.writeFixed64(8, this.bytes_sent);
            if (this.has_bytes_received)
                writer.writeFixed64(9, this.bytes_received);
            if (this.has_last_packet_sent_timestamp)
                writer.writeDouble(10, this.last_packet_sent_timestamp);
            if (this.has_last_packet_received_timestamp)
                writer.writeDouble(11, this.last_packet_received_timestamp);
            if (this.has_first_request_timestamp)
                writer.writeDouble(12, this.first_request_timestamp);
            if (this.has_last_request_timestamp)
                writer.writeDouble(13, this.last_request_timestamp);
            if (this.has_last_response_timestamp)
                writer.writeDouble(14, this.last_response_timestamp);
            if (this.has_total_round_trip_time)
                writer.writeFloat(15, this.total_round_trip_time);
            if (this.has_current_round_trip_time)
                writer.writeFloat(16, this.current_round_trip_time);
            if (this.has_available_outgoing_bitrate)
                writer.writeFloat(17, this.available_outgoing_bitrate);
            if (this.has_available_incoming_bitrate)
                writer.writeFloat(18, this.available_incoming_bitrate);
            if (this.has_circuit_breaker_trigger_count)
                writer.writeFixed32(19, this.circuit_breaker_trigger_count);
            if (this.has_requests_received)
                writer.writeFixed64(20, this.requests_received);
            if (this.has_requests_sent)
                writer.writeFixed64(21, this.requests_sent);
            if (this.has_responses_received)
                writer.writeFixed64(22, this.responses_received);
            if (this.has_responses_sent)
                writer.writeFixed64(23, this.responses_sent);
            if (this.has_retransmissions_received)
                writer.writeFixed64(24, this.retransmissions_received);
            if (this.has_retransmissions_sent)
                writer.writeFixed64(25, this.retransmissions_sent);
            if (this.has_consent_requests_sent)
                writer.writeFixed64(26, this.consent_requests_sent);
            if (this.has_consent_expired_timestamp)
                writer.writeDouble(27, this.consent_expired_timestamp);
            if (this.has_packets_discarded_on_send)
                writer.writeFixed32(28, this.packets_discarded_on_send);
            if (this.has_bytes_discarded_on_send)
                writer.writeFixed64(29, this.bytes_discarded_on_send);
            if (this.has_request_bytes_sent)
                writer.writeFixed64(30, this.request_bytes_sent);
            if (this.has_consent_request_bytes_sent)
                writer.writeFixed64(31, this.consent_request_bytes_sent);
            if (this.has_response_bytes_sent)
                writer.writeFixed64(32, this.response_bytes_sent);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCIceCandidatePairStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCIceCandidatePairStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.transport_id = reader.readString();
                        break;
                    case 2:
                        message.local_candidate_id = reader.readString();
                        break;
                    case 3:
                        message.remote_candidate_id = reader.readString();
                        break;
                    case 4:
                        message.state = reader.readEnum();
                        break;
                    case 5:
                        message.nominated = reader.readBool();
                        break;
                    case 6:
                        message.packets_sent = reader.readFixed64();
                        break;
                    case 7:
                        message.packets_received = reader.readFixed64();
                        break;
                    case 8:
                        message.bytes_sent = reader.readFixed64();
                        break;
                    case 9:
                        message.bytes_received = reader.readFixed64();
                        break;
                    case 10:
                        message.last_packet_sent_timestamp = reader.readDouble();
                        break;
                    case 11:
                        message.last_packet_received_timestamp = reader.readDouble();
                        break;
                    case 12:
                        message.first_request_timestamp = reader.readDouble();
                        break;
                    case 13:
                        message.last_request_timestamp = reader.readDouble();
                        break;
                    case 14:
                        message.last_response_timestamp = reader.readDouble();
                        break;
                    case 15:
                        message.total_round_trip_time = reader.readFloat();
                        break;
                    case 16:
                        message.current_round_trip_time = reader.readFloat();
                        break;
                    case 17:
                        message.available_outgoing_bitrate = reader.readFloat();
                        break;
                    case 18:
                        message.available_incoming_bitrate = reader.readFloat();
                        break;
                    case 19:
                        message.circuit_breaker_trigger_count = reader.readFixed32();
                        break;
                    case 20:
                        message.requests_received = reader.readFixed64();
                        break;
                    case 21:
                        message.requests_sent = reader.readFixed64();
                        break;
                    case 22:
                        message.responses_received = reader.readFixed64();
                        break;
                    case 23:
                        message.responses_sent = reader.readFixed64();
                        break;
                    case 24:
                        message.retransmissions_received = reader.readFixed64();
                        break;
                    case 25:
                        message.retransmissions_sent = reader.readFixed64();
                        break;
                    case 26:
                        message.consent_requests_sent = reader.readFixed64();
                        break;
                    case 27:
                        message.consent_expired_timestamp = reader.readDouble();
                        break;
                    case 28:
                        message.packets_discarded_on_send = reader.readFixed32();
                        break;
                    case 29:
                        message.bytes_discarded_on_send = reader.readFixed64();
                        break;
                    case 30:
                        message.request_bytes_sent = reader.readFixed64();
                        break;
                    case 31:
                        message.consent_request_bytes_sent = reader.readFixed64();
                        break;
                    case 32:
                        message.response_bytes_sent = reader.readFixed64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCIceCandidatePairStats {
            return RTCIceCandidatePairStats.deserialize(bytes);
        }
    }
    export class RTCIceCandidateStats extends pb_1.Message {
        #one_of_decls: number[][] = [[2], [3], [4], [6], [7], [8]];
        constructor(data?: any[] | ({
            transport_id?: string;
            candidate_type?: RTCIceCandidateType;
        } & (({
            address?: string;
        }) | ({
            port?: number;
        }) | ({
            protocol?: string;
        }) | ({
            priority?: number;
        }) | ({
            url?: string;
        }) | ({
            relay_protocol?: string;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transport_id" in data && data.transport_id != undefined) {
                    this.transport_id = data.transport_id;
                }
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("port" in data && data.port != undefined) {
                    this.port = data.port;
                }
                if ("protocol" in data && data.protocol != undefined) {
                    this.protocol = data.protocol;
                }
                if ("candidate_type" in data && data.candidate_type != undefined) {
                    this.candidate_type = data.candidate_type;
                }
                if ("priority" in data && data.priority != undefined) {
                    this.priority = data.priority;
                }
                if ("url" in data && data.url != undefined) {
                    this.url = data.url;
                }
                if ("relay_protocol" in data && data.relay_protocol != undefined) {
                    this.relay_protocol = data.relay_protocol;
                }
            }
        }
        get transport_id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set transport_id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
        }
        get has_address() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get port() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set port(value: number) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[1], value);
        }
        get has_port() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get protocol() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set protocol(value: string) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[2], value);
        }
        get has_protocol() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get candidate_type() {
            return pb_1.Message.getFieldWithDefault(this, 5, RTCIceCandidateType.Host) as RTCIceCandidateType;
        }
        set candidate_type(value: RTCIceCandidateType) {
            pb_1.Message.setField(this, 5, value);
        }
        get priority() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set priority(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[3], value);
        }
        get has_priority() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get url() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set url(value: string) {
            pb_1.Message.setOneofField(this, 7, this.#one_of_decls[4], value);
        }
        get has_url() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get relay_protocol() {
            return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
        }
        set relay_protocol(value: string) {
            pb_1.Message.setOneofField(this, 8, this.#one_of_decls[5], value);
        }
        get has_relay_protocol() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get _address() {
            const cases: {
                [index: number]: "none" | "address";
            } = {
                0: "none",
                2: "address"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _port() {
            const cases: {
                [index: number]: "none" | "port";
            } = {
                0: "none",
                3: "port"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _protocol() {
            const cases: {
                [index: number]: "none" | "protocol";
            } = {
                0: "none",
                4: "protocol"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        get _priority() {
            const cases: {
                [index: number]: "none" | "priority";
            } = {
                0: "none",
                6: "priority"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        get _url() {
            const cases: {
                [index: number]: "none" | "url";
            } = {
                0: "none",
                7: "url"
            };
            return cases[pb_1.Message.computeOneofCase(this, [7])];
        }
        get _relay_protocol() {
            const cases: {
                [index: number]: "none" | "relay_protocol";
            } = {
                0: "none",
                8: "relay_protocol"
            };
            return cases[pb_1.Message.computeOneofCase(this, [8])];
        }
        static fromObject(data: {
            transport_id?: string;
            address?: string;
            port?: number;
            protocol?: string;
            candidate_type?: RTCIceCandidateType;
            priority?: number;
            url?: string;
            relay_protocol?: string;
        }): RTCIceCandidateStats {
            const message = new RTCIceCandidateStats({});
            if (data.transport_id != null) {
                message.transport_id = data.transport_id;
            }
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.port != null) {
                message.port = data.port;
            }
            if (data.protocol != null) {
                message.protocol = data.protocol;
            }
            if (data.candidate_type != null) {
                message.candidate_type = data.candidate_type;
            }
            if (data.priority != null) {
                message.priority = data.priority;
            }
            if (data.url != null) {
                message.url = data.url;
            }
            if (data.relay_protocol != null) {
                message.relay_protocol = data.relay_protocol;
            }
            return message;
        }
        toObject() {
            const data: {
                transport_id?: string;
                address?: string;
                port?: number;
                protocol?: string;
                candidate_type?: RTCIceCandidateType;
                priority?: number;
                url?: string;
                relay_protocol?: string;
            } = {};
            if (this.transport_id != null) {
                data.transport_id = this.transport_id;
            }
            if (this.address != null) {
                data.address = this.address;
            }
            if (this.port != null) {
                data.port = this.port;
            }
            if (this.protocol != null) {
                data.protocol = this.protocol;
            }
            if (this.candidate_type != null) {
                data.candidate_type = this.candidate_type;
            }
            if (this.priority != null) {
                data.priority = this.priority;
            }
            if (this.url != null) {
                data.url = this.url;
            }
            if (this.relay_protocol != null) {
                data.relay_protocol = this.relay_protocol;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.transport_id.length)
                writer.writeString(1, this.transport_id);
            if (this.has_address)
                writer.writeString(2, this.address);
            if (this.has_port)
                writer.writeSfixed32(3, this.port);
            if (this.has_protocol)
                writer.writeString(4, this.protocol);
            if (this.candidate_type != RTCIceCandidateType.Host)
                writer.writeEnum(5, this.candidate_type);
            if (this.has_priority)
                writer.writeSfixed32(6, this.priority);
            if (this.has_url)
                writer.writeString(7, this.url);
            if (this.has_relay_protocol)
                writer.writeString(8, this.relay_protocol);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCIceCandidateStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCIceCandidateStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.transport_id = reader.readString();
                        break;
                    case 2:
                        message.address = reader.readString();
                        break;
                    case 3:
                        message.port = reader.readSfixed32();
                        break;
                    case 4:
                        message.protocol = reader.readString();
                        break;
                    case 5:
                        message.candidate_type = reader.readEnum();
                        break;
                    case 6:
                        message.priority = reader.readSfixed32();
                        break;
                    case 7:
                        message.url = reader.readString();
                        break;
                    case 8:
                        message.relay_protocol = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCIceCandidateStats {
            return RTCIceCandidateStats.deserialize(bytes);
        }
    }
    export class RTCCertificateStats extends pb_1.Message {
        #one_of_decls: number[][] = [[4]];
        constructor(data?: any[] | ({
            fingerprint?: string;
            fingerprint_algorithm?: string;
            base64_certificate?: string;
        } & (({
            issuer_certificate_id?: string;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("fingerprint" in data && data.fingerprint != undefined) {
                    this.fingerprint = data.fingerprint;
                }
                if ("fingerprint_algorithm" in data && data.fingerprint_algorithm != undefined) {
                    this.fingerprint_algorithm = data.fingerprint_algorithm;
                }
                if ("base64_certificate" in data && data.base64_certificate != undefined) {
                    this.base64_certificate = data.base64_certificate;
                }
                if ("issuer_certificate_id" in data && data.issuer_certificate_id != undefined) {
                    this.issuer_certificate_id = data.issuer_certificate_id;
                }
            }
        }
        get fingerprint() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set fingerprint(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get fingerprint_algorithm() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set fingerprint_algorithm(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get base64_certificate() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set base64_certificate(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get issuer_certificate_id() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set issuer_certificate_id(value: string) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0], value);
        }
        get has_issuer_certificate_id() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get _issuer_certificate_id() {
            const cases: {
                [index: number]: "none" | "issuer_certificate_id";
            } = {
                0: "none",
                4: "issuer_certificate_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        static fromObject(data: {
            fingerprint?: string;
            fingerprint_algorithm?: string;
            base64_certificate?: string;
            issuer_certificate_id?: string;
        }): RTCCertificateStats {
            const message = new RTCCertificateStats({});
            if (data.fingerprint != null) {
                message.fingerprint = data.fingerprint;
            }
            if (data.fingerprint_algorithm != null) {
                message.fingerprint_algorithm = data.fingerprint_algorithm;
            }
            if (data.base64_certificate != null) {
                message.base64_certificate = data.base64_certificate;
            }
            if (data.issuer_certificate_id != null) {
                message.issuer_certificate_id = data.issuer_certificate_id;
            }
            return message;
        }
        toObject() {
            const data: {
                fingerprint?: string;
                fingerprint_algorithm?: string;
                base64_certificate?: string;
                issuer_certificate_id?: string;
            } = {};
            if (this.fingerprint != null) {
                data.fingerprint = this.fingerprint;
            }
            if (this.fingerprint_algorithm != null) {
                data.fingerprint_algorithm = this.fingerprint_algorithm;
            }
            if (this.base64_certificate != null) {
                data.base64_certificate = this.base64_certificate;
            }
            if (this.issuer_certificate_id != null) {
                data.issuer_certificate_id = this.issuer_certificate_id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.fingerprint.length)
                writer.writeString(1, this.fingerprint);
            if (this.fingerprint_algorithm.length)
                writer.writeString(2, this.fingerprint_algorithm);
            if (this.base64_certificate.length)
                writer.writeString(3, this.base64_certificate);
            if (this.has_issuer_certificate_id)
                writer.writeString(4, this.issuer_certificate_id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCCertificateStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCCertificateStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.fingerprint = reader.readString();
                        break;
                    case 2:
                        message.fingerprint_algorithm = reader.readString();
                        break;
                    case 3:
                        message.base64_certificate = reader.readString();
                        break;
                    case 4:
                        message.issuer_certificate_id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCCertificateStats {
            return RTCCertificateStats.deserialize(bytes);
        }
    }
    export class RTCIceServerStats extends pb_1.Message {
        #one_of_decls: number[][] = [[2], [3], [4], [5], [6]];
        constructor(data?: any[] | ({
            url?: string;
        } & (({
            port?: number;
        }) | ({
            relay_protocol?: string;
        }) | ({
            total_requests_sent?: number;
        }) | ({
            total_responses_received?: number;
        }) | ({
            total_round_trip_time?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("url" in data && data.url != undefined) {
                    this.url = data.url;
                }
                if ("port" in data && data.port != undefined) {
                    this.port = data.port;
                }
                if ("relay_protocol" in data && data.relay_protocol != undefined) {
                    this.relay_protocol = data.relay_protocol;
                }
                if ("total_requests_sent" in data && data.total_requests_sent != undefined) {
                    this.total_requests_sent = data.total_requests_sent;
                }
                if ("total_responses_received" in data && data.total_responses_received != undefined) {
                    this.total_responses_received = data.total_responses_received;
                }
                if ("total_round_trip_time" in data && data.total_round_trip_time != undefined) {
                    this.total_round_trip_time = data.total_round_trip_time;
                }
            }
        }
        get url() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set url(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get port() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set port(value: number) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
        }
        get has_port() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get relay_protocol() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set relay_protocol(value: string) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[1], value);
        }
        get has_relay_protocol() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get total_requests_sent() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set total_requests_sent(value: number) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[2], value);
        }
        get has_total_requests_sent() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get total_responses_received() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set total_responses_received(value: number) {
            pb_1.Message.setOneofField(this, 5, this.#one_of_decls[3], value);
        }
        get has_total_responses_received() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get total_round_trip_time() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set total_round_trip_time(value: number) {
            pb_1.Message.setOneofField(this, 6, this.#one_of_decls[4], value);
        }
        get has_total_round_trip_time() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get _port() {
            const cases: {
                [index: number]: "none" | "port";
            } = {
                0: "none",
                2: "port"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _relay_protocol() {
            const cases: {
                [index: number]: "none" | "relay_protocol";
            } = {
                0: "none",
                3: "relay_protocol"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _total_requests_sent() {
            const cases: {
                [index: number]: "none" | "total_requests_sent";
            } = {
                0: "none",
                4: "total_requests_sent"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        get _total_responses_received() {
            const cases: {
                [index: number]: "none" | "total_responses_received";
            } = {
                0: "none",
                5: "total_responses_received"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        get _total_round_trip_time() {
            const cases: {
                [index: number]: "none" | "total_round_trip_time";
            } = {
                0: "none",
                6: "total_round_trip_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])];
        }
        static fromObject(data: {
            url?: string;
            port?: number;
            relay_protocol?: string;
            total_requests_sent?: number;
            total_responses_received?: number;
            total_round_trip_time?: number;
        }): RTCIceServerStats {
            const message = new RTCIceServerStats({});
            if (data.url != null) {
                message.url = data.url;
            }
            if (data.port != null) {
                message.port = data.port;
            }
            if (data.relay_protocol != null) {
                message.relay_protocol = data.relay_protocol;
            }
            if (data.total_requests_sent != null) {
                message.total_requests_sent = data.total_requests_sent;
            }
            if (data.total_responses_received != null) {
                message.total_responses_received = data.total_responses_received;
            }
            if (data.total_round_trip_time != null) {
                message.total_round_trip_time = data.total_round_trip_time;
            }
            return message;
        }
        toObject() {
            const data: {
                url?: string;
                port?: number;
                relay_protocol?: string;
                total_requests_sent?: number;
                total_responses_received?: number;
                total_round_trip_time?: number;
            } = {};
            if (this.url != null) {
                data.url = this.url;
            }
            if (this.port != null) {
                data.port = this.port;
            }
            if (this.relay_protocol != null) {
                data.relay_protocol = this.relay_protocol;
            }
            if (this.total_requests_sent != null) {
                data.total_requests_sent = this.total_requests_sent;
            }
            if (this.total_responses_received != null) {
                data.total_responses_received = this.total_responses_received;
            }
            if (this.total_round_trip_time != null) {
                data.total_round_trip_time = this.total_round_trip_time;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.url.length)
                writer.writeString(1, this.url);
            if (this.has_port)
                writer.writeSfixed32(2, this.port);
            if (this.has_relay_protocol)
                writer.writeString(3, this.relay_protocol);
            if (this.has_total_requests_sent)
                writer.writeFixed32(4, this.total_requests_sent);
            if (this.has_total_responses_received)
                writer.writeFixed32(5, this.total_responses_received);
            if (this.has_total_round_trip_time)
                writer.writeFloat(6, this.total_round_trip_time);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCIceServerStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCIceServerStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.url = reader.readString();
                        break;
                    case 2:
                        message.port = reader.readSfixed32();
                        break;
                    case 3:
                        message.relay_protocol = reader.readString();
                        break;
                    case 4:
                        message.total_requests_sent = reader.readFixed32();
                        break;
                    case 5:
                        message.total_responses_received = reader.readFixed32();
                        break;
                    case 6:
                        message.total_round_trip_time = reader.readFloat();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCIceServerStats {
            return RTCIceServerStats.deserialize(bytes);
        }
    }
    export class RTCStats extends pb_1.Message {
        #one_of_decls: number[][] = [[3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]];
        constructor(data?: any[] | ({
            timestamp?: number;
            id?: string;
        } & (({
            media_source_audio?: RTCAudioSourceStats;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: RTCVideoSourceStats;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: RTCAudioReceiverStats;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: RTCAudioSenderStats;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: RTCVideoSenderStats;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: RTCVideoReceiverStats;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: RTCOutboundRtpStreamStats;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: RTCRemoteOutboundRtpStreamStats;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: RTCInboundRtpStreamStats;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: RTCRemoteInboundRtpStreamStats;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: RTCCodecStats;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: RTCRtpContributingSourceStats;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: RTCPeerConnectionStats;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: RTCDataChannelStats;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: RTCRtpTransceiverStats;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: RTCTransportStats;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: RTCSctpTransportStats;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: RTCIceCandidatePairStats;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: RTCIceCandidateStats;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: RTCIceCandidateStats;
            certificate?: never;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: RTCCertificateStats;
            ice_server?: never;
        } | {
            media_source_audio?: never;
            media_source_video?: never;
            receiver_audio?: never;
            sender_audio?: never;
            sender_video?: never;
            receiver_video?: never;
            outbound_rtp?: never;
            remote_outbound_rtp?: never;
            inbound_rtp?: never;
            remote_inbound_rtp?: never;
            codec?: never;
            csrc?: never;
            peer_connection?: never;
            data_channel?: never;
            transceiver?: never;
            transport?: never;
            sctp_transport?: never;
            candidate_pair?: never;
            local_candidate?: never;
            remote_candidate?: never;
            certificate?: never;
            ice_server?: RTCIceServerStats;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("timestamp" in data && data.timestamp != undefined) {
                    this.timestamp = data.timestamp;
                }
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("media_source_audio" in data && data.media_source_audio != undefined) {
                    this.media_source_audio = data.media_source_audio;
                }
                if ("media_source_video" in data && data.media_source_video != undefined) {
                    this.media_source_video = data.media_source_video;
                }
                if ("receiver_audio" in data && data.receiver_audio != undefined) {
                    this.receiver_audio = data.receiver_audio;
                }
                if ("sender_audio" in data && data.sender_audio != undefined) {
                    this.sender_audio = data.sender_audio;
                }
                if ("sender_video" in data && data.sender_video != undefined) {
                    this.sender_video = data.sender_video;
                }
                if ("receiver_video" in data && data.receiver_video != undefined) {
                    this.receiver_video = data.receiver_video;
                }
                if ("outbound_rtp" in data && data.outbound_rtp != undefined) {
                    this.outbound_rtp = data.outbound_rtp;
                }
                if ("remote_outbound_rtp" in data && data.remote_outbound_rtp != undefined) {
                    this.remote_outbound_rtp = data.remote_outbound_rtp;
                }
                if ("inbound_rtp" in data && data.inbound_rtp != undefined) {
                    this.inbound_rtp = data.inbound_rtp;
                }
                if ("remote_inbound_rtp" in data && data.remote_inbound_rtp != undefined) {
                    this.remote_inbound_rtp = data.remote_inbound_rtp;
                }
                if ("codec" in data && data.codec != undefined) {
                    this.codec = data.codec;
                }
                if ("csrc" in data && data.csrc != undefined) {
                    this.csrc = data.csrc;
                }
                if ("peer_connection" in data && data.peer_connection != undefined) {
                    this.peer_connection = data.peer_connection;
                }
                if ("data_channel" in data && data.data_channel != undefined) {
                    this.data_channel = data.data_channel;
                }
                if ("transceiver" in data && data.transceiver != undefined) {
                    this.transceiver = data.transceiver;
                }
                if ("transport" in data && data.transport != undefined) {
                    this.transport = data.transport;
                }
                if ("sctp_transport" in data && data.sctp_transport != undefined) {
                    this.sctp_transport = data.sctp_transport;
                }
                if ("candidate_pair" in data && data.candidate_pair != undefined) {
                    this.candidate_pair = data.candidate_pair;
                }
                if ("local_candidate" in data && data.local_candidate != undefined) {
                    this.local_candidate = data.local_candidate;
                }
                if ("remote_candidate" in data && data.remote_candidate != undefined) {
                    this.remote_candidate = data.remote_candidate;
                }
                if ("certificate" in data && data.certificate != undefined) {
                    this.certificate = data.certificate;
                }
                if ("ice_server" in data && data.ice_server != undefined) {
                    this.ice_server = data.ice_server;
                }
            }
        }
        get timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set timestamp(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get media_source_audio() {
            return pb_1.Message.getWrapperField(this, RTCAudioSourceStats, 3) as RTCAudioSourceStats;
        }
        set media_source_audio(value: RTCAudioSourceStats) {
            pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
        }
        get has_media_source_audio() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get media_source_video() {
            return pb_1.Message.getWrapperField(this, RTCVideoSourceStats, 4) as RTCVideoSourceStats;
        }
        set media_source_video(value: RTCVideoSourceStats) {
            pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
        }
        get has_media_source_video() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get receiver_audio() {
            return pb_1.Message.getWrapperField(this, RTCAudioReceiverStats, 5) as RTCAudioReceiverStats;
        }
        set receiver_audio(value: RTCAudioReceiverStats) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
        }
        get has_receiver_audio() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get sender_audio() {
            return pb_1.Message.getWrapperField(this, RTCAudioSenderStats, 6) as RTCAudioSenderStats;
        }
        set sender_audio(value: RTCAudioSenderStats) {
            pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
        }
        get has_sender_audio() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get sender_video() {
            return pb_1.Message.getWrapperField(this, RTCVideoSenderStats, 7) as RTCVideoSenderStats;
        }
        set sender_video(value: RTCVideoSenderStats) {
            pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
        }
        get has_sender_video() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get receiver_video() {
            return pb_1.Message.getWrapperField(this, RTCVideoReceiverStats, 8) as RTCVideoReceiverStats;
        }
        set receiver_video(value: RTCVideoReceiverStats) {
            pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
        }
        get has_receiver_video() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get outbound_rtp() {
            return pb_1.Message.getWrapperField(this, RTCOutboundRtpStreamStats, 9) as RTCOutboundRtpStreamStats;
        }
        set outbound_rtp(value: RTCOutboundRtpStreamStats) {
            pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0], value);
        }
        get has_outbound_rtp() {
            return pb_1.Message.getField(this, 9) != null;
        }
        get remote_outbound_rtp() {
            return pb_1.Message.getWrapperField(this, RTCRemoteOutboundRtpStreamStats, 10) as RTCRemoteOutboundRtpStreamStats;
        }
        set remote_outbound_rtp(value: RTCRemoteOutboundRtpStreamStats) {
            pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0], value);
        }
        get has_remote_outbound_rtp() {
            return pb_1.Message.getField(this, 10) != null;
        }
        get inbound_rtp() {
            return pb_1.Message.getWrapperField(this, RTCInboundRtpStreamStats, 11) as RTCInboundRtpStreamStats;
        }
        set inbound_rtp(value: RTCInboundRtpStreamStats) {
            pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0], value);
        }
        get has_inbound_rtp() {
            return pb_1.Message.getField(this, 11) != null;
        }
        get remote_inbound_rtp() {
            return pb_1.Message.getWrapperField(this, RTCRemoteInboundRtpStreamStats, 12) as RTCRemoteInboundRtpStreamStats;
        }
        set remote_inbound_rtp(value: RTCRemoteInboundRtpStreamStats) {
            pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0], value);
        }
        get has_remote_inbound_rtp() {
            return pb_1.Message.getField(this, 12) != null;
        }
        get codec() {
            return pb_1.Message.getWrapperField(this, RTCCodecStats, 13) as RTCCodecStats;
        }
        set codec(value: RTCCodecStats) {
            pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0], value);
        }
        get has_codec() {
            return pb_1.Message.getField(this, 13) != null;
        }
        get csrc() {
            return pb_1.Message.getWrapperField(this, RTCRtpContributingSourceStats, 14) as RTCRtpContributingSourceStats;
        }
        set csrc(value: RTCRtpContributingSourceStats) {
            pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0], value);
        }
        get has_csrc() {
            return pb_1.Message.getField(this, 14) != null;
        }
        get peer_connection() {
            return pb_1.Message.getWrapperField(this, RTCPeerConnectionStats, 15) as RTCPeerConnectionStats;
        }
        set peer_connection(value: RTCPeerConnectionStats) {
            pb_1.Message.setOneofWrapperField(this, 15, this.#one_of_decls[0], value);
        }
        get has_peer_connection() {
            return pb_1.Message.getField(this, 15) != null;
        }
        get data_channel() {
            return pb_1.Message.getWrapperField(this, RTCDataChannelStats, 16) as RTCDataChannelStats;
        }
        set data_channel(value: RTCDataChannelStats) {
            pb_1.Message.setOneofWrapperField(this, 16, this.#one_of_decls[0], value);
        }
        get has_data_channel() {
            return pb_1.Message.getField(this, 16) != null;
        }
        get transceiver() {
            return pb_1.Message.getWrapperField(this, RTCRtpTransceiverStats, 17) as RTCRtpTransceiverStats;
        }
        set transceiver(value: RTCRtpTransceiverStats) {
            pb_1.Message.setOneofWrapperField(this, 17, this.#one_of_decls[0], value);
        }
        get has_transceiver() {
            return pb_1.Message.getField(this, 17) != null;
        }
        get transport() {
            return pb_1.Message.getWrapperField(this, RTCTransportStats, 18) as RTCTransportStats;
        }
        set transport(value: RTCTransportStats) {
            pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0], value);
        }
        get has_transport() {
            return pb_1.Message.getField(this, 18) != null;
        }
        get sctp_transport() {
            return pb_1.Message.getWrapperField(this, RTCSctpTransportStats, 19) as RTCSctpTransportStats;
        }
        set sctp_transport(value: RTCSctpTransportStats) {
            pb_1.Message.setOneofWrapperField(this, 19, this.#one_of_decls[0], value);
        }
        get has_sctp_transport() {
            return pb_1.Message.getField(this, 19) != null;
        }
        get candidate_pair() {
            return pb_1.Message.getWrapperField(this, RTCIceCandidatePairStats, 20) as RTCIceCandidatePairStats;
        }
        set candidate_pair(value: RTCIceCandidatePairStats) {
            pb_1.Message.setOneofWrapperField(this, 20, this.#one_of_decls[0], value);
        }
        get has_candidate_pair() {
            return pb_1.Message.getField(this, 20) != null;
        }
        get local_candidate() {
            return pb_1.Message.getWrapperField(this, RTCIceCandidateStats, 21) as RTCIceCandidateStats;
        }
        set local_candidate(value: RTCIceCandidateStats) {
            pb_1.Message.setOneofWrapperField(this, 21, this.#one_of_decls[0], value);
        }
        get has_local_candidate() {
            return pb_1.Message.getField(this, 21) != null;
        }
        get remote_candidate() {
            return pb_1.Message.getWrapperField(this, RTCIceCandidateStats, 22) as RTCIceCandidateStats;
        }
        set remote_candidate(value: RTCIceCandidateStats) {
            pb_1.Message.setOneofWrapperField(this, 22, this.#one_of_decls[0], value);
        }
        get has_remote_candidate() {
            return pb_1.Message.getField(this, 22) != null;
        }
        get certificate() {
            return pb_1.Message.getWrapperField(this, RTCCertificateStats, 23) as RTCCertificateStats;
        }
        set certificate(value: RTCCertificateStats) {
            pb_1.Message.setOneofWrapperField(this, 23, this.#one_of_decls[0], value);
        }
        get has_certificate() {
            return pb_1.Message.getField(this, 23) != null;
        }
        get ice_server() {
            return pb_1.Message.getWrapperField(this, RTCIceServerStats, 24) as RTCIceServerStats;
        }
        set ice_server(value: RTCIceServerStats) {
            pb_1.Message.setOneofWrapperField(this, 24, this.#one_of_decls[0], value);
        }
        get has_ice_server() {
            return pb_1.Message.getField(this, 24) != null;
        }
        get content() {
            const cases: {
                [index: number]: "none" | "media_source_audio" | "media_source_video" | "receiver_audio" | "sender_audio" | "sender_video" | "receiver_video" | "outbound_rtp" | "remote_outbound_rtp" | "inbound_rtp" | "remote_inbound_rtp" | "codec" | "csrc" | "peer_connection" | "data_channel" | "transceiver" | "transport" | "sctp_transport" | "candidate_pair" | "local_candidate" | "remote_candidate" | "certificate" | "ice_server";
            } = {
                0: "none",
                3: "media_source_audio",
                4: "media_source_video",
                5: "receiver_audio",
                6: "sender_audio",
                7: "sender_video",
                8: "receiver_video",
                9: "outbound_rtp",
                10: "remote_outbound_rtp",
                11: "inbound_rtp",
                12: "remote_inbound_rtp",
                13: "codec",
                14: "csrc",
                15: "peer_connection",
                16: "data_channel",
                17: "transceiver",
                18: "transport",
                19: "sctp_transport",
                20: "candidate_pair",
                21: "local_candidate",
                22: "remote_candidate",
                23: "certificate",
                24: "ice_server"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24])];
        }
        static fromObject(data: {
            timestamp?: number;
            id?: string;
            media_source_audio?: ReturnType<typeof RTCAudioSourceStats.prototype.toObject>;
            media_source_video?: ReturnType<typeof RTCVideoSourceStats.prototype.toObject>;
            receiver_audio?: ReturnType<typeof RTCAudioReceiverStats.prototype.toObject>;
            sender_audio?: ReturnType<typeof RTCAudioSenderStats.prototype.toObject>;
            sender_video?: ReturnType<typeof RTCVideoSenderStats.prototype.toObject>;
            receiver_video?: ReturnType<typeof RTCVideoReceiverStats.prototype.toObject>;
            outbound_rtp?: ReturnType<typeof RTCOutboundRtpStreamStats.prototype.toObject>;
            remote_outbound_rtp?: ReturnType<typeof RTCRemoteOutboundRtpStreamStats.prototype.toObject>;
            inbound_rtp?: ReturnType<typeof RTCInboundRtpStreamStats.prototype.toObject>;
            remote_inbound_rtp?: ReturnType<typeof RTCRemoteInboundRtpStreamStats.prototype.toObject>;
            codec?: ReturnType<typeof RTCCodecStats.prototype.toObject>;
            csrc?: ReturnType<typeof RTCRtpContributingSourceStats.prototype.toObject>;
            peer_connection?: ReturnType<typeof RTCPeerConnectionStats.prototype.toObject>;
            data_channel?: ReturnType<typeof RTCDataChannelStats.prototype.toObject>;
            transceiver?: ReturnType<typeof RTCRtpTransceiverStats.prototype.toObject>;
            transport?: ReturnType<typeof RTCTransportStats.prototype.toObject>;
            sctp_transport?: ReturnType<typeof RTCSctpTransportStats.prototype.toObject>;
            candidate_pair?: ReturnType<typeof RTCIceCandidatePairStats.prototype.toObject>;
            local_candidate?: ReturnType<typeof RTCIceCandidateStats.prototype.toObject>;
            remote_candidate?: ReturnType<typeof RTCIceCandidateStats.prototype.toObject>;
            certificate?: ReturnType<typeof RTCCertificateStats.prototype.toObject>;
            ice_server?: ReturnType<typeof RTCIceServerStats.prototype.toObject>;
        }): RTCStats {
            const message = new RTCStats({});
            if (data.timestamp != null) {
                message.timestamp = data.timestamp;
            }
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.media_source_audio != null) {
                message.media_source_audio = RTCAudioSourceStats.fromObject(data.media_source_audio);
            }
            if (data.media_source_video != null) {
                message.media_source_video = RTCVideoSourceStats.fromObject(data.media_source_video);
            }
            if (data.receiver_audio != null) {
                message.receiver_audio = RTCAudioReceiverStats.fromObject(data.receiver_audio);
            }
            if (data.sender_audio != null) {
                message.sender_audio = RTCAudioSenderStats.fromObject(data.sender_audio);
            }
            if (data.sender_video != null) {
                message.sender_video = RTCVideoSenderStats.fromObject(data.sender_video);
            }
            if (data.receiver_video != null) {
                message.receiver_video = RTCVideoReceiverStats.fromObject(data.receiver_video);
            }
            if (data.outbound_rtp != null) {
                message.outbound_rtp = RTCOutboundRtpStreamStats.fromObject(data.outbound_rtp);
            }
            if (data.remote_outbound_rtp != null) {
                message.remote_outbound_rtp = RTCRemoteOutboundRtpStreamStats.fromObject(data.remote_outbound_rtp);
            }
            if (data.inbound_rtp != null) {
                message.inbound_rtp = RTCInboundRtpStreamStats.fromObject(data.inbound_rtp);
            }
            if (data.remote_inbound_rtp != null) {
                message.remote_inbound_rtp = RTCRemoteInboundRtpStreamStats.fromObject(data.remote_inbound_rtp);
            }
            if (data.codec != null) {
                message.codec = RTCCodecStats.fromObject(data.codec);
            }
            if (data.csrc != null) {
                message.csrc = RTCRtpContributingSourceStats.fromObject(data.csrc);
            }
            if (data.peer_connection != null) {
                message.peer_connection = RTCPeerConnectionStats.fromObject(data.peer_connection);
            }
            if (data.data_channel != null) {
                message.data_channel = RTCDataChannelStats.fromObject(data.data_channel);
            }
            if (data.transceiver != null) {
                message.transceiver = RTCRtpTransceiverStats.fromObject(data.transceiver);
            }
            if (data.transport != null) {
                message.transport = RTCTransportStats.fromObject(data.transport);
            }
            if (data.sctp_transport != null) {
                message.sctp_transport = RTCSctpTransportStats.fromObject(data.sctp_transport);
            }
            if (data.candidate_pair != null) {
                message.candidate_pair = RTCIceCandidatePairStats.fromObject(data.candidate_pair);
            }
            if (data.local_candidate != null) {
                message.local_candidate = RTCIceCandidateStats.fromObject(data.local_candidate);
            }
            if (data.remote_candidate != null) {
                message.remote_candidate = RTCIceCandidateStats.fromObject(data.remote_candidate);
            }
            if (data.certificate != null) {
                message.certificate = RTCCertificateStats.fromObject(data.certificate);
            }
            if (data.ice_server != null) {
                message.ice_server = RTCIceServerStats.fromObject(data.ice_server);
            }
            return message;
        }
        toObject() {
            const data: {
                timestamp?: number;
                id?: string;
                media_source_audio?: ReturnType<typeof RTCAudioSourceStats.prototype.toObject>;
                media_source_video?: ReturnType<typeof RTCVideoSourceStats.prototype.toObject>;
                receiver_audio?: ReturnType<typeof RTCAudioReceiverStats.prototype.toObject>;
                sender_audio?: ReturnType<typeof RTCAudioSenderStats.prototype.toObject>;
                sender_video?: ReturnType<typeof RTCVideoSenderStats.prototype.toObject>;
                receiver_video?: ReturnType<typeof RTCVideoReceiverStats.prototype.toObject>;
                outbound_rtp?: ReturnType<typeof RTCOutboundRtpStreamStats.prototype.toObject>;
                remote_outbound_rtp?: ReturnType<typeof RTCRemoteOutboundRtpStreamStats.prototype.toObject>;
                inbound_rtp?: ReturnType<typeof RTCInboundRtpStreamStats.prototype.toObject>;
                remote_inbound_rtp?: ReturnType<typeof RTCRemoteInboundRtpStreamStats.prototype.toObject>;
                codec?: ReturnType<typeof RTCCodecStats.prototype.toObject>;
                csrc?: ReturnType<typeof RTCRtpContributingSourceStats.prototype.toObject>;
                peer_connection?: ReturnType<typeof RTCPeerConnectionStats.prototype.toObject>;
                data_channel?: ReturnType<typeof RTCDataChannelStats.prototype.toObject>;
                transceiver?: ReturnType<typeof RTCRtpTransceiverStats.prototype.toObject>;
                transport?: ReturnType<typeof RTCTransportStats.prototype.toObject>;
                sctp_transport?: ReturnType<typeof RTCSctpTransportStats.prototype.toObject>;
                candidate_pair?: ReturnType<typeof RTCIceCandidatePairStats.prototype.toObject>;
                local_candidate?: ReturnType<typeof RTCIceCandidateStats.prototype.toObject>;
                remote_candidate?: ReturnType<typeof RTCIceCandidateStats.prototype.toObject>;
                certificate?: ReturnType<typeof RTCCertificateStats.prototype.toObject>;
                ice_server?: ReturnType<typeof RTCIceServerStats.prototype.toObject>;
            } = {};
            if (this.timestamp != null) {
                data.timestamp = this.timestamp;
            }
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.media_source_audio != null) {
                data.media_source_audio = this.media_source_audio.toObject();
            }
            if (this.media_source_video != null) {
                data.media_source_video = this.media_source_video.toObject();
            }
            if (this.receiver_audio != null) {
                data.receiver_audio = this.receiver_audio.toObject();
            }
            if (this.sender_audio != null) {
                data.sender_audio = this.sender_audio.toObject();
            }
            if (this.sender_video != null) {
                data.sender_video = this.sender_video.toObject();
            }
            if (this.receiver_video != null) {
                data.receiver_video = this.receiver_video.toObject();
            }
            if (this.outbound_rtp != null) {
                data.outbound_rtp = this.outbound_rtp.toObject();
            }
            if (this.remote_outbound_rtp != null) {
                data.remote_outbound_rtp = this.remote_outbound_rtp.toObject();
            }
            if (this.inbound_rtp != null) {
                data.inbound_rtp = this.inbound_rtp.toObject();
            }
            if (this.remote_inbound_rtp != null) {
                data.remote_inbound_rtp = this.remote_inbound_rtp.toObject();
            }
            if (this.codec != null) {
                data.codec = this.codec.toObject();
            }
            if (this.csrc != null) {
                data.csrc = this.csrc.toObject();
            }
            if (this.peer_connection != null) {
                data.peer_connection = this.peer_connection.toObject();
            }
            if (this.data_channel != null) {
                data.data_channel = this.data_channel.toObject();
            }
            if (this.transceiver != null) {
                data.transceiver = this.transceiver.toObject();
            }
            if (this.transport != null) {
                data.transport = this.transport.toObject();
            }
            if (this.sctp_transport != null) {
                data.sctp_transport = this.sctp_transport.toObject();
            }
            if (this.candidate_pair != null) {
                data.candidate_pair = this.candidate_pair.toObject();
            }
            if (this.local_candidate != null) {
                data.local_candidate = this.local_candidate.toObject();
            }
            if (this.remote_candidate != null) {
                data.remote_candidate = this.remote_candidate.toObject();
            }
            if (this.certificate != null) {
                data.certificate = this.certificate.toObject();
            }
            if (this.ice_server != null) {
                data.ice_server = this.ice_server.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.timestamp != 0)
                writer.writeDouble(1, this.timestamp);
            if (this.id.length)
                writer.writeString(2, this.id);
            if (this.has_media_source_audio)
                writer.writeMessage(3, this.media_source_audio, () => this.media_source_audio.serialize(writer));
            if (this.has_media_source_video)
                writer.writeMessage(4, this.media_source_video, () => this.media_source_video.serialize(writer));
            if (this.has_receiver_audio)
                writer.writeMessage(5, this.receiver_audio, () => this.receiver_audio.serialize(writer));
            if (this.has_sender_audio)
                writer.writeMessage(6, this.sender_audio, () => this.sender_audio.serialize(writer));
            if (this.has_sender_video)
                writer.writeMessage(7, this.sender_video, () => this.sender_video.serialize(writer));
            if (this.has_receiver_video)
                writer.writeMessage(8, this.receiver_video, () => this.receiver_video.serialize(writer));
            if (this.has_outbound_rtp)
                writer.writeMessage(9, this.outbound_rtp, () => this.outbound_rtp.serialize(writer));
            if (this.has_remote_outbound_rtp)
                writer.writeMessage(10, this.remote_outbound_rtp, () => this.remote_outbound_rtp.serialize(writer));
            if (this.has_inbound_rtp)
                writer.writeMessage(11, this.inbound_rtp, () => this.inbound_rtp.serialize(writer));
            if (this.has_remote_inbound_rtp)
                writer.writeMessage(12, this.remote_inbound_rtp, () => this.remote_inbound_rtp.serialize(writer));
            if (this.has_codec)
                writer.writeMessage(13, this.codec, () => this.codec.serialize(writer));
            if (this.has_csrc)
                writer.writeMessage(14, this.csrc, () => this.csrc.serialize(writer));
            if (this.has_peer_connection)
                writer.writeMessage(15, this.peer_connection, () => this.peer_connection.serialize(writer));
            if (this.has_data_channel)
                writer.writeMessage(16, this.data_channel, () => this.data_channel.serialize(writer));
            if (this.has_transceiver)
                writer.writeMessage(17, this.transceiver, () => this.transceiver.serialize(writer));
            if (this.has_transport)
                writer.writeMessage(18, this.transport, () => this.transport.serialize(writer));
            if (this.has_sctp_transport)
                writer.writeMessage(19, this.sctp_transport, () => this.sctp_transport.serialize(writer));
            if (this.has_candidate_pair)
                writer.writeMessage(20, this.candidate_pair, () => this.candidate_pair.serialize(writer));
            if (this.has_local_candidate)
                writer.writeMessage(21, this.local_candidate, () => this.local_candidate.serialize(writer));
            if (this.has_remote_candidate)
                writer.writeMessage(22, this.remote_candidate, () => this.remote_candidate.serialize(writer));
            if (this.has_certificate)
                writer.writeMessage(23, this.certificate, () => this.certificate.serialize(writer));
            if (this.has_ice_server)
                writer.writeMessage(24, this.ice_server, () => this.ice_server.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCStats {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCStats();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.timestamp = reader.readDouble();
                        break;
                    case 2:
                        message.id = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.media_source_audio, () => message.media_source_audio = RTCAudioSourceStats.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.media_source_video, () => message.media_source_video = RTCVideoSourceStats.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.receiver_audio, () => message.receiver_audio = RTCAudioReceiverStats.deserialize(reader));
                        break;
                    case 6:
                        reader.readMessage(message.sender_audio, () => message.sender_audio = RTCAudioSenderStats.deserialize(reader));
                        break;
                    case 7:
                        reader.readMessage(message.sender_video, () => message.sender_video = RTCVideoSenderStats.deserialize(reader));
                        break;
                    case 8:
                        reader.readMessage(message.receiver_video, () => message.receiver_video = RTCVideoReceiverStats.deserialize(reader));
                        break;
                    case 9:
                        reader.readMessage(message.outbound_rtp, () => message.outbound_rtp = RTCOutboundRtpStreamStats.deserialize(reader));
                        break;
                    case 10:
                        reader.readMessage(message.remote_outbound_rtp, () => message.remote_outbound_rtp = RTCRemoteOutboundRtpStreamStats.deserialize(reader));
                        break;
                    case 11:
                        reader.readMessage(message.inbound_rtp, () => message.inbound_rtp = RTCInboundRtpStreamStats.deserialize(reader));
                        break;
                    case 12:
                        reader.readMessage(message.remote_inbound_rtp, () => message.remote_inbound_rtp = RTCRemoteInboundRtpStreamStats.deserialize(reader));
                        break;
                    case 13:
                        reader.readMessage(message.codec, () => message.codec = RTCCodecStats.deserialize(reader));
                        break;
                    case 14:
                        reader.readMessage(message.csrc, () => message.csrc = RTCRtpContributingSourceStats.deserialize(reader));
                        break;
                    case 15:
                        reader.readMessage(message.peer_connection, () => message.peer_connection = RTCPeerConnectionStats.deserialize(reader));
                        break;
                    case 16:
                        reader.readMessage(message.data_channel, () => message.data_channel = RTCDataChannelStats.deserialize(reader));
                        break;
                    case 17:
                        reader.readMessage(message.transceiver, () => message.transceiver = RTCRtpTransceiverStats.deserialize(reader));
                        break;
                    case 18:
                        reader.readMessage(message.transport, () => message.transport = RTCTransportStats.deserialize(reader));
                        break;
                    case 19:
                        reader.readMessage(message.sctp_transport, () => message.sctp_transport = RTCSctpTransportStats.deserialize(reader));
                        break;
                    case 20:
                        reader.readMessage(message.candidate_pair, () => message.candidate_pair = RTCIceCandidatePairStats.deserialize(reader));
                        break;
                    case 21:
                        reader.readMessage(message.local_candidate, () => message.local_candidate = RTCIceCandidateStats.deserialize(reader));
                        break;
                    case 22:
                        reader.readMessage(message.remote_candidate, () => message.remote_candidate = RTCIceCandidateStats.deserialize(reader));
                        break;
                    case 23:
                        reader.readMessage(message.certificate, () => message.certificate = RTCCertificateStats.deserialize(reader));
                        break;
                    case 24:
                        reader.readMessage(message.ice_server, () => message.ice_server = RTCIceServerStats.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCStats {
            return RTCStats.deserialize(bytes);
        }
    }
    export class RTCStatsReport extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            stats?: RTCStats[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("stats" in data && data.stats != undefined) {
                    this.stats = data.stats;
                }
            }
        }
        get stats() {
            return pb_1.Message.getRepeatedWrapperField(this, RTCStats, 1) as RTCStats[];
        }
        set stats(value: RTCStats[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            stats?: ReturnType<typeof RTCStats.prototype.toObject>[];
        }): RTCStatsReport {
            const message = new RTCStatsReport({});
            if (data.stats != null) {
                message.stats = data.stats.map(item => RTCStats.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                stats?: ReturnType<typeof RTCStats.prototype.toObject>[];
            } = {};
            if (this.stats != null) {
                data.stats = this.stats.map((item: RTCStats) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.stats.length)
                writer.writeRepeatedMessage(1, this.stats, (item: RTCStats) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RTCStatsReport {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RTCStatsReport();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.stats, () => pb_1.Message.addToRepeatedWrapperField(message, 1, RTCStats.deserialize(reader), RTCStats));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RTCStatsReport {
            return RTCStatsReport.deserialize(bytes);
        }
    }
}
