import React from 'react'
import * as rx from 'rxjs'
import SentMediaControls from './SentMediaControls'
import TransportStatisticsView from './TransportStatisticsView'
import { SciezkaTicketSubject } from '../client/TicketSubject'
import { IcePolicy, OutgoingMediaViewState, TransportStatisticsViewState } from '../client/Domain'
import { FollowerController, FollowerMemberViewState } from '../client/FollowerController'
import { LeaderController, LeaderMemberViewState } from '../client/LeaderController'
import CallMembersView from './CallMembersView'
import { getGarconUrl } from '../Utils'

type CallViewProps = {
    callId: string,
    accountId: string,
    clientId: string,
    subject: SciezkaTicketSubject,
    icePolicy: IcePolicy,
    maxCameraCaptureDimension: number | null,
    region: string | null,
}

type CallViewState = {
    peer: FollowerMemberViewState | LeaderMemberViewState | null,
    outgoingMedia: null | OutgoingMediaViewState,
    transportStatistics: null | TransportStatisticsViewState
}

export default class CallView extends React.Component<CallViewProps, CallViewState> {
    private controller: FollowerController | LeaderController | null = null;
    private viewStateUpdatedSub: rx.SubscriptionLike | null = null;
    private facingMode: "user" | "environment" = "user";

    constructor(props: CallViewProps) {
        super(props)
        this.state = {
            peer: null,
            outgoingMedia: null,
            transportStatistics: null,
        }
    }

    private readonly switchCamera = () => {
        if (this.facingMode === "user") {
            this.facingMode = "environment";
        } else {
            this.facingMode = "user";
        }
        console.log(`Switch facing mode to ${this.facingMode}`);
        this.controller?.startCameraTrack(this.facingMode);
        this.controller?.sendCameraVideo();
    };

    private readonly toggleCameraEnabled = (enable: boolean) => {
        if (this.controller) {
            if (enable) {
                this.controller.startCameraTrack(this.facingMode);
                this.controller.sendCameraVideo();
            } else {
                this.controller.stopCameraTrack();
            }
        }
    }

    private readonly toggleScreenEnabled = (enable: boolean) => {
        if (this.controller) {
            if (enable) {
                this.controller.startScreenTracks();
                this.controller.sendScreenVideoAndAudio();
            } else {
                this.controller.stopScreenTracks();
            }
        }
    }

    private readonly toggleMicrophoneEnabled = (enabled: boolean) => {
        if (this.controller) {
            if (enabled) {
                this.controller.startMicrophoneTrack();
                this.controller.sendMicrophoneTrack();
            } else {
                this.controller.stopMicrophoneTrack();
            }
        }
    }

    private readonly closeWebSocket = () => {
        if (this.controller) {
            this.controller.closeWebSocket()
        }
    }

    private readonly restartIce = () => {
        if (this.controller) {
            this.controller.restartIce()
        }
    }

    async componentDidMount() {
        const garconUrl = await getGarconUrl();
        if (!garconUrl) {
            console.error("Cannot get garcon url");
            return;
        }

        let controller : LeaderController | FollowerController | null = null;

        if (this.props.subject === SciezkaTicketSubject.Broadcast || this.props.subject === SciezkaTicketSubject.Caller) {
            controller = new LeaderController({
                accountId: this.props.accountId,
                clientId: this.props.clientId,
                callId: this.props.callId,
                garconUrl: garconUrl,
                subject: this.props.subject,
                icePolicy: this.props.icePolicy,
                maxCameraCaptureDimension: this.props.maxCameraCaptureDimension,
                region: this.props.region,
            });
        } else {
            controller = new FollowerController({
                accountId: this.props.accountId,
                clientId: this.props.clientId,
                callId: this.props.callId,
                garconUrl: garconUrl,
                subject: this.props.subject,
                icePolicy: this.props.icePolicy,
                maxCameraCaptureDimension: this.props.maxCameraCaptureDimension,
                region: this.props.region,
            });
        }

        let viewStateUpdatedSub = controller.viewStateChanged.subscribe(viewState => {
            this.setState({
                peer: viewState.peer,
                outgoingMedia: viewState.outgoingMedia,
                transportStatistics: viewState.transportStatistics,
            })
        })
        await controller.join();

        this.controller = controller;
        this.viewStateUpdatedSub = viewStateUpdatedSub;
    }

    async componentWillUnmount() {
        if (this.viewStateUpdatedSub !== null) {
            this.viewStateUpdatedSub.unsubscribe();
            this.viewStateUpdatedSub = null;
        }
        if (this.controller !== null) {
            await this.controller.leave();
            this.controller = null;
        }
    }

    render() {
        let outgoingMediaControls = null;
        if (this.state.outgoingMedia) {
            outgoingMediaControls = <SentMediaControls
                cameraEnabled={this.state.outgoingMedia.microphoneAndCamera.stream.getVideoTracks().length > 0}
                microphoneEnabled={this.state.outgoingMedia.microphoneAndCamera.stream.getAudioTracks().length > 0}
                screenEnabled={this.state.outgoingMedia.screen.stream.getVideoTracks().length > 0}
                toggleCameraEnabled={this.toggleCameraEnabled}
                toggleMicrophoneEnabled={this.toggleMicrophoneEnabled}
                toggleScreenEnabled={this.toggleScreenEnabled}
                closeWebSocket={this.closeWebSocket}
                restartIce={this.restartIce}
                switchCamera={this.switchCamera} />
        }
        let transportStatisticsView = null;
        if (this.state.transportStatistics) {
            transportStatisticsView = <TransportStatisticsView {...this.state.transportStatistics} />
        }

        return (
            <div className="room-view">
                {transportStatisticsView}
                <CallMembersView
                    peer={this.state.peer}
                    onViewPortChanged={(s, x) => this.controller?.updateViewPort(s, x)}
                    outgoingMedia={this.state.outgoingMedia} />
                {outgoingMediaControls}
            </div>
        )
    }
}