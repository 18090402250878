import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient, SciezkaTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { checkGarconUrl, tryFetchSciezkaTicket, tryFetchTicket } from "./Utils";
import KaldunTicketSubject, { SciezkaTicketSubject } from "../client/TicketSubject";
import { Ticket, RTCIceServer } from "../generated/submodules/garcon-api/tickets_pb";


export default class VerifyIceServersGeneration implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.LiveParty,
        Features.PushToTalk,
        Features.Calls,
        Services.Garcon,
    ]);
    readonly name: string = "Verify Ice servers list by ticket type";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const kaldunClient = new KaldunTicketsClient(garconUrl);
        const sciezkaClient = new SciezkaTicketsClient(garconUrl);

        const lpTicket = await tryFetchTicket(kaldunClient, {
            accountId: randomString(),
            clientId: randomString(),
            roomId: randomString(),
            subject: KaldunTicketSubject.BroadcastAndView,
        });

        checkIceServers(assert, lpTicket, KaldunTicketSubject.BroadcastAndView);

        const pttListenTicket = await tryFetchTicket(kaldunClient, {
            accountId: randomString(),
            clientId: randomString(),
            roomId: randomString(),
            subject: KaldunTicketSubject.Listen,
        });

        checkIceServers(assert, pttListenTicket, KaldunTicketSubject.Listen);

        const pttTalkTicket = await tryFetchTicket(kaldunClient, {
            accountId: randomString(),
            clientId: randomString(),
            roomId: randomString(),
            subject: KaldunTicketSubject.Talk,
        });

        checkIceServers(assert, pttTalkTicket, KaldunTicketSubject.Talk);

        const callerTicket = await tryFetchSciezkaTicket(sciezkaClient, {
            accountId: randomString(),
            clientId: randomString(),
            roomId: randomString(),
            subject: SciezkaTicketSubject.Caller,
        });

        checkIceServers(assert, callerTicket, SciezkaTicketSubject.Caller);

        const calleeTicket = await tryFetchSciezkaTicket(sciezkaClient, {
            accountId: randomString(),
            clientId: randomString(),
            roomId: randomString(),
            subject: SciezkaTicketSubject.Callee,
        });

        checkIceServers(assert, calleeTicket, SciezkaTicketSubject.Callee);
    }
}

function checkIceServers(assert: Assert, ticket: Ticket | null, subject: KaldunTicketSubject | SciezkaTicketSubject) {
    assert.true(!!ticket, `Ticket received with subject ${subject}`);
    if (!ticket) {
        return;
    }

    function protocolVerificator(
        iceServer: RTCIceServer,
        verificator: (url: string) => boolean,
    ): boolean {
        return iceServer
            .getUrlsList()
            .filter(verificator)
            .length > 0
    }

    ticket
        .getIceServersList()
        .flatMap(x => x.getIceServersList())
        .forEach(iceServer => {
            assert.true(protocolVerificator(iceServer, (url) =>
                url.startsWith("turn:") && url.includes("transport=udp")
            ), `Subject = ${subject}, Ice server contains turn via udp`);
            assert.true(protocolVerificator(iceServer, (url) =>
                url.startsWith("turns:") && url.includes("transport=udp")
            ), `Subject = ${subject}, Ice server contains turn via dtls`);
            assert.true(protocolVerificator(iceServer, (url) =>
                url.startsWith("turn:") && url.includes("transport=tcp")
            ), `Subject = ${subject}, Ice server contains turn via tcp`);
            assert.true(protocolVerificator(iceServer, (url) =>
                url.startsWith("turns:") && url.includes("transport=tcp")
            ), `Subject = ${subject}, Ice server contains turn via tls`);
            assert.true(protocolVerificator(iceServer, (url) =>
                url.startsWith("stun:")
            ), `Subject = ${subject}, Ice server contains stun`);
    });

    const urlsNumber = ticket
        .getIceServersList()
        .flatMap(x => x.getIceServersList())
        .map(x => x.getUrlsList().length)

    assert.equal(Math.max(...urlsNumber), 6, `Subject = ${subject}, Each server contains maximum 6 urls`);
    assert.equal(Math.min(...urlsNumber), 6, `Subject = ${subject}, Each server contains minumim 6 urls`);
}