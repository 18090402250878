import * as rx from 'rxjs'

export type ReactiveWebSocketEvents = {
    type: "opened"
    send: (msg: Blob | ArrayBufferLike | ArrayBufferView | string) => void
    close: () => void
} | {
    type: "closed"
    code: number
    reason: string
} | {
    type: "error"
} | {
    type: "message"
    data: Blob | ArrayBufferLike | ArrayBufferView | string
}

export const ReactiveWebSocket = (url: string): rx.Observable<ReactiveWebSocketEvents> => {
    return new rx.Observable(subscriber => {
        const ws = new WebSocket(url);
        ws.onopen = (_) => {
            console.log(`WebSocket connection to ${url} opened`)
            subscriber.next({ type: "opened", send: (msg) => ws.send(msg), close: () => ws.close() })
        };
        ws.onerror = (_) => {
            console.log(`WebSocket connection to ${url} error`)
            subscriber.next({ type: "error" })
            subscriber.complete();
        }
        ws.onclose = (ev) => {
            console.log(`WebSocket connection to ${url} closed: ${ev.code} ${ev.reason}`)
            subscriber.next({ type: "closed", code: ev.code, reason: ev.reason })
            subscriber.complete();
        }
        ws.onmessage = (ev) => {
            subscriber.next({ type: "message", data: ev.data })
        }
        return () => {
            ws.close();
        }
    })
}

export default ReactiveWebSocket