import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { RegionalIceServers } from "../generated/submodules/garcon-api/tickets_pb";
import { checkGarconUrl, checkRegionalCoturnServers, checkTicket, tryFetchTicket } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";
import { IcePolicy } from "../client/Domain";


export default class CoturnInLiveParty implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.LiveParty,
        Services.Garcon,
        Services.Coturn,
    ]);
    readonly name: string = "Check connection for turn servers from Live Party ticket";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        async function getServers(): Promise<Array<RegionalIceServers> | undefined> {
            const accountId = randomString();
            const clientId = randomString();
            const roomId = randomString();

            const ticket = await tryFetchTicket(client, {
                accountId,
                clientId,
                roomId,
                subject: KaldunTicketSubject.BroadcastAndView,
            });

            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                accountId,
                clientId,
                roomId,
                subject: KaldunTicketSubject.BroadcastAndView
            });

            return ticket?.getIceServersList();
        }

        const initiatorServers = await getServers();
        const receiverServers = await getServers();
        
        assert.true(!!initiatorServers, "Initiator coturn servers exists");
        assert.true(!!receiverServers, "Receiver coturn servers exists");

        if (!initiatorServers || !receiverServers) {
            return;
        }

        await checkRegionalCoturnServers(
            assert,
            KaldunTicketSubject.BroadcastAndView,
            initiatorServers,
            receiverServers,
            [
                IcePolicy.All,
                IcePolicy.Relay,
                IcePolicy.TcpRelay,
                IcePolicy.TlsRelay,
                IcePolicy.UdpRelay,
            ]
        );
    }
}