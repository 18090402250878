import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString, } from "../Utils";
import { checkGarconUrl, checkTicket, tryFetchTicket, connectSignalling, mockVideoStream, mockAudioStream } from "./Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import KaldunTicketSubject from "../client/TicketSubject";
import RoomSession from "../client/RoomSession";
import { IcePolicy } from "../client/Domain";
import Delay from "../client/Delay";
import * as rtc_stat from '../client/WebRTCStatistics'
import { AudioSource, TrackStatus, VideoSource } from "../client/Messages";

export default class PttTalkBandwidthEstimatorActive implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.PushToTalk,
        Services.Garcon,
        Services.Kvashanina,
        Services.Kaldun,
        Services.Mediasoup,
    ]);

    get name(): string {
        return `Outgoing audio bitrate does not degrade over time on perfect network`
    }

    constructor() {
    }

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        const user = {
            accountId: randomString(),
            clientId: randomString(),
            roomId: randomString(),
        };

        const ticket = await tryFetchTicket(client, {
            ...user,
            subject: KaldunTicketSubject.Talk,
        });

        checkTicket(assert, {
            ticket: ticket?.getTicket(),
            ...user,
            subject: KaldunTicketSubject.Talk
        });

        if (!ticket) {
            return;
        }

        const signalling = await connectSignalling(assert, ticket.getEndpoint(), user, KaldunTicketSubject.Talk);
        if (!signalling) {
            return;
        }
    
        const session = new RoomSession({
            roomId: user.roomId,
            icePolicy: IcePolicy.All,
            enableScreenShare: false,
            ticketSubject: KaldunTicketSubject.Talk,
            iceServers: [],
        });

        const offer = await session.createInitialOffer();
        const joinResult = await signalling.join({
            offer,
            allowAutomaticSimulcast: false
        });

        assert.true("join_room_v2" in joinResult, `User joined to room = ${user.roomId}`);
    
        if ("join_room_v2" in joinResult) {
            assert.true(await session.applyProducedSdpOffer(joinResult.join_room_v2.sdp_offer), `Applied produced sdp offer in room = ${user.roomId}`);
            assert.true(await session.applyProducedSdpAnswer(joinResult.join_room_v2.sdp_answer), `Aplied produced sdp answer in room = ${user.roomId}`);
        };

        const { mediaStream } = await mockAudioStream();
    
        const audioTrack = mediaStream.getAudioTracks()[0];
        session.microphoneSendTransceiver!.sender.replaceTrack(audioTrack);

        await signalling.updateMedia({ produced_tracks: [
            {
                mid: session.microphoneSendTransceiver!.mid!,
                src: {
                    aud: AudioSource.Microphone
                },
                st: TrackStatus.On
            }
        ]})

        const outboundAudioRtpFilter = (report: rtc_stat.RTCStatsReport): boolean => {
            return report.type === 'outbound-rtp' && report.kind === 'audio'
        };

        const initialStat = await session.getStats();
        let initialAudioStat = Array.from(initialStat.values()).find(outboundAudioRtpFilter) as rtc_stat.RTCOutboundRtpStreamStats | undefined;
        console.log(`Initial statistics: `, Array.from(initialStat.values()));
        console.log(`Initial audio statistics: `, JSON.stringify(initialAudioStat));

        const [monitorDurationSeconds] = [30];

        await Delay(monitorDurationSeconds * 1000);

        console.log("Monitoring completed");

        const finalStat = await session.getStats();
        let finalAudioStat = Array.from(finalStat.values()).find(outboundAudioRtpFilter) as rtc_stat.RTCOutboundRtpStreamStats | undefined;

        console.log(`Final statistics:`, Array.from(finalStat.values()));
        console.log(`Final audio statistics:`, JSON.stringify(finalAudioStat));

        assert.true(typeof(initialAudioStat) !== "undefined", `Initial audio statistics found`);
        assert.true(typeof(finalAudioStat) !== "undefined", `Final audio statistics found`);
        if (initialAudioStat?.kind !== "audio" || finalAudioStat?.kind !== "audio") {
            assert.false(true, "Bad audio statistics");
            return;
        }
        if (typeof(initialAudioStat.targetBitrate) === "undefined") {
            assert.true(false, "target bitrate is missing");
            return;
        }
        if (typeof(finalAudioStat.targetBitrate) === "undefined") {
            assert.true(false, "target bitrate is missing");
            return;
        }

        assert.true(
            ((initialAudioStat.targetBitrate - finalAudioStat.targetBitrate) / initialAudioStat.targetBitrate) < 0.02,
            "Bitrate change should not change more than 2%");

        const leaveResult = await signalling.leave();
        assert.true("leave_room" in leaveResult, `User leaved from room = ${user.roomId}`);
        await session.terminate();
    }
}
