import React from 'react'
import * as rx from 'rxjs'
import VideoResolutionView from './VideoResolutionView'
import * as messages from '../client/Messages'

export type Props = {
  stream: MediaStream,
  showControls: boolean,
  audioOnly?: boolean,
  updatePrefferedLayer?: (preffered_layer?: messages.VideoLayer) => void;
}

type State = {
  showControls: boolean
  videoResolution: null | {
    width: number
    height: number
  }
}

class ReceivedMediaView extends React.Component<Props, State> {
  private readonly videoNode = React.createRef<HTMLVideoElement>()
  private readonly audioNode = React.createRef<HTMLVideoElement>()
  private videoMutedSub: rx.SubscriptionLike | null = null;
  private videoEndedSub: rx.SubscriptionLike | null = null;
  private videoUnmutedSub: rx.SubscriptionLike | null = null;

  private readonly onSizeChanged = () => {
    if (!this.videoNode.current) {
      return;
    }

    const w = this.videoNode.current.clientWidth;
    const h = this.videoNode.current.clientHeight;

    console.log("Video element size: " + JSON.stringify({ w: w, h: h }));

    if (this.props.updatePrefferedLayer) {
      if (h <= 180) {
        this.props.updatePrefferedLayer({ spatial: 0 });
      } else if (h <= 360) {
        this.props.updatePrefferedLayer({ spatial: 1 });
      } else {
        this.props.updatePrefferedLayer({ spatial: 2 });
      }
    }
  }

  private readonly sizeObserver = new ResizeObserver(this.onSizeChanged);

  private readonly onVideoSizeChanged = (e: Event) => {
    let t = e.target as HTMLVideoElement;
    if (t) {
      this.setState({
        videoResolution: {
          width: t.videoWidth,
          height: t.videoHeight, 
        }
      })
    }
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      showControls: props.showControls,
      videoResolution: null
    }
  }

  componentDidMount() {
    const videoTracks = this.props.stream.getVideoTracks();
    if (videoTracks.length > 0) {
      this.videoMutedSub = rx.fromEvent(videoTracks[0], 'mute').subscribe(_ => {
        this.forceUpdate()
      })
      this.videoEndedSub = rx.fromEvent(videoTracks[0], 'ended').subscribe(_ => {
        this.forceUpdate()
      })
      this.videoUnmutedSub = rx.fromEvent(videoTracks[0], 'unmute').subscribe(_ => {
        this.forceUpdate()
      })
    }

    if (this.videoNode.current && this.videoNode.current.srcObject !== this.props.stream) {
      this.videoNode.current.srcObject = this.props.stream
    }

    if (this.audioNode.current && this.audioNode.current.srcObject !== this.props.stream) {
      this.audioNode.current.srcObject = this.props.stream
    }
    if (this.videoNode.current) {
      this.videoNode.current.addEventListener('resize', this.onVideoSizeChanged)
      this.videoNode.current.addEventListener('loadedmetadata', this.onVideoSizeChanged)
      this.onSizeChanged();
      this.sizeObserver.observe(this.videoNode.current);
      try {
        this.videoNode.current.play()
      } catch (error) {
        console.log(error)
        //
      }
    }
  }

  componentWillUnmount() {
    if (this.videoNode.current) {
      this.videoNode.current.removeEventListener('resize', this.onVideoSizeChanged)
      this.videoNode.current.removeEventListener('loadedmetadata', this.onVideoSizeChanged)
      this.sizeObserver.unobserve(this.videoNode.current);
    }
    this.videoMutedSub?.unsubscribe();
    this.videoMutedSub = null;
    this.videoUnmutedSub?.unsubscribe();
    this.videoUnmutedSub = null;
    this.videoEndedSub?.unsubscribe();
    this.videoEndedSub = null;
  }

  render() {
    const videoTracks = this.props.stream.getVideoTracks();
    const playAudioViaVideoElement = videoTracks.length > 0 && (videoTracks[0].enabled && !videoTracks[0].muted);

    return (
      <div style={{height: "100%", backgroundColor: "#1B1E20"}}>
        {this.props.audioOnly ? null : <video
          ref={this.videoNode}
          autoPlay
          playsInline
          muted={!playAudioViaVideoElement}
          controls={this.state.showControls} />}
        <audio
          ref={this.audioNode}
          autoPlay={true}
          muted={this.props.audioOnly ? false : playAudioViaVideoElement} />
        <div className="received-media-statistics">
          {this.state.videoResolution ? <VideoResolutionView icon="&#x21d3;" width={this.state.videoResolution.width} height={this.state.videoResolution.height} fps={null} /> : null}
        </div>
      </div>
    )
  }
}

export default ReceivedMediaView