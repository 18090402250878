import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { makeStyles } from '@mui/styles';
import {
    Box,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material'
import { TransportStatisticsViewState as Props } from '../client/Domain'

const useStyles = makeStyles({
    customTable: {
        "& .MuiTableCell-sizeSmall": {
            padding: "0px 0px 0px 0px"
        }
    },
});

const TransportStatisticsView = (props: Props) => {
    const styles = useStyles()
    return (
        <Box sx={{ color: 'text.secondary' }} className="transport-statistics-view">
            <Table size="small" classes={{ root: styles.customTable }}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>MiB</TableCell>
                        <TableCell>Kibit/s</TableCell>
                        <TableCell>Pkts</TableCell>
                        <TableCell>Pkts/sec</TableCell>
                        {props.candidatePair
                            ? [<TableCell>Candidate</TableCell>,
                            <TableCell>MiB</TableCell>,
                            <TableCell>Kibit/s</TableCell>,
                            // <TableCell>Packets</TableCell>,
                            // <TableCell>Packets/sec</TableCell>,
                            <TableCell>RTT ms</TableCell>,
                            <TableCell>Avl bitrate</TableCell>]
                            : null
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell><UploadIcon /></TableCell>
                        <TableCell>{(props.bytesSent.total / 1024 / 1024).toFixed(3)}</TableCell>
                        <TableCell>{(8 * props.bytesSent.derivative / 1024).toFixed(0)}</TableCell>
                        <TableCell>{props.packetsSent.total}</TableCell>
                        <TableCell>{props.packetsSent.derivative.toFixed(0)}</TableCell>
                        {props.candidatePair
                            ? [<TableCell>{props.candidatePair.local.candidateType} {props.candidatePair.local.relayProtocol} {props.candidatePair.local.protocol} {props.candidatePair.local.address}:{props.candidatePair.local.port}</TableCell>,
                            <TableCell>{(props.candidatePair.bytesSent.total / 1024 / 1024).toFixed(3)}</TableCell>,
                            <TableCell>{(8 * props.candidatePair.bytesSent.derivative / 1024).toFixed(0)}</TableCell>,
                            // <TableCell>{props.candidatePair.packetsSent.total}</TableCell>,
                            // <TableCell>{props.candidatePair.packetsSent.derivative.toFixed(0)}</TableCell>,
                            <TableCell>{props.candidatePair.currentRoundTripTime ? (props.candidatePair.currentRoundTripTime * 1024).toFixed(0) : null}</TableCell>,
                            <TableCell>{props.candidatePair.availableOutgoingBitrate || "N/A"}</TableCell>]
                            : null
                        }
                    </TableRow>
                    <TableRow>
                        <TableCell><DownloadIcon /></TableCell>
                        <TableCell>{(props.bytesReceived.total / 1024 / 1024).toFixed(3)}</TableCell>
                        <TableCell>{(8 * props.bytesReceived.derivative / 1024).toFixed(0)}</TableCell>
                        <TableCell>{props.packetsReceived.total}</TableCell>
                        <TableCell>{props.packetsReceived.derivative.toFixed(0)}</TableCell>
                        {props.candidatePair
                            ? [<TableCell>{props.candidatePair.remote.protocol} {props.candidatePair.remote.address}:{props.candidatePair.remote.port}</TableCell>,
                            <TableCell>{(props.candidatePair.bytesReceived.total / 1024 / 1024).toFixed(3)}</TableCell>,
                            <TableCell>{(8 * props.candidatePair.bytesReceived.derivative / 1024).toFixed(0)}</TableCell>,
                            // <TableCell>{props.candidatePair.packetsReceived.total}</TableCell>,
                            // <TableCell>{props.candidatePair.packetsReceived.derivative.toFixed(0)}</TableCell>,
                            <TableCell>{props.candidatePair.currentRoundTripTime ? (props.candidatePair.currentRoundTripTime * 1024).toFixed(0) : null}</TableCell>,
                            <TableCell>{props.candidatePair.availableIncomingBitrate || "N/A"}</TableCell>]
                            : null
                        }
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    )
};

export default TransportStatisticsView;