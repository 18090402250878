import { Serie, Datum } from '@nivo/line'
import Queue from 'denque'

export default class TimeSeries {
    private readonly name: string;
    private readonly capacity: number;
    private lastVal: number | undefined;
    private readonly values: Queue<number>;
    private readonly timestamps: Queue<number>;

    constructor(name: string, capacity: number) {
        this.name = name;
        this.capacity = capacity;
        this.values = new Queue<number>([], { capacity: capacity })
        this.timestamps = new Queue<number>([], { capacity: capacity })
    }

    public get isEmpty() {
        return this.values.isEmpty();
    }

    public addValue(value: number, timestamp: number) {
        if (this.values.length === this.capacity) {
            this.values.shift();
            this.timestamps.shift();
        }
        this.values.push(value);
        this.timestamps.push(timestamp);
        this.lastVal = value;
    }

    public get lastValue(): number | undefined {
        return this.lastVal;
    }

    public get serie(): Serie {
        const data: Datum[] = [];
        if (this.values.length > 0) {
            const baseTimestamp = this.timestamps.peekAt(0)!;
            for (let i = 1; i < this.values.length; ++i) {
                data.push({
                    x: this.timestamps.peekAt(i)! - baseTimestamp,
                    y: this.values.peekAt(i),
                })
            }
        }

        return {
            id: this.name,
            data
        }
    }
}