import { v4 as uuidv4 } from 'uuid';
import * as rx from 'rxjs'
import * as rxops from 'rxjs/operators'
import { sciezka_messages } from '../generated/submodules/sciezka-messages/client_messages';
import { IcePolicy } from './Domain';

interface FollowerSessionConfiguration {
  callId: string,
  iceServers: Array<RTCIceServer>,
  icePolicy: IcePolicy,
}


class FollowerSession {
  private readonly callId: string;
  private readonly peerConnectionId: string = uuidv4();
  private readonly pc: RTCPeerConnection
  private readonly audioTransceiver: RTCRtpTransceiver
  private readonly cameraTransceiver: RTCRtpTransceiver
  private readonly screenAudioTransceiver: RTCRtpTransceiver
  private readonly screenVideoTransceiver: RTCRtpTransceiver
  private cameraStream: MediaStream | null = null;
  private screenStream: MediaStream | null = null;
  private readonly dataChannel: RTCDataChannel
  private remoteIceCandidates: Array<RTCIceCandidateInit> = [];

  private readonly signalingStateSub: rx.Subscription;
  private readonly connectionStateSub: rx.Subscription;
  private readonly iceErrorSub: rx.Subscription;
  private readonly iceGatheringSub: rx.Subscription;
  private readonly negotiationNeededSub: rx.Subscription;
  private readonly iceRestartRequiredSub: rx.Subscription
  private readonly iceRestartRequiredObservable: rx.Observable<void>
  private readonly iceCandidateObservable: rx.Observable<RTCIceCandidateInit>
  private readonly iceCandidateSub: rx.Subscription

  private readonly dataChannelMessageSubject: rx.Subject<sciezka_messages.PeerMessage> = new rx.Subject();

  constructor(configuration: FollowerSessionConfiguration) {

    const iceServers = configuration.iceServers.map(x => {
      let urls : string[] = Array.isArray(x.urls) ? x.urls : [ x.urls ];

      const iceServer : RTCIceServer = {
        ...x,
        urls: urls.filter(url => {
          switch (configuration.icePolicy) {
            case "all": return true;
            case "relay": return true;
            case "tcp-relay": return url.includes("transport=tcp");
            case "udp-relay": return url.includes("transport=udp");
            case "tcp-tls-relay": return url.includes("transport=tcp") && url.startsWith("turns");
            case "udp-tls-relay": return url.includes("transport=udp") && url.startsWith("turns");
            default: return false;
          }
        }),
      }
      return iceServer;
    })

    this.callId = configuration.callId;
    this.pc = new RTCPeerConnection({
      bundlePolicy: "max-bundle",
      rtcpMuxPolicy: "require",
      iceServers: iceServers,
      iceTransportPolicy: configuration.icePolicy === "all" ? "all" : "relay",
    })

    this.signalingStateSub = rx.fromEvent(this.pc, 'signalingstatechange').subscribe(_ => {
      console.log(`[call = ${this.callId}]: onSignalingStateChange: signalingState=`, this.pc.signalingState)
    })

    this.connectionStateSub = rx.fromEvent(this.pc, 'connectionstatechange').subscribe(_ => {
      console.log(`[call = ${this.callId}]: onConnectionStateChange: connectionState=`, this.pc.connectionState)
    })

    const iceRestartRequiredObservable = rx.connectable(rx.fromEvent(this.pc, 'iceconnectionstatechange').pipe(
      rxops.tap(ev => {
        let pc = ev.target as RTCPeerConnection;
        console.log(`[room = ${this.callId}]: onIceConnectionStateChange: iceConnectionState=`, pc.iceConnectionState)
      }),
      rxops.switchMap(ev => {
        let pc = ev.target as RTCPeerConnection;
        switch (pc.iceConnectionState) {
          case 'new':
          case 'checking':
          case 'connected':
          case 'completed':
          case 'closed':
            return rx.EMPTY
          case 'disconnected':
            return rx.of(void 0).pipe(rx.delay(5000))
          case 'failed':
            return rx.of(void 0)
        }
      })
    ))
    this.iceRestartRequiredSub = iceRestartRequiredObservable.connect();
    this.iceRestartRequiredObservable = iceRestartRequiredObservable;

    this.iceErrorSub = rx.fromEvent<RTCPeerConnectionIceErrorEvent>(this.pc, 'icecandidateerror').subscribe(ev => {
      console.log(`[call = ${this.callId}]: onIceCandidateError: code=`, ev.errorCode, `, text=`, ev.errorText)
    })

    this.iceGatheringSub = rx.fromEvent(this.pc, 'icegatheringstatechange').subscribe(ev => {
      console.log(`[call = ${this.callId}]: onIceGatheringStateChange: iceGatheringState=`, this.pc.iceGatheringState)
    })

    this.negotiationNeededSub = rx.fromEvent(this.pc, 'negotiationneeded').subscribe(ev => {
      console.log(`[call = ${this.callId}]: onNegotiationNeeded: `)
    })

    const iceCandidateObservable = rx.connectable(rx.fromEvent<RTCPeerConnectionIceEvent>(this.pc, 'icecandidate').pipe(
      rxops.filter(x => x.candidate !== null),
      rxops.map(ev => {
        console.log(`[call = ${this.callId}]: onIceCandidate: candidate=`, ev.candidate)
        return ev.candidate!.toJSON()!;
      }),
    ));

    this.iceCandidateSub = iceCandidateObservable.connect();
    this.iceCandidateObservable = iceCandidateObservable;

    this.pc.ontrack = (ev) => {
      if (ev.transceiver.mid === this.cameraTransceiver.mid) {
        this.cameraStream = ev.streams[0];
      }

      if (ev.transceiver.mid === this.audioTransceiver.mid) {
        this.cameraStream = ev.streams[0];
      }

      if (ev.transceiver.mid === this.screenVideoTransceiver.mid) {
        this.screenStream = ev.streams[0];
      }

      if (ev.transceiver.mid === this.screenAudioTransceiver.mid) {
        this.screenStream = ev.streams[0];
      }
    }

    this.dataChannel = this.pc.createDataChannel("data");

    this.dataChannel.onmessage = async (ev) => {
      let buffer: ArrayBufferLike
      if (ev.data instanceof Blob) {
        buffer = await ev.data.arrayBuffer();
      } else if (ev.data instanceof ArrayBuffer) {
        buffer = ev.data
      } else {
        console.log("Error unsupported message: ", ev.data);
        return;
      }

      const message = sciezka_messages.PeerMessage.deserializeBinary(new Uint8Array(buffer));

      console.log("Incoming PeerMessage: ", message.toObject());

      this.dataChannelMessageSubject.next(message);
    };

    const micAndCamStream = new MediaStream()
    this.audioTransceiver = this.pc.addTransceiver("audio", {
      direction: "sendrecv", 
      streams: [micAndCamStream],
      sendEncodings: [{
        adaptivePtime: true,
      } as RTCRtpEncodingParameters]
    })

    let cameraTransceiver = this.pc.addTransceiver("video", {
      direction: "sendrecv", 
      streams: [micAndCamStream],
    })

    const codecPriority = new Map([
      [ "video/AV1", 0 ],
      [ "video/VP9", 1 ],
      [ "video/VP8", 2 ],
      [ "video/H264", 3 ],
    ]);

    const codecs = RTCRtpSender.getCapabilities("video")?.codecs ?? [];
    codecs.sort((x, y) => {
      const vx = codecPriority.get(x.mimeType) ?? 10;
      const vy = codecPriority.get(y.mimeType) ?? 10;
      return vx - vy;
    });

    (cameraTransceiver as any).setCodecPreferences(codecs);

    this.cameraTransceiver = cameraTransceiver;

    const screenStream = new MediaStream()
    this.screenAudioTransceiver = this.pc.addTransceiver("audio", {
      direction: "sendrecv", 
      streams: [screenStream],
      sendEncodings: [{
        adaptivePtime: true,
      } as RTCRtpEncodingParameters]
    })

    const screenVideoTransceiver = this.pc.addTransceiver("video", {
      direction: "sendrecv", 
      streams: [screenStream],
    });

    (screenVideoTransceiver as any).setCodecPreferences(codecs);

    this.screenVideoTransceiver = screenVideoTransceiver;

    let screenSendParameters = this.screenVideoTransceiver.sender.getParameters();
    this.screenVideoTransceiver.sender.setParameters({...screenSendParameters, degradationPreference: "maintain-resolution"}).then(() => {
      console.log(this.screenVideoTransceiver!.sender.getParameters());
    });
  }

  public get id(): string {
    return this.peerConnectionId;
  }

  public get iceCandidate(): rx.Observable<RTCIceCandidateInit> {
    return this.iceCandidateObservable;
  }

  public get remoteCameraStream(): MediaStream {
    if (!this.cameraStream) {
      throw Error("no camera stream");
    }

    return this.cameraStream;
  }

  public get remoteScreenStream(): MediaStream {
    if (!this.screenStream) {
      throw Error("no screen stream");
    }

    return this.screenStream;
  }

  public get microphoneSendTransceiver(): RTCRtpTransceiver {
    return this.audioTransceiver;
  }

  public get cameraSendTransceiver(): RTCRtpTransceiver {
    return this.cameraTransceiver
  }

  public get screenVideoSendTransceiver(): RTCRtpTransceiver {
    return this.screenVideoTransceiver
  }

  public get screenAudioSendTransceiver(): RTCRtpTransceiver {
    return this.screenAudioTransceiver
  }

  public get iceRestartRequired(): rx.Observable<void> {
    return this.iceRestartRequiredObservable
  }

  public getStats(): Promise<RTCStatsReport> {
    return this.pc.getStats()
  }

  public async createInitialOffer(): Promise<string | null> {
    try {
      await this.pc.setLocalDescription();
      let sessionDescription = this.pc.localDescription!;
      console.log(`Created initial offer: ${sessionDescription.sdp}`)
      
      return sessionDescription.sdp;
    } catch (error) {
      console.log(`Failed to create initial offer, error is ${error}`)

      return null
    }
  }

  public get messageReceived(): rx.Observable<sciezka_messages.PeerMessage> {
    return this.dataChannelMessageSubject.asObservable();
  }

  public send(message: sciezka_messages.PeerMessage) {
    console.log("Outgoing PeerMessage: ", message.toObject());

    this.dataChannel.send(message.serializeBinary());
  }

  public async applyRemoteSdpAnswer(answer: string): Promise<boolean> {
    try {
      await this.pc.setRemoteDescription({sdp: answer, type: "answer"})
      console.log("Effective remote description", this.pc.remoteDescription?.sdp);   

      while (this.remoteIceCandidates.length) {
        this.applyCandidate(this.remoteIceCandidates.pop()!);
      }

      return true;
    } catch(error) {
      console.log(`applyRemoteSdpAnswer failed: ${error}`)
      return false;
    }
  }

  public async applyCandidate(candidate: RTCIceCandidateInit) {
    if (this.pc.remoteDescription) {
      await this.pc.addIceCandidate(candidate);
    } else {
      this.remoteIceCandidates.push(candidate);
    }
  }

  public get connectionState() {
    return this.pc.connectionState;
  }

  public async terminate() {
    this.connectionStateSub.unsubscribe();
    this.signalingStateSub.unsubscribe();
    this.iceErrorSub.unsubscribe();
    this.iceGatheringSub.unsubscribe();
    this.negotiationNeededSub.unsubscribe();
    this.iceRestartRequiredSub.unsubscribe();
    this.pc.close();
  }
}

export default FollowerSession