// source: tickets.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.tickets.RTCIceCredentialType', null, global);
goog.exportSymbol('proto.tickets.RTCIceServer', null, global);
goog.exportSymbol('proto.tickets.RegionalIceServers', null, global);
goog.exportSymbol('proto.tickets.Ticket', null, global);
goog.exportSymbol('proto.tickets.TicketError', null, global);
goog.exportSymbol('proto.tickets.TicketRequest', null, global);
goog.exportSymbol('proto.tickets.TicketResponse', null, global);
goog.exportSymbol('proto.tickets.TicketResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tickets.RTCIceServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tickets.RTCIceServer.repeatedFields_, null);
};
goog.inherits(proto.tickets.RTCIceServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tickets.RTCIceServer.displayName = 'proto.tickets.RTCIceServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tickets.RegionalIceServers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tickets.RegionalIceServers.repeatedFields_, null);
};
goog.inherits(proto.tickets.RegionalIceServers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tickets.RegionalIceServers.displayName = 'proto.tickets.RegionalIceServers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tickets.Ticket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tickets.Ticket.repeatedFields_, null);
};
goog.inherits(proto.tickets.Ticket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tickets.Ticket.displayName = 'proto.tickets.Ticket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tickets.TicketRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tickets.TicketRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tickets.TicketRequest.displayName = 'proto.tickets.TicketRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tickets.TicketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.tickets.TicketResponse.oneofGroups_);
};
goog.inherits(proto.tickets.TicketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tickets.TicketResponse.displayName = 'proto.tickets.TicketResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tickets.RTCIceServer.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tickets.RTCIceServer.prototype.toObject = function(opt_includeInstance) {
  return proto.tickets.RTCIceServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tickets.RTCIceServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tickets.RTCIceServer.toObject = function(includeInstance, msg) {
  var f, obj = {
    credential: jspb.Message.getFieldWithDefault(msg, 1, ""),
    credentialType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    urlsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    hostname: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tickets.RTCIceServer}
 */
proto.tickets.RTCIceServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tickets.RTCIceServer;
  return proto.tickets.RTCIceServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tickets.RTCIceServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tickets.RTCIceServer}
 */
proto.tickets.RTCIceServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredential(value);
      break;
    case 2:
      var value = /** @type {!proto.tickets.RTCIceCredentialType} */ (reader.readEnum());
      msg.setCredentialType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addUrls(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tickets.RTCIceServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tickets.RTCIceServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tickets.RTCIceServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tickets.RTCIceServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!proto.tickets.RTCIceCredentialType} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string credential = 1;
 * @return {string}
 */
proto.tickets.RTCIceServer.prototype.getCredential = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tickets.RTCIceServer} returns this
 */
proto.tickets.RTCIceServer.prototype.setCredential = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.tickets.RTCIceServer} returns this
 */
proto.tickets.RTCIceServer.prototype.clearCredential = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tickets.RTCIceServer.prototype.hasCredential = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RTCIceCredentialType credential_type = 2;
 * @return {!proto.tickets.RTCIceCredentialType}
 */
proto.tickets.RTCIceServer.prototype.getCredentialType = function() {
  return /** @type {!proto.tickets.RTCIceCredentialType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.tickets.RTCIceCredentialType} value
 * @return {!proto.tickets.RTCIceServer} returns this
 */
proto.tickets.RTCIceServer.prototype.setCredentialType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.tickets.RTCIceServer} returns this
 */
proto.tickets.RTCIceServer.prototype.clearCredentialType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tickets.RTCIceServer.prototype.hasCredentialType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.tickets.RTCIceServer.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tickets.RTCIceServer} returns this
 */
proto.tickets.RTCIceServer.prototype.setUsername = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.tickets.RTCIceServer} returns this
 */
proto.tickets.RTCIceServer.prototype.clearUsername = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tickets.RTCIceServer.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string urls = 4;
 * @return {!Array<string>}
 */
proto.tickets.RTCIceServer.prototype.getUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.tickets.RTCIceServer} returns this
 */
proto.tickets.RTCIceServer.prototype.setUrlsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.tickets.RTCIceServer} returns this
 */
proto.tickets.RTCIceServer.prototype.addUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tickets.RTCIceServer} returns this
 */
proto.tickets.RTCIceServer.prototype.clearUrlsList = function() {
  return this.setUrlsList([]);
};


/**
 * optional string hostname = 5;
 * @return {string}
 */
proto.tickets.RTCIceServer.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tickets.RTCIceServer} returns this
 */
proto.tickets.RTCIceServer.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tickets.RegionalIceServers.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tickets.RegionalIceServers.prototype.toObject = function(opt_includeInstance) {
  return proto.tickets.RegionalIceServers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tickets.RegionalIceServers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tickets.RegionalIceServers.toObject = function(includeInstance, msg) {
  var f, obj = {
    region: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iceServersList: jspb.Message.toObjectList(msg.getIceServersList(),
    proto.tickets.RTCIceServer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tickets.RegionalIceServers}
 */
proto.tickets.RegionalIceServers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tickets.RegionalIceServers;
  return proto.tickets.RegionalIceServers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tickets.RegionalIceServers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tickets.RegionalIceServers}
 */
proto.tickets.RegionalIceServers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 2:
      var value = new proto.tickets.RTCIceServer;
      reader.readMessage(value,proto.tickets.RTCIceServer.deserializeBinaryFromReader);
      msg.addIceServers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tickets.RegionalIceServers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tickets.RegionalIceServers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tickets.RegionalIceServers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tickets.RegionalIceServers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIceServersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.tickets.RTCIceServer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string region = 1;
 * @return {string}
 */
proto.tickets.RegionalIceServers.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tickets.RegionalIceServers} returns this
 */
proto.tickets.RegionalIceServers.prototype.setRegion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.tickets.RegionalIceServers} returns this
 */
proto.tickets.RegionalIceServers.prototype.clearRegion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tickets.RegionalIceServers.prototype.hasRegion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated RTCIceServer ice_servers = 2;
 * @return {!Array<!proto.tickets.RTCIceServer>}
 */
proto.tickets.RegionalIceServers.prototype.getIceServersList = function() {
  return /** @type{!Array<!proto.tickets.RTCIceServer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tickets.RTCIceServer, 2));
};


/**
 * @param {!Array<!proto.tickets.RTCIceServer>} value
 * @return {!proto.tickets.RegionalIceServers} returns this
*/
proto.tickets.RegionalIceServers.prototype.setIceServersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.tickets.RTCIceServer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tickets.RTCIceServer}
 */
proto.tickets.RegionalIceServers.prototype.addIceServers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.tickets.RTCIceServer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tickets.RegionalIceServers} returns this
 */
proto.tickets.RegionalIceServers.prototype.clearIceServersList = function() {
  return this.setIceServersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tickets.Ticket.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tickets.Ticket.prototype.toObject = function(opt_includeInstance) {
  return proto.tickets.Ticket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tickets.Ticket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tickets.Ticket.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticket: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iceServersList: jspb.Message.toObjectList(msg.getIceServersList(),
    proto.tickets.RegionalIceServers.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tickets.Ticket}
 */
proto.tickets.Ticket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tickets.Ticket;
  return proto.tickets.Ticket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tickets.Ticket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tickets.Ticket}
 */
proto.tickets.Ticket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicket(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    case 3:
      var value = new proto.tickets.RegionalIceServers;
      reader.readMessage(value,proto.tickets.RegionalIceServers.deserializeBinaryFromReader);
      msg.addIceServers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tickets.Ticket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tickets.Ticket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tickets.Ticket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tickets.Ticket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicket();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIceServersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.tickets.RegionalIceServers.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ticket = 1;
 * @return {string}
 */
proto.tickets.Ticket.prototype.getTicket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tickets.Ticket} returns this
 */
proto.tickets.Ticket.prototype.setTicket = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string endpoint = 2;
 * @return {string}
 */
proto.tickets.Ticket.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tickets.Ticket} returns this
 */
proto.tickets.Ticket.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RegionalIceServers ice_servers = 3;
 * @return {!Array<!proto.tickets.RegionalIceServers>}
 */
proto.tickets.Ticket.prototype.getIceServersList = function() {
  return /** @type{!Array<!proto.tickets.RegionalIceServers>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tickets.RegionalIceServers, 3));
};


/**
 * @param {!Array<!proto.tickets.RegionalIceServers>} value
 * @return {!proto.tickets.Ticket} returns this
*/
proto.tickets.Ticket.prototype.setIceServersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.tickets.RegionalIceServers=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tickets.RegionalIceServers}
 */
proto.tickets.Ticket.prototype.addIceServers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.tickets.RegionalIceServers, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tickets.Ticket} returns this
 */
proto.tickets.Ticket.prototype.clearIceServersList = function() {
  return this.setIceServersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tickets.TicketRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tickets.TicketRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tickets.TicketRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tickets.TicketRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tickets.TicketRequest}
 */
proto.tickets.TicketRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tickets.TicketRequest;
  return proto.tickets.TicketRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tickets.TicketRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tickets.TicketRequest}
 */
proto.tickets.TicketRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tickets.TicketRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tickets.TicketRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tickets.TicketRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tickets.TicketRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.tickets.TicketRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tickets.TicketRequest} returns this
 */
proto.tickets.TicketRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_id = 2;
 * @return {string}
 */
proto.tickets.TicketRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tickets.TicketRequest} returns this
 */
proto.tickets.TicketRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.tickets.TicketRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tickets.TicketRequest} returns this
 */
proto.tickets.TicketRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.tickets.TicketResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.tickets.TicketResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  TICKET: 1,
  ERROR: 2
};

/**
 * @return {proto.tickets.TicketResponse.ResultCase}
 */
proto.tickets.TicketResponse.prototype.getResultCase = function() {
  return /** @type {proto.tickets.TicketResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.tickets.TicketResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tickets.TicketResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tickets.TicketResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tickets.TicketResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tickets.TicketResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticket: (f = msg.getTicket()) && proto.tickets.Ticket.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tickets.TicketResponse}
 */
proto.tickets.TicketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tickets.TicketResponse;
  return proto.tickets.TicketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tickets.TicketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tickets.TicketResponse}
 */
proto.tickets.TicketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tickets.Ticket;
      reader.readMessage(value,proto.tickets.Ticket.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    case 2:
      var value = /** @type {!proto.tickets.TicketError} */ (reader.readEnum());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tickets.TicketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tickets.TicketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tickets.TicketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tickets.TicketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.tickets.Ticket.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.tickets.TicketError} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional Ticket ticket = 1;
 * @return {?proto.tickets.Ticket}
 */
proto.tickets.TicketResponse.prototype.getTicket = function() {
  return /** @type{?proto.tickets.Ticket} */ (
    jspb.Message.getWrapperField(this, proto.tickets.Ticket, 1));
};


/**
 * @param {?proto.tickets.Ticket|undefined} value
 * @return {!proto.tickets.TicketResponse} returns this
*/
proto.tickets.TicketResponse.prototype.setTicket = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.tickets.TicketResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tickets.TicketResponse} returns this
 */
proto.tickets.TicketResponse.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tickets.TicketResponse.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TicketError error = 2;
 * @return {!proto.tickets.TicketError}
 */
proto.tickets.TicketResponse.prototype.getError = function() {
  return /** @type {!proto.tickets.TicketError} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.tickets.TicketError} value
 * @return {!proto.tickets.TicketResponse} returns this
 */
proto.tickets.TicketResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.tickets.TicketResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.tickets.TicketResponse} returns this
 */
proto.tickets.TicketResponse.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 2, proto.tickets.TicketResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tickets.TicketResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.tickets.RTCIceCredentialType = {
  O_AUTH: 0,
  PASSWORD: 1
};

/**
 * @enum {number}
 */
proto.tickets.TicketError = {
  UNKNOWN: 0,
  INCORRECT_ROOM_ID: 1,
  INCORRECT_ACCOUNT_ID: 2,
  INCORRECT_CLIENT_ID: 3
};

goog.object.extend(exports, proto.tickets);
