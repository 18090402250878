/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.26.1
 * source: submodules/client-messages/protos/websocket.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export class EnableRTCStatsReporting extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        dictionary_download_url?: string;
        etag?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("dictionary_download_url" in data && data.dictionary_download_url != undefined) {
                this.dictionary_download_url = data.dictionary_download_url;
            }
            if ("etag" in data && data.etag != undefined) {
                this.etag = data.etag;
            }
        }
    }
    get dictionary_download_url() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set dictionary_download_url(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get etag() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set etag(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        dictionary_download_url?: string;
        etag?: string;
    }): EnableRTCStatsReporting {
        const message = new EnableRTCStatsReporting({});
        if (data.dictionary_download_url != null) {
            message.dictionary_download_url = data.dictionary_download_url;
        }
        if (data.etag != null) {
            message.etag = data.etag;
        }
        return message;
    }
    toObject() {
        const data: {
            dictionary_download_url?: string;
            etag?: string;
        } = {};
        if (this.dictionary_download_url != null) {
            data.dictionary_download_url = this.dictionary_download_url;
        }
        if (this.etag != null) {
            data.etag = this.etag;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.dictionary_download_url.length)
            writer.writeString(1, this.dictionary_download_url);
        if (this.etag.length)
            writer.writeString(2, this.etag);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): EnableRTCStatsReporting {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new EnableRTCStatsReporting();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.dictionary_download_url = reader.readString();
                    break;
                case 2:
                    message.etag = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): EnableRTCStatsReporting {
        return EnableRTCStatsReporting.deserialize(bytes);
    }
}
export class CompressedRTCStatsReport extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        etag?: string;
        report?: Uint8Array;
        peer_connection_id?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("etag" in data && data.etag != undefined) {
                this.etag = data.etag;
            }
            if ("report" in data && data.report != undefined) {
                this.report = data.report;
            }
            if ("peer_connection_id" in data && data.peer_connection_id != undefined) {
                this.peer_connection_id = data.peer_connection_id;
            }
        }
    }
    get etag() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set etag(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get report() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set report(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get peer_connection_id() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set peer_connection_id(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        etag?: string;
        report?: Uint8Array;
        peer_connection_id?: string;
    }): CompressedRTCStatsReport {
        const message = new CompressedRTCStatsReport({});
        if (data.etag != null) {
            message.etag = data.etag;
        }
        if (data.report != null) {
            message.report = data.report;
        }
        if (data.peer_connection_id != null) {
            message.peer_connection_id = data.peer_connection_id;
        }
        return message;
    }
    toObject() {
        const data: {
            etag?: string;
            report?: Uint8Array;
            peer_connection_id?: string;
        } = {};
        if (this.etag != null) {
            data.etag = this.etag;
        }
        if (this.report != null) {
            data.report = this.report;
        }
        if (this.peer_connection_id != null) {
            data.peer_connection_id = this.peer_connection_id;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.etag.length)
            writer.writeString(1, this.etag);
        if (this.report.length)
            writer.writeBytes(2, this.report);
        if (this.peer_connection_id.length)
            writer.writeString(3, this.peer_connection_id);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CompressedRTCStatsReport {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CompressedRTCStatsReport();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.etag = reader.readString();
                    break;
                case 2:
                    message.report = reader.readBytes();
                    break;
                case 3:
                    message.peer_connection_id = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): CompressedRTCStatsReport {
        return CompressedRTCStatsReport.deserialize(bytes);
    }
}
export class KaldunIncomingMessage extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        seq?: number;
    } & (({
        rtc_report?: CompressedRTCStatsReport;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("seq" in data && data.seq != undefined) {
                this.seq = data.seq;
            }
            if ("rtc_report" in data && data.rtc_report != undefined) {
                this.rtc_report = data.rtc_report;
            }
        }
    }
    get seq() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set seq(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get rtc_report() {
        return pb_1.Message.getWrapperField(this, CompressedRTCStatsReport, 2) as CompressedRTCStatsReport;
    }
    set rtc_report(value: CompressedRTCStatsReport) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get has_rtc_report() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get value() {
        const cases: {
            [index: number]: "none" | "rtc_report";
        } = {
            0: "none",
            2: "rtc_report"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        seq?: number;
        rtc_report?: ReturnType<typeof CompressedRTCStatsReport.prototype.toObject>;
    }): KaldunIncomingMessage {
        const message = new KaldunIncomingMessage({});
        if (data.seq != null) {
            message.seq = data.seq;
        }
        if (data.rtc_report != null) {
            message.rtc_report = CompressedRTCStatsReport.fromObject(data.rtc_report);
        }
        return message;
    }
    toObject() {
        const data: {
            seq?: number;
            rtc_report?: ReturnType<typeof CompressedRTCStatsReport.prototype.toObject>;
        } = {};
        if (this.seq != null) {
            data.seq = this.seq;
        }
        if (this.rtc_report != null) {
            data.rtc_report = this.rtc_report.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.seq != 0)
            writer.writeUint32(1, this.seq);
        if (this.has_rtc_report)
            writer.writeMessage(2, this.rtc_report, () => this.rtc_report.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): KaldunIncomingMessage {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new KaldunIncomingMessage();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.seq = reader.readUint32();
                    break;
                case 2:
                    reader.readMessage(message.rtc_report, () => message.rtc_report = CompressedRTCStatsReport.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): KaldunIncomingMessage {
        return KaldunIncomingMessage.deserialize(bytes);
    }
}
export class KaldunOutgoingMessage extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        seq?: number;
    } & (({
        enable_rtc_reporting?: EnableRTCStatsReporting;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("seq" in data && data.seq != undefined) {
                this.seq = data.seq;
            }
            if ("enable_rtc_reporting" in data && data.enable_rtc_reporting != undefined) {
                this.enable_rtc_reporting = data.enable_rtc_reporting;
            }
        }
    }
    get seq() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set seq(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get enable_rtc_reporting() {
        return pb_1.Message.getWrapperField(this, EnableRTCStatsReporting, 2) as EnableRTCStatsReporting;
    }
    set enable_rtc_reporting(value: EnableRTCStatsReporting) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
    }
    get has_enable_rtc_reporting() {
        return pb_1.Message.getField(this, 2) != null;
    }
    get value() {
        const cases: {
            [index: number]: "none" | "enable_rtc_reporting";
        } = {
            0: "none",
            2: "enable_rtc_reporting"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])];
    }
    static fromObject(data: {
        seq?: number;
        enable_rtc_reporting?: ReturnType<typeof EnableRTCStatsReporting.prototype.toObject>;
    }): KaldunOutgoingMessage {
        const message = new KaldunOutgoingMessage({});
        if (data.seq != null) {
            message.seq = data.seq;
        }
        if (data.enable_rtc_reporting != null) {
            message.enable_rtc_reporting = EnableRTCStatsReporting.fromObject(data.enable_rtc_reporting);
        }
        return message;
    }
    toObject() {
        const data: {
            seq?: number;
            enable_rtc_reporting?: ReturnType<typeof EnableRTCStatsReporting.prototype.toObject>;
        } = {};
        if (this.seq != null) {
            data.seq = this.seq;
        }
        if (this.enable_rtc_reporting != null) {
            data.enable_rtc_reporting = this.enable_rtc_reporting.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.seq != 0)
            writer.writeUint32(1, this.seq);
        if (this.has_enable_rtc_reporting)
            writer.writeMessage(2, this.enable_rtc_reporting, () => this.enable_rtc_reporting.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): KaldunOutgoingMessage {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new KaldunOutgoingMessage();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.seq = reader.readUint32();
                    break;
                case 2:
                    reader.readMessage(message.enable_rtc_reporting, () => message.enable_rtc_reporting = EnableRTCStatsReporting.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): KaldunOutgoingMessage {
        return KaldunOutgoingMessage.deserialize(bytes);
    }
}
