import React from 'react'
import {
  ToggleButton
} from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { SentMediaStatisticsView } from './SentMediaStatisticsView'
import { OutgoingMediaStreamViewState } from '../client/Domain'
import VideoResolutionView from './VideoResolutionView'


export type Props = OutgoingMediaStreamViewState & {
  displayName: string
}

type State = {
  showStatistics: boolean
}

class SentMediaView extends React.Component<Props, State> {
  private readonly videoNode = React.createRef<HTMLVideoElement>()

  private readonly onVideoSizeChanged = (e: Event) => {
    this.forceUpdate();
  }

  private readonly toggleStatisticsVisibility = () => {
    this.setState((prev) => ({ showStatistics: !prev.showStatistics }));
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      showStatistics: false,
    }
  }

  componentDidMount() {
    if (this.videoNode.current && this.videoNode.current.srcObject !== this.props.stream) {
      this.videoNode.current.srcObject = this.props.stream
    }
    if (this.videoNode.current) {
      this.videoNode.current.addEventListener('resize', this.onVideoSizeChanged)
      this.videoNode.current.addEventListener('loadedmetadata', this.onVideoSizeChanged)
    }
  }

  componentWillUnmount() {
    if (this.videoNode.current) {
      this.videoNode.current.removeEventListener('resize', this.onVideoSizeChanged)
      this.videoNode.current.removeEventListener('loadedmetadata', this.onVideoSizeChanged)
    }
  }

  render() {

    let width = this.props.videoTrackStat?.captureWidth;
    let height = this.props.videoTrackStat?.captureHeight;
    let fps = this.props.videoTrackStat?.captureFps;

    const videoTracks = this.props.stream.getVideoTracks();
    if (videoTracks.length > 0) {
      const settings = videoTracks[0].getSettings();
      if (!height) {
        height = settings.height;
      }
      if (!width) {
        width = settings.width;
      }
      if (!fps) {
        fps = settings.frameRate
      }
    }

    const statisticsClasses = this.state.showStatistics ? "" : "hidden";
    const togglerStyles = {
      maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', marginRight: '8px'
    }

    return (
      <div className="peer-cell">
        <video
          ref={this.videoNode}
          autoPlay
          className="frontal-camera-video"
          playsInline
          muted={true} />
        <span className="display-name">You ({this.props.displayName})</span>

        <div className={statisticsClasses}>
          <SentMediaStatisticsView {...this.props} />
        </div>
        {(width && height && fps) ? <VideoResolutionView icon="&#x23FA;" width={width} height={height} fps={fps} /> : null}

        <div className="statistics-toggle">
          <ToggleButton size="small" style={togglerStyles} value="show-statistics" selected={this.state.showStatistics} onChange={this.toggleStatisticsVisibility} >
            <QueryStatsIcon />
          </ToggleButton>
        </div>
      </div>
    )
  }
}

export default SentMediaView