import React from 'react'
import ReceivedMediaView from './ReceivedMediaView'
import ReceivedAudioTrackInfoView from './ReceivedAudioTrackInfoView'
import ReceivedVideoTrackInfoView from './ReceivedVideoTrackInfoView'
import * as messages from '../client/Messages'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

type Props = {
  displayName: string
  setPausedForIncomingTracks: (tracks: Array<{mid: string, pause: boolean}>) => void;
  updatePrefferedLayer: (mid: string, preffered_layer?: messages.VideoLayer) => void;
  cameraStream?: {
    mediaStream: MediaStream
    audioTrack?: {
      mid: string
      src?: messages.MediaSourceA
      status?: messages.TrackStatus
      paused?: boolean
      score?: messages.ConsumedTrackScore
    }
    videoTrack?: {
      mid: string
      src?: messages.MediaSourceV
      status?: messages.TrackStatus
      paused?: boolean
      forwarded_layer?: messages.ConsumedVideoLayer
      score?: messages.ConsumedTrackScore
    }
  }
  screenStream?: {
    mediaStream: MediaStream
    audioTrack?: {
      mid: string
      src?: messages.MediaSourceA
      status?: messages.TrackStatus
      paused?: boolean
      score?: messages.ConsumedTrackScore
    }
    videoTrack?: {
      mid: string
      src?: messages.MediaSourceV
      status?: messages.TrackStatus
      paused?: boolean
      forwarded_layer?: messages.ConsumedVideoLayer
      score?: messages.ConsumedTrackScore
    }
  }
}

export default function PeerView(props: Props) {
  const [tab, setTab] = React.useState('camera');

  const handleChange = (_: any, newValue: string) => {
    setTab(newValue);
  };

  let audioTrackInfo = null;
  if (props.cameraStream?.audioTrack) {
    audioTrackInfo = <ReceivedAudioTrackInfoView {...props.cameraStream.audioTrack} /> 
  }
  let videoTrackInfo = null;
  if (props.cameraStream?.videoTrack) {
    videoTrackInfo = <ReceivedVideoTrackInfoView {...props.cameraStream.videoTrack} />
  }
  let screenVideoTrackInfo = null;
  if (props.screenStream?.videoTrack) {
    screenVideoTrackInfo = <ReceivedVideoTrackInfoView {...props.screenStream.videoTrack} />
  }
  let screenAudioTrackInfo = null;
  if (props.screenStream?.videoTrack) {
    screenAudioTrackInfo = <ReceivedAudioTrackInfoView {...props.screenStream.audioTrack} />
  }

  const showTabs = props.screenStream?.videoTrack?.status === messages.TrackStatus.On;

  const selectIncomingMedia = (tab: "camera" | "screen") => {
    const tracks : Array<{mid: string, pause: boolean}> = [];
    if (props.cameraStream?.videoTrack) {
      tracks.push({
        mid: props.cameraStream.videoTrack.mid,
        pause: tab === "screen"
      });
    }

    if (props.screenStream?.videoTrack) {
      tracks.push({
        mid: props.screenStream.videoTrack.mid,
        pause: tab === "camera"
      });
    }

    if (props.cameraStream?.audioTrack) {
      tracks.push({
        mid: props.cameraStream.audioTrack.mid,
        pause: false
      });
    }

    if (props.screenStream?.audioTrack) {
      tracks.push({
        mid: props.screenStream.audioTrack.mid,
        pause: tab === "camera"
      });
    }

    props.setPausedForIncomingTracks(tracks);
  };

  let cameraTab: any | undefined;
  if (props.cameraStream) {
    cameraTab = (
      <div>
        <ReceivedMediaView
          key={props.cameraStream.mediaStream.id}
          stream={props.cameraStream.mediaStream}
          showControls={false}
          updatePrefferedLayer={layer => {
            if (props.cameraStream?.videoTrack?.mid) {
              props.updatePrefferedLayer(props.cameraStream?.videoTrack.mid, layer);
            }
          }}
        />
        <div className="track-info-container">
          {audioTrackInfo}
          {videoTrackInfo}
        </div>
      </div>
    )
  }

  let screenTab: any | undefined
  if (props.screenStream) {
    screenTab = (
      <div>
        <ReceivedMediaView
          key={props.screenStream.mediaStream.id}
          stream={props.screenStream.mediaStream}
          showControls={true} />
        <div className="track-info-container">
          {screenAudioTrackInfo}
          {screenVideoTrackInfo}
        </div>
        {props.cameraStream 
          ? <ReceivedMediaView
              key={props.cameraStream.mediaStream.id} 
              stream={props.cameraStream.mediaStream}
              showControls={false}
              audioOnly={true} />
          : null}
      </div>
    )
  }

  return (
    <div className="peer-cell">
      <Box sx={{ height: '100%', typography: 'body1' }}>
        <TabContext value={tab}>
  `       <TabPanel value="camera" sx={{ padding: 0 }}>
            {cameraTab}
          </TabPanel>`
          <TabPanel value="screen" sx={{ padding: 0 }}>
            {screenTab}
          </TabPanel>
          <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex:100, visibility: showTabs ? 'visible' : 'hidden' }}>
            <TabList onChange={handleChange} orientation='vertical'>
              <Tab label="Camera" value="camera" onClick={() => selectIncomingMedia("camera")} />
              <Tab label="Screen" value="screen" onClick={() => selectIncomingMedia("screen")} />
            </TabList>
          </Box>
        </TabContext>
      </Box>
      
      <span className="display-name">{props.displayName}</span>
    </div>
  );
}