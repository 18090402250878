import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { RegionalIceServers } from "../generated/submodules/garcon-api/tickets_pb";
import { checkGarconUrl, checkRegionalCoturnServers, checkTicket, tryFetchTicket } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";
import { IcePolicy } from "../client/Domain";


export default class CoturnInPushToTalk implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.PushToTalk,
        Services.Garcon,
        Services.Coturn,
    ]);
    readonly name: string = "Check connection for turn servers from Push to Talk tickets";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        async function getServers(subject: KaldunTicketSubject): Promise<Array<RegionalIceServers> | undefined> {
            const accountId = randomString();
            const clientId = randomString();
            const roomId = randomString();

            const ticket = await tryFetchTicket(client, {
                accountId,
                clientId,
                roomId,
                subject,
            });

            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                accountId,
                clientId,
                roomId,
                subject,
            });

            return ticket?.getIceServersList();
        }

        const subjects = [
            KaldunTicketSubject.Listen,
            KaldunTicketSubject.Talk,
            KaldunTicketSubject.TalkAndListen,
        ];

        for (let subject of subjects) {
            const initiatorServers = await getServers(subject);
            const receiverServers = await getServers(subject);
            
            assert.true(!!initiatorServers, `Initiator with subject = ${subject} coturn servers exists`);
            assert.true(!!receiverServers, `Receiver with subcjet = ${subject} coturn servers exists`);

            if (!initiatorServers || !receiverServers) {
                return;
            }

            await checkRegionalCoturnServers(
                assert,
                subject,
                initiatorServers,
                receiverServers,
                [
                    IcePolicy.All,
                    IcePolicy.Relay,
                    IcePolicy.TcpRelay,
                    IcePolicy.TlsRelay,
                    IcePolicy.UdpRelay,
                ]
            );
        }
        
    }
}