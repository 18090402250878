export enum AudioSource {
  Microphone = 'mic',
  Screen = 'scr'
}

export enum VideoSource {
  Camera = 'cam',
  Screen = 'scr'
}

export interface MediaSourceA {
  aud: AudioSource
}

export interface MediaSourceV {
  vid: VideoSource
}

export enum TrackStatus {
  On = 'on',
  Off = 'off',
  Muted = 'mut'
}

export interface ProducedTrackInfo {
  mid: string
  src: MediaSourceA | MediaSourceV
  st: TrackStatus
}

export type VideoLayer = {
  spatial: number
  temporal?: number
}

export type ConsumedVideoLayer = { none: {} } | { layer: VideoLayer }

export type ConsumedTrackScore = {
  score: number,
  producer_score: number,
  producer_scores: Array<number>,
}

export interface PreferredConsumedTrackInfo {
    mid: string
    pause?: boolean,
    net_prio?: number,
    pref_layer?: VideoLayer
}

export type UpdateMediaRequest = {
  produced_tracks?: Array<ProducedTrackInfo>
  consumed_tracks?: Array<PreferredConsumedTrackInfo>
}

export type UpdateMediaResponse = {
}

export interface DebugJoinConfiguration {
  allocate_tcp_candidates?: boolean
  allow_automatic_simulcast?: boolean
  video_producer_bandwidth_limit_kbps?: number
}

export interface JoinRoomV2Request {
  ticket?: string
  region?: string
  sdp_offer: string
  debug_configuration?: DebugJoinConfiguration
}

export interface JoinRoomV2Response {
  sdp_offer: string
  sdp_answer: string
  sdp_server_offer?: string
}

export interface RestartIceRequest {
}

export interface RestartIceResponse {
}


export interface AuthenticateRequest {
  ticket: string,
}

export type AuthenticateResponse = {
  join_required: boolean,
}

export interface ConsumedTrackInfo {
    mid: string
    src?: MediaSourceA | MediaSourceV
    st?: TrackStatus
    paused?: boolean
    net_prio?: number,
    fwd_layer?: ConsumedVideoLayer,
    pref_layer?: VideoLayer,
    score?: ConsumedTrackScore,
}

export enum MemberStatus {
  Disconnected  = 'disconnected',
  Connected = 'connected',
  Connecting = 'connecting'
}

export interface MemberInfo {
  acc: string,
  st: MemberStatus
  tracks?: Array<ConsumedTrackInfo>
}

export type RoomUpdatedNotification = {
  consumed_media_sdp_offer?: string
  members: Array<MemberInfo>
}

export type LeaveRoomRequest = {
}

export type LeaveRoomResponse = {
}

export enum MaintenanceComponent {
  SignalingServer = "signaling",
  MediaServer = "media",
  TranscodingServer = "transcoding",
}

export type MaintenanceNotification = {
  component?: MaintenanceComponent
}

export type ClientErrorDetails = {
  kind: String,
  description?: String
}

export type ServerErrorDetails = {
  kind: String,
  description?: String
}

export type ErrorResponse = {
  client: null | ClientErrorDetails
} | {
  server: null | ServerErrorDetails
}

export type Request = {
  seq: number
} | {
  authenticate: AuthenticateRequest
} | {
  restart_ice: RestartIceRequest
} | {
  join_room_v2: JoinRoomV2Request
} | {
  update_media: UpdateMediaRequest,
} | {
  leave_room: LeaveRoomRequest,
};

export type ResponseOrNotification = {
  seq: number
} & ({
  authenticate: AuthenticateResponse,
} | {
  restart_ice: RestartIceResponse
} | {
  join_room_v2: JoinRoomV2Response
} | {
  leave_room: LeaveRoomResponse
} | {
  update_media: UpdateMediaResponse
} | {
  room_updated: RoomUpdatedNotification
} | {
  maintenance: MaintenanceNotification
} | {
  error: ErrorResponse
})