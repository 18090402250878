import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { checkGarconUrl, checkTicket, joinToKaldunRoomAndReturnState, tryFetchTicket } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";
import { SignalingState } from "../client/RoomSignaling";
import { IcePolicy } from "../client/Domain";


export default class JoinToPushToTalkWithDifferentRoles implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.PushToTalk,
        Services.Garcon,
        Services.Kvashanina,
        Services.Kaldun,
        Services.Mediasoup,
    ]);
    readonly name: string = "Join to push to talk with different roles";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        const subjects = [
            {
                firstSubject: KaldunTicketSubject.Talk,
                secondSubject: KaldunTicketSubject.Listen,
            },
            {
                firstSubject: KaldunTicketSubject.Listen,
                secondSubject: KaldunTicketSubject.Talk,
            },
        ];

        for (let { firstSubject, secondSubject } of subjects) {
            const user = { accountId: randomString(), clientId: randomString(), roomId: randomString() };

            const ticket = await tryFetchTicket(client, {
                ...user,
                subject: firstSubject,
            });

            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                ...user,
                subject: firstSubject,
            });

            if (!ticket) {
                return;
            }

            const first = await joinToKaldunRoomAndReturnState({assert, ticket, user, icePolicy: IcePolicy.All, subject: firstSubject});
            if (!first) {
                return;
            }

            const secondTicket = await tryFetchTicket(client, {
                ...user,
                subject: secondSubject,
            });

            checkTicket(assert, {
                ticket: secondTicket?.getTicket(),
                ...user,
                subject: secondSubject,
            });

            if (!secondTicket) {
                return;
            }

            const second = await joinToKaldunRoomAndReturnState({assert, ticket: secondTicket, user, icePolicy: IcePolicy.All, subject: secondSubject});
            if (!second) {
                return;
            }

            assert.equal(first.signalling.signalingState, SignalingState.Connected, `Fisrt user session still be connected after creation second session: room = ${user.roomId}, first subject = ${firstSubject}, second subject = ${secondSubject}`);
            const leaveResultFirst = await first.signalling.leave();
            assert.true("leave_room" in leaveResultFirst, `User leaved from room = ${user.roomId}, subject = ${firstSubject}`);
            await first.session.terminate();

            const leaveResultSecond = await second.signalling.leave();
            assert.true("leave_room" in leaveResultSecond, `User leaved from room = ${user.roomId}, subject = ${secondSubject}`);
            await second.session.terminate();
        }
    }
}
