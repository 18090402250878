import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { checkGarconUrl, checkTicket, tryFetchTicket, connectSignalling } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";
import RoomSession from "../client/RoomSession";
import { IcePolicy } from "../client/Domain";


export default class JoinToLivePartyWithSimulcast implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.LiveParty,
        Services.Garcon,
        Services.Coturn,
        Services.Kvashanina,
        Services.Kaldun,
        Services.Mediasoup,
    ]);

    readonly allowAutomaticSimulcast: boolean

    get name(): string {
        return `Join to live party with automatic simulcast ${this.allowAutomaticSimulcast ? "enabled" : "disabled"}`
    }

    constructor({allowAutomaticSimulcast}: {allowAutomaticSimulcast: boolean}) {
        this.allowAutomaticSimulcast = allowAutomaticSimulcast
    }

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        const user = {
            accountId: randomString(),
            clientId: randomString(),
            roomId: randomString(),
        };

        const ticket = await tryFetchTicket(client, {
            ...user,
            subject: KaldunTicketSubject.BroadcastAndView,
        });

        checkTicket(assert, {
            ticket: ticket?.getTicket(),
            ...user,
            subject: KaldunTicketSubject.BroadcastAndView
        });

        if (!ticket) {
            return;
        }

        const signalling = await connectSignalling(assert, ticket.getEndpoint(), user, KaldunTicketSubject.BroadcastAndView);
        if (!signalling) {
            return;
        }
    
        const session = new RoomSession({
            roomId: user.roomId,
            icePolicy: IcePolicy.All,
            enableScreenShare: false,
            ticketSubject: KaldunTicketSubject.BroadcastAndView,
            iceServers: [],
        });

        for (const tran of [session.cameraSendTransceiver, session.screenSendTransceiver]) {
            if (tran) {
                assert.equal(
                    tran.sender.getParameters().encodings.length,
                    1,
                    `Verity the initial number of encodings in video transceivers is 1`);
            }
        }

        const offer = await session.createInitialOffer();
        const joinResult = await signalling.join({ offer, allowAutomaticSimulcast: this.allowAutomaticSimulcast });

        assert.true("join_room_v2" in joinResult, `User joined to room = ${user.roomId}`);
    
        if ("join_room_v2" in joinResult) {
            assert.true(await session.applyProducedSdpOffer(joinResult.join_room_v2.sdp_offer), `Applied produced sdp offer in room = ${user.roomId}`);
            assert.true(await session.applyProducedSdpAnswer(joinResult.join_room_v2.sdp_answer), `Aplied produced sdp answer in room = ${user.roomId}`);
        };

        const expectedEncodingsCount = this.allowAutomaticSimulcast ? 3 : 1;
        for (const tran of [session.cameraSendTransceiver, session.screenSendTransceiver]) {
            if (tran) {
                assert.equal(
                    tran.sender.getParameters().encodings.length,
                    expectedEncodingsCount,
                    `Verity the actual number of encodings in video transceiver is ${expectedEncodingsCount} when allowAutomaticSimulcast = ${this.allowAutomaticSimulcast}`);
            }
        }

        const leaveResult = await signalling.leave();
        assert.true("leave_room" in leaveResult, `User leaved from room = ${user.roomId}`);
        await session.terminate();
    }
}
