import React from 'react'
import {
    RoomMemberViewState
} from '../client/RoomController'
import SentMediaView from './SentMediaView'
import PeerView from './PeerView'
import GridView from './GridView'
import * as messages from '../client/Messages'
import { OutgoingMediaViewState } from '../client/Domain'

type Props = {
    members: Array<RoomMemberViewState>,
    outgoingMedia: null | OutgoingMediaViewState,
    displayName: string
    setPausedForIncomingTracks: (tracks: Array<{ mid: string, pause: boolean }>) => void;
    updatePrefferedLayer: (mid: string, preffered_layer?: messages.VideoLayer) => void;
}

export default class RoomMembersView extends React.Component<Props, {}> {
    render() {
        let outgoingCameraView = null;
        let outgoingScreenView = null;
        if (this.props.outgoingMedia) {
            outgoingCameraView = <SentMediaView
                key="outgoing-camera-view"
                displayName={this.props.displayName}
                {...this.props.outgoingMedia.microphoneAndCamera} />

            if (this.props.outgoingMedia.screen.stream.getVideoTracks().length > 0) {
                outgoingScreenView = <SentMediaView
                    key="outgoing-screen-view"
                    displayName={this.props.displayName}
                    {...this.props.outgoingMedia.screen} />
            }
        }

        return (
            <div className="room-members-view">
                <GridView aspectRatio={16/9}>
                    {outgoingCameraView}
                    {outgoingScreenView}
                    {this.props.members.map(member =>
                        <PeerView
                            key={member.id}
                            setPausedForIncomingTracks={this.props.setPausedForIncomingTracks}
                            updatePrefferedLayer={this.props.updatePrefferedLayer}
                            displayName={member.displayName}
                            cameraStream={member.cameraStream}
                            screenStream={member.screenStream} />)}
                </GridView>
            </div>
        )
    }
}