import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from './App';
import QUnit from 'qunit';
import 'qunit-reporter-junit';
import 'qunit/qunit/qunit.css'
import './tests/Entrypoint'
import Entrypoint from './tests/Entrypoint';

const query = new URLSearchParams(window.location.search);

if (query.get("test") === "1") {
  Entrypoint();
  QUnit.start()
  ReactDOM.render(
    <React.StrictMode>
      <div id="qunit"></div>
      <div id="qunit-fixture"></div>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}


