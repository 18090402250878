/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.26.1
 * source: client_messages.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export namespace sciezka_messages {
    export enum TrackState {
        OFF = 0,
        ON = 1,
        MUTED = 2
    }
    export enum TrackSource {
        CAMERA = 0,
        MIC = 1,
        SCREEN_VIDEO = 2,
        SCREEN_AUDIO = 3
    }
    export enum TrackDirection {
        IN = 0,
        OUT = 1
    }
    export class TransceiverEntry extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            mid?: string;
            source?: TrackSource;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("mid" in data && data.mid != undefined) {
                    this.mid = data.mid;
                }
                if ("source" in data && data.source != undefined) {
                    this.source = data.source;
                }
            }
        }
        get mid() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set mid(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get source() {
            return pb_1.Message.getFieldWithDefault(this, 2, TrackSource.CAMERA) as TrackSource;
        }
        set source(value: TrackSource) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            mid?: string;
            source?: TrackSource;
        }): TransceiverEntry {
            const message = new TransceiverEntry({});
            if (data.mid != null) {
                message.mid = data.mid;
            }
            if (data.source != null) {
                message.source = data.source;
            }
            return message;
        }
        toObject() {
            const data: {
                mid?: string;
                source?: TrackSource;
            } = {};
            if (this.mid != null) {
                data.mid = this.mid;
            }
            if (this.source != null) {
                data.source = this.source;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.mid.length)
                writer.writeString(1, this.mid);
            if (this.source != TrackSource.CAMERA)
                writer.writeEnum(2, this.source);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransceiverEntry {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransceiverEntry();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.mid = reader.readString();
                        break;
                    case 2:
                        message.source = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TransceiverEntry {
            return TransceiverEntry.deserialize(bytes);
        }
    }
    export class TrackInitialState extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            source?: TrackSource;
            direction?: TrackDirection;
            state?: TrackState;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("source" in data && data.source != undefined) {
                    this.source = data.source;
                }
                if ("direction" in data && data.direction != undefined) {
                    this.direction = data.direction;
                }
                if ("state" in data && data.state != undefined) {
                    this.state = data.state;
                }
            }
        }
        get source() {
            return pb_1.Message.getFieldWithDefault(this, 1, TrackSource.CAMERA) as TrackSource;
        }
        set source(value: TrackSource) {
            pb_1.Message.setField(this, 1, value);
        }
        get direction() {
            return pb_1.Message.getFieldWithDefault(this, 2, TrackDirection.IN) as TrackDirection;
        }
        set direction(value: TrackDirection) {
            pb_1.Message.setField(this, 2, value);
        }
        get state() {
            return pb_1.Message.getFieldWithDefault(this, 3, TrackState.OFF) as TrackState;
        }
        set state(value: TrackState) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            source?: TrackSource;
            direction?: TrackDirection;
            state?: TrackState;
        }): TrackInitialState {
            const message = new TrackInitialState({});
            if (data.source != null) {
                message.source = data.source;
            }
            if (data.direction != null) {
                message.direction = data.direction;
            }
            if (data.state != null) {
                message.state = data.state;
            }
            return message;
        }
        toObject() {
            const data: {
                source?: TrackSource;
                direction?: TrackDirection;
                state?: TrackState;
            } = {};
            if (this.source != null) {
                data.source = this.source;
            }
            if (this.direction != null) {
                data.direction = this.direction;
            }
            if (this.state != null) {
                data.state = this.state;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.source != TrackSource.CAMERA)
                writer.writeEnum(1, this.source);
            if (this.direction != TrackDirection.IN)
                writer.writeEnum(2, this.direction);
            if (this.state != TrackState.OFF)
                writer.writeEnum(3, this.state);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TrackInitialState {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TrackInitialState();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.source = reader.readEnum();
                        break;
                    case 2:
                        message.direction = reader.readEnum();
                        break;
                    case 3:
                        message.state = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TrackInitialState {
            return TrackInitialState.deserialize(bytes);
        }
    }
    export class FollowerHello extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sdp_offer?: string;
            transceivers?: TransceiverEntry[];
            initial_track_states?: TrackInitialState[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2, 3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sdp_offer" in data && data.sdp_offer != undefined) {
                    this.sdp_offer = data.sdp_offer;
                }
                if ("transceivers" in data && data.transceivers != undefined) {
                    this.transceivers = data.transceivers;
                }
                if ("initial_track_states" in data && data.initial_track_states != undefined) {
                    this.initial_track_states = data.initial_track_states;
                }
            }
        }
        get sdp_offer() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sdp_offer(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get transceivers() {
            return pb_1.Message.getRepeatedWrapperField(this, TransceiverEntry, 2) as TransceiverEntry[];
        }
        set transceivers(value: TransceiverEntry[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        get initial_track_states() {
            return pb_1.Message.getRepeatedWrapperField(this, TrackInitialState, 3) as TrackInitialState[];
        }
        set initial_track_states(value: TrackInitialState[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        static fromObject(data: {
            sdp_offer?: string;
            transceivers?: ReturnType<typeof TransceiverEntry.prototype.toObject>[];
            initial_track_states?: ReturnType<typeof TrackInitialState.prototype.toObject>[];
        }): FollowerHello {
            const message = new FollowerHello({});
            if (data.sdp_offer != null) {
                message.sdp_offer = data.sdp_offer;
            }
            if (data.transceivers != null) {
                message.transceivers = data.transceivers.map(item => TransceiverEntry.fromObject(item));
            }
            if (data.initial_track_states != null) {
                message.initial_track_states = data.initial_track_states.map(item => TrackInitialState.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                sdp_offer?: string;
                transceivers?: ReturnType<typeof TransceiverEntry.prototype.toObject>[];
                initial_track_states?: ReturnType<typeof TrackInitialState.prototype.toObject>[];
            } = {};
            if (this.sdp_offer != null) {
                data.sdp_offer = this.sdp_offer;
            }
            if (this.transceivers != null) {
                data.transceivers = this.transceivers.map((item: TransceiverEntry) => item.toObject());
            }
            if (this.initial_track_states != null) {
                data.initial_track_states = this.initial_track_states.map((item: TrackInitialState) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sdp_offer.length)
                writer.writeString(1, this.sdp_offer);
            if (this.transceivers.length)
                writer.writeRepeatedMessage(2, this.transceivers, (item: TransceiverEntry) => item.serialize(writer));
            if (this.initial_track_states.length)
                writer.writeRepeatedMessage(3, this.initial_track_states, (item: TrackInitialState) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FollowerHello {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FollowerHello();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sdp_offer = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.transceivers, () => pb_1.Message.addToRepeatedWrapperField(message, 2, TransceiverEntry.deserialize(reader), TransceiverEntry));
                        break;
                    case 3:
                        reader.readMessage(message.initial_track_states, () => pb_1.Message.addToRepeatedWrapperField(message, 3, TrackInitialState.deserialize(reader), TrackInitialState));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FollowerHello {
            return FollowerHello.deserialize(bytes);
        }
    }
    export class LeaderHello extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sdp_answer?: string;
            initial_track_states?: TrackInitialState[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sdp_answer" in data && data.sdp_answer != undefined) {
                    this.sdp_answer = data.sdp_answer;
                }
                if ("initial_track_states" in data && data.initial_track_states != undefined) {
                    this.initial_track_states = data.initial_track_states;
                }
            }
        }
        get sdp_answer() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sdp_answer(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get initial_track_states() {
            return pb_1.Message.getRepeatedWrapperField(this, TrackInitialState, 2) as TrackInitialState[];
        }
        set initial_track_states(value: TrackInitialState[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data: {
            sdp_answer?: string;
            initial_track_states?: ReturnType<typeof TrackInitialState.prototype.toObject>[];
        }): LeaderHello {
            const message = new LeaderHello({});
            if (data.sdp_answer != null) {
                message.sdp_answer = data.sdp_answer;
            }
            if (data.initial_track_states != null) {
                message.initial_track_states = data.initial_track_states.map(item => TrackInitialState.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                sdp_answer?: string;
                initial_track_states?: ReturnType<typeof TrackInitialState.prototype.toObject>[];
            } = {};
            if (this.sdp_answer != null) {
                data.sdp_answer = this.sdp_answer;
            }
            if (this.initial_track_states != null) {
                data.initial_track_states = this.initial_track_states.map((item: TrackInitialState) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sdp_answer.length)
                writer.writeString(1, this.sdp_answer);
            if (this.initial_track_states.length)
                writer.writeRepeatedMessage(2, this.initial_track_states, (item: TrackInitialState) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LeaderHello {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LeaderHello();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sdp_answer = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.initial_track_states, () => pb_1.Message.addToRepeatedWrapperField(message, 2, TrackInitialState.deserialize(reader), TrackInitialState));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): LeaderHello {
            return LeaderHello.deserialize(bytes);
        }
    }
    export class IceCandidate extends pb_1.Message {
        #one_of_decls: number[][] = [[1], [2], [3], [4]];
        constructor(data?: any[] | ({} & (({
            candidate?: string;
        }) | ({
            sdp_mid?: string;
        }) | ({
            sdp_m_line_index?: number;
        }) | ({
            username_fragment?: string;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("candidate" in data && data.candidate != undefined) {
                    this.candidate = data.candidate;
                }
                if ("sdp_mid" in data && data.sdp_mid != undefined) {
                    this.sdp_mid = data.sdp_mid;
                }
                if ("sdp_m_line_index" in data && data.sdp_m_line_index != undefined) {
                    this.sdp_m_line_index = data.sdp_m_line_index;
                }
                if ("username_fragment" in data && data.username_fragment != undefined) {
                    this.username_fragment = data.username_fragment;
                }
            }
        }
        get candidate() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set candidate(value: string) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_candidate() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get sdp_mid() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set sdp_mid(value: string) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
        }
        get has_sdp_mid() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get sdp_m_line_index() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set sdp_m_line_index(value: number) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[2], value);
        }
        get has_sdp_m_line_index() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get username_fragment() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set username_fragment(value: string) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[3], value);
        }
        get has_username_fragment() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get _candidate() {
            const cases: {
                [index: number]: "none" | "candidate";
            } = {
                0: "none",
                1: "candidate"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        get _sdp_mid() {
            const cases: {
                [index: number]: "none" | "sdp_mid";
            } = {
                0: "none",
                2: "sdp_mid"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _sdp_m_line_index() {
            const cases: {
                [index: number]: "none" | "sdp_m_line_index";
            } = {
                0: "none",
                3: "sdp_m_line_index"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _username_fragment() {
            const cases: {
                [index: number]: "none" | "username_fragment";
            } = {
                0: "none",
                4: "username_fragment"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        static fromObject(data: {
            candidate?: string;
            sdp_mid?: string;
            sdp_m_line_index?: number;
            username_fragment?: string;
        }): IceCandidate {
            const message = new IceCandidate({});
            if (data.candidate != null) {
                message.candidate = data.candidate;
            }
            if (data.sdp_mid != null) {
                message.sdp_mid = data.sdp_mid;
            }
            if (data.sdp_m_line_index != null) {
                message.sdp_m_line_index = data.sdp_m_line_index;
            }
            if (data.username_fragment != null) {
                message.username_fragment = data.username_fragment;
            }
            return message;
        }
        toObject() {
            const data: {
                candidate?: string;
                sdp_mid?: string;
                sdp_m_line_index?: number;
                username_fragment?: string;
            } = {};
            if (this.candidate != null) {
                data.candidate = this.candidate;
            }
            if (this.sdp_mid != null) {
                data.sdp_mid = this.sdp_mid;
            }
            if (this.sdp_m_line_index != null) {
                data.sdp_m_line_index = this.sdp_m_line_index;
            }
            if (this.username_fragment != null) {
                data.username_fragment = this.username_fragment;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_candidate)
                writer.writeString(1, this.candidate);
            if (this.has_sdp_mid)
                writer.writeString(2, this.sdp_mid);
            if (this.has_sdp_m_line_index)
                writer.writeFixed32(3, this.sdp_m_line_index);
            if (this.has_username_fragment)
                writer.writeString(4, this.username_fragment);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IceCandidate {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IceCandidate();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.candidate = reader.readString();
                        break;
                    case 2:
                        message.sdp_mid = reader.readString();
                        break;
                    case 3:
                        message.sdp_m_line_index = reader.readFixed32();
                        break;
                    case 4:
                        message.username_fragment = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): IceCandidate {
            return IceCandidate.deserialize(bytes);
        }
    }
    export class IceCandidates extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            ice_candidates?: IceCandidate[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("ice_candidates" in data && data.ice_candidates != undefined) {
                    this.ice_candidates = data.ice_candidates;
                }
            }
        }
        get ice_candidates() {
            return pb_1.Message.getRepeatedWrapperField(this, IceCandidate, 1) as IceCandidate[];
        }
        set ice_candidates(value: IceCandidate[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            ice_candidates?: ReturnType<typeof IceCandidate.prototype.toObject>[];
        }): IceCandidates {
            const message = new IceCandidates({});
            if (data.ice_candidates != null) {
                message.ice_candidates = data.ice_candidates.map(item => IceCandidate.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                ice_candidates?: ReturnType<typeof IceCandidate.prototype.toObject>[];
            } = {};
            if (this.ice_candidates != null) {
                data.ice_candidates = this.ice_candidates.map((item: IceCandidate) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.ice_candidates.length)
                writer.writeRepeatedMessage(1, this.ice_candidates, (item: IceCandidate) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IceCandidates {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IceCandidates();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.ice_candidates, () => pb_1.Message.addToRepeatedWrapperField(message, 1, IceCandidate.deserialize(reader), IceCandidate));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): IceCandidates {
            return IceCandidates.deserialize(bytes);
        }
    }
    export class Select extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): Select {
            const message = new Select({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Select {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Select();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): Select {
            return Select.deserialize(bytes);
        }
    }
    export class Disconnect extends pb_1.Message {
        #one_of_decls: number[][] = [[1]];
        constructor(data?: any[] | ({} & (({
            reason?: Disconnect.Reason;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("reason" in data && data.reason != undefined) {
                    this.reason = data.reason;
                }
            }
        }
        get reason() {
            return pb_1.Message.getFieldWithDefault(this, 1, Disconnect.Reason.HANGUP) as Disconnect.Reason;
        }
        set reason(value: Disconnect.Reason) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_reason() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get _reason() {
            const cases: {
                [index: number]: "none" | "reason";
            } = {
                0: "none",
                1: "reason"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        static fromObject(data: {
            reason?: Disconnect.Reason;
        }): Disconnect {
            const message = new Disconnect({});
            if (data.reason != null) {
                message.reason = data.reason;
            }
            return message;
        }
        toObject() {
            const data: {
                reason?: Disconnect.Reason;
            } = {};
            if (this.reason != null) {
                data.reason = this.reason;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_reason)
                writer.writeEnum(1, this.reason);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Disconnect {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Disconnect();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.reason = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): Disconnect {
            return Disconnect.deserialize(bytes);
        }
    }
    export namespace Disconnect {
        export enum Reason {
            HANGUP = 0,
            CONNECTED_ON_OTHER_DEVICE = 1,
            BUSY = 2,
            DECLINE = 3,
            SWITCH_SIGNALLING = 4
        }
    }
    export class LeaderSwitched extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data: {}): LeaderSwitched {
            const message = new LeaderSwitched({});
            return message;
        }
        toObject() {
            const data: {} = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LeaderSwitched {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LeaderSwitched();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): LeaderSwitched {
            return LeaderSwitched.deserialize(bytes);
        }
    }
    export class EnableRTCStatsReporting extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            dictionary_download_url?: string;
            etag?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("dictionary_download_url" in data && data.dictionary_download_url != undefined) {
                    this.dictionary_download_url = data.dictionary_download_url;
                }
                if ("etag" in data && data.etag != undefined) {
                    this.etag = data.etag;
                }
            }
        }
        get dictionary_download_url() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set dictionary_download_url(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get etag() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set etag(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            dictionary_download_url?: string;
            etag?: string;
        }): EnableRTCStatsReporting {
            const message = new EnableRTCStatsReporting({});
            if (data.dictionary_download_url != null) {
                message.dictionary_download_url = data.dictionary_download_url;
            }
            if (data.etag != null) {
                message.etag = data.etag;
            }
            return message;
        }
        toObject() {
            const data: {
                dictionary_download_url?: string;
                etag?: string;
            } = {};
            if (this.dictionary_download_url != null) {
                data.dictionary_download_url = this.dictionary_download_url;
            }
            if (this.etag != null) {
                data.etag = this.etag;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.dictionary_download_url.length)
                writer.writeString(1, this.dictionary_download_url);
            if (this.etag.length)
                writer.writeString(2, this.etag);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): EnableRTCStatsReporting {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new EnableRTCStatsReporting();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.dictionary_download_url = reader.readString();
                        break;
                    case 2:
                        message.etag = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): EnableRTCStatsReporting {
            return EnableRTCStatsReporting.deserialize(bytes);
        }
    }
    export class CompressedRTCStatsReport extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            etag?: string;
            report?: Uint8Array;
            peer_connection_id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("etag" in data && data.etag != undefined) {
                    this.etag = data.etag;
                }
                if ("report" in data && data.report != undefined) {
                    this.report = data.report;
                }
                if ("peer_connection_id" in data && data.peer_connection_id != undefined) {
                    this.peer_connection_id = data.peer_connection_id;
                }
            }
        }
        get etag() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set etag(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get report() {
            return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
        }
        set report(value: Uint8Array) {
            pb_1.Message.setField(this, 2, value);
        }
        get peer_connection_id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set peer_connection_id(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            etag?: string;
            report?: Uint8Array;
            peer_connection_id?: string;
        }): CompressedRTCStatsReport {
            const message = new CompressedRTCStatsReport({});
            if (data.etag != null) {
                message.etag = data.etag;
            }
            if (data.report != null) {
                message.report = data.report;
            }
            if (data.peer_connection_id != null) {
                message.peer_connection_id = data.peer_connection_id;
            }
            return message;
        }
        toObject() {
            const data: {
                etag?: string;
                report?: Uint8Array;
                peer_connection_id?: string;
            } = {};
            if (this.etag != null) {
                data.etag = this.etag;
            }
            if (this.report != null) {
                data.report = this.report;
            }
            if (this.peer_connection_id != null) {
                data.peer_connection_id = this.peer_connection_id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.etag.length)
                writer.writeString(1, this.etag);
            if (this.report.length)
                writer.writeBytes(2, this.report);
            if (this.peer_connection_id.length)
                writer.writeString(3, this.peer_connection_id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CompressedRTCStatsReport {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CompressedRTCStatsReport();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.etag = reader.readString();
                        break;
                    case 2:
                        message.report = reader.readBytes();
                        break;
                    case 3:
                        message.peer_connection_id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): CompressedRTCStatsReport {
            return CompressedRTCStatsReport.deserialize(bytes);
        }
    }
    export class SciezkaMessage extends pb_1.Message {
        #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 8]];
        constructor(data?: any[] | ({} & (({
            follower_hello?: FollowerHello;
            leader_hello?: never;
            ice_candidates?: never;
            disconnect?: never;
            select?: never;
            leader_switched?: never;
            enable_rtc_reporting?: never;
            rtc_report?: never;
        } | {
            follower_hello?: never;
            leader_hello?: LeaderHello;
            ice_candidates?: never;
            disconnect?: never;
            select?: never;
            leader_switched?: never;
            enable_rtc_reporting?: never;
            rtc_report?: never;
        } | {
            follower_hello?: never;
            leader_hello?: never;
            ice_candidates?: IceCandidates;
            disconnect?: never;
            select?: never;
            leader_switched?: never;
            enable_rtc_reporting?: never;
            rtc_report?: never;
        } | {
            follower_hello?: never;
            leader_hello?: never;
            ice_candidates?: never;
            disconnect?: Disconnect;
            select?: never;
            leader_switched?: never;
            enable_rtc_reporting?: never;
            rtc_report?: never;
        } | {
            follower_hello?: never;
            leader_hello?: never;
            ice_candidates?: never;
            disconnect?: never;
            select?: Select;
            leader_switched?: never;
            enable_rtc_reporting?: never;
            rtc_report?: never;
        } | {
            follower_hello?: never;
            leader_hello?: never;
            ice_candidates?: never;
            disconnect?: never;
            select?: never;
            leader_switched?: LeaderSwitched;
            enable_rtc_reporting?: never;
            rtc_report?: never;
        } | {
            follower_hello?: never;
            leader_hello?: never;
            ice_candidates?: never;
            disconnect?: never;
            select?: never;
            leader_switched?: never;
            enable_rtc_reporting?: EnableRTCStatsReporting;
            rtc_report?: never;
        } | {
            follower_hello?: never;
            leader_hello?: never;
            ice_candidates?: never;
            disconnect?: never;
            select?: never;
            leader_switched?: never;
            enable_rtc_reporting?: never;
            rtc_report?: CompressedRTCStatsReport;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("follower_hello" in data && data.follower_hello != undefined) {
                    this.follower_hello = data.follower_hello;
                }
                if ("leader_hello" in data && data.leader_hello != undefined) {
                    this.leader_hello = data.leader_hello;
                }
                if ("ice_candidates" in data && data.ice_candidates != undefined) {
                    this.ice_candidates = data.ice_candidates;
                }
                if ("disconnect" in data && data.disconnect != undefined) {
                    this.disconnect = data.disconnect;
                }
                if ("select" in data && data.select != undefined) {
                    this.select = data.select;
                }
                if ("leader_switched" in data && data.leader_switched != undefined) {
                    this.leader_switched = data.leader_switched;
                }
                if ("enable_rtc_reporting" in data && data.enable_rtc_reporting != undefined) {
                    this.enable_rtc_reporting = data.enable_rtc_reporting;
                }
                if ("rtc_report" in data && data.rtc_report != undefined) {
                    this.rtc_report = data.rtc_report;
                }
            }
        }
        get follower_hello() {
            return pb_1.Message.getWrapperField(this, FollowerHello, 1) as FollowerHello;
        }
        set follower_hello(value: FollowerHello) {
            pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
        }
        get has_follower_hello() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get leader_hello() {
            return pb_1.Message.getWrapperField(this, LeaderHello, 2) as LeaderHello;
        }
        set leader_hello(value: LeaderHello) {
            pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
        }
        get has_leader_hello() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get ice_candidates() {
            return pb_1.Message.getWrapperField(this, IceCandidates, 3) as IceCandidates;
        }
        set ice_candidates(value: IceCandidates) {
            pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
        }
        get has_ice_candidates() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get disconnect() {
            return pb_1.Message.getWrapperField(this, Disconnect, 4) as Disconnect;
        }
        set disconnect(value: Disconnect) {
            pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
        }
        get has_disconnect() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get select() {
            return pb_1.Message.getWrapperField(this, Select, 5) as Select;
        }
        set select(value: Select) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
        }
        get has_select() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get leader_switched() {
            return pb_1.Message.getWrapperField(this, LeaderSwitched, 6) as LeaderSwitched;
        }
        set leader_switched(value: LeaderSwitched) {
            pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
        }
        get has_leader_switched() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get enable_rtc_reporting() {
            return pb_1.Message.getWrapperField(this, EnableRTCStatsReporting, 7) as EnableRTCStatsReporting;
        }
        set enable_rtc_reporting(value: EnableRTCStatsReporting) {
            pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0], value);
        }
        get has_enable_rtc_reporting() {
            return pb_1.Message.getField(this, 7) != null;
        }
        get rtc_report() {
            return pb_1.Message.getWrapperField(this, CompressedRTCStatsReport, 8) as CompressedRTCStatsReport;
        }
        set rtc_report(value: CompressedRTCStatsReport) {
            pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0], value);
        }
        get has_rtc_report() {
            return pb_1.Message.getField(this, 8) != null;
        }
        get content() {
            const cases: {
                [index: number]: "none" | "follower_hello" | "leader_hello" | "ice_candidates" | "disconnect" | "select" | "leader_switched" | "enable_rtc_reporting" | "rtc_report";
            } = {
                0: "none",
                1: "follower_hello",
                2: "leader_hello",
                3: "ice_candidates",
                4: "disconnect",
                5: "select",
                6: "leader_switched",
                7: "enable_rtc_reporting",
                8: "rtc_report"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 8])];
        }
        static fromObject(data: {
            follower_hello?: ReturnType<typeof FollowerHello.prototype.toObject>;
            leader_hello?: ReturnType<typeof LeaderHello.prototype.toObject>;
            ice_candidates?: ReturnType<typeof IceCandidates.prototype.toObject>;
            disconnect?: ReturnType<typeof Disconnect.prototype.toObject>;
            select?: ReturnType<typeof Select.prototype.toObject>;
            leader_switched?: ReturnType<typeof LeaderSwitched.prototype.toObject>;
            enable_rtc_reporting?: ReturnType<typeof EnableRTCStatsReporting.prototype.toObject>;
            rtc_report?: ReturnType<typeof CompressedRTCStatsReport.prototype.toObject>;
        }): SciezkaMessage {
            const message = new SciezkaMessage({});
            if (data.follower_hello != null) {
                message.follower_hello = FollowerHello.fromObject(data.follower_hello);
            }
            if (data.leader_hello != null) {
                message.leader_hello = LeaderHello.fromObject(data.leader_hello);
            }
            if (data.ice_candidates != null) {
                message.ice_candidates = IceCandidates.fromObject(data.ice_candidates);
            }
            if (data.disconnect != null) {
                message.disconnect = Disconnect.fromObject(data.disconnect);
            }
            if (data.select != null) {
                message.select = Select.fromObject(data.select);
            }
            if (data.leader_switched != null) {
                message.leader_switched = LeaderSwitched.fromObject(data.leader_switched);
            }
            if (data.enable_rtc_reporting != null) {
                message.enable_rtc_reporting = EnableRTCStatsReporting.fromObject(data.enable_rtc_reporting);
            }
            if (data.rtc_report != null) {
                message.rtc_report = CompressedRTCStatsReport.fromObject(data.rtc_report);
            }
            return message;
        }
        toObject() {
            const data: {
                follower_hello?: ReturnType<typeof FollowerHello.prototype.toObject>;
                leader_hello?: ReturnType<typeof LeaderHello.prototype.toObject>;
                ice_candidates?: ReturnType<typeof IceCandidates.prototype.toObject>;
                disconnect?: ReturnType<typeof Disconnect.prototype.toObject>;
                select?: ReturnType<typeof Select.prototype.toObject>;
                leader_switched?: ReturnType<typeof LeaderSwitched.prototype.toObject>;
                enable_rtc_reporting?: ReturnType<typeof EnableRTCStatsReporting.prototype.toObject>;
                rtc_report?: ReturnType<typeof CompressedRTCStatsReport.prototype.toObject>;
            } = {};
            if (this.follower_hello != null) {
                data.follower_hello = this.follower_hello.toObject();
            }
            if (this.leader_hello != null) {
                data.leader_hello = this.leader_hello.toObject();
            }
            if (this.ice_candidates != null) {
                data.ice_candidates = this.ice_candidates.toObject();
            }
            if (this.disconnect != null) {
                data.disconnect = this.disconnect.toObject();
            }
            if (this.select != null) {
                data.select = this.select.toObject();
            }
            if (this.leader_switched != null) {
                data.leader_switched = this.leader_switched.toObject();
            }
            if (this.enable_rtc_reporting != null) {
                data.enable_rtc_reporting = this.enable_rtc_reporting.toObject();
            }
            if (this.rtc_report != null) {
                data.rtc_report = this.rtc_report.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_follower_hello)
                writer.writeMessage(1, this.follower_hello, () => this.follower_hello.serialize(writer));
            if (this.has_leader_hello)
                writer.writeMessage(2, this.leader_hello, () => this.leader_hello.serialize(writer));
            if (this.has_ice_candidates)
                writer.writeMessage(3, this.ice_candidates, () => this.ice_candidates.serialize(writer));
            if (this.has_disconnect)
                writer.writeMessage(4, this.disconnect, () => this.disconnect.serialize(writer));
            if (this.has_select)
                writer.writeMessage(5, this.select, () => this.select.serialize(writer));
            if (this.has_leader_switched)
                writer.writeMessage(6, this.leader_switched, () => this.leader_switched.serialize(writer));
            if (this.has_enable_rtc_reporting)
                writer.writeMessage(7, this.enable_rtc_reporting, () => this.enable_rtc_reporting.serialize(writer));
            if (this.has_rtc_report)
                writer.writeMessage(8, this.rtc_report, () => this.rtc_report.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SciezkaMessage {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SciezkaMessage();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.follower_hello, () => message.follower_hello = FollowerHello.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.leader_hello, () => message.leader_hello = LeaderHello.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.ice_candidates, () => message.ice_candidates = IceCandidates.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.disconnect, () => message.disconnect = Disconnect.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.select, () => message.select = Select.deserialize(reader));
                        break;
                    case 6:
                        reader.readMessage(message.leader_switched, () => message.leader_switched = LeaderSwitched.deserialize(reader));
                        break;
                    case 7:
                        reader.readMessage(message.enable_rtc_reporting, () => message.enable_rtc_reporting = EnableRTCStatsReporting.deserialize(reader));
                        break;
                    case 8:
                        reader.readMessage(message.rtc_report, () => message.rtc_report = CompressedRTCStatsReport.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SciezkaMessage {
            return SciezkaMessage.deserialize(bytes);
        }
    }
    export class IncomingSciezkaMessage extends pb_1.Message {
        #one_of_decls: number[][] = [[1]];
        constructor(data?: any[] | ({
            message?: SciezkaMessage;
        } & (({
            destination_client_id?: string;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("destination_client_id" in data && data.destination_client_id != undefined) {
                    this.destination_client_id = data.destination_client_id;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get destination_client_id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set destination_client_id(value: string) {
            pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
        }
        get has_destination_client_id() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get message() {
            return pb_1.Message.getWrapperField(this, SciezkaMessage, 2) as SciezkaMessage;
        }
        set message(value: SciezkaMessage) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_message() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get _destination_client_id() {
            const cases: {
                [index: number]: "none" | "destination_client_id";
            } = {
                0: "none",
                1: "destination_client_id"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1])];
        }
        static fromObject(data: {
            destination_client_id?: string;
            message?: ReturnType<typeof SciezkaMessage.prototype.toObject>;
        }): IncomingSciezkaMessage {
            const message = new IncomingSciezkaMessage({});
            if (data.destination_client_id != null) {
                message.destination_client_id = data.destination_client_id;
            }
            if (data.message != null) {
                message.message = SciezkaMessage.fromObject(data.message);
            }
            return message;
        }
        toObject() {
            const data: {
                destination_client_id?: string;
                message?: ReturnType<typeof SciezkaMessage.prototype.toObject>;
            } = {};
            if (this.destination_client_id != null) {
                data.destination_client_id = this.destination_client_id;
            }
            if (this.message != null) {
                data.message = this.message.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_destination_client_id)
                writer.writeString(1, this.destination_client_id);
            if (this.has_message)
                writer.writeMessage(2, this.message, () => this.message.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IncomingSciezkaMessage {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IncomingSciezkaMessage();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.destination_client_id = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.message, () => message.message = SciezkaMessage.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): IncomingSciezkaMessage {
            return IncomingSciezkaMessage.deserialize(bytes);
        }
    }
    export class OutgoingSciezkaMessage extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            source_client_id?: string;
            message?: SciezkaMessage;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("source_client_id" in data && data.source_client_id != undefined) {
                    this.source_client_id = data.source_client_id;
                }
                if ("message" in data && data.message != undefined) {
                    this.message = data.message;
                }
            }
        }
        get source_client_id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set source_client_id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get message() {
            return pb_1.Message.getWrapperField(this, SciezkaMessage, 2) as SciezkaMessage;
        }
        set message(value: SciezkaMessage) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_message() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            source_client_id?: string;
            message?: ReturnType<typeof SciezkaMessage.prototype.toObject>;
        }): OutgoingSciezkaMessage {
            const message = new OutgoingSciezkaMessage({});
            if (data.source_client_id != null) {
                message.source_client_id = data.source_client_id;
            }
            if (data.message != null) {
                message.message = SciezkaMessage.fromObject(data.message);
            }
            return message;
        }
        toObject() {
            const data: {
                source_client_id?: string;
                message?: ReturnType<typeof SciezkaMessage.prototype.toObject>;
            } = {};
            if (this.source_client_id != null) {
                data.source_client_id = this.source_client_id;
            }
            if (this.message != null) {
                data.message = this.message.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.source_client_id.length)
                writer.writeString(1, this.source_client_id);
            if (this.has_message)
                writer.writeMessage(2, this.message, () => this.message.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OutgoingSciezkaMessage {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OutgoingSciezkaMessage();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.source_client_id = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.message, () => message.message = SciezkaMessage.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): OutgoingSciezkaMessage {
            return OutgoingSciezkaMessage.deserialize(bytes);
        }
    }
    export class TrackUpdate extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            source?: TrackSource;
            direction?: TrackDirection;
            state?: TrackState;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("source" in data && data.source != undefined) {
                    this.source = data.source;
                }
                if ("direction" in data && data.direction != undefined) {
                    this.direction = data.direction;
                }
                if ("state" in data && data.state != undefined) {
                    this.state = data.state;
                }
            }
        }
        get source() {
            return pb_1.Message.getFieldWithDefault(this, 1, TrackSource.CAMERA) as TrackSource;
        }
        set source(value: TrackSource) {
            pb_1.Message.setField(this, 1, value);
        }
        get direction() {
            return pb_1.Message.getFieldWithDefault(this, 2, TrackDirection.IN) as TrackDirection;
        }
        set direction(value: TrackDirection) {
            pb_1.Message.setField(this, 2, value);
        }
        get state() {
            return pb_1.Message.getFieldWithDefault(this, 3, TrackState.OFF) as TrackState;
        }
        set state(value: TrackState) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            source?: TrackSource;
            direction?: TrackDirection;
            state?: TrackState;
        }): TrackUpdate {
            const message = new TrackUpdate({});
            if (data.source != null) {
                message.source = data.source;
            }
            if (data.direction != null) {
                message.direction = data.direction;
            }
            if (data.state != null) {
                message.state = data.state;
            }
            return message;
        }
        toObject() {
            const data: {
                source?: TrackSource;
                direction?: TrackDirection;
                state?: TrackState;
            } = {};
            if (this.source != null) {
                data.source = this.source;
            }
            if (this.direction != null) {
                data.direction = this.direction;
            }
            if (this.state != null) {
                data.state = this.state;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.source != TrackSource.CAMERA)
                writer.writeEnum(1, this.source);
            if (this.direction != TrackDirection.IN)
                writer.writeEnum(2, this.direction);
            if (this.state != TrackState.OFF)
                writer.writeEnum(3, this.state);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TrackUpdate {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TrackUpdate();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.source = reader.readEnum();
                        break;
                    case 2:
                        message.direction = reader.readEnum();
                        break;
                    case 3:
                        message.state = reader.readEnum();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): TrackUpdate {
            return TrackUpdate.deserialize(bytes);
        }
    }
    export class ViewPortChanged extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            source?: TrackSource;
            width?: number;
            height?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("source" in data && data.source != undefined) {
                    this.source = data.source;
                }
                if ("width" in data && data.width != undefined) {
                    this.width = data.width;
                }
                if ("height" in data && data.height != undefined) {
                    this.height = data.height;
                }
            }
        }
        get source() {
            return pb_1.Message.getFieldWithDefault(this, 1, TrackSource.CAMERA) as TrackSource;
        }
        set source(value: TrackSource) {
            pb_1.Message.setField(this, 1, value);
        }
        get width() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set width(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get height() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set height(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            source?: TrackSource;
            width?: number;
            height?: number;
        }): ViewPortChanged {
            const message = new ViewPortChanged({});
            if (data.source != null) {
                message.source = data.source;
            }
            if (data.width != null) {
                message.width = data.width;
            }
            if (data.height != null) {
                message.height = data.height;
            }
            return message;
        }
        toObject() {
            const data: {
                source?: TrackSource;
                width?: number;
                height?: number;
            } = {};
            if (this.source != null) {
                data.source = this.source;
            }
            if (this.width != null) {
                data.width = this.width;
            }
            if (this.height != null) {
                data.height = this.height;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.source != TrackSource.CAMERA)
                writer.writeEnum(1, this.source);
            if (this.width != 0)
                writer.writeUint32(2, this.width);
            if (this.height != 0)
                writer.writeUint32(3, this.height);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ViewPortChanged {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ViewPortChanged();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.source = reader.readEnum();
                        break;
                    case 2:
                        message.width = reader.readUint32();
                        break;
                    case 3:
                        message.height = reader.readUint32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ViewPortChanged {
            return ViewPortChanged.deserialize(bytes);
        }
    }
    export class PeerMessage extends pb_1.Message {
        #one_of_decls: number[][] = [[1, 2]];
        constructor(data?: any[] | ({} & (({
            track_update?: TrackUpdate;
            view_port_changed?: never;
        } | {
            track_update?: never;
            view_port_changed?: ViewPortChanged;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("track_update" in data && data.track_update != undefined) {
                    this.track_update = data.track_update;
                }
                if ("view_port_changed" in data && data.view_port_changed != undefined) {
                    this.view_port_changed = data.view_port_changed;
                }
            }
        }
        get track_update() {
            return pb_1.Message.getWrapperField(this, TrackUpdate, 1) as TrackUpdate;
        }
        set track_update(value: TrackUpdate) {
            pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
        }
        get has_track_update() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get view_port_changed() {
            return pb_1.Message.getWrapperField(this, ViewPortChanged, 2) as ViewPortChanged;
        }
        set view_port_changed(value: ViewPortChanged) {
            pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
        }
        get has_view_port_changed() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get content() {
            const cases: {
                [index: number]: "none" | "track_update" | "view_port_changed";
            } = {
                0: "none",
                1: "track_update",
                2: "view_port_changed"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1, 2])];
        }
        static fromObject(data: {
            track_update?: ReturnType<typeof TrackUpdate.prototype.toObject>;
            view_port_changed?: ReturnType<typeof ViewPortChanged.prototype.toObject>;
        }): PeerMessage {
            const message = new PeerMessage({});
            if (data.track_update != null) {
                message.track_update = TrackUpdate.fromObject(data.track_update);
            }
            if (data.view_port_changed != null) {
                message.view_port_changed = ViewPortChanged.fromObject(data.view_port_changed);
            }
            return message;
        }
        toObject() {
            const data: {
                track_update?: ReturnType<typeof TrackUpdate.prototype.toObject>;
                view_port_changed?: ReturnType<typeof ViewPortChanged.prototype.toObject>;
            } = {};
            if (this.track_update != null) {
                data.track_update = this.track_update.toObject();
            }
            if (this.view_port_changed != null) {
                data.view_port_changed = this.view_port_changed.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_track_update)
                writer.writeMessage(1, this.track_update, () => this.track_update.serialize(writer));
            if (this.has_view_port_changed)
                writer.writeMessage(2, this.view_port_changed, () => this.view_port_changed.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PeerMessage {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PeerMessage();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.track_update, () => message.track_update = TrackUpdate.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.view_port_changed, () => message.view_port_changed = ViewPortChanged.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): PeerMessage {
            return PeerMessage.deserialize(bytes);
        }
    }
}
