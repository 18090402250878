import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString, } from "../Utils";
import { checkGarconUrl, checkTicket, tryFetchTicket, connectSignalling, mockVideoStream } from "./Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import KaldunTicketSubject from "../client/TicketSubject";
import RoomSession from "../client/RoomSession";
import { IcePolicy } from "../client/Domain";
import Delay from "../client/Delay";
import * as rtc_stat from '../client/WebRTCStatistics'
import { TrackStatus, VideoSource } from "../client/Messages";

export default class LivePartyTotalProducedBandwidthLimit implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.LiveParty,
        Services.Garcon,
        Services.Kvashanina,
        Services.Kaldun,
        Services.Mediasoup,
    ]);

    readonly videoProducerBandwidthLimitKbps: number | null

    get name(): string {
        return `Video bitrate in live party is ${this.videoProducerBandwidthLimitKbps ? `limited to ${this.videoProducerBandwidthLimitKbps} kpbs` : "unlimited"}`
    }

    constructor({videoProducerBandwidthLimitKbps}: {videoProducerBandwidthLimitKbps: number | null}) {
        this.videoProducerBandwidthLimitKbps = videoProducerBandwidthLimitKbps
    }

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        const user = {
            accountId: randomString(),
            clientId: randomString(),
            roomId: randomString(),
        };

        const ticket = await tryFetchTicket(client, {
            ...user,
            subject: KaldunTicketSubject.BroadcastAndView,
        });

        checkTicket(assert, {
            ticket: ticket?.getTicket(),
            ...user,
            subject: KaldunTicketSubject.BroadcastAndView
        });

        if (!ticket) {
            return;
        }

        const signalling = await connectSignalling(assert, ticket.getEndpoint(), user, KaldunTicketSubject.BroadcastAndView);
        if (!signalling) {
            return;
        }
    
        const session = new RoomSession({
            roomId: user.roomId,
            icePolicy: IcePolicy.All,
            enableScreenShare: false,
            ticketSubject: KaldunTicketSubject.BroadcastAndView,
            iceServers: [],
        });

        const offer = await session.createInitialOffer();
        const joinResult = await signalling.join({
            offer,
            videoProducerBandwidthLimitKbps: this.videoProducerBandwidthLimitKbps || undefined,
            allowAutomaticSimulcast: false
        });

        assert.true("join_room_v2" in joinResult, `User joined to room = ${user.roomId}`);
    
        if ("join_room_v2" in joinResult) {
            assert.true(await session.applyProducedSdpOffer(joinResult.join_room_v2.sdp_offer), `Applied produced sdp offer in room = ${user.roomId}`);
            assert.true(await session.applyProducedSdpAnswer(joinResult.join_room_v2.sdp_answer), `Aplied produced sdp answer in room = ${user.roomId}`);
        };

        const { mediaStream } = await mockVideoStream();
    
        const videoTrack = mediaStream.getVideoTracks()[0];
        session.cameraSendTransceiver!.sender.replaceTrack(videoTrack);

        await signalling.updateMedia({ produced_tracks: [
            {
                mid: session.cameraSendTransceiver!.mid!,
                src: {
                    vid: VideoSource.Camera
                },
                st: TrackStatus.On
            }
        ]})

        const [warmupDurationSeconds, monitorDurationSeconds] = [15, 30];

        await Delay(warmupDurationSeconds * 1000);

        const outboundVideoRtpFilter = (report: rtc_stat.RTCStatsReport): boolean => {
            return report.type === 'outbound-rtp' && report.kind === 'video'
        };

        console.log("Warmup completed");

        const initialStat = await session.getStats();
        let initialCameraStat = Array.from(initialStat.values()).find(outboundVideoRtpFilter) as rtc_stat.RTCOutboundRtpStreamStats | undefined;
        console.log(`Initial statistics: `, Array.from(initialStat.values()));
        console.log(`Initial video statistics: `, JSON.stringify(initialCameraStat));

        await Delay(monitorDurationSeconds * 1000);

        const finalStat = await session.getStats();
        let finalCameraStat = Array.from(finalStat.values()).find(outboundVideoRtpFilter) as rtc_stat.RTCOutboundRtpStreamStats | undefined;

        console.log(`Final statistics:`, Array.from(finalStat.values()));
        console.log(`Final video statistics:`, JSON.stringify(finalCameraStat));

        assert.true(typeof(initialCameraStat) !== "undefined", `Initial video statistics found`);
        assert.true(typeof(finalCameraStat) !== "undefined", `Final video statistics found`);
        if (initialCameraStat?.kind !== "video" || finalCameraStat?.kind !== "video") {
            return;
        }

        if (this.videoProducerBandwidthLimitKbps !== null) {
            const maxBitrate = this.videoProducerBandwidthLimitKbps * 1000;
            assert.true(finalCameraStat.targetBitrate! < maxBitrate,
                        `Expect target encoder bitrate ${finalCameraStat.targetBitrate} not exceed the limit ${maxBitrate}`)
            const actualBandwidthQualityLimitationDuration = finalCameraStat.qualityLimitationDurations!.bandwidth - initialCameraStat.qualityLimitationDurations!.bandwidth;
            const expectedBandwidthQualityLimitationDuration = monitorDurationSeconds;
            assert.true(actualBandwidthQualityLimitationDuration > expectedBandwidthQualityLimitationDuration,
                        `Bandwidth video quality limitation is ${actualBandwidthQualityLimitationDuration} sec >= ${expectedBandwidthQualityLimitationDuration}`);
            const actualVideoBitsSent = (finalCameraStat.bytesSent! - initialCameraStat.bytesSent!) * 8;
            const maxVideoBitsSent = this.videoProducerBandwidthLimitKbps * 1000 * monitorDurationSeconds;
            assert.true(actualVideoBitsSent < maxVideoBitsSent,
                        `Actual video bit sent ${actualVideoBitsSent} is larger than exepected ${maxVideoBitsSent}`);
        } else {
            const actualBandwidthQualityLimitationDuration = finalCameraStat.qualityLimitationDurations!.bandwidth - initialCameraStat.qualityLimitationDurations!.bandwidth;
            const expectedBandwidthQualityLimitationDuration = 0.1;
            assert.true(actualBandwidthQualityLimitationDuration < expectedBandwidthQualityLimitationDuration, 
                        `Bandwidth video quality limitation is expected ${actualBandwidthQualityLimitationDuration} sec < ${expectedBandwidthQualityLimitationDuration} sec`);
            const actualNoneQualityLimitationDuration = finalCameraStat.qualityLimitationDurations!.none - initialCameraStat.qualityLimitationDurations!.none;
            const minNoneQualityLimitationDuration = monitorDurationSeconds;
            assert.true(actualNoneQualityLimitationDuration > minNoneQualityLimitationDuration,
                        `None video quality limitation duration is ${actualBandwidthQualityLimitationDuration} < ${minNoneQualityLimitationDuration}`);
        }

        const leaveResult = await signalling.leave();
        assert.true("leave_room" in leaveResult, `User leaved from room = ${user.roomId}`);
        await session.terminate();
    }
}
