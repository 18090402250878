
import * as messages from './Messages'

type TrackState = {
    mid: string,
    streamName?: string,
    src?: messages.MediaSourceA | messages.MediaSourceV,
    status?: messages.TrackStatus,
    paused?: boolean,
    net_priority?: number,
    forwarded_layer?: messages.ConsumedVideoLayer,
    preferred_layer?: messages.VideoLayer,
    score?: messages.ConsumedTrackScore,
    mediaStreamTrack?: MediaStreamTrack,
}

type MemberState = {
    accountId: string,
    displayName: string,
    status: messages.MemberStatus
    tracks: Map<string, TrackState>,
    streams: Map<string, MediaStream>,
}

type AudioTrackViewState = {
    mid: string
    src?: messages.MediaSourceA
    status?: messages.TrackStatus
    paused?: boolean
    score?: messages.ConsumedTrackScore
}

type VideoTrackViewState = {
    mid: string
    src?: messages.MediaSourceV
    status?: messages.TrackStatus
    paused?: boolean
    forwarded_layer?: messages.ConsumedVideoLayer
    score?: messages.ConsumedTrackScore
}

type StreamViewState = {
    mediaStream: MediaStream,
    audioTrack?: AudioTrackViewState,
    videoTrack?: VideoTrackViewState,
}

export type MemberViewState = {
    id: string
    displayName: string
    cameraStream?: StreamViewState,
    screenStream?: StreamViewState,
}

export const encodeAccountAndDisplayName = (accountId: string, displayName: string) => {
    return JSON.stringify({a: accountId, n: displayName})
}

const decodeAccountAndDisplayName = (encodedAccountId: string) => {
    try {
        let r = JSON.parse(encodedAccountId)
        if (typeof r.a === 'string' && typeof r.n === 'string') {
            return {
                accountId: r.a,
                displayName: r.n,
            }
        } 
    } 
    catch(e) {
        // ignore
    }
    return { accountId: encodedAccountId }
}

export class RoomMembers {
    private readonly members = new Map<string, MemberState>();

    public updateMember(member: messages.MemberInfo) {
        console.log("update member: ", member);
        if (member.st === messages.MemberStatus.Disconnected) {
            console.log("Member ", member.acc, " has disconnected");
            this.members.delete(member.acc)
            return;
        }

        let memberState = this.members.get(member.acc);
        if (!memberState) {
            const accAndName = decodeAccountAndDisplayName(member.acc);
            memberState = {
                accountId: member.acc,
                status: member.st,
                displayName: accAndName.displayName ?? accAndName.accountId,
                streams: new Map(),
                tracks: new Map(),
            }
            this.members.set(member.acc, memberState);
        }
        if (typeof member.st !== 'undefined') {
            memberState.status = member.st
        }

        if (member.tracks) {
            for (var trackInfo of member.tracks) {
                console.log("will update track: ", trackInfo);
                let trackState = memberState.tracks.get(trackInfo.mid);
                if (!trackState) {
                    trackState = {
                        mid: trackInfo.mid,
                    }
                    memberState.tracks.set(trackInfo.mid, trackState);
                    console.log(`saved track with mid ${trackInfo.mid}: `, trackState);
                }

                if (typeof(trackInfo.src) !== "undefined") {
                    trackState.src = trackInfo.src
                }
                if (typeof(trackInfo.st) !== "undefined") {
                    trackState.status = trackInfo.st
                }
                if (typeof(trackInfo.paused) !== "undefined") {
                    trackState.paused = trackInfo.paused
                }
                if (typeof(trackInfo.net_prio) !== "undefined") {
                    trackState.net_priority = trackInfo.net_prio
                }
                if (typeof(trackInfo.pref_layer) !== "undefined") {
                    trackState.preferred_layer = trackInfo.pref_layer;
                }
                if (typeof(trackInfo.fwd_layer) !== "undefined") {
                    trackState.forwarded_layer = trackInfo.fwd_layer;
                }
                if (typeof(trackInfo.score) != "undefined") {
                    trackState.score = trackInfo.score;
                }
            }
        }
        console.log(`Member ${memberState.accountId} after update: `, memberState)
    }

    private findMemberByTrack(mid: string): MemberState | null {
        for (let memberState of this.members.values()) {
            if (memberState.tracks) {
                if (memberState.tracks.get(mid)) {
                    return memberState;
                }
            }
        }
        return null;
    }

    public addTrack(mid: string, track: MediaStreamTrack, stream: MediaStream) {
        let memberState = this.findMemberByTrack(mid);
        if (!memberState) {
            console.log("Unable to find member by track mid ", mid);
            return;
        }
        let trackInfo = memberState.tracks.get(mid);
        if (!trackInfo) {
            trackInfo = {
                mid: mid
            }
            memberState.tracks.set(trackInfo.mid, trackInfo);
        }
        trackInfo.streamName = stream.id;
        trackInfo.mediaStreamTrack = track;
        memberState.streams.set(stream.id, stream);
        console.log(`Member ${memberState.accountId} after track added: `, memberState)
    }

    public unpauseCameraIncomingTracks(): Array<messages.PreferredConsumedTrackInfo> {
        let result: Array<messages.PreferredConsumedTrackInfo> = []
        for (const member of this.members.values()) {
            for (const track of member.tracks.values()) {
                if (track.paused) {
                    if (track.src && 'vid' in track.src && track.src.vid === messages.VideoSource.Screen) {
                        continue;
                    }

                    if (track.src && 'aud' in track.src && track.src.aud === messages.AudioSource.Screen) {
                        continue;
                    }

                    result.push({
                        mid: track.mid,
                        pause: false,
                    })
                }
            }
        }
        return result;
    }

    public get viewState(): Array<MemberViewState> {
        let result = [];
        for (let member of this.members.values()) {
            let cameraStreamState: StreamViewState | undefined = undefined
            let screenStreamState: StreamViewState | undefined = undefined

            for (let stream of member.streams.values()) {
                let isScreenStream = false
                let audioTrack: AudioTrackViewState | undefined = undefined
                for (const streamTrack of stream.getAudioTracks()) {
                    for (const track of member.tracks.values()) {
                        if (streamTrack === track.mediaStreamTrack) {
                            let src: undefined | messages.MediaSourceA;
                            if (track.src && 'aud' in track.src) {
                                src = track.src
                            }
                            audioTrack = {
                                mid: track.mid,
                                src: src,
                                paused: track.paused,
                                score: track.score,
                                status: track.status,
                            };

                            if (src?.aud === messages.AudioSource.Screen) {
                                isScreenStream = true
                            }
                            break;
                        }
                    }
                }

                let videoTrack: VideoTrackViewState | undefined = undefined
                for (const streamTrack of stream.getVideoTracks()) {
                    for (const track of member.tracks.values()) {
                        if (streamTrack === track.mediaStreamTrack) {
                            let src: undefined | messages.MediaSourceV;
                            if (track.src && 'vid' in track.src) {
                                src = track.src
                            }
                            videoTrack = {
                                mid: track.mid,
                                src: src,
                                paused: track.paused,
                                score: track.score,
                                status: track.status,
                                forwarded_layer: track.forwarded_layer,
                            };
                            if (src?.vid === messages.VideoSource.Screen) {
                                isScreenStream = true
                            }
                            break;
                        }
                    }
                }
                if (isScreenStream) {
                    screenStreamState = {
                        mediaStream: stream,
                        audioTrack: audioTrack,
                        videoTrack: videoTrack,
                    }
                } else {
                    cameraStreamState = {
                        mediaStream: stream,
                        audioTrack: audioTrack,
                        videoTrack: videoTrack,
                    }
                }
            }
            result.push({
                id: member.accountId,
                displayName: member.displayName,
                cameraStream: cameraStreamState,
                screenStream: screenStreamState,
            })
        }
        return result;
    }

    public clear() {
        this.members.clear()
    }
}