import React from 'react';
import {
    Stack,
    Box
} from '@mui/material';

import SentAudioStatisticsView from './SentAudioStatisticsView';
import SentVideoStatisticsView from './SentVideoStatisticsView';
import { OutgoingMediaStatViewState } from '../client/Domain';

type Props = OutgoingMediaStatViewState & {
}

export class SentMediaStatisticsView extends React.Component<Props, {}> {
    render() {
        return (<Box sx={{ color: 'white' }} className="sent-media-statistics-view">
            <Stack>
                {this.props.audioTrackStat ? <SentAudioStatisticsView {...this.props.audioTrackStat} /> : null}
                {this.props.videoTrackStat ? <SentVideoStatisticsView {...this.props.videoTrackStat} /> : null}
            </Stack>
        </Box>)
    }
}