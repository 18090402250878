import React from 'react'
import { makeStyles } from '@mui/styles';
import {
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';

import { OutgoingVideoTrackStatViewState } from '../client/Domain'

const useStyles = makeStyles({
    customTable: {
        "& .MuiTableCell-sizeSmall": {
            padding: "0px 0px 0px 4px",
            fontSize: "xx-small",
            lineHeight: "1.25",
            color: 'white',
        }
    },
});

type Props = OutgoingVideoTrackStatViewState & {
}

const SentVideoStatisticsView = (props: Props) => {
    const styles = useStyles();

    const layerMetrics = props.layers.map(layer => {
        const pairs = ([
            { name: 'codec (pt)', value: `${layer.codec} (${layer.codecPayloadType})` },
            { name: 'rid [ssrc, rtx]', value: `${layer.rid || 'n/a'} [${layer.ssrc}, ${layer.rtxSsrc || '???'}]` },
            { name: 'res@fps sent', value: `${layer.width}x${layer.height}@${layer.framesSent.derivative.toFixed(0)}` },
            { name: 'video bytes sent', value: `${(8 * layer.bytesSent.derivative / 1024).toFixed(1)} KiB/s; ${(layer.bytesSent.total / 1024 / 1024).toFixed(1)} MiB`},
            { name: 'header bytes sent', value: `${(8 * layer.headerBytesSent.derivative / 1024).toFixed(1)} KiB/s; ${(layer.headerBytesSent.total / 1024 / 1024).toFixed(1)} MiB`},
            { name: 'rtx bytes sent', value: `${(layer.retransmittedBytesSent.derivative / 1024).toFixed(1)} KiB/s; ${(layer.retransmittedBytesSent.total / 1024).toFixed(1)} KiB`},
            { name: 'packets sent', value: `${layer.packetsSent.derivative.toFixed(0)} pps; ${layer.packetsSent.total} p`},
            { name: 'rtx packets sent', value: `${layer.retransmittedPacketsSent.derivative.toFixed(0)} pps; ${layer.retransmittedPacketsSent.total} p` },
            { name: "bytes discarded", value: `${(layer.bytesDiscardedOnSend.derivative / 1024).toFixed(1)} KiB/s; ${layer.bytesDiscardedOnSend.total.toFixed(2)} KiB`},
            { name: "packets discarded", value: `${layer.packetsDiscardedOnSend.derivative.toFixed(0)} pps; ${layer.packetsDiscardedOnSend.total} p`},
            { name: 'frames sent', value: `${layer.framesSent.derivative.toFixed(0)} fps; ${layer.framesSent.total} f` },
            { name: 'huge frames sent', value: `${layer.hugeFramesSent.derivative.toFixed(0)} fps; ${layer.hugeFramesSent.total} f`},
            { name: 'frame encoded', value: `${layer.framesEncoded.derivative.toFixed(0)} fps; ${layer.framesEncoded.total} f; ${layer.encodeTime.msPerFrame.toFixed(1)} ms/f` },
            { name: 'key frame encoded', value: `${layer.keyFramesEncoded.derivative.toFixed(0)} fps; ${layer.keyFramesEncoded.total} f` },
            { name: 'fir/pli/nack received', value: `${layer.firCount.derivative.toFixed(0)} rps; ${layer.firCount.total} r / ${layer.pliCount.derivative.toFixed(0)} rps; ${layer.pliCount.total} r / ${layer.nackCount.derivative.toFixed(0)} rps; ${layer.nackCount.total} r`},
            { name: `quality limitation`, value: `${layer.qualityLimitationReason} [no: ${(layer.qualityLimitationDuration.none).toFixed(0)}s; cpu: ${(layer.qualityLimitationDuration.cpu).toFixed(0)}s; net: ${(layer.qualityLimitationDuration.bandwidth).toFixed(0)}s, oth: ${(layer.qualityLimitationDuration.other).toFixed(0)}s]`}
        ])
        if (layer.jitter !== null && layer.rtt !== null && layer.estimatedPacketsLoss !== null) {
            pairs.push({ name: "rtcp", value: `jitter: ${ (1000 * layer.jitter.value).toFixed(1) } ms; rtt: ${ ( 1000 * layer.rtt.current).toFixed(1) } ms, avg ${ (1000 * layer.rtt.average).toFixed(1) } ms; loss: ${layer.estimatedPacketsLoss.packetsLost} p, ${layer.estimatedPacketsLoss.fractionLost} %` });
        } else {
            pairs.push({ name: "rtcp", value: `n/a` })
        }
        return pairs;
    });
    const metricsPerLayer: Array<{name: string, values: Array<string>}> = [];
    for (let i = 0; layerMetrics.length > 0 && i < layerMetrics[0].length; ++i) {
        metricsPerLayer.push({
            name: layerMetrics[0][i].name,
            values: layerMetrics.map(m => m[i].value)
        })
    }

    return (<Box>
        <Table size="small" classes={{ root: styles.customTable }}>
            <TableBody>
                {metricsPerLayer.map((pair) => {
                    return (<TableRow key={pair.name}>
                        <TableCell>{pair.name}</TableCell>
                        {pair.values.map((val, i) => {
                            return (<TableCell key={i}>{val}</TableCell>)
                        })}
                    </TableRow>)
                })}
            </TableBody>
        </Table>
    </Box>)
}

export default SentVideoStatisticsView;