import { IcePolicy, RTCIceServerWithHostname } from "./client/Domain";


export async function getGarconUrl(): Promise<string | null> {
    const response = await fetch("/static/garcon_url.txt");
    if (response.ok) {
        const garconUrl = await response.text();
        console.log(`Garcon url: ${garconUrl}`);
        return garconUrl;
    } else {
        console.error(`Request failed with status: ${response.status}`);
        return null;
    }
}

export function filterServersByPolicy(params: {
  servers: Array<RTCIceServerWithHostname>,
  icePolicy: IcePolicy,
}): Array<RTCIceServerWithHostname> {
  function filterByPolicy(urls: Array<string> | string, icePolicy: IcePolicy): Array<string> {
      function filter(url: string): boolean {
        switch (icePolicy) {
          case IcePolicy.All: return true;
          case IcePolicy.Relay: return !url.startsWith("stun");
          case IcePolicy.TcpRelay: return url.includes("transport=tcp") && !url.startsWith("turns");
          case IcePolicy.UdpRelay: return url.includes("transport=udp") && !url.startsWith("turns");
          case IcePolicy.TlsRelay: return url.includes("transport=tcp") && url.startsWith("turns");
          case IcePolicy.DtlsRelay: return url.includes("transport=udp") && url.startsWith("turns");
          default: return false;
        }
      }

      if (typeof(urls) === 'string') {
        return filter(urls) ? [ urls ] : [];
      } else {
        return urls.filter(filter);
      }
  }

  return params.servers.flatMap(server => {
      const urls = filterByPolicy(server.urls, params.icePolicy);
      if (urls.length === 0) {
          return [];
      } else {
          return [
              {
                ...server,
                urls
              }
          ];
      }
  });
}

export function randomString() {
    function getRandomByte() {
      return Math.floor(Math.random() * 256);
    }
  
    const bytes = Uint8Array.from([
      getRandomByte(),
      getRandomByte(),
      getRandomByte(),
      getRandomByte(),
      getRandomByte(),
      getRandomByte(),
      getRandomByte(),
      getRandomByte(),
    ])
  
    function bytesToBase64(bytes: Uint8Array) {
      const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join("");
      return btoa(binString);
    }
    
    return bytesToBase64(bytes);
  }