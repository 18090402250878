import React from 'react'
import { makeStyles } from '@mui/styles';
import {
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import { OutgoingAudioTrackStatViewState } from '../client/Domain';

const useStyles = makeStyles({
    customTable: {
        "& .MuiTableCell-sizeSmall": {
            padding: "0px 0px 0px 4px",
            fontSize: "xx-small",
            lineHeight: "1.25",
            color: 'white',
        }
    },
});

type Props = OutgoingAudioTrackStatViewState & {
}

const SentAudioStatisticsView = (props: Props) => {
    const styles = useStyles();

    const pairs = [
        { name: "codec (pt)", value: `${props.codec} (${props.codecPayloadType})`},
        { name: "ssrc", value: `${props.ssrc} (0x${props.ssrc.toString(16)})`},
        { name: "level/echo ret loss", value: `${props.audioLevel.value.toFixed(2)} / ${props.echoReturnLoss.value.toFixed(2)}`},
        { name: "audio bytes sent", value: `${(8 * props.bytesSent.derivative / 1024).toFixed(2)} KiB/s; ${(props.bytesSent.total / 1024 / 1024).toFixed(2)} MiB`},
        { name: "headers bytes sent", value: `${(8 * props.headerBytesSent.derivative / 1024).toFixed(2)} KiB/s; ${(props.headerBytesSent.total / 1024 / 1024).toFixed(2)} MiB`},
        { name: "rtx bytes sent", value: `${props.retransmittedPacketsSent.derivative.toFixed(0)} B/s; ${(props.retransmittedBytesSent.total / 1024).toFixed(0)} KiB`},
        { name: "packets sent", value: `${props.packetsSent.derivative.toFixed(0)} pps; ${props.packetsSent.total.toFixed(0)} p`},
        { name: "nack received", value: `${props.nackCount.derivative.toFixed(0)} rps; ${props.nackCount.total.toFixed(0)} r`},
        { name: "bytes discarded", value: `${(props.bytesDiscardedOnSend.derivative / 1024).toFixed(1)} KiB/s; ${props.bytesDiscardedOnSend.total.toFixed(2)} KiB`},
        { name: "packets discarded", value: `${props.packetsDiscardedOnSend.derivative.toFixed(0)} pps; ${props.packetsDiscardedOnSend.total} p`},
    ];
    
    if (props.jitter !== null && props.rtt !== null && props.estimatedPacketsLoss !== null) {
        pairs.push({ name: "rtcp", value: `jitter: ${ (1000 * props.jitter.value).toFixed(1) } ms; rtt: ${ ( 1000 * props.rtt.current).toFixed(1) } ms, avg ${ (1000 * props.rtt.average).toFixed(1) } ms; loss: ${props.estimatedPacketsLoss.packetsLost} p, ${props.estimatedPacketsLoss.fractionLost} %` });
    }

    return (<Box>
        <Table size="small" classes={{ root: styles.customTable }}>
            <TableBody>
                {pairs.map(pair => {
                    return (<TableRow key={pair.name}>
                        <TableCell>
                            {pair.name}
                        </TableCell>
                        <TableCell>
                            {pair.value}
                        </TableCell>
                    </TableRow>)
                })}
            </TableBody>
        </Table>
    </Box>)
}

export default SentAudioStatisticsView;