import { ITestCase } from "./ITestCase";
import { getGarconUrl, randomString } from "../Utils";
import { Features, Services } from "./Modules";
import { KaldunTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { checkGarconUrl, checkTicket, joinToKaldunRoomAndReturnState, tryFetchTicket } from "./Utils";
import KaldunTicketSubject from "../client/TicketSubject";
import { SignalingState } from "../client/RoomSignaling";
import Delay from "../client/Delay";
import { IcePolicy } from "../client/Domain";


export default class JoinToPushToTalkWithReconnect implements ITestCase {
    readonly tags: Set<string> = new Set([
        Features.PushToTalk,
        Services.Garcon,
        Services.Kvashanina,
        Services.Kaldun,
        Services.Mediasoup,
    ]);
    readonly name: string = "Join to push to talk with reconnection";

    async callback(assert: Assert): Promise<void> {
        const garconUrl = checkGarconUrl(assert, await getGarconUrl());

        const client = new KaldunTicketsClient(garconUrl);

        for (let subject of [KaldunTicketSubject.Listen, KaldunTicketSubject.Talk, KaldunTicketSubject.TalkAndListen]) {
            const user = { accountId: randomString(), clientId: randomString(), roomId: randomString(), subject };

            const ticket = await tryFetchTicket(client, user);

            checkTicket(assert, {
                ticket: ticket?.getTicket(),
                ...user,
            });

            if (!ticket) {
                return;
            }

            const initial = await joinToKaldunRoomAndReturnState({assert, ticket, user, icePolicy: IcePolicy.All, subject});
            if (!initial) {
                return;
            }

            const reconnectTicket = await tryFetchTicket(client, user);

            checkTicket(assert, {
                ticket: reconnectTicket?.getTicket(),
                ...user,
            });

            if (!reconnectTicket) {
                return;
            }

            const reconnected = await joinToKaldunRoomAndReturnState({assert, ticket: reconnectTicket, user, icePolicy: IcePolicy.All, subject});
            if (!reconnected) {
                return;
            }

            await Delay(1000);

            assert.equal(initial.signalling.signalingState, SignalingState.Interrupted, `Fisrt user session closed by signalling server after creation second session: room = ${user.roomId}, subject = ${subject}`);
            await initial.session.terminate();

            const leaveResult = await reconnected.signalling.leave();
            assert.true("leave_room" in leaveResult, `User leaved from room = ${user.roomId}, subject = ${subject} after reconnect`);
            await reconnected.session.terminate();
        }
    }
}
