import React from 'react'
import './App.css'
import RoomView from './components/RoomView'
import KaldunTicketSubject, { SciezkaTicketSubject } from './client/TicketSubject'

import { uniqueNamesGenerator, adjectives, colors, animals } from 'unique-names-generator';
import { IcePolicy } from './client/Domain'
import CallView from './components/CallView'
import { randomString } from './Utils';


type RoomViewState = {
  roomId: string,
  accountId: string,
  clientId: string,
  displayName: string,
  region: string | null,
  subject: KaldunTicketSubject,
  enableTcp: boolean,
  icePolicy: IcePolicy,
  maxCameraCaptureDimension: number | null
  allowAutomaticSimulcast: boolean | null,
  videoProducerBandwidthLimitKbps: number | null,
};

type CallViewState = {
  callId: string,
  accountId: string,
  clientId: string,
  subject: SciezkaTicketSubject,
  icePolicy: IcePolicy,
  maxCameraCaptureDimension: number | null,
  region: string | null,
}

type AppViewState = RoomViewState | CallViewState;



class App extends React.Component<{}, AppViewState> {

  constructor(props: any) {
    super(props);

    const query = new URLSearchParams(window.location.search);

    const maxCameraCaptureDimension = query.get("max_capture_dim");
    const icePolicy = (query.get("ice_policy") ?? "all") as IcePolicy;
    const clientId = query.get("client_id") ?? randomString();
    const accountId = query.get("account_id") ?? randomString();
    const displayName = query.get("name") ?? uniqueNamesGenerator({
      dictionaries: [colors, adjectives, animals],
      length: 3,
      separator: ' ',
      style: 'capital'
    });

    const region = query.get("region");

    if (query.has("call")) {
      const callId = query.get("call") ?? "sample";
      let subject = query.get("subject")?.toLowerCase() || "view";
      let normalizedSubject: SciezkaTicketSubject = Object.values<string>(SciezkaTicketSubject).includes(subject) ? (subject as SciezkaTicketSubject) : SciezkaTicketSubject.View

      this.state = {
        callId,
        clientId,
        accountId,
        subject: normalizedSubject,
        icePolicy,
        maxCameraCaptureDimension: maxCameraCaptureDimension !== null ? Number.parseInt(maxCameraCaptureDimension) : null,
        region,
      };

    } else {
      const enableTcp = query.get("enable_tcp") === "1";
      const roomId = query.get("room") ?? "echo_room";
      const allowAutomaticSimulcast = query.has("allow_automatic_simulcast") 
        ? (["1", "true"].includes(String(query.get("allow_automatic_simulcast")).toLocaleLowerCase()))
        : null;
      
      const videoProducerBandwidthLimitKbps = query.get("video_producer_bandwidth_limit_kbps")
      let subject = query.get("subject")?.toLowerCase() || "broadcast_and_view";
      let normalizedSubject: KaldunTicketSubject = Object.values<string>(KaldunTicketSubject).includes(subject) ? (subject as KaldunTicketSubject) : KaldunTicketSubject.BroadcastAndView

      this.state = {
        roomId,
        displayName,
        clientId,
        accountId,
        subject: normalizedSubject,
        region,
        enableTcp,
        icePolicy,
        maxCameraCaptureDimension: maxCameraCaptureDimension !== null ? Number.parseInt(maxCameraCaptureDimension) : null,
        allowAutomaticSimulcast,
        videoProducerBandwidthLimitKbps: videoProducerBandwidthLimitKbps !== null ? Number.parseInt(videoProducerBandwidthLimitKbps) : null,
      };
    }
  }

  render() {
    if ("callId" in this.state) {
      return (
        <CallView 
          accountId={this.state.accountId} 
          clientId={this.state.clientId} 
          callId={this.state.callId}
          subject={this.state.subject}
          icePolicy={this.state.icePolicy}
          maxCameraCaptureDimension={this.state.maxCameraCaptureDimension}
          region={this.state.region}
          />
      )
    } else {
      return (
        <RoomView 
          accountId={this.state.accountId} 
          clientId={this.state.clientId} 
          displayName={this.state.displayName}
          region={this.state.region}
          roomId={this.state.roomId}
          subject={this.state.subject}
          enableTcp={this.state.enableTcp}
          icePolicy={this.state.icePolicy}
          maxCameraCaptureDimension={this.state.maxCameraCaptureDimension}
          allowAutomaticSimulcast={this.state.allowAutomaticSimulcast}
          videoProducerBandwidthLimitKbps={this.state.videoProducerBandwidthLimitKbps} />
      )
    }
  }
}

export default App
