import { randomString } from "../Utils";
import Delay from "../client/Delay";
import { IcePolicy, RTCIceServerWithHostname } from "../client/Domain";
import RoomSession from "../client/RoomSession";
import RoomSignaling, { SignalingState } from "../client/RoomSignaling";
import KaldunTicketSubject, { SciezkaTicketSubject } from "../client/TicketSubject";
import { KaldunTicketsClient, SciezkaTicketsClient } from "../generated/submodules/garcon-api/TicketsServiceClientPb";
import { RTCIceServer as ProtoRTCIceServer, RegionalIceServers, Ticket, TicketRequest, TicketResponse } from "../generated/submodules/garcon-api/tickets_pb";
import { sciezka_messages } from "../generated/submodules/sciezka-messages/client_messages";

export interface User {
    accountId: string,
    clientId: string,
    roomId: string,
}

export type UserWithSubject = User & { subject: KaldunTicketSubject };

export function checkGarconUrl(assert: Assert, garconUrl: string | null): string {
    assert.true(!!garconUrl, `rtc-garcon url received ${garconUrl}`)
    return garconUrl!;
}

function parseClaims(body: string): any | null {
    try {
        const parsedBody = atob(body);
        return JSON.parse(parsedBody);
    } catch (ex) {
        console.error(`cannot parse jwt body with ${ex}`);
        return null;
    }
}

export function connectSignalling(
    assert: Assert,
    endpoint: string,
    user: User,
    subject: KaldunTicketSubject
): Promise<RoomSignaling | null> {
    return new Promise(resolve => {
        const signalling = new RoomSignaling(0);
        const sub = signalling.signalingStateChanged.subscribe(state => {
            if (state === SignalingState.New) {
                return;
            }

            assert.equal(state, SignalingState.Connected, `User ${user.accountId} connected to signalling server in room = ${user.roomId} with subject = ${subject}`);
            sub.unsubscribe();
            resolve(state === SignalingState.Connected ? signalling : null);
        });
        signalling.connect(endpoint);
    });
}

export function generateNUsers(roomId: string, subject: KaldunTicketSubject, n: number): Array<UserWithSubject> {
    const users = [];
    for(let i = 0; i < n; i++) {
        users.push({
            roomId,
            accountId: randomString(),
            clientId: randomString(),
            subject,
        })
    }
    return users;
}

export async function tryFetchTicket(client: KaldunTicketsClient, args: {
    subject: KaldunTicketSubject,
} & User): Promise<Ticket | null> {
    const request = new TicketRequest();
    request.setAccountId(args.accountId);
    request.setClientId(args.clientId);
    request.setRoomId(args.roomId);

    async function call(client: KaldunTicketsClient, subject: KaldunTicketSubject, request: TicketRequest): Promise<TicketResponse> {
        switch(args.subject) {
            case KaldunTicketSubject.BroadcastAndView:
                return await client.getBroadcastAndViewTicket(request, null);
            case KaldunTicketSubject.Broadcast:
                return await client.getBroadcastTicket(request, null);
            case KaldunTicketSubject.View:
                return await client.getViewTicket(request, null);
            case KaldunTicketSubject.TalkAndListen:
                return await client.getTalkAndListenTicket(request, null);
            case KaldunTicketSubject.Talk:
                return await client.getTalkTicket(request, null);
            case KaldunTicketSubject.Listen:
                return await client.getListenTicket(request, null);
            case KaldunTicketSubject.AudioRoomListen:
                return await client.getAudioRoomListenTicket(request, null);
            case KaldunTicketSubject.AudioRoomTalk:
                return await client.getAudioRoomTalkTicket(request, null);
            case KaldunTicketSubject.DatingBroadcast:
                return await client.getDatingBroadcastTicket(request, null);
            case KaldunTicketSubject.DatingView:
                return await client.getDatingViewTicket(request, null);
            default: throw new Error(`Unknown subject: ${subject}`);
        }
    }
    
    const response = await call(client, args.subject, request);
    return response.getTicket() ?? null;
}

export async function tryFetchSciezkaTicket(client: SciezkaTicketsClient, args: {
    subject: SciezkaTicketSubject,
} & User): Promise<Ticket | null> {
    const request = new TicketRequest();
    request.setAccountId(args.accountId);
    request.setClientId(args.clientId);
    request.setRoomId(args.roomId);

    async function call(client: SciezkaTicketsClient, subject: SciezkaTicketSubject, request: TicketRequest): Promise<TicketResponse> {
        switch(args.subject) {
            case SciezkaTicketSubject.Broadcast:
                return await client.getBroadcastTicket(request, null);
            case SciezkaTicketSubject.View:
                return await client.getViewTicket(request, null);
            case SciezkaTicketSubject.Caller:
                return await client.getCallerTicket(request, null);
            case SciezkaTicketSubject.Callee:
                return await client.getCalleeTicket(request, null);
            default: throw new Error(`Unknown subject: ${subject}`);
        }
    }
    
    const response = await call(client, args.subject, request);
    return response.getTicket() ?? null;
}

export function tryFetchNTickets(client: KaldunTicketsClient, args: {
    users: Array<UserWithSubject>
}): Promise<Array<{ ticket: Ticket | null, user: UserWithSubject }>> {
    return Promise.all(args.users.map(async user => {
        const ticket = await tryFetchTicket(client, user);
        return { ticket, user };
    }));
}

export function checkTicket(
    assert: Assert,
    params: {
        ticket: string | undefined,
        subject: KaldunTicketSubject | SciezkaTicketSubject,
    } & User) {

    const { ticket, accountId, clientId, roomId, subject } = params;
    assert.true(!!ticket, `Ticket received for user: accountId = ${accountId}, clientId = ${clientId}, roomId = ${roomId}, subject = ${subject}`);

    if (!ticket) {
        return;
    }

    const jwtParts = ticket.split('.');
    assert.equal(jwtParts.length, 3, `JWT contain 3 parts(header, body, signature) for user: accountId = ${accountId}, clientId = ${clientId}, roomId = ${roomId}, subject = ${subject}`);

    const base64body = jwtParts[1];

    const jsonBody = parseClaims(base64body);

    assert.true(!!jsonBody, `JWT body parsed for user: accountId = ${accountId}, clientId = ${clientId}, roomId = ${roomId}, subject = ${subject}`);

    if (!jsonBody) {
        return;
    }

    assert.equal(jsonBody["account_id"], accountId, `account_id is equal for user: accountId = ${accountId}, clientId = ${clientId}, roomId = ${roomId}, subject = ${subject}`);
    assert.equal(jsonBody["client_id"], clientId, `client_id equal for user: accountId = ${accountId}, clientId = ${clientId}, roomId = ${roomId}, subject = ${subject}`);
    assert.equal(jsonBody["room"], roomId, `room_id is equal for user: accountId = ${accountId}, clientId = ${clientId}, roomId = ${roomId}, subject = ${subject}`);
    assert.equal(jsonBody["sub"], subject, `subject is equal for user: accountId = ${accountId}, clientId = ${clientId}, roomId = ${roomId}, subject = ${subject}`);
}

export async function checkConnectionToSignallingServer(
    assert: Assert,
    ticket: Ticket,
    subject: SciezkaTicketSubject | KaldunTicketSubject
) {
    const url = (subject === SciezkaTicketSubject.Caller || subject === SciezkaTicketSubject.Callee)
        ? (`${ticket.getEndpoint()}?ticket=${ticket.getTicket()}`)
        : ticket.getEndpoint();

    const connection = new WebSocket(url);

    function waitConnectOrTimeout(connection: WebSocket): Promise<"ok" | "timeout"> {
        return new Promise(resolve => {
            connection.onopen = () => {
                resolve("ok");
            };

            setTimeout(() => {
                if (connection.readyState !== WebSocket.OPEN) {
                    resolve("timeout");
                }
            }, 1000);
        });
    }
    
    const connectResult = await waitConnectOrTimeout(connection);

    assert.equal(connectResult, "ok", `Client with subject = ${subject} connected without timeout: ${ticket.getEndpoint()}`);

    connection.close();
}

export function checkSignallingServer(
    assert: Assert,
    tickets: (string | undefined)[],
) {
    const signallingServers = tickets.map(ticket => {
        if (!ticket) {
            return null;
        }

        const jwtParts = ticket.split('.');
        const base64body = jwtParts[1];
        const jsonBody = parseClaims(base64body);

        if (!jsonBody) {
            return null;
        }

        return { server: jsonBody["aud"] ?? null, roomSessionId: jsonBody["room_session_id"] ?? null };
    });

    signallingServers.forEach((server, index) => {
        assert.true(!!server?.server, `Server identificator exists for user number ${index}`);
        assert.true(!!server?.roomSessionId, `Room session id exists for user number ${index}`);
    });

    assert.equal(new Set(signallingServers.map(x => x?.server)).size, 1, "All tickets contain the same signalling server");
    assert.equal(new Set(signallingServers.map(x => x?.roomSessionId)).size, 1, "All tickets contain the same room session id");
}

export async function checkRegionalCoturnServers(
    assert: Assert,
    subject: KaldunTicketSubject | SciezkaTicketSubject,
    initiatorServers: Array<RegionalIceServers>,
    receiverServers: Array<RegionalIceServers>,
    icePolicies: Array<IcePolicy>,
): Promise<void> {
    assert.true(
        initiatorServers.length > 0,
        `Subejct = ${subject}: Number of initiator ice servers lists more than 0`
    );

    assert.true(
        receiverServers.length > 0,
        `Subejct = ${subject}: Number of receiver ice servers lists more than 0`
    );

    for(let initiatorRegionalServers of initiatorServers) {
        for(let initiatorIcePolicy of icePolicies) {
            for(let receiverRegionalServers of receiverServers) {
                for(let receiverIcePolicy of icePolicies) {
                    await checkRelayConnection(
                        assert,
                        subject,
                        {
                            servers: initiatorRegionalServers.getIceServersList(),
                            icePolicy: initiatorIcePolicy,
                            region: initiatorRegionalServers.getRegion() ?? "Empty",
                        },
                        {
                            servers: receiverRegionalServers.getIceServersList(),
                            icePolicy: receiverIcePolicy,
                            region: receiverRegionalServers.getRegion() ?? "Empty",
                        },
                    );
                }
            }            
        }
    }
}

function filterServersByPolicy(params: {
    servers: Array<ProtoRTCIceServer>,
    icePolicy: IcePolicy,
}): Array<RTCIceServerWithHostname> {
    function filterByPolicy(urls: Array<string>, icePolicy: IcePolicy): Array<string> {
        return urls.filter(x => {
            switch (icePolicy) {
                case "all": return true;
                case "relay": return !x.startsWith("stun");
                case "tcp-relay": return x.includes("transport=tcp") && !x.startsWith("turns");
                case "udp-relay": return x.includes("transport=udp") && !x.startsWith("turns");
                case "tcp-tls-relay": return x.includes("transport=tcp") && x.startsWith("turns");
                case "udp-tls-relay": return x.includes("transport=udp") && x.startsWith("turns");
                default: return false;
              }
        });
    }

    return params.servers.flatMap(server => {
        const urls = filterByPolicy(server.getUrlsList(), params.icePolicy);
        if (urls.length === 0) {
            return [];
        } else {
            return [
                {
                    credential: server.hasCredential() ? server.getCredential() : undefined,
                    urls: urls,
                    username: server.hasUsername() ? server.getUsername() : undefined,
                    hostname: server.getHostname(),
                }
            ];
        }
    });
}

async function checkRelayConnection(
    assert: Assert,
    subject: KaldunTicketSubject | SciezkaTicketSubject,
    inititatorServers: { servers: Array<ProtoRTCIceServer>, icePolicy: IcePolicy, region: string },
    receiverServers: { servers: Array<ProtoRTCIceServer>, icePolicy: IcePolicy, region: string },
): Promise<void> {

    const initiatorIceServers = filterServersByPolicy(inititatorServers);
    const receiverIceServers = filterServersByPolicy(receiverServers);

    assert.true(
        initiatorIceServers.length > 0,
        `Initiator: subejct = ${subject}, region = ${inititatorServers.region}, icePolicy = ${inititatorServers.icePolicy} servers count > 0`
    );
    assert.true(
        receiverIceServers.length > 0,
        `Receiver: subejct = ${subject}, region = ${receiverServers.region}, icePolicy = ${receiverServers.icePolicy} servers count > 0`
    );

    return new Promise(async resolve => {
        const connectionInitiator = new RTCPeerConnection({
            bundlePolicy: "max-bundle",
            rtcpMuxPolicy: "require",
            iceServers: initiatorIceServers,
            iceTransportPolicy: "relay",
        });
    
        const connectionReceiver = new RTCPeerConnection({
            bundlePolicy: "max-bundle",
            rtcpMuxPolicy: "require",
            iceServers: receiverIceServers,
            iceTransportPolicy: "relay",
        });
    
        connectionInitiator.createDataChannel("test-data-channel");

        let tries = 10;
        let negotiated = false;
        let initiatorCandidates : Array<RTCIceCandidate> = [];
        let receiverCandidates : Array<RTCIceCandidate> = [];
        connectionInitiator.onicecandidate = async ev => {
            if (ev.candidate) {
                if (negotiated) {
                    await connectionReceiver.addIceCandidate(ev.candidate);
                } else {
                    initiatorCandidates.push(ev.candidate);
                }
            }
        };

        connectionReceiver.onicecandidate = async ev => {
            if (ev.candidate) {
                if (negotiated) {
                    await connectionInitiator.addIceCandidate(ev.candidate);
                } else {
                    receiverCandidates.push(ev.candidate);
                }
            }
        };

        while (tries > 0) {
            console.info(`Tries left ${tries}`);
            await connectionInitiator.setLocalDescription();
            await connectionReceiver.setRemoteDescription(connectionInitiator.localDescription!);
            await connectionReceiver.setLocalDescription();
            await connectionInitiator.setRemoteDescription(connectionReceiver.localDescription!);
            negotiated = true;

            await Promise.all([
                Promise.all(initiatorCandidates.map(x => connectionReceiver.addIceCandidate(x))),
                Promise.all(receiverCandidates.map(x => connectionInitiator.addIceCandidate(x))),
            ]);

            await Delay(1000);

            if (connectionReceiver.connectionState === "connected") {
                assert.true(
                    true,
                    `Initiator peer connection connected (subejct = ${subject}): Initiator region = ${inititatorServers.region}, icePolicy = ${inititatorServers.icePolicy}; Receiver region = ${receiverServers.region}, icePolicy = ${receiverServers.icePolicy};`,
                )
                connectionReceiver.close();
                connectionInitiator.close();
                resolve();
                return;
            } else {
                connectionInitiator.restartIce();
                initiatorCandidates = [];
                receiverCandidates = [];
                negotiated = false;
                tries--;
            }
        }
        assert.true(
            false,
            `Initiator peer connection connected (subejct = ${subject}): Initiator region = ${inititatorServers.region}, icePolicy = ${inititatorServers.icePolicy}; Receiver region = ${receiverServers.region}, icePolicy = ${receiverServers.icePolicy};`,
        )
    });
}

export async function joinToKaldunRoom({
    assert,
    ticket,
    user,
    subject,
    icePolicy = IcePolicy.All,
}: {
    assert: Assert,
    ticket: Ticket,
    user: User,
    subject: KaldunTicketSubject,
    icePolicy: IcePolicy
}): Promise<void> {

    const result = await joinToKaldunRoomAndReturnState({
        assert,
        ticket,
        user,
        icePolicy,
        subject,
    });
    if (!result) {
        return;
    }
    const {session, signalling} = result;
    const leaveResult = await signalling.leave();
    assert.true("leave_room" in leaveResult, `User leaved from room = ${user.roomId}, subject = ${subject}, icePolicy = ${icePolicy}`);
    await session.terminate();
}

export async function joinToKaldunRoomAndReturnState({
    assert,
    ticket,
    user,
    subject,
    icePolicy = IcePolicy.All,
}: {
    assert: Assert,
    ticket: Ticket,
    user: User,
    subject: KaldunTicketSubject,
    icePolicy: IcePolicy
}): Promise<{ session: RoomSession, signalling: RoomSignaling } | null> {
    const signalling = await connectSignalling(assert, ticket.getEndpoint(), user, subject);

    if (!signalling) {
        return null;
    }

    const session = new RoomSession({
        roomId: user.roomId,
        icePolicy: icePolicy,
        enableScreenShare: false,
        ticketSubject: subject,
        iceServers: ticket.getIceServersList().flatMap(x => x.getIceServersList().map(y => {
            return {
                credential: y.hasCredential() ? y.getCredential() : undefined,
                username: y.hasUsername() ? y.getUsername() : undefined,
                urls: y.getUrlsList(),
                hostname: y.getHostname(),
            }
        })),
    });

    const offer = await session.createInitialOffer();
    const joinResult = await signalling.join({ offer });

    assert.true("join_room_v2" in joinResult, `User joined to room = ${user.roomId}, subject = ${subject}`);

    if ("join_room_v2" in joinResult) {
        assert.true(await session.applyProducedSdpOffer(joinResult.join_room_v2.sdp_offer), `Aplied produced sdp offer in room = ${user.roomId}, subject = ${subject}`);
        assert.true(await session.applyProducedSdpAnswer(joinResult.join_room_v2.sdp_answer), `Aplied produced sdp answer in room = ${user.roomId}, subject = ${subject}`);
        if (joinResult.join_room_v2.sdp_server_offer) {
            assert.true(await session.applyConsumedSdpOffer(joinResult.join_room_v2.sdp_server_offer), `Aplied consumed sdp answer in room = ${user.roomId}, subject = ${subject}`);
        }
    };

    return { session, signalling, };
}


export function createIceCandidatesMessage(iceCandidates: Array<RTCIceCandidateInit>): sciezka_messages.IceCandidates {
    return new sciezka_messages.IceCandidates({
        ice_candidates: iceCandidates.map(x => new sciezka_messages.IceCandidate({
            candidate: x.candidate ?? undefined,
            sdp_mid: x.sdpMid ?? undefined,
            sdp_m_line_index: x.sdpMLineIndex ?? undefined,
            username_fragment: x.usernameFragment ?? undefined,
        })),
    });
}

export function createFollowerHello(offer: string, transceivers: {
    mic: string,
    camera: string,
    screenAudio: string,
    screenVideo: string,
}): sciezka_messages.FollowerHello {
    return new sciezka_messages.FollowerHello({
        sdp_offer: offer,
        transceivers: [
            new sciezka_messages.TransceiverEntry({
                mid: transceivers.mic,
                source: sciezka_messages.TrackSource.MIC,
            }),
            new sciezka_messages.TransceiverEntry({
                mid: transceivers.camera,
                source: sciezka_messages.TrackSource.CAMERA,
            }),
            new sciezka_messages.TransceiverEntry({
                mid: transceivers.screenAudio,
                source: sciezka_messages.TrackSource.SCREEN_AUDIO,
            }),
            new sciezka_messages.TransceiverEntry({
                mid: transceivers.screenVideo,
                source: sciezka_messages.TrackSource.SCREEN_VIDEO,
            }),
        ],
        initial_track_states: [
            new sciezka_messages.TrackInitialState({
                direction: sciezka_messages.TrackDirection.IN,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.MIC,
                direction: sciezka_messages.TrackDirection.IN,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                direction: sciezka_messages.TrackDirection.IN,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                direction: sciezka_messages.TrackDirection.IN,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.CAMERA,
                direction: sciezka_messages.TrackDirection.OUT,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.MIC,
                direction: sciezka_messages.TrackDirection.OUT,
                state: sciezka_messages.TrackState.ON,
            }),                        
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                direction: sciezka_messages.TrackDirection.OUT,
                state: sciezka_messages.TrackState.OFF,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                direction: sciezka_messages.TrackDirection.OUT,
                state: sciezka_messages.TrackState.OFF,
            }),
        ],
    });
}

export function createLeaderHello(answer: string): sciezka_messages.LeaderHello {
    return new sciezka_messages.LeaderHello({
        sdp_answer: answer,
        initial_track_states: [
            new sciezka_messages.TrackInitialState({
                direction: sciezka_messages.TrackDirection.IN,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.MIC,
                direction: sciezka_messages.TrackDirection.IN,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                direction: sciezka_messages.TrackDirection.IN,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                direction: sciezka_messages.TrackDirection.IN,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.CAMERA,
                direction: sciezka_messages.TrackDirection.OUT,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.MIC,
                direction: sciezka_messages.TrackDirection.OUT,
                state: sciezka_messages.TrackState.ON,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.SCREEN_VIDEO,
                direction: sciezka_messages.TrackDirection.OUT,
                state: sciezka_messages.TrackState.OFF,
            }),
            new sciezka_messages.TrackInitialState({
                source: sciezka_messages.TrackSource.SCREEN_AUDIO,
                direction: sciezka_messages.TrackDirection.OUT,
                state: sciezka_messages.TrackState.OFF,
            }),
        ],
    });
}

export async function mockVideoStream(): Promise<{ mediaStream: MediaStream }> {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
            aspectRatio: {
                ideal: 16.0 / 9.0
            }
        }
    })
    return { mediaStream }
}

export async function mockAudioStream(): Promise<{ mediaStream: MediaStream }> {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
    })
    return { mediaStream }
}